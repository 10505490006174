import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="block block__single block__single__legal">
      <div className="legal privacy-policy">
        <h2>Política de privacidad</h2>
        <h3>1. INTRODUCCIÓN</h3>
        <p>
          Su privacidad es importante para nosotros, así que, mediante este
          documento, le explicamos qué datos recogemos de los usuarios, como los
          utilizamos y para qué, entre otras indicaciones.
        </p>
        <p>
          Para su información, nunca pediremos más información de la que
          realmente necesitaremos para los servicios requeridos; nunca la
          compartiremos con terceros, excepto por obligación legal o con su
          autorización previa; y nunca utilizaremos sus datos para otra
          finalidad no indicada previamente.
        </p>
        <p>
          Como usuarios, deberá leer atentamente esta Política de privacidad de
          manera regular, y siempre que introduzca sus datos personales, ya que
          este documento puede sufrir modificaciones ya que el prestador puede
          modificar cualquier tipo de información que pudiera aparecer en la web
          para cumplir con la normativa o actualizar la política, y sin que
          exista la obligación de preavisar o poner en conocimiento de los
          usuarios dichas obligaciones, siendo suficientes la publicación en el
          sitio web del prestador.
        </p>
        <h3>2. RESPONSABLE DEL TRATAMIENTO:</h3>
        <p>
          Identidad: <b>TOT COMERCIAL, S.A.</b> (también el prestador)
          <br />
          NIF: <b>A25031154</b>
          <br />
          Dirección postal: <b>Partida Horta Amunt s/n, 25600, Balaguer</b>
          <br />
          Correo electrónico:{' '}
          <b>
            <a target="_blank" href="mailto:gespasa@gespasa.es">
              gespasa@gespasa.es
            </a>
          </b>
        </p>
        <p>
          <b>TOT COMERCIAL, S.A.</b>, como responsable del sitio web y
          aplicación web, de conformidad con lo dispuesto en el Reglamento (UE)
          2016/679 de 27 de abril de 2016 (RGPD) relativo a la protección de las
          personas físicas en lo que respecta al tratamiento de datos personales
          ya la libre circulación de estos datos y demás normativa vigente en
          materia de protección de datos personales, y por la Ley 34/2002, de 11
          de julio, de Servicios de la Sociedad de la Información y del Comercio
          Electrónico (LSSICE), le informa que tiene implementadas las medidas
          de seguridad necesarias, de índole técnica y organizativas, para
          garantizar y proteger la confidencialidad, integridad y disponibilidad
          de los datos introducidos.
        </p>
        <p>
          En relación a los datos de los usuarios contenidos dentro de la
          aplicación web (de ahora en adelante <b>DIESELPLUS</b>),{' '}
          <b>TOT COMERCIAL, S.A.</b>
          será el encargado del tratamiento porqué presta el servicio de gestión
          de la plataforma para el control de existencias de los carburantes,
          lubricantes u otros productos que pueden medirse a través de equipos
          compatibles con DIESELPLUS, y para el control de la flota a través de
          sus consumos de combustible u otros productos y de su posicionamiento.
          La plataforma muestra estos contenidos como listas, informes, alarmas,
          estadísticas o ser enviados a través de correo electrónico a los
          usuarios. Entre ambas partes existe el contrato de encargado de
          tratamiento debidamente firmado.
        </p>
        <h3>3. MÉTODO DE OBTENCIÓN DE LOS DATOS y DATOS QUE RECAUDAMOS:</h3>
        <p>Los datos que recogemos y cómo las obtenemos es la siguiente:</p>
        <p>
          1. Mediante el formulario de contacto: nombre, empresa, ubicación,
          email, teléfono
          <br />
          2. Mediante el formulario de recogida de datos para el registro y
          contratación: nombre, empresa, ubicación, email, teléfono, datos
          bancarios o información sobre una tarjeta de crédito o de débito y la
          información para la configuración de la cuenta según las licencias
          contratadas: depósitos, productos, equipos de medición o sondas de
          nivel.
          <br />
          3. Mediante formularios en <b>DIESELPLUS</b>: datos relacionados con
          la cuenta del Usuario para la identificación del personal y vehículos
          autorizados al repostaje, información acerca del almacenaje y
          movimientos de los carburantes, lubricantes u otros productos.
          <br />
          4. Mediante comunicación directa con los equipos compatibles o
          plataformas de integración: recepción telemática de repostajes en los
          equipos de suministro, lecturas de nivel, lecturas de consumo,
          posicionamiento e información de la flota de vehículos.
        </p>
        <p>No se tratan categorías de datos especialmente protegidos.</p>
        <h3>4. FINALIDAD DEL TRATAMIENTO:</h3>
        <p>
          Sus datos personales sólo se utilizarán para las siguientes
          finalidades, prestando su consentimiento para cada una de las
          finalidades en el apartado de la web o documento correspondiente:
          <br />
          1. <b>Formulario de contacto:</b> para responder a las consultas y / o
          proporcionar informaciones requeridas por el usuario;
          <br />
          2. <b>Formulario de registro y contratación:</b> para la creación y
          configuración de una cuenta en <b>DIESELPLUS</b> y poder acceder a los
          servicios de la aplicación web. Incluye información bancaria y forma
          de pago, para gestionar la contratación online y renovación de las
          licencias.
          <br />
          3. <b>Formularios DIESELPLUS:</b>
          <ul>
            <li>
              Gestión del personal y flota de vehículos autorizados al
              repostaje.
            </li>
            <li>
              Gestión del carburante, fluidos u otros productos de la
              organización.
            </li>
            <li>
              Gestión de los equipos de almacenaje y medición necesario para el
              funcionamiento de la aplicación.
            </li>
          </ul>
          4. <b>Comunicación directa con equipos compatibles:</b>
          <ul>
            <li>
              Gestión de movimientos y existencias del carburante, fluidos u
              otros productos de la organización
            </li>
            <li>
              Gestión de la información acerca del estado y ubicación de la
              flota de vehículos.
            </li>
            <li>
              Geolocalización: si se contrata el módulo de integración de
              aplicaciones de geolocalización de terceros compatibles, la
              ubicación del trabajador que forme parte de la flota será
              registrada por DIESELPLUS y actualizada regularmente cuando esta
              opción está activada. DIESELPLUS queda eximido de la
              responsabilidad de informar al trabajador o usuario. Esta
              responsabilidad recae sobre el cliente que contrata el servicio.
            </li>
          </ul>
          5. <b>Otras finalidades:</b>
          <ul>
            <li>
              Realizar las gestiones comerciales, administrativas, fiscales,
              contables que sean necesarias en virtud de la legislación vigente;
            </li>
            <li>
              Conocer su opinión y mejorar los servicios prestados por la web;
            </li>
          </ul>
          Puede revocar en cualquier momento su consentimiento remitiendo un
          escrito con el asunto "Baja" en el mail reseñado.
        </p>
        <p>
          De acuerdo con la LSSICE, <b>TOT COMERCIAL, S.A.</b> no realiza
          prácticas de SPAM, por lo tanto, no envía correos comerciales por
          e-mail que no hayan sido previamente solicitados o autorizados por el
          usuario. En consecuencia, en todas las comunicaciones que recibirá del
          prestador, el usuario tiene la posibilidad de cancelar su
          consentimiento expreso para recibir nuestras comunicaciones.
        </p>
        <p>
          No trataremos sus datos personales para ningún otro fin de las
          descritas excepto por obligación legal o requerimiento judicial.
        </p>
        <p>
          No será objeto de decisiones basadas en tratamientos automatizados que
          produzcan efectos sobre sus datos.
        </p>
        <h3>5. LEGITIMACIÓN DEL TRATAMIENTO</h3>
        <p>
          La base legal para el tratamiento de los datos es su consentimiento
          otorgado en el momento de dar sus datos en el formulario
          correspondiente para llevar a cabo las finalidades, en el momento de
          marcación de la casilla de verificación.
        </p>
        <p>
          El no facilitar los datos personales solicitados o el no aceptar la
          presente política de privacidad supone la imposibilidad de conseguir
          las finalidades anteriormente descritas.
        </p>
        <p>
          En los casos en que haya una previa relación contractual entre las
          partes, la legitimación para el desarrollo de las obligaciones
          administrativas, fiscales, contables y laborales que sean necesarias
          en virtud de la legislación vigente será{' '}
          <b>la existencia previa de la relación comercial</b> establecida entre
          las partes.
        </p>
        <p>
          La oferta prospectiva o comercial de productos y servicios está basada
          en el consentimiento que se le solicita, sin que en ningún caso la
          retirada de este consentimiento condicione la ejecución del contrato
          de suscripción.
        </p>
        <h3>6. PLAZO DE CONSERVACIÓN DE LOS DATOS:</h3>
        <p>
          Sus datos se conservarán mientras dure la relación comercial con
          nosotros o ejerce su derecho de cancelación u oposición, o limitación
          al tratamiento. Sin embargo, conservaremos sus datos en los plazos
          legalmente previstos en las disposiciones aplicables.
        </p>
        <p>
          Los datos proporcionados y almacenados a través de los formularios{' '}
          <b>DIESELPLUS</b> o sus equipos compatibles, están vinculados a las
          licencias de uso del usuario. Sus plazos de conservación pueden estar
          regulados por el contrato SAAS o los anexos con la información
          particular según las licencias contratadas.
        </p>
        <h3>7. DESTINATARIOS DE CESIONES O TRANSFERENCIAS:</h3>
        <p>
          <b>TOT COMERCIAL, S.A.</b> no lleva a cabo ninguna cesión o
          comunicación de datos ni dentro ni fuera de la UE. El prestador
          únicamente facilitará información a las fuerzas y cuerpos de seguridad
          bajo orden judicial o por obligación de una norma legal, sin perjuicio
          de poder bloquear o cancelar su cuenta si puede haber indicios de la
          comisión de algún delito por parte del usuario. La información
          facilitada será sólo la que disponga en ese momento el prestador.
        </p>
        <p>
          La información que nos proporcione tanto a través de este sitio web
          como a través de la aplicación web será alojada en los servidores de{' '}
          <b>TOT COMERCIAL, S.A.</b>, contratados a la empresa que presta el
          servicio de alojamiento de la web. El tratamiento de los datos de la
          entidad se encuentra regulado mediante un contrato de encargado del
          tratamiento entre el prestador y esta empresa.
        </p>
        <h3>8. DERECHOS DE LAS PERSONAS INTERESADAS:</h3>
        <p>
          Como usuario-interesado, puede solicitar el ejercicio de los
          siguientes derechos ante <b>TOT COMERCIAL, S.A.</b> presentando un
          escrito a la dirección postal del encabezamiento o enviando un correo
          electrónico a <a href="mailto:lopd@gespasa.es">lopd@gespasa.es</a>,
          indicando como Asunto: "PROTECCIÓN DE DATOS: DERECHOS DE LOS
          AFECTADOS".
        </p>
        <p>
          Derechos:
          <ul>
            <li>
              <b>Derecho de acceso:</b> permite al interesado conocer y obtener
              información sobre sus datos de carácter personal sometidos a
              tratamiento.
            </li>
            <li>
              <b>Derecho de rectificación o supresión:</b> permite corregir
              errores y modificar los datos que resulten ser inexactos o
              incompletos
            </li>
            <li>
              <b>Derecho de cancelación:</b> permite que se supriman los datos
              que sean inadecuados o excesivos
            </li>
            <li>
              <b>Derecho de oposición:</b> derecho del interesado a que no se
              lleve a cabo el tratamiento de sus datos de carácter personal o se
              cese en el mismo.
            </li>
            <li>
              <b>Limitación del tratamiento:</b> conlleva el marcado de los
              datos personales conservados, con el fin de limitar su futuro
              tratamiento, para el ejercicio o la defensa de reclamaciones.
            </li>
            <li>
              <b>Portabilidad de los datos:</b> facilitación de los datos objeto
              de tratamiento al interesado, para que éste pueda transmitir a
              otro responsable, sin impedimentos.
            </li>
            <li>
              <b>
                Derecho a no ser objeto de decisiones individuales automatizadas
                (incluida la elaboración de perfiles):
              </b>{' '}
              derecho a no ser objeto de una decisión basada en el tratamiento
              automatizado que produzca efectos o afecte significativamente
            </li>
          </ul>
        </p>
        <p>
          Como usuario, tiene derecho a retirar el consentimiento dado en
          cualquier momento. La retirada del consentimiento no afectará a la
          licitud del tratamiento efectuado antes de la retirada del
          consentimiento.
        </p>
        <p>
          También tiene derecho a presentar una reclamación ante la autoridad de
          control si considera que pueden haberse visto vulnerados sus derechos
          en relación a la protección de sus datos (
          <a target="_blank" href="http://www.aepd.es">
            www.aepd.es
          </a>
          ).
        </p>
        <h3>9. INFORMACIÓN ADDICIONAL</h3>
        <h4>MEDIDAS DE SEGURIDAD</h4>
        <p>
          Los datos que nos facilite se tratarán de forma confidencial. El
          prestador ha adoptado todas las medidas técnicas y organizativas y
          todos los niveles de protección necesarios para garantizar la
          seguridad en el tratamiento de los datos y evitar su alteración,
          pérdida, robo, tratamiento o acceso no autorizado, de acuerdo al
          estado de la tecnología y naturaleza de los datos almacenados.
          Asimismo, se garantiza también que el tratamiento y registro en
          ficheros, programas, sistemas o equipos, locales y centros cumplen con
          los requisitos y condiciones de integridad y seguridad establecidas en
          la normativa vigente.
        </p>
        <h4>IDIOMA</h4>
        <p>
          El idioma aplicable a esta política de privacidad es el castellano.
          Por tanto, en caso de que hubiera alguna contradicción en alguna de
          las versiones facilitadas en otros idiomas, prevalecerá la versión en
          castellano.
        </p>
        <h4>CAMBIOS EN LA PRESENTE POLÍTICA DE PRIVACIDAD</h4>
        <p>
          <b>TOT COMERCIAL, S.A.</b> se reserva el derecho a modificar la
          presente política para adaptarla a novedades legislativas o
          jurisprudenciales.
        </p>
        <h4>REVOCABILIDAD</h4>
        <p>
          El consentimiento prestado, tanto por el tratamiento como para la
          cesión de los datos de los interesados, es revocable en cualquier
          momento comunicándolo a <b>TOT COMERCIAL, S.A.</b> en los términos
          establecidos en esta Política para el ejercicio de los derechos. Esta
          revocación en ningún caso tendrá carácter retroactivo.
        </p>
        <h4>LEGISLACIÓN</h4>
        <p>
          Con carácter general las relaciones entre <b>TOT COMERCIAL, S.A.</b>{' '}
          con los Usuarios de sus servicios telemáticos, presentes en esta web,
          están sometidos a la legislación y jurisdicción española a la que se
          someten expresamente las partes, siendo competentes para la resolución
          de todos los conflictos derivados o relacionados con su uso los
          Juzgados y Tribunales de Lleida.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
