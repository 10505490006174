import React from 'react';

import { Layout } from 'antd';

import DieselplusLogo from '../../images/dieselplus-logo-white.svg';
import FooterMenu from './FooterMenu';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import routes from '../../routes';

const Footer: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Layout.Footer className="s-footer">
      <span className="block">
        <span className="s-footer__left">
          <div className="s-footer__left__logo-wrapper">
            <img
              src={DieselplusLogo}
              onClick={() => {
                navigate(routes.root());
              }}
            />
          </div>
          <FooterMenu />
        </span>
        <span className="s-footer__copyright">{`© ${dayjs().year()} Gespasa`}</span>
      </span>
    </Layout.Footer>
  );
};

export default Footer;
