import React, { useEffect, useState } from 'react';

import ModalForm from './ModalForm';
import ResourceInnerTable from '../ResourceTable/ResourceInnerTable';
import { TableColumn } from '../../models/table-column.model';
import useTranslate from '../../hooks/useTranslate';
import { Button, Switch } from 'antd';
import useToggleCookies from '../../hooks/useToggleCookies';
import { getCookie } from '../../lib/cookies.lib';

const ALL_COLUMNS: string[] = ['cookie_type', 'cookie_allow'];
const ALIGN_RIGHT_COLUMNS: string[] = ['cookie_allow'];

interface Props {
  formModalTitle: string;
  formModalBody: string;
  isModalVisible: boolean;
  onCancel: () => void;
  onFinish: () => void;
}

const CookieConsentConfigure: React.FC<Props> = ({
  formModalTitle,
  formModalBody,
  isModalVisible,
  onCancel,
  onFinish
}: Props) => {
  const t = useTranslate();
  const { setCookies } = useToggleCookies();
  const [marketingCookies, setMarketingCookies] = useState<boolean>(false);

  useEffect(() => {
    const consentCookie = getCookie('dp_consent_cookies');

    if (!!consentCookie && consentCookie.charAt(1) === '1') {
      setMarketingCookies(true);
    }
  }, []);

  const columns: TableColumn[] = ALL_COLUMNS.map((id: string) => ({
    id,
    title: id.toUpperCase(),
    align: ALIGN_RIGHT_COLUMNS.some((rightId: string) => rightId === id)
      ? 'right'
      : 'left',
    sorter: false
  }));

  const dataSource: Record<string, React.ReactNode>[] = [
    {
      key: 'functional',
      cookie_type: t({ id: 'COOKIES.FUNCTIONAL' }),
      cookie_allow: t({ id: 'COOKIES.REQUIRED' })
    },
    {
      key: 'marketing',
      cookie_type: t({ id: 'COOKIES.MARKETING' }),
      cookie_allow: (
        <Switch checked={marketingCookies} onChange={setMarketingCookies} />
      )
    }
  ];

  return (
    <ModalForm
      formModalTitle={formModalTitle}
      isFormModalVisible={isModalVisible}
      onCancel={onCancel}
      className="cookies-configure-form"
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t({ id: 'FORMS.CANCEL' })}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          onClick={() => {
            setCookies(marketingCookies);
            onFinish();
          }}
        >
          {t({ id: 'FORMS.ACCEPT' })}
        </Button>
      ]}
      inner
    >
      <div className="no-tab-div">{formModalBody}</div>
      <ResourceInnerTable dataSource={dataSource} columns={columns} />
    </ModalForm>
  );
};

export default CookieConsentConfigure;
