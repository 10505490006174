import { variables } from '../core/variables';

export const functions = {
  '& .s-functions': {
    '& .titles': {
      marginBottom: '50px'
    },

    '& .ant-col': {
      marginBottom: '44px'
    },

    '& h3': {
      fontWeight: 500,
      fontSize: '20px',

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        fontSize: '16px'
      }
    },

    '& h4': {
      fontWeight: 700,
      fontSize: '42px',
      lineHeight: 1.3,

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        fontSize: '32px',
        lineHeight: 1.25
      }
    },

    '& h5': {
      color: variables['@red-diesel'],
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: 1.2,
      margin: '20px',
      marginBottom: '10px'
    },

    '& .ant-card': {
      height: '100%',
      borderRadius: variables['@border-radius'],
      border: 'none',

      '& .ant-card-body': {
        padding: '20px',

        '& p': {
          color: '#44454b',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: 1.5,
          margin: '20px'
        }
      }
    },

    '& .header': {
      paddingTop: '50px',
      paddingBottom: '80px',
      backgroundImage: `linear-gradient(180deg, ${variables['@background-color-gradient']} 19.34%, ${variables['@background-color']} 100%)`,

      '& h2': {
        color: '#ecedee',
        fontWeight: 800,
        fontSize: '54px',
        lineHeight: 1.05,
        marginRight: '30px',

        [`@media (max-width: ${variables['@mobile-width']})`]: {
          fontSize: '42px',
          lineHeight: 1.2
        },

        [`@media (max-width: ${variables['@responsive-width']})`]: {
          marginRight: 0
        }
      },

      '& p': {
        color: '#a9abb1',
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: 1.2,
        marginRight: '80px',

        [`@media (max-width: ${variables['@mobile-width']})`]: {
          fontSize: '20px',
          lineHeight: 1.25
        },

        [`@media (max-width: ${variables['@responsive-width']})`]: {
          marginRight: 0
        }
      }
    },

    '& .icons': {
      paddingTop: '16px',
      backgroundColor: 'white',
      boxShadow: '5px 10px 31px 0 rgba(29, 30, 32, 0.1)',

      '& .data': {
        color: '#61636b',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 0,

        '& img': {
          paddingBottom: '10px',
          width: '54px',
          cursor: 'pointer',

          [`@media (max-width: ${variables['@responsive-width']})`]: {
            paddingBottom: '4px',
            width: '42px'
          }
        },

        '& span': {
          cursor: 'pointer',
          fontSize: '16px',
          lineHeight: 1,
          paddingBottom: '16px'
        }
      }
    },

    '& .supplies': {
      paddingTop: '111px',
      paddingBottom: '75px',
      backgroundColor: variables['@background-color-light'],

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        paddingTop: '70px',
        paddingBottom: '64px'
      },

      '& h3': {
        color: '#61636b'
      },

      '& h4': {
        color: '#1d1e20'
      },

      '& h5': {
        marginLeft: 0
      },

      '& p': {
        color: '#44454b',
        fontWeight: 400,
        fontSize: '16px',
        marginRight: '80px',
        marginTop: '20px',
        lineHeight: 1.5,

        [`@media (max-width: ${variables['@responsive-width']})`]: {
          marginRight: 0
        }
      },

      '& .data + .data': {
        marginTop: '50px'
      }
    },

    '& .tanks': {
      paddingTop: '111px',
      paddingBottom: '75px',
      backgroundColor: variables['@background-color'],

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        paddingTop: '70px',
        paddingBottom: '64px'
      },

      '& h3': {
        color: '#a9abb1'
      },

      '& h4': {
        color: '#ecedee'
      },

      '& .ant-card': {
        backgroundColor: variables['@background-color-light'],
        boxShadow: '5px 10px 31px 0 rgba(29, 30, 32, 0.1)',

        '& .ant-card-body': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',

          '& .image': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          },

          '& img': {
            marginTop: '20px',
            width: '100%'
          }
        }
      }
    },

    '& .consumption': {
      paddingTop: '111px',
      paddingBottom: '75px',
      backgroundColor: variables['@background-color-light'],

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        paddingTop: '70px',
        paddingBottom: '64px'
      },

      '& h3': {
        color: '#61636b'
      },

      '& h4': {
        color: '#1d1e20'
      },

      '& h5': {
        marginLeft: 0
      },

      '& p': {
        color: '#44454b',
        fontWeight: 400,
        fontSize: '16px',
        marginRight: '80px',
        marginTop: '20px',
        lineHeight: 1.5,

        [`@media (max-width: ${variables['@responsive-width']})`]: {
          marginRight: 0
        }
      }
    },

    '& .integrations': {
      paddingTop: '111px',
      paddingBottom: '75px',
      backgroundColor: variables['@background-color'],

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        paddingTop: '70px',
        paddingBottom: '64px'
      },

      '& h3': {
        color: '#a9abb1'
      },

      '& h4': {
        color: '#ecedee'
      },

      '& .ant-row': {
        justifyContent: 'center'
      },

      '& .ant-card': {
        backgroundColor: variables['@background-color-light'],
        boxShadow: '5px 10px 31px 0 rgba(29, 30, 32, 0.1)',

        '& .ant-card-body': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',

          '& .image': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          },

          '& img': {
            marginTop: '20px',
            width: '100%'
          }
        }
      }
    }
  }
};
