import React from 'react';
import useIntl from '../hooks/useIntl';
import TermsOfServiceEs from './es/TermsOfService';
import TermsOfServiceEn from './en/TermsOfService';

const TermsOfService: React.FC = () => {
  const { locale } = useIntl();

  switch (locale) {
    case 'es':
      return <TermsOfServiceEs />;
    default:
      return <TermsOfServiceEn />;
  }
};

export default TermsOfService;
