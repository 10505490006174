import React from 'react';

import useLocale from '../../hooks/useIntl';

interface Props {
  className?: string;
  imageName: string;
  pageName: string;
}

const CustomImage: React.FC<Props> = ({
  className,
  imageName,
  pageName
}: Props) => {
  const { locale } = useLocale();

  let imageSrc = undefined;

  const fixedImageName = imageName.replace(/_/, '-');

  try {
    imageSrc = require(`../../images/${pageName}/${fixedImageName}.png`);
  } catch {}

  if (!!!imageSrc) {
    try {
      imageSrc = require(`../../images/${pageName}/${locale}/${fixedImageName}.png`);
    } catch {}
  }

  return <img className={className} src={imageSrc} />;
};

export default CustomImage;
