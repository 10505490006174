import React from 'react';

const CookiesPolicy: React.FC = () => {
  return (
    <div className="block block__single block__single__legal">
      <div className="legal cookies-policy">
        <h2>Política de cookies</h2>
        <h3>1. INTRODUCCIÓN</h3>
        <p>
          La web de <b>TOT COMERCIAL, S.A.</b> utiliza cookies propias y
          tecnologías similares. Una cookie es un archivo que se descarga en su
          ordenador al acceder a determinadas páginas web. Las cookies permiten
          a una página web, entre otras finalidades, asegurar el correcto
          funcionamiento de la página, permitir al Usuario un acceso más rápido
          a los servicios seleccionados, almacenar y recuperar información sobre
          los hábitos de navegación de un usuario o de su equipo e incluso,
          dependiendo de la información que contengan y de la forma en que
          utilice su equipo, se pueden utilizar para reconocer al usuario. Las
          cookies se asocian únicamente a un usuario anónimo y su ordenador o
          dispositivo y no proporcionan referencias que permitan conocer sus
          datos personales, salvo permiso expreso de aquél.
        </p>
        <p>
          El usuario puede, en todo momento, aceptar o rechazar las cookies
          instaladas que no sean estrictamente necesarias para el correcto
          funcionamiento de la web y el acceso al Usuario a sus servicios, a
          través del panel de ajuste de cookies proporcionado en nuestra web.
          Asimismo, podrá configurar su navegador en todo momento sin que ello
          perjudique la posibilidad del Usuario de acceder a los contenidos. Sin
          embargo, le informamos de que el rechazo de las cookies puede
          disminuir el buen funcionamiento de la web.
        </p>
        <h3>2. COOKIES AFECTADAS POR LA NORMATIVA Y COOKIES EXENTAS</h3>
        <p>
          Según la directiva de la UE, las cookies que requieren el
          consentimiento informado por parte del usuario son las cookies de
          analítica, las de publicidad y afiliación, quedando exceptuadas las de
          carácter técnico y las necesarias para el funcionamiento del sitio web
          o la prestación de servicios expresamente demandados por el usuario.
        </p>
        <h3>3. TIPO DE COOKIES</h3>
        <p>
          a) Según la <b>entidad que las gestione</b>, hay Cookies{' '}
          <b>propias</b> (aquellas que se envían al equipo terminal del usuario
          desde un equipo o dominio gestionado por el propio editor y desde el
          que se presta el servicio Solicitado por el usuario) y de{' '}
          <b>terceros</b> (las que se envían al equipo terminal del usuario
          desde un equipo o dominio que no es gestionado por el editor, sino por
          otra entidad que trata los datos obtenidos mediante las cookies).
        </p>
        <p>
          b) Según el <b>tiempo que permanecen activas</b>, están las de{' '}
          <b>sesión</b> (diseñadas para pedir y almacenar datos mientras el
          usuario accede a una página web) y las <b>persistentes</b> (en que los
          datos se almacenan en el terminal y pueden ser accedidos y tratados
          durante un período definido por el responsable de la cookie -puede ir
          de unos minutos a varios años).
        </p>
        <p>
          c) Según la <b>finalidad</b> para la que tratan la información que
          recopilan, pueden ser:
          <ul>
            <li>
              <b>Cookies técnicas</b> (necesarias para el uso de la web y la
              prestación del servicio contratado),
            </li>
            <li>
              <b>Cookies de personalización</b> (que permiten al usuario acceder
              al servicio con características predefinidas, tales como el
              idioma, tipo de navegador, configuración regional, etc.),
            </li>
            <li>
              <b>Cookies de análisis</b> (recogen información del uso que se
              realiza de la web),
            </li>
            <li>
              <b>Cookies publicitarias</b> (recogen información sobre las
              preferencias y elecciones personales de los usuarios),
            </li>
            <li>
              <b>Cookies de afiliados</b> (permiten hacer un seguimiento de las
              visitas procedentes de otras webs, con las que el sitio web
              establece un contrato de afiliación).
            </li>
          </ul>
        </p>
        <h3>4. COOKIES EXENTAS:</h3>
        <p>
          <ul>
            <li>
              las que permiten únicamente la comunicación entre el equipo del
              usuario y la red.
            </li>
            <li>
              las estrictamente necesarias para prestar un servicio expresamente
              solicitado por el usuario.
            </li>
          </ul>
        </p>
        <p>
          Asimismo, el GT29, en su Dictamen 4/201210, ha interpretado que entre
          las cookies exceptuadas estarían aquellas que tienen por finalidad:
        </p>
        <ul>
          <li>
            Cookies de “entrada del usuario” (Las cookies de sesión y de entrada
            de usuario se suelen utilizar para rastrear las acciones del usuario
            al rellenar los formularios en línea en varias páginas, o como cesta
            de la compra para hacer el seguimiento de los artículos que el
            usuario ha seleccionado al pulsar un botón)
          </li>
          <li>
            Cookies de autenticación o identificación de usuario (únicamente de
            sesión).
          </li>
          <li>
            Cookies de seguridad del usuario (Por ejemplo, las cookies
            utilizadas para detectar intentos erróneos y reiterados de conexión
            a un sitio web)
          </li>
          <li>Cookies de sesión de reproductor multimedia.</li>
          <li>Cookies de sesión para equilibrar la carga.</li>
          <li>Cookies de personalización de la interfaz de usuario.</li>
          <li>
            Determinadas cookies de complemento (plug-in) para intercambiar
            contenidos Sociales (La excepción sólo se aplica para usuarios que
            han decidido mantener la sesión abierta)
          </li>
        </ul>
        <h3>5. TIPO DE COOKIES UTILITZADAS PARA ESTE SITIO WEB</h3>
        <table>
          <tr>
            <th>Nombre</th>
            <th>Finalidad</th>
            <th>Caducidad</th>
            <th>Tipo cookie</th>
          </tr>
          <tr className="table-owner">
            <td colSpan={4}>Youtube</td>
          </tr>
          <tr>
            <td>CONSENT</td>
            <td>
              Guardar el estado de un usuario respecto a sus elecciones de
              cookies.
            </td>
            <td>2 años</td>
            <td>Publicitarias</td>
          </tr>
          <tr>
            <td>YSC</td>
            <td>Guardar y hacer seguimiento de la interacción.</td>
            <td>Sesión</td>
            <td>Publicitarias</td>
          </tr>
          <tr>
            <td>VISITOR_INFO1_LIVE</td>
            <td>
              Guardar información del usuario para recomendaciones y fines
              publicitarios.
            </td>
            <td>6 meses</td>
            <td>Publicitarias</td>
          </tr>
          <tr className="table-owner">
            <td colSpan={4}>Propias</td>
          </tr>
          <tr>
            <td>dp_consent_cookies</td>
            <td>Guardar las preferencias del consentimiento de cookies.</td>
            <td>1 año</td>
            <td>Necesarias</td>
          </tr>
          <tr>
            <td>dp_account_token</td>
            <td>
              Guardar la identificación del usuario para recordar el acceso.
            </td>
            <td>1 año</td>
            <td>Necesarias</td>
          </tr>
        </table>
        <h3>6. ¿QUÉ OTRAS TECNOLOGÍAS SIMILARES UTILIZAMOS?</h3>
        <p>
          Usamos la característica denominada almacenamiento local o
          “localStorage”. Por almacenamiento local entendemos cualquier fichero
          creado en tu dispositivo de navegación por la web. Esta información se
          almacena en el navegador web y no se envía ni a{' '}
          <b>TOT COMERCIAL, S.A.</b> ni a ningún tercero.
        </p>
        <p>
          Para borrar “localStorage” el usuario la debe borrar el historial de
          navegación. Si no desea usar el almacenamiento local, debe modificar
          los ajustes en la configuración de su navegador, en cualquier momento.
          En este caso, debemos informarle de que las características de nuestra
          web y aplicación web serán limitadas o dejaran de estar disponibles y
          no podrá acceder a su usuario.
        </p>
        <h3>7. REVOCACIÓN</h3>
        <p>
          En todo momento podrá acceder a la configuración de su navegador
          aceptando o rechazando todas las cookies, o bien seleccionar aquellas
          que la instalación admite y las que no, siguiendo uno de los
          siguientes procedimientos, que depende del navegador que utilice:
        </p>
        <p>
          Google Chrome (en el Menú Herramientas): Configuración{' > '}Mostrar
          opciones avanzadas{' > '}Privacidad (Configuración de contenido)
          {' > '}Cookies:
          <br />
          <a
            target="_blank"
            href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es"
          >
            https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es
          </a>
        </p>
        <p>
          Microsoft Internet Explorer (en el Menú Herramientas): Opciones de
          Internet{' > '}Privacidad{' > '}Avanzada:
          <br />
          <a
            target="_blank"
            href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies"
          >
            https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies
          </a>
        </p>
        <p>
          Firefox: Opciones{' > '}Privacidad{' > '}Cookies:
          <br />
          <a
            target="_blank"
            href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"
          >
            https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias
          </a>
        </p>
        <p>
          Safari, iPad y iPhone: Preferencias{' > '}Privacidad:
          <br />
          <a
            target="_blank"
            href="https://support.apple.com/es-es/guide/safari/sfri40732"
          >
            https://support.apple.com/es-es/guide/safari/sfri40732
          </a>
        </p>
        <p>
          Opera: Configuración{' > '}Opciones{' > '}Avanzada{' > '}Cookies:
          <br />
          <a
            target="_blank"
            href="https://help.opera.com/es/latest/web-preferences/#cookies"
          >
            https://help.opera.com/es/latest/web-preferences/#cookies
          </a>
        </p>
        <p>
          Estos navegadores están sometidos a cambios o modificaciones, por lo
          que no podemos garantizar que se ajusten completamente a la versión de
          su navegador. También puede ser que utilice otro navegador no
          contemplado en estos enlaces como Konqueror, Arora, Flock, etc. Para
          evitar estos desajustes, puede acceder directamente desde las opciones
          de su navegador, generalmente en el menú de "Opciones" en la sección
          de "Privacidad". (Por favor, consulte la ayuda de su navegador para
          más información).
        </p>

        <h3>8. CAMBIOS EN LA PRESENTE POLÍTICA DE COOKIES</h3>
        <p>
          <b>TOT COMERCIAL, S.A.</b> se reserva el derecho a modificar la
          presente política para adaptarla a novedades legislativas o
          jurisprudenciales.
        </p>

        <h3>9. DESACTIVACIÓN / ACTIVACIÓN Y ELIMINACIÓN DE COOKIES</h3>
        <p>
          Para restringir o bloquear las cookies, se hace a través de la
          configuración del navegador.
        </p>
        <p>
          Si no desea que los sitios web pongan ninguna cookie en su equipo,
          puede adaptar la configuración del navegador de modo que se le
          notifique antes de que se coloque ninguna cookie. Del mismo modo,
          puede adaptar la configuración de forma que el navegador rechace todas
          las cookies, o únicamente las cookies de terceros. También puede
          eliminar cualquiera de las cookies que ya se encuentren en el equipo.
          Recuerde que deberá adaptarse por separado la configuración de cada
          navegador y equipo que utilice.
        </p>
        <p>
          Recuerde que, si no desea recibir cookies, ya no podremos garantizar
          que nuestro sitio web funcione como es debido. Quizá algunas funciones
          del sitio se pierdan y es posible que ya no pueda ver ciertos sitios
          web. Además, rechazó las cookies no significa que ya no vaya a ver
          anuncios publicitarios. Simplemente los anuncios no se ajustarán a sus
          intereses y se repetirán con más frecuencia.
        </p>
        <p>
          Cada navegador tiene un método diferente para adaptar la
          configuración. Si es necesario, consulte la función de ayuda del
          navegador para establecer la configuración correcta.
        </p>
        <p>
          Para desactivar las cookies en el teléfono móvil, consulte el manual
          del dispositivo para obtener más información.
        </p>
        <p>
          Puede obtener más información sobre las cookies en Internet,{' '}
          <a target="_blank" href="http://www.aboutcookies.org/">
            http://www.aboutcookies.org/
          </a>
          .
        </p>
        <p>
          Teniendo en cuenta la forma en que funciona Internet y los sitios web,
          no siempre contamos con información de las cookies que colocan
          terceras partes a través de nuestro sitio web. Esto se aplica
          especialmente a casos en que la página web contiene lo que se
          denominan elementos integrados: textos, documentos, imágenes o breves
          películas que se almacenan en otra parte, pero se muestran en nuestro
          sitio web o mediante el mismo.
        </p>
        <p>
          Por tanto, en caso de que se encuentre con este tipo de cookies en
          este sitio web y no estén enumeradas en la lista anterior, le rogamos
          que nos lo comunique. O bien póngase en contacto directamente con el
          tercero para pedirle información sobre las cookies que coloca, la
          finalidad y la duración de la cookie, y como ha garantizado su
          privacidad.
        </p>
        <h3>
          10. INFORMACIÓN RELACIONADA CON EL TRATAMIENTO DE DATOS PERSONALES
          (ART.13 RGPD)
        </h3>
        <h4>
          ¿Quién es el responsable del tratamiento de sus datos personales?
        </h4>
        <p>
          TOT COMERCIAL, S.A. es el responsable del tratamiento de los datos
          personales que usted nos proporciona, sito en Partida Horta Amunt s/n,
          25600, Balaguer y con CIF A25031154 y mail de contacto{' '}
          <a target="_blank" href="mailto:lopd@gespasa.es">
            lopd@gespasa.es
          </a>
        </p>
        <h4>¿Para qué utilizamos sus datos personales?</h4>
        <p>
          La información recopilada a través de las cookies propias (cookies de
          sesión y de entrada de usuario) se suelen utilizar para rastrear las
          acciones del usuario al rellenar los formularios en línea en varias
          páginas, o como cesta de la compra para hacer el seguimiento de los
          artículos que el usuario ha seleccionado al pulsar un botón, o para
          personalizar la interface del usuario al visitar la página web.
        </p>
        <p>
          La información recopilada a través de las cookies de terceros es la
          siguiente:
          <ul>
            <li>
              A través de las cookies analíticas se analizan sus hábitos de
              navegación para mejorar los servicios prestados;
            </li>
            <li>
              A través de las cookies comportamentales se recoge información
              sobre las preferencias del usuario para gestionar los espacios
              publicitarios de la forma más eficaz posible; y
            </li>
            <li>
              a través de las cookies publicitarias se mide el rendimiento de
              los anuncios y se proporcionan recomendaciones relativas a
              productos basadas en datos estadísticos.
            </li>
          </ul>
        </p>
        <p>
          Para ver exactamente el uso de las cookies véase el cuadro sobre el
          uso de las cookies en esta web.
        </p>
        <h4>
          ¿Cuál es la base legal para el tratamiento de sus datos personales?
        </h4>
        <p>
          La legitimación para el tratamiento de sus datos personales viene dada
          por su consentimiento, otorgado en el momento de ponerse en contacto
          con nosotros, para la utilización de cookies analíticas,
          comportamentales y publicitarias; para las cookies de sesión o
          personalización, la legitimación para el tratamiento viene dada por el
          interés legítimo del prestador (responsable del tratamiento).
        </p>
        <h4>¿A quién comunicamos sus datos?</h4>
        <p>
          No se cederán ni comunicarán datos a terceros, salvo por obligación
          legal o a los terceros indicados en el caso de uso de cookies
          gestionadas por éstos.
        </p>
        <h4>¿Cuál es el plazo de conservación de sus datos?</h4>
        <p>
          El plazo de conservación de los datos recogidos es el indicado en el
          cuadro sobre el uso de las cookies en esta web.
        </p>
        <h4>¿Cuáles son sus derechos?</h4>
        <p>
          Los derechos que amparan al usuario son los de acceso, rectificación,
          supresión, portabilidad de los mismos, retirada del consentimiento
          (sin que ello afecte a la licitud del tratamiento otorgado previamente
          a su retirada), oposición al tratamiento, limitación (en determinadas
          circunstancias) y a presentar una reclamación ante la autoridad de
          control (la Agencia Española de protección de datos,{' '}
          <a target="_blank" href="http://www.aepd.es">
            www.aepd.es
          </a>
          ).
        </p>
      </div>
    </div>
  );
};

export default CookiesPolicy;
