import React from 'react';

interface Props {
  title: string;
  description: JSX.Element | string;
}

const InfoLegalText: React.FC<Props> = ({ title, description }: Props) => {
  return (
    <p className="info-legal-text">
      <span className="info-legal-text__title">{title}:</span>
      <span className="info-legal-text__description">{description}</span>
    </p>
  );
};

export default InfoLegalText;
