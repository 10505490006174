import { Button } from 'antd';
import React, { useEffect } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import useToggleCookies from '../../hooks/useToggleCookies';
import useTranslate from '../../hooks/useTranslate';
import CustomImage from './CustomImage';

interface Props {
  videoId: string;
  placeholderImageName: string;
  article?: boolean;
}

const YoutubeEmbedPlayer: React.FC<Props> = ({
  videoId,
  placeholderImageName,
  article
}: Props) => {
  const t = useTranslate();
  const { marketingCookie, setBannerVisible } = useToggleCookies();

  const resize = (): void => {
    const element = document?.querySelector(':root') as HTMLElement;

    let height = 0;

    if (article) {
      const articleElement = document
        .getElementsByClassName('article-content-data')?.[0]
        ?.getBoundingClientRect();

      height = (577 / 1140) * articleElement.width;
    } else {
      if (window.innerWidth < 1140) {
        height = (641 / 1140) * window.innerWidth - 9;
      } else {
        height = 641;
      }
    }

    element.style?.setProperty('--iframe-height', `${height}px`);
  };

  const callSizeChanged = () => setTimeout(resize, 500);

  useEffect(() => {
    callSizeChanged();
    window.addEventListener('resize', () => callSizeChanged());
    return () => {
      window.removeEventListener('resize', callSizeChanged);
    };
  }, []);

  const opts: YouTubeProps['opts'] = {
    frameborder: '0',
    playerVars: {
      autoplay: 1,
      loop: 1,
      playlist: videoId,
      mute: 1
    }
  };

  return marketingCookie ? (
    <YouTube
      loading="lazy"
      className="video-player"
      videoId={videoId}
      opts={opts}
    />
  ) : (
    <div className="placeholder-youtube-video">
      <CustomImage
        className="placeholder-image"
        pageName="youtube"
        imageName={placeholderImageName}
      />
      <div className="youtube-cookies">
        <span>{t({ id: 'YOUTUBE.COOKIES' })}</span>
        <Button
          className="layout-button"
          onClick={() => {
            setBannerVisible(true);
          }}
        >
          {t({ id: 'YOUTUBE.COOKIES_BUTTON' })}
        </Button>
      </div>
    </div>
  );
};

export default YoutubeEmbedPlayer;
