import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { EnvironmentProvider } from './hooks/useEnvironment';
import { LocaleProvider } from './hooks/useIntl';
import { ToggleCookiesProvider } from './hooks/useToggleCookies';
import { AntDesignProvider } from './providers/AntDesignProvider';
import { FormatProvider } from './hooks/useFormat';
import AppRouter from './Router';

import jss from 'jss';
import camelCase from 'jss-plugin-camel-case';
import { useStyles } from './stylesheets';

export const App: React.FC = () => {
  const classes = useStyles();

  useEffect(() => {
    document.getElementsByTagName('body')[0].className = classes.themeStyle;
  }, []);

  jss.use(camelCase());

  return (
    <BrowserRouter>
      <LocaleProvider>
        <FormatProvider>
          <AntDesignProvider>
            <EnvironmentProvider>
              <ToggleCookiesProvider>
                <AppRouter />
              </ToggleCookiesProvider>
            </EnvironmentProvider>
          </AntDesignProvider>
        </FormatProvider>
      </LocaleProvider>
    </BrowserRouter>
  );
};
