export const oldBrowsers = {
  '& .ant-badge': {
    '& .ant-badge-count': {
      right: 0
    }
  },

  '& .ant-modal': {
    minWidth: '60%',

    '&.is-fullheight-modal': {
      height: 'calc(var(--vh) - 4rem)',

      '& textarea': {
        height: 'calc(var(--vh) - 16rem)'
      }
    },

    '& .ant-modal-close': {
      right: '11px'
    }
  },

  '& .ant-modal-root': {
    '& .ant-modal-wrap': {
      right: 0,
      left: 0
    }
  },

  '& .ant-select': {
    '& .ant-select-arrow': {
      right: '11px',
      left: 'auto'
    },

    '& .ant-select-clear': {
      right: '11px',
      left: 'auto'
    },

    '&-selection-search': {
      right: '11px',
      left: '11px'
    }
  },

  '& .ant-picker-range': {
    '& .ant-picker-clear': {
      right: '11px'
    }
  },

  '& .ant-picker-dropdown .ant-picker-cell::before': {
    left: 0,
    right: 0
  },

  '& .ant-picker': {
    '& .ant-picker-clear': {
      right: 0
    }
  },

  '& .ant-input-search-button': {
    borderRadius: '0px 2px 2px 0px'
  },

  '& .ant-input-group:first-child': {
    borderRadius: '2px 0px 0px 2px'
  },

  '& .ant-checkbox-inner:after': {
    left: '21.5%'
  },

  '& .ant-switch': {
    '& .ant-switch-handle': {
      left: '2px',

      '&:before': {
        left: 0,
        right: 0
      }
    },

    '&.ant-switch-checked': {
      '& .ant-switch-handle': {
        left: 'calc(100% - 20px) !important'
      }
    }
  },

  '& .custom-pagination': {
    margin: '20px',
    position: 'absolute',
    right: 0
  },

  '& .ant-drawer': {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    '& .ant-drawer-header': {
      padding: '22px 24px'
    }
  },

  '& .ant-tag': {
    padding: '0 7px'
  },

  '& .ant-spin-dot-item': {
    '&:nth-child(1)': {
      left: 0
    },

    '&:nth-child(4)': {
      left: 0
    },

    '&:nth-child(2)': {
      right: 0
    },

    '&:nth-child(3)': {
      right: 0
    }
  },

  '& .ant-menu-vertical .ant-menu-item': {
    paddingLeft: '16px',
    paddingRight: '16px',
    marginLeft: '4px',
    marginRight: '4px'
  }
};
