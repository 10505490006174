import React, { useMemo } from 'react';

import { ConfigProvider } from 'antd';

import { templateValidateMessages } from '../lib/template-validate-messages.lib';
import useTranslate from '../hooks/useTranslate';
import useIntl from '../hooks/useIntl';

import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

import { AliasToken } from 'antd/es/theme/interface';

interface Props {
  children: React.ReactNode;
}

export const AntDesignProvider: React.FC<Props> = ({ children }: Props) => {
  const t = useTranslate();
  const { fullLocale } = useIntl();

  const validateMessages = templateValidateMessages(t);

  const locale = useMemo(() => {
    dayjs.extend(quarterOfYear);
    dayjs.extend(updateLocale);
    dayjs.extend(utc);

    return fullLocale;
  }, [fullLocale]);

  const defaultStyleVars: Partial<AliasToken> = {
    borderRadius: 2,
    fontFamily:
      "Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"
  };

  return (
    <ConfigProvider
      locale={locale}
      form={{ validateMessages }}
      theme={{ token: defaultStyleVars }}
    >
      {children}
    </ConfigProvider>
  );
};
