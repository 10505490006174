import React from 'react';
import { Button } from 'antd';

import useTranslate from '../../hooks/useTranslate';
import { getCookie } from '../../../src/lib/cookies.lib';
import PrivacyLink from './PrivacyLink';
import CookieConsentConfigure from './CookieConsentConfigure';
import classNames from 'classnames';
import useToggleMenu from '../../hooks/useToggleCookies';

interface Props {
  closeBanner: () => void;
}

const CookieConsent: React.FC<Props> = ({ closeBanner }) => {
  const t = useTranslate();
  const { bannerVisible, setBannerVisible, setCookies } = useToggleMenu();

  return (
    <>
      {!!!getCookie('dp_consent_cookies') && (
        <div className={classNames('cookies')}>
          <div>
            {t(
              { id: 'COOKIES.TEXT' },
              { cookiesLink: <PrivacyLink privacyFileName="cookies_policy" /> }
            )}
          </div>

          <div className="buttons">
            <Button
              onClick={() => {
                setCookies(true);
                closeBanner();
              }}
            >
              {t({ id: 'COOKIES.ACCEPT_BUTTON' })}
            </Button>
            <Button
              onClick={() => {
                setCookies(false);
                closeBanner();
              }}
            >
              {t({ id: 'COOKIES.DECLINE_BUTTON' })}
            </Button>
            <Button
              onClick={() => {
                setBannerVisible(true);
              }}
            >
              {t({ id: 'COOKIES.CONFIG_BUTTON' })}
            </Button>
          </div>
        </div>
      )}

      {bannerVisible && (
        <CookieConsentConfigure
          formModalTitle={t({ id: 'COOKIES.CONFIG_TITLE' })}
          formModalBody={t({ id: 'COOKIES.CONFIG_TEXT' })}
          isModalVisible={bannerVisible}
          onFinish={() => {
            setBannerVisible(false);
            closeBanner();
          }}
          onCancel={() => {
            setBannerVisible(false);
          }}
        />
      )}
    </>
  );
};

export default CookieConsent;
