import { useIntl } from 'react-intl';

// Returns a function to use translations for form fields.
// Based on a MODEL and a FIELD params it will try to search for a translation of
// FORMS.MODEL.FIELD or MODEL.FIELD if the first one is not found.
export default function useFieldTranslate(): Function {
  const intl = useIntl();

  return (model: string, field: string): string => {
    if (!!model && !!field) {
      const formKey = `FORMS.${model.toUpperCase()}.${field.toUpperCase()}`;
      const modelKey = `${model.toUpperCase()}.${field.toUpperCase()}`;

      return intl.messages[formKey]
        ? intl.formatMessage({ id: formKey })
        : intl.formatMessage({ id: modelKey });
    } else {
      return '';
    }
  };
}
