import { contact } from './contact';
import { footer } from './footer';
import { functions } from './functions';
import { header } from './header';
import { home } from './home';

export const blocks = {
  ...contact,
  ...footer,
  ...functions,
  ...header,
  ...home
};
