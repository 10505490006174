import { variables } from '../core/variables';

export const drawers = {
  '& .s-drawer': {
    '& .ant-drawer-content-wrapper': {
      width: '480px !important',

      '@media (max-width: 480px)': {
        width: '100% !important'
      }
    },

    '& .ant-divider': {
      margin: 0,
      marginTop: '5px',
      marginBottom: '15px'
    },

    '&__action-buttons': {
      textAlign: 'left',

      '& button': {
        marginRight: '10px !important',
        marginLeft: '0 !important'
      },

      '& button + button': {
        marginRight: '0 !important'
      },

      '@media (max-width: 576px)': {
        display: 'block !important',

        '& button': {
          marginRight: '0 !important',
          marginLeft: '0 !important',
          width: '100% !important'
        },

        '& button + button': {
          marginTop: '10px !important'
        }
      }
    },

    '& a': {
      color: 'rgba(0, 0, 0, 0.65)'
    },

    '&__links': {
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',

      '& .ant-divider': {
        marginBottom: '5px'
      },

      '& a, span': {
        color: variables['@hint-color'],
        cursor: 'pointer'
      }
    },

    '& .steps-layout': {
      margin: '23px',
      height: 'calc(100% - 23px - 23px)'
    }
  },

  '& .ant-drawer-header': {
    background: variables['@red-diesel'],
    borderRadius: 0,
    height: '44px'
  },

  '& .ant-drawer-close, .ant-drawer-close:hover': {
    color: 'white',
    margin: 0,
    marginRight: '-4px',
    padding: 0
  },

  '& .ant-drawer-header-title': {
    color: 'white',
    lineHeight: '12px',
    flexDirection: 'row-reverse',

    '& .ant-drawer-title': {
      color: 'white'
    }
  }
};
