import React from 'react';

const LegalNotice: React.FC = () => {
  return (
    <div className="block block__single block__single__legal">
      <div className="legal legal-notice">
        <h2>Legal notice</h2>
        <p>
          In accordance with Article 10 of Act 34/2002 on Information Society
          Services and Electronic Commerce, we hereby inform users of our
          details:
        </p>
        <p>
          Corporate Name: <b>TOT COMERCIAL, S.A.</b>
          <br />
          Registered Office: <b>Partida Horta Amunt s/n, 25600, Balaguer</b>
          <br />
          NIF (Tax ID No.): <b>A25031154</b>
          <br />
          EMail:{' '}
          <b>
            <a target="_blank" href="mailto:gespasa@gespasa.es">
              gespasa@gespasa.es
            </a>
          </b>
          <br />
          Web:{' '}
          <b>
            <a target="_blank" href="www.gespasa.es">
              www.gespasa.es
            </a>
          </b>
          <br />
          Registration Information:{' '}
          <b>
            Inscrita en el Registro Mercantil de Lleida, Folio 17, Tomo 190,
            Libro 143
          </b>
        </p>
        <h3>1. PURPOSE</h3>
        <p>
          <b>TOT COMERCIAL, S.A.</b> (hereinafter the ‘Provider’), as the party
          responsible for the website and web application, makes this document
          available to users. It governs their use of the domains
          dieselplus.net, mydieselplus.net and their subdomains, and our
          intention is to use it to comply with the obligations under Act
          34/2002 on Information Society Services and Electronic Commerce
          (LSSICE or LSSI in Spanish) and to inform all users of the terms and
          conditions of use of the website.
        </p>
        <p>
          Through the website, <b>TOT COMERCIAL, S.A.</b> provides users with
          access and use of the different services and content
        </p>
        <p>
          Any person accessing this website assumes the role of user and any
          person accessing to use the web application (hereinafter ‘
          <b>DIESELPLUS</b>’) will do so as a user/client (both parties referred
          to as ‘users’) and implies full and unreserved acceptance of each and
          every one of the provisions included in this Legal Notice, as well as
          any other legal provisions that may be applicable.
        </p>
        <p>
          Users should carefully read this Legal Notice whenever they enter the
          website or web application, as it may have been modified as the
          provider reserves the right to modify any type of information that may
          appear on the website, without any obligation to give prior notice or
          inform them of such obligations. Publication on the Provider’s website
          will be sufficient.
        </p>
        <h3>
          2. TERMS AND CONDITIONS OF ACCESS AND USE OF THE WEBSITE AND WEB
          APPLICATION
        </h3>
        <h4>2.1. Access and use of the website and web application.</h4>
        <p>
          In general, browsing the website does not require Users to subscribe
          or sign up beforehand. That said, <b>TOT COMERCIAL, S.A.</b> restricts
          the use of the <b>DIESELPLUS</b> web application to Users that have
          purchased the service beforehand, which means that Users must be
          registered. Initial registration of the User will be done by the
          Provider, which will send the credentials. Access will be through the
          URL provided by the Provider. It is the User’s responsibility to
          change the credentials provided by the Provider when accessing for the
          first time.
        </p>
        <h4>2.2. Truthfulness of information.</h4>
        <p>
          All information provided by the User must be truthful. For these
          purposes, the User hereby warrants the authenticity of the data
          provided through the forms, contracts, quotes for the subscription of
          the services. It will be the User’s responsibility to keep all
          information provided to <b>TOT COMERCIAL, S.A.</b> always up-to-date
          in accordance with their actual situation at all times. In any case,
          the User will be solely liable for any false or inaccurate statements
          made and for any damage caused to the Provider or third parties.
        </p>
        <h4>2.3. Authorised Users.</h4>
        <p>
          <b>DIESELPLUS</b> is <b>ONLY</b> available{' '}
          <b>to users older than 18 years of age</b>. By using this website, the
          User represents and warrants that they are of legal age in their
          country of residence to enter into a binding contract with us and meet
          all applicable requirements. If they do not meet all these
          requirements, they should not access or use the platform.
        </p>
        <p>
          The User is hereby informed that the provider reserves the right to
          restrict, suspend or terminate their access to the website or web
          application, adopting any technical measure necessary for such
          purpose, in the event that they violate these terms and conditions,
          any other special terms and conditions contained in the website,
          third-party rights or applicable legislation.
        </p>
        <h4>
          2.4. Obligation to use the website and web application properly.
        </h4>
        <p>
          The User undertakes to use the website in accordance with the Law and
          this Legal Notice, as well as good morals and decency. To this end,
          the User will refrain from using the website or web application for
          illegal or banned purposes, to violate the rights and interests of
          third parties, or in any way that may damage, disable, overload,
          deteriorate or prevent the normal use of computer equipment or
          documents, files and all kinds of content stored on any computer
          equipment of the Provider.
        </p>
        <p>
          In particular, including, but not limited to, the User undertakes not
          to transmit, disseminate or make available to any third party
          information, data, content, messages, graphics, drawings, sound or
          image files, photographs, recordings, software and, in general, any
          kind of material that:
          <br />
          (A) is contrary to, disregards or violates fundamental rights and
          public liberties recognised constitutionally, in international
          treaties and other current legislation;
          <br />
          (B) induces, incites or promotes actions that are criminal, demeaning,
          defamatory, violent or, in general, contrary to the law, good morals
          and public order;
          <br />
          (C) induces, incites or promotes discriminatory acts, attitudes or
          thoughts based on gender, race, religion, beliefs, age or status;
          <br />
          (D) is contrary to the right to honour, personal or family privacy or
          the right to self-image;
          <br />
          (E) in any way damages the credibility of the Provider or third
          parties; and
          <br />
          (F) constitutes illegal, false or unfair advertising.
        </p>
        <h3>3. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h3>
        <p>
          The Provider is exempt from any type of liability arising from the
          information published on the website and web application, provided
          that this information has been tampered with or introduced by a third
          party.
        </p>
        <p>
          This website and the web application have been reviewed and tested to
          ensure that they work properly. In principle, proper operation can be
          guaranteed 365 days a year, 24 hours a day. Nevertheless, the provider
          does not rule out the possibility of the existence of certain
          programming errors, or the occurrence of force majeure events, natural
          disasters, natural disasters, strikes, or similar circumstances that
          make it impossible to access this website, the web application or any
          of the services offered, such as API access or communication with
          control and measuring equipment.
        </p>
        <p>
          Access to the website or any of the services offered could temporarily
          be suspended without prior notice due to system failure, maintenance
          or repair work for reasons beyond the Provider’s control. The Provider
          assumes no liability whatsoever in such cases.
        </p>
        <p>
          <b>TOT COMERCIAL, S.A.</b> does not grant any guarantee nor is it
          liable, in any case, for damages of any nature that may arise from the
          lack of availability, maintenance or effective operation of the
          website, web application or their services and content; for the
          existence of viruses, malware or harmful content; for any use that is
          illegal, negligent, fraudulent or contrary to this Legal Notice and
          the terms and conditions of use; or for the lack of lawfulness,
          quality, reliability, usability and availability of the services
          provided by third parties and available to the users on this website.
        </p>
        <p>
          The Provider will in no way be liable for any damage that may arise
          from the illegal or inappropriate use of this website.
        </p>
        <h3>
          4. ALTERATIONS TO THE CONTENT OF THE WEBSITE AND WEB APPLICATION
        </h3>
        <p>
          It is strictly forbidden to alter, copy, modify, decompile,
          disassemble, reverse engineer any part of the website, the web
          application or their content, circumvent, disable or otherwise tamper
          with (or attempt to circumvent, disable or tamper with) the security
          functions or other functions of the program and use the website, the
          web application or their content for commercial or advertising
          purposes.
          <br />
          It is strictly forbidden to use it for the purpose of damaging the
          property, rights or interests of the Provider or third parties. It is
          likewise strictly forbidden to use it in any other way that alters,
          damages or renders useless the computer networks, servers, equipment,
          products and software of the Provider or third parties.
        </p>
        <h3>5. COOKIES</h3>
        <p>
          The Provider’s website may use cookies (small information files that
          the server sends to the computer of the person accessing the page) and
          similar technologies to perform certain functions that are considered
          essential for the proper functioning and display of the website. The
          cookies used are, in any case, temporary in nature and have the sole
          purpose of making browsing more efficient, disappearing once the
          User’s session ends. The similar technologies used such as
          LocalStorage must be deleted by the User from their browsing history.
          Under no circumstances will cookies be used to collect personal data.
          For more information, please see our Cookies Policy at{' '}
          <a target="_blank" href="https://www.dieselplus.net/cookies_policy">
            https://www.dieselplus.net/cookies_policy
          </a>
          .
        </p>
        <h3>6. LINKS</h3>
        <p>
          From the website, it is possible to be redirected to sponsored
          content, adverts and/or links to affiliate and/or third-party
          websites.
        </p>
        <p>
          Among the links provided, there is the possibility to inform or refer
          to third-party products and/or services. Given that there is no way to
          control any content entered by third parties,{' '}
          <b>TOT COMERCIAL, S.A.</b> assumes no liability whatsoever with regard
          to such content, nor does it guarantee the experience, integrity or
          quality of their products. In any case, the Provider states that it
          will immediately withdraw any content that may contravene national or
          international legislation, good morals or public order, immediately
          withdrawing the redirection to these websites and notifying the
          competent authorities of the content in question.
        </p>
        <p>
          <b>TOT COMERCIAL, S.A.</b> will keep these links published until they
          are removed or suspended by the provider or advertiser, and will not
          be liable although these adverts may be indexed by other search
          engines outside this website.
        </p>
        <p>
          The Provider assumes no liability for the information and stored
          content in, including, but not limited to, forums, chats, blog
          generators, comments, social media or any other medium that allows
          third parties to publish content independently on the Provider's
          website. However, and in compliance with articles 11 and 16 of the
          LSSICE, the Provider makes available to all users, authorities and
          security forces any content that may concern or contravene national or
          international legislation, third-party rights or good morals and
          public order, and actively collaborates in the withdrawal or, when
          necessary, the blocking of such content. If a User believes that some
          content could be classified this way, they should notify the website
          administrator immediately.
        </p>
        <p>
          Nevertheless, <b>TOT COMERCIAL, S.A.</b> hereby informs users that any
          contractual or extra-contractual relationship that a User enters into
          with such third parties, affiliates or advertisers through the link
          provided by this website will be valid only and exclusively between
          the User and the advertiser and/or third party and that{' '}
          <b>TOT COMERCIAL, S.A.</b> will assume no liability whatsoever for any
          damage that may arise from the provision of such services and/or
          contractual or extra-contractual relationships entered into between
          the User and the advertisers or third parties contacted through this
          portal as the Provider only acts as an intermediary or advertising
          medium.
        </p>
        <h3>7. PROTECTION OF PERSONAL DATA</h3>
        <p>
          The Provider is deeply committed to complying with the personal data
          protection regulations and ensures full compliance with the
          obligations set forth, as well as the implementation of the security
          measures provided for in the European Data Protection Regulation and
          the Spanish regulations on the protection of personal data. For more
          information, please see our Privacy Policy at{' '}
          <a target="_blank" href="https://www.dieselplus.net/privacy_policy">
            https://www.dieselplus.net/privacy_policy
          </a>
          .
        </p>
        <h3>8. INTELLECTUAL AND INDUSTRIAL PROPERTY</h3>
        <p>
          The website, including, but not limited to, the programming, editing,
          compilation and other elements necessary for its operation, the
          designs, logos, texts, photographs and/or graphics, are the property
          of the provider or, if necessary, it has a licence or express
          authorisation from the authors. All website content is duly protected
          by the legislation on intellectual and industrial property.
        </p>
        <p>
          Regardless of the purpose for which it is intended, total or partial
          reproduction, use, distribution and public communication require prior
          written authorisation from the provider. Any use not authorised by the
          provider beforehand will be considered a serious breach of the
          author's intellectual or industrial property rights.
        </p>
        <p>
          The designs, logos, texts and/or graphics not belonging to the
          provider which may appear on the website belong to their respective
          owners, which are responsible for any possible disputes that may arise
          regarding them. In any case, the provider has their express and prior
          authorisation.
        </p>
        <p>
          The provider acknowledges the owners’ corresponding industrial and
          intellectual property rights over them, and their mention or
          appearance on the website does not imply the existence of any rights,
          responsibility or liability of the provider in relation to them, nor
          any endorsement, sponsorship or recommendation by it.
        </p>
        <p>
          If any User would like to make any comments regarding possible
          breaches of intellectual or industrial property rights or the content
          of the website, they may do so using the email address specified
          above.
        </p>
        <h3>9. APPLICABLE LAW AND JURISDICTION</h3>
        <p>
          For the resolution of any disputes or matters related to this website
          or the activities performed thereon, Spanish law shall be applicable,
          with the parties expressly submitting themselves to the jurisdiction
          of the Courts and Tribunals of Lleida, which shall settle any disputes
          arising from or related to its use.
        </p>
      </div>
    </div>
  );
};

export default LegalNotice;
