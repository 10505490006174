export const CONFIG = {
  API_URL: process.env.REACT_APP_API_URL || 'http://localhost:3000/api/v1',
  APP_URL: process.env.REACT_APP_URL || 'https://app.dieselplus.net',
  DEMO_URL: process.env.REACT_APP_DEMO_URL || 'https://demo.dieselplus.net',
  COOKIES_POLICY_URL:
    process.env.REACT_APP_POLICIES_COOKIES_POLICY_URL ||
    'http://localhost:3000',
  PRIVACY_POLICY_URL:
    process.env.REACT_APP_POLICIES_PRIVACY_POLICY_URL ||
    'http://localhost:3000',
  LEGAL_NOTICE_URL:
    process.env.REACT_APP_POLICIES_LEGAL_NOTICE_URL || 'http://localhost:3000',
  TERMS_OF_SERVICE_URL:
    process.env.REACT_APP_POLICIES_TERMS_OF_SERVICE_URL ||
    'http://localhost:3000',
  MAINTENANCE_URL:
    process.env.REACT_APP_MAINTENANCE_URL ||
    'http://localhost:3001/maintenance.html',
  PAGINATION_PAGE_SIZE: 20,
  LANGUAGE: {
    DEFAULT: 'es'
  }
};
