import { getNoTracking } from './client';
import { toParams } from '../lib/params.lib';
import { CompactItem, CompactItemResponse } from '../models/compact-item.model';

export async function getAreasCompact(
  name: string,
  language: string,
  country_id?: string
): Promise<CompactItem[]> {
  const params = {
    ...toParams({ name }),
    ...toParams({ language }),
    ...toParams({ country_id }),
    ...toParams({ sort_by: 'name', sort_direction: 'asc' })
  };

  return getNoTracking<CompactItemResponse>('/areas/compact', params).then(
    (resp: CompactItemResponse) => resp.data
  );
}
