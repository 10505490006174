import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useTranslate from '../../hooks/useTranslate';
import routes from '../../routes';
import CustomImage from './CustomImage';

const RequestDemoFooter: React.FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();

  return (
    <div className="s-footer__request-demo">
      <div className="block">
        <div className="content half left">
          <h3>{t({ id: 'FOOTER.REQUEST_DEMO.TITLE' })}</h3>
          <p>{t({ id: 'FOOTER.REQUEST_DEMO.TEXT' })}</p>
          <Button
            className="layout-button"
            onClick={() => {
              navigate(routes.contact());
            }}
          >
            {t({ id: 'HOME.HEADER.BUTTON' })}
          </Button>
        </div>
      </div>
      <CustomImage
        className="background-image"
        pageName="footer"
        imageName="demo"
      />
    </div>
  );
};

export default RequestDemoFooter;
