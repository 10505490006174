import React, { createContext, useContext, useEffect, useState } from 'react';
import { getCookie, setCookie } from '../lib/cookies.lib';

interface ToggleCookiesContextState {
  bannerVisible: boolean;
  marketingCookie: boolean;
  setBannerVisible: (bannerVisible: boolean) => void;
  setCookies: (marketingCookie: boolean) => void;
}

const ToggleCookiesContext = createContext<ToggleCookiesContextState>({
  bannerVisible: false,
  marketingCookie: false,
  setBannerVisible: () => undefined,
  setCookies: () => undefined
});

interface Props {
  children: React.ReactNode;
}

export const ToggleCookiesProvider: React.FC<Props> = ({ children }: Props) => {
  const [bannerVisible, setBannerVisible] = useState<boolean>(false);
  const [marketingCookie, setMarketingCookie] = useState<boolean>(false);

  const setCookies = (newMarketingCookie: boolean): void => {
    let cookieValue = '1';

    if (newMarketingCookie) {
      cookieValue += '1';
      setMarketingCookie(true);
    } else {
      cookieValue += '0';
      setMarketingCookie(false);
    }

    setCookie('dp_consent_cookies', cookieValue);
  };

  useEffect(() => {
    const consentCookie = getCookie('dp_consent_cookies');

    if (!!consentCookie && consentCookie.charAt(1) === '1') {
      setMarketingCookie(true);
    }
  }, []);

  return (
    <ToggleCookiesContext.Provider
      value={{ bannerVisible, setBannerVisible, setCookies, marketingCookie }}
    >
      {children}
    </ToggleCookiesContext.Provider>
  );
};

export default function useToggleCookies(): ToggleCookiesContextState {
  return useContext(ToggleCookiesContext);
}
