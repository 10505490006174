import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Input, message, Row, Select, Switch } from 'antd';
import useFieldTranslate from '../../hooks/useFieldTranslate';
import useFormErrors from '../../hooks/useFormErrors';

import useTranslate from '../../hooks/useTranslate';
import { contactValidation } from '../../lib/validations/contact';
import FormLabel from '../Shared/FormLabel';

import { Store } from 'antd/lib/form/interface';
import PrivacyLink from '../Shared/PrivacyLink';
import { API } from '../../api';
import { displayErrors } from '../../lib/display-errors.lib';
import { CompactItem } from '../../models/compact-item.model';
import { Country } from '../../models/country.model';
import useLocale from '../../hooks/useIntl';
import { debounce } from 'lodash';
import InfoLegalText from '../Shared/InfoLegalText';

const initialValues: Store = {
  department: 'purchase',
  country_id: 'ES',
  agree_sales: false
};

const Contact: React.FC = () => {
  const t = useTranslate();
  const { locale } = useLocale();
  const fieldTranslate = useFieldTranslate();
  const {
    fieldErrors,
    alertErrors,
    clearValidations,
    setFormErrors,
    setAlertErrors
  } = useFormErrors();

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<Store>({});
  const [currentCountry, setCurrentCountry] = useState<Country>();

  const isSpain = useMemo(
    () => currentCountry?.id.toString() === 'ES',
    [currentCountry]
  );

  const validation = contactValidation(fieldErrors);

  const [countries, setCountries] = useState<Country[]>([]);
  const [countriesQuery, setCountriesQuery] = useState<string>('');
  const [loadingCountries, setLoadingCountries] = useState<boolean>(false);

  useEffect(() => {
    setLoadingCountries(true);

    API.countries
      .getCompact(countriesQuery, locale)
      .then((countriesCompact: Country[]) => {
        setCountries(countriesCompact);
        setLoadingCountries(false);
      });
  }, [countriesQuery, locale]);

  const [areas, setAreas] = useState<CompactItem[]>([]);
  const [areasQuery, setAreasQuery] = useState<string>('');
  const [loadingAreas, setLoadingAreas] = useState<boolean>(false);

  const setAreasDefaultValue = () => {
    setLoadingAreas(true);
    API.areas
      .getCompact(areasQuery, locale, currentCountry?.id)
      .then((areasCompact: CompactItem[]) => {
        setAreas(areasCompact);
        if (areasCompact.length > 0) {
          const firstArea = areasCompact[0];
          form.setFieldsValue({ area_id: firstArea.id });
          setFormValues(form.getFieldsValue());
        }
        setLoadingAreas(false);
      });
  };

  useEffect(() => {
    setLoadingAreas(true);
    API.areas
      .getCompact(areasQuery, locale, currentCountry?.id)
      .then((areasCompact: CompactItem[]) => {
        setAreas(areasCompact);
        setLoadingAreas(false);
      });
  }, [areasQuery]);

  useEffect(() => {
    setFormValues(form.getFieldsValue());
    setCountries([{ id: 'ES', name: 'España', dial_code: '+34' }]);
    setCurrentCountry({ id: 'ES', name: 'España', dial_code: '+34' });
    setAreasDefaultValue();
  }, []);

  useEffect(() => {
    displayErrors(alertErrors, 'contact', t, fieldTranslate);
  }, [alertErrors]);

  useEffect(() => {
    setCurrentCountry(
      countries.find(({ id }: Country) => formValues.country_id === id)
    );
  }, [formValues.country_id]);

  const cleanForm = (): void => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    setFormValues(initialValues);
  };

  const onBackendValidationError = async (
    response: Response
  ): Promise<void> => {
    const body = await response?.json();
    setFormErrors(body?.errors ? body.errors : []);
    form.validateFields();
  };

  const onFinish = async (values: Record<string, string>) => {
    if (values.agree_legal) {
      const formDataParams = {
        ...initialValues,
        ...values,
        department: fieldTranslate('contact.departments', values.department),
        phone:
          !!!values.phone || values.phone === ''
            ? undefined
            : `${currentCountry?.dial_code} ${values.phone}`
      };

      try {
        await API.contact.sendEmail(formDataParams);
        cleanForm();
        message.success(t({ id: 'FORMS.SUCCESS' }));
      } catch (response) {
        onBackendValidationError(response as Response);
      }
    } else {
      message.error(t({ id: 'FORMS.NO_LEGAL' }));
    }
  };

  return (
    <div className="s-contact">
      <div className="header">
        <div className="block block__single">
          <div className="content">
            <h2>{t({ id: 'CONTACT.HEADER.TITLE' })}</h2>
            <p>{t({ id: 'CONTACT.HEADER.SUBTITLE' })}</p>
          </div>
        </div>
      </div>
      <div className="form">
        <div className="block block__single">
          <div className="content left">
            <h3>{t({ id: 'CONTACT.FORM' })}</h3>
            <Form
              id="contact-form"
              form={form}
              onValuesChange={(changedValues) => {
                clearValidations(Object.keys(changedValues), fieldErrors);
                setFormValues(form.getFieldsValue());
              }}
              initialValues={initialValues}
              onFinish={onFinish}
              onFinishFailed={setAlertErrors}
            >
              <h4>{t({ id: 'FORMS.CONTACT.CONTACT_TITLE' })}</h4>

              <Row gutter={16}>
                <Col xs={24} lg={12}>
                  <FormLabel
                    label={fieldTranslate('contact', 'name')}
                    required
                  />
                  <Form.Item rules={validation('name')} name="name">
                    <Input autoFocus />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <FormLabel
                    label={fieldTranslate('contact', 'last_name')}
                    required
                  />
                  <Form.Item rules={validation('last_name')} name="last_name">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} lg={12}>
                  <FormLabel
                    label={fieldTranslate('contact', 'position')}
                    required
                  />
                  <Form.Item rules={validation('position')} name="position">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <FormLabel
                    label={fieldTranslate('contact', 'department')}
                    required
                  />
                  <Form.Item rules={validation('department')} name="department">
                    <Select>
                      {[
                        'purchase',
                        'technical',
                        'sales',
                        'management',
                        'other'
                      ].map((department: string) => (
                        <Select.Option key={department} value={department}>
                          {fieldTranslate('contact.departments', department)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <FormLabel
                    label={fieldTranslate('contact', 'country')}
                    required
                  />
                  <Form.Item rules={validation('country_id')} name="country_id">
                    <Select
                      loading={loadingCountries}
                      onSearch={debounce(setCountriesQuery, 300)}
                      filterOption={false}
                      showSearch
                      onChange={() => {
                        setCountriesQuery('');
                      }}
                    >
                      {countries.map(({ id, name }: Country) => {
                        return (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {isSpain && (
                <Row gutter={16}>
                  <Col span={24}>
                    <FormLabel
                      label={fieldTranslate('contact', 'area')}
                      required
                    />
                    <Form.Item rules={validation('area_id')} name="area_id">
                      <Select
                        loading={loadingAreas}
                        onSearch={debounce(setAreasQuery, 300)}
                        filterOption={false}
                        showSearch
                        onChange={() => {
                          setAreasQuery('');
                        }}
                      >
                        {areas.map(({ id, name }: CompactItem) => {
                          return (
                            <Select.Option key={id} value={id}>
                              {name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row gutter={16}>
                <Col span={24}>
                  <FormLabel label={fieldTranslate('contact', 'phone')} />
                  <Form.Item rules={validation('phone')} name="phone">
                    <Input addonBefore={currentCountry?.dial_code} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <FormLabel
                    label={fieldTranslate('contact', 'email')}
                    required
                  />
                  <Form.Item rules={validation('email')} name="email">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <h4>{t({ id: 'FORMS.CONTACT.REQUEST_TITLE' })}</h4>

              <Row gutter={16}>
                <Col span={24}>
                  <FormLabel
                    label={fieldTranslate('contact', 'message_subject')}
                    extra={t(
                      { id: 'FORMS.LENGTH' },
                      {
                        current: formValues?.message_subject?.length || 0,
                        total: 50
                      }
                    )}
                    required
                  />
                  <Form.Item
                    rules={validation('message_subject')}
                    name="message_subject"
                  >
                    <Input maxLength={50} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <FormLabel
                    label={fieldTranslate('contact', 'message_text')}
                    extra={t(
                      { id: 'FORMS.LENGTH' },
                      {
                        current: formValues?.message_text?.length || 0,
                        total: 500
                      }
                    )}
                    required
                  />
                  <Form.Item
                    rules={validation('message_text')}
                    name="message_text"
                  >
                    <Input.TextArea
                      autoSize={{ maxRows: 10, minRows: 10 }}
                      maxLength={500}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <InfoLegalText
                    title={t({ id: 'FORMS.CONTACT.DATA_CONTROLLER' })}
                    description={
                      <b>{t({ id: 'FORMS.CONTACT.DATA_CONTROLLER_TEXT' })}</b>
                    }
                  />
                  <InfoLegalText
                    title={t({ id: 'FORMS.CONTACT.AIM' })}
                    description={t({ id: 'FORMS.CONTACT.AIM_TEXT' })}
                  />
                  <InfoLegalText
                    title={t({ id: 'FORMS.CONTACT.LEGITIMATION' })}
                    description={t({ id: 'FORMS.CONTACT.LEGITIMATION_TEXT' })}
                  />
                  <InfoLegalText
                    title={t({ id: 'FORMS.CONTACT.ASSIGNMENT' })}
                    description={t({ id: 'FORMS.CONTACT.ASSIGNMENT_TEXT' })}
                  />
                  <InfoLegalText
                    title={t({ id: 'FORMS.CONTACT.RIGHTS' })}
                    description={t(
                      { id: 'FORMS.CONTACT.RIGHTS_TEXT' },
                      {
                        aepd_link: (
                          <a
                            href="www.aepd.es"
                            target="_blank"
                            rel="noreferrer"
                          >
                            www.aepd.es
                          </a>
                        )
                      }
                    )}
                  />
                  <InfoLegalText
                    title={t({ id: 'FORMS.CONTACT.ADDITIONAL_INFORMATION' })}
                    description={t(
                      { id: 'FORMS.CONTACT.ADDITIONAL_INFORMATION_TEXT' },
                      {
                        privacy_policy: (
                          <PrivacyLink privacyFileName="privacy_policy" />
                        )
                      }
                    )}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="agree-legal"
                    name="agree_legal"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  <span className="switch-text">
                    {t({ id: 'FORMS.CONTACT.AGREE_LEGAL' })}
                  </span>
                </Col>
              </Row>
            </Form>

            <Button
              className="layout-button"
              form="contact-form"
              htmlType="submit"
            >
              {t({ id: 'FORMS.CONTACT.SEND' })}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
