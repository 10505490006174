import { variables } from '../core/variables';

export const layout = {
  height: '100%',
  margin: 0,
  lineHeight: '1.5715',
  fontSize: variables['@font-size-lg'],

  '& [disabled]': {
    pointerEvents: 'initial'
  },

  '& #root': {
    height: '100%'
  },

  '& .layout-button, .layout-button:focus': {
    marginTop: '10px',
    backgroundColor: variables['@red-diesel'],
    color: 'white',
    border: 'none',
    fontWeight: 700,
    fontSize: '20px',
    height: 'auto',
    padding: '18px 42px',
    lineHeight: 1,
    letterSpacing: '0.01em',
    borderRadius: variables['@border-radius'],

    '&:hover': {
      backgroundColor: variables['@red-diesel-hover'],
      color: 'white'
    },

    [`@media (max-width: ${variables['@mobile-width']})`]: {
      fontSize: '16px'
    }
  },

  '& .half': {
    width: '50%',

    [`@media (max-width: ${variables['@responsive-width']})`]: {
      width: '100%'
    },

    '& img': {
      width: '100%'
    }
  },

  '& .ant-layout-content': {
    textAlign: '-webkit-center',
    marginTop: variables['@layout-header-height']
  },

  '& .ant-spin-nested-loading, .ant-spin-container': {
    height: '100%'
  },

  '& .ant-spin-forced': {
    height: 'var(--vh)',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  '& .ant-message-notice-content': {
    borderRadius: variables['@border-radius-antd'],
    fontFamily: variables['@font-family']
  },

  '& .placeholder-youtube-video': {
    position: 'relative',

    '& .placeholder-image': {
      width: '100%',
      borderRadius: variables['@border-radius']
    },

    '& .youtube-cookies': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: variables['@border-radius'],
      backgroundColor: 'white',
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',

      '& button': {
        backgroundColor: variables['@layout-header-background'],
        padding: '10px 16px',
        fontSize: '16px',

        '&:hover': {
          backgroundColor: variables['@red-diesel']
        }
      }
    }
  },

  '& .block': {
    width: '100%',
    maxWidth: variables['@block-width'],
    padding: '0 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px auto',

    [`@media (max-width: ${variables['@responsive-width']})`]: {
      flexDirection: 'column'
    },

    '&__header': {
      flexDirection: 'row'
    },

    '&__full': {
      maxWidth: variables['@max-block-width'],
      margin: '0px auto'
    },

    '&__single': {
      justifyContent: 'center',
      margin: '0px auto',

      '&__legal': {
        alignItems: 'flex-start'
      }
    },

    '&__top': {
      alignItems: 'flex-start'
    },

    '& .content': {
      display: 'block',
      textAlign: 'center',

      '&__full': {
        width: '100%'
      }
    },

    '& .left': {
      textAlign: 'left'
    },

    '& .right': {
      textAlign: 'right'
    }
  },

  '& .cookies': {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    maxWidth: '650px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '20px',
    color: 'black',
    boxShadow: variables['@box-shadow-base'],
    borderRadius: '2px',
    fontFamily: variables['@font-family'],

    '& a': {
      color: variables['@hint-color']
    },

    '& .buttons': {
      width: '100%',
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'space-between',

      '& button': {
        width: '200px',
        color: 'white',
        textTransform: 'uppercase',
        backgroundColor: variables['@action-button-bg-color'],
        borderColor: variables['@action-button-bg-color'],

        '&:hover, &:focus': {
          color: 'white',
          backgroundColor: variables['@action-button-hover-bg-color'],
          borderColor: variables['@action-button-hover-bg-color']
        }
      }
    }
  },

  '& .video-player': {
    width: '100%',

    '& iframe': {
      display: 'block',
      margin: 0,
      height: 'var(--iframe-height)',
      width: '100%',
      borderRadius: variables['@border-radius']
    }
  },

  '& h1, h2, h3, h4, h5, h6': {
    margin: 0
  },

  '& .legal': {
    margin: '50px 0',
    textAlign: 'left',

    '& h2': {
      fontSize: '36px',
      fontWeight: 700
    },

    '& h3': {
      fontSize: '24px',
      fontWeight: 600
    },

    '& table': {
      borderCollapse: 'collapse',
      width: '100%',
      marginBottom: '20px'
    },

    '& td, th': {
      border: '1px solid #dddddd',
      textAlign: 'left',
      padding: '8px'
    },

    '& .table-owner': {
      backgroundColor: '#dddddd'
    }
  },

  '& .ant-switch': {
    backgroundColor: '#a9abb1'
  },

  '& .ant-switch-checked': {
    background: `${variables['@red-diesel']} !important`
  },

  '@media (max-width: 768px)': {
    '& .cookies': {
      maxWidth: '250px',

      '& .buttons': {
        flexDirection: 'column',

        '& button': {
          width: '100%'
        },

        '& button + button': {
          marginTop: '10px'
        }
      }
    }
  }
};
