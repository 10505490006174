import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="block block__single block__single__legal">
      <div className="legal privacy-policy">
        <h2>Privacy policy</h2>
        <h3>1. INTRODUCTION</h3>
        <p>
          Your privacy is important to us, which is why – through this document
          – we explain what data we collect from Users, how we use it, and for
          what purpose, among other things.
        </p>
        <p>
          For your information, we will never ask for more information than what
          is strictly necessary for the services required; we will never share
          data with third parties, unless legally obliged to do so or with prior
          authorisation; and we will never use your data for any purpose other
          than what was specified beforehand.
        </p>
        <p>
          As a User, you should carefully read this Privacy Policy on a regular
          basis and whenever you enter your personal data. This is because this
          document may have been modified as the provider may modify any type of
          information that may appear on the website to comply with the
          regulations or update the policy, without any obligation to give prior
          notice or inform users of such obligations. Publication on the
          Provider’s website will be sufficient.
        </p>
        <h3>2. CONTROLLER:</h3>
        <p>
          Identity: <b>TOT COMERCIAL, S.A.</b> (also the ‘Provider’)
          <br />
          NIF (Tax ID No.): <b>A25031154</b>
          <br />
          Mailing address: <b>Partida Horta Amunt s/n, 25600, Balaguer</b>
          <br />
          Email:{' '}
          <b>
            <a target="_blank" href="mailto:gespasa@gespasa.es">
              gespasa@gespasa.es
            </a>
          </b>
        </p>
        <p>
          <b>TOT COMERCIAL, S.A.</b>, as the party responsible for the website
          and web application, in accordance with the provisions of Regulation
          (EU) 2016/679 of 27 April 2016 on the protection of natural persons
          with regard to the processing of personal data and on the free
          movement of such data and other current legislation on the protection
          of such personal data, and Act 34/2002 of 11 July on Information
          Society Services and Electronic Commerce (LSSICE), hereby informs you
          that it has implemented the necessary technical and organisational
          measures to guarantee and protect the confidentiality, integrity and
          availability of the data entered.
        </p>
        <p>
          In relation to Users’ data contained within the web application
          (hereinafter ‘<b>DIESELPLUS</b>’), <b>TOT COMERCIAL, S.A.</b> will be
          the controller as it provides the service of managing the platform for
          the control of stocks of fuels, lubricants or other products that can
          be measured through equipment compatible with <b>DIESELPLUS</b>, and
          for the control of the fleet through its consumption of fuel or other
          products and its positioning. The platform displays such content as
          lists, reports, alarms, statistics or sends it via email to Users.
          There is a duly signed data processing agreement between both parties.
        </p>
        <h3>3. METHOD OF COLLECTING DATA AND DATA THAT WE COLLECT:</h3>
        <p>The data that we collect and how we obtain it are as follows:</p>
        <p>
          1. Through the contact form: name, company, location, email, phone
          number
          <br />
          2. Through the data collection form for registration and contracting:
          name, company, location, email, phone number, bank details or
          information regarding a credit or debit card and information for
          setting up the account according to the licences purchased: tanks,
          products, measuring equipment or level probes.
          <br />
          3. Through the forms on <b>DIESELPLUS</b>: data relating to the User’s
          account to identify personnel and vehicles authorised for refuelling,
          information on the storage and flow of fuels, lubricants or other
          products.
          <br />
          4. Through direct communication with compatible equipment or
          integration platforms: telematic receipt of refuelling in the supply
          equipment, level readings, consumption readings, positioning and
          information on the vehicle fleet.
        </p>
        <p>Special data categories of personal data are not processed.</p>
        <h3>4. PURPOSE OF PROCESSING:</h3>
        <p>
          Your personal data will only be used for the following purposes, with
          your consent for each purpose in the relevant section of the website
          or document provided:
          <br />
          1. <b>Contact form:</b> to respond to queries and/or provide
          information required by the User.
          <br />
          2. <b>Registration and contracting form:</b> for the creation and
          configuration of a <b>DIESELPLUS</b> account and to access the
          services of the web application. This includes bank information and
          payment method to manage online contracting and licence renewals.
          <br />
          3. <b>DIESELPLUS forms:</b>
          <ul>
            <li>
              Management of personnel and vehicle fleet authorised for
              refuelling.
            </li>
            <li>
              Management of fuel, fluids or other products from the
              organisation.
            </li>
            <li>
              Management of storage and measuring equipment necessary for the
              application to work.
            </li>
          </ul>
          4. <b>Direct communication with compatible equipment:</b>
          <ul>
            <li>
              Management of flows and stocks of fuel, fluids or other products
              from the organisation.
            </li>
            <li>
              Management of information regarding the status and location of the
              vehicle fleet.
            </li>
            <li>
              Geolocation: if the integration module for compatible third-party
              geolocation applications is purchased, the location of the worker
              who forms part of the fleet will be registered by DIESELPLUS and
              regularly updated when this option is enabled. DIESELPLUS is
              exempt from any responsibility of informing the worker or User.
              This responsibility lies with the client that purchases the
              service.
            </li>
          </ul>
          5. <b>Other purposes:</b>
          <ul>
            <li>
              To carry out commercial, administrative, tax, accounting
              procedures that are necessary in accordance with current
              legislation;
            </li>
            <li>
              To learn your opinion and improve the services provided by the
              website;
            </li>
          </ul>
          You may revoke your consent at any time by sending an email to the
          aforementioned email address with the subject ‘Unsubscribe’.
        </p>
        <p>
          In accordance with the LSSICE, <b>TOT COMERCIAL, S.A.</b> does not
          send SPAM so it does not send marketing emails that have not been
          previously requested or authorised by you. As a result, users have the
          option to revoke their express consent to receive our emails in all
          the emails they will receive from the provider.
        </p>
        <p>
          We will not process your personal data for any purpose other than the
          ones described, unless legally obliged to do so or by court order.
        </p>
        <p>
          You will not be subjected to automated decision-making that produces
          effects on your data.
        </p>
        <h3>5. LAWFUL BASIS FOR PROCESSING</h3>
        <p>
          The lawful basis for the processing of your data is your consent,
          given at the time when you provided your data in the corresponding
          form to fulfil the purposes, when you ticked the relevant box.
        </p>
        <p>
          Failure to provide the personal data requested or not agreeing to this
          Privacy Policy means that it will not be possible to fulfil the
          aforementioned purposes.
        </p>
        <p>
          In those cases where there is a prior contractual relationship between
          the parties, the lawful basis to comply with administrative, tax,
          accounting and labour obligations that are necessary by virtue of
          current legislation will be the{' '}
          <b>prior existence of the business relationship</b> between the
          parties.
        </p>
        <p>
          The prospective or commercial offer is based on the consent that is
          requested, and under no circumstances will the withdrawal of such
          consent condition the performance of the subscription agreement.
        </p>
        <h3>6. DATA RETENTION PERIOD:</h3>
        <p>
          Your data will be kept for the duration of your business relationship
          with us or until you exercise your right to erasure, your right to
          object or your right to restrict processing. Nevertheless, we will
          keep your data for the statutory retention periods pursuant to the
          applicable provisions.
        </p>
        <p>
          The data provided and stored through the forms on <b>DIESELPLUS</b> or
          its compatible equipment is linked to the User’s licence to use. Its
          retention periods may be regulated by the SAAS agreement or the
          annexes with the particular information according to the licences
          purchased.
        </p>
        <h3>7. RECIPIENTS OF ASSIGNMENTS OR TRANSFERS:</h3>
        <p>
          <b>TOT COMERCIAL, S.A.</b> does not assign or transfer data either
          inside or outside the EU. The Provider will only provide information
          to the law enforcement authorities by court order or when obliged to
          do so by a legal standard, without prejudice to the possibility of
          blocking or cancelling an account if there are any signs that a user
          has committed an offence. The information provided will only be what
          the provider has at the time.
        </p>
        <p>
          The information you provide us with through this website or the web
          application will be hosted on the servers of{' '}
          <b>TOT COMERCIAL, S.A.</b>, contracted with the company that provides
          web hosting services to it. The processing of the entity’s data is
          regulated by a data processing agreement between the provider and this
          company.
        </p>
        <h3>8. RIGHTS OF DATA SUBJECTS:</h3>
        <p>
          As a User/data subject, you may request the exercise of the following
          rights before <b>TOT COMERCIAL, S.A.</b> by sending a letter to the
          mailing address in the heading or by emailing{' '}
          <a href="mailto:lopd@gespasa.es">lopd@gespasa.es</a>, with the
          Subject: "DATA PROTECTION: RIGHTS OF DATA SUBJECTS".
        </p>
        <p>
          Rights:
          <ul>
            <li>
              <b>Right of access:</b> gives data subjects the right to know and
              obtain information regarding their personal data which has been
              subjected to processing.
            </li>
            <li>
              <b>Right to rectification:</b> gives data subjects the right to
              rectify errors and have inaccurate or incomplete data rectified.
            </li>
            <li>
              <b>Right to erasure:</b> gives data subjects the right to ask for
              any data that is inadequate or excessive to be deleted.
            </li>
            <li>
              <b>Right to object:</b> gives data subjects the right to object to
              the processing of their personal data or stop an organisation from
              doing so.
            </li>
            <li>
              <b>Right to restrict processing:</b> gives data subjects the right
              to limit the way their personal data is used, to restrict its
              processing in the future, for the exercise or defence of claims.
            </li>
            <li>
              <b>Right to data portability:</b> gives data subjects the right to
              obtain the data undergoing processing so they can transfer it to
              another controller without any hindrance.
            </li>
            <li>
              <b>
                Right to not be subject to automated decision-making (including
                profiling):
              </b>{' '}
              gives data subjects the right to not be subject to automated
              decision-making that produces a significant effect on a person.
            </li>
          </ul>
        </p>
        <p>
          As a user, you have the right to revoke the consent you have given at
          any time. Withdrawal of consent will not affect the lawfulness of the
          processing carried out prior to the withdrawal of such consent.
        </p>
        <p>
          You also have the right to file a complaint with the competent
          supervisory authority if you believe that your rights with regard to
          the protection of your data have been violated (
          <a target="_blank" href="http://www.aepd.es">
            www.aepd.es
          </a>
          ).
        </p>
        <h3>9. ADDITIONAL INFORMATION</h3>
        <h4>SECURITY MEASURES</h4>
        <p>
          The data you provide us with will be treated as confidential. The
          provider has adopted all the technical and organisational measures and
          all the necessary levels of protection to ensure security in the
          processing of data and prevent its alteration, loss, theft,
          unauthorised processing or access, in accordance with the state of the
          art and nature of the data stored. Likewise, it also guarantees that
          the processing and registration of data in files, programs, systems or
          equipment, premises and centres comply with the data integrity and
          security requirements and conditions provided for in current
          legislation.
        </p>
        <h4>LANGUAGE</h4>
        <p>
          This Privacy Policy is in Spanish. Therefore, in the event of any
          conflict between any of the versions provided in other languages, the
          Spanish version will prevail.
        </p>
        <h4>CHANGES TO THIS PRIVACY POLICY</h4>
        <p>
          <b>TOT COMERCIAL, S.A.</b> reserves the right to amend this policy to
          adapt it to legislative or case law changes.
        </p>
        <h4>REVOCABILITY</h4>
        <p>
          The consent given, whether for processing or transfer of the data of
          data subjects, can be revoked at any time by notifying{' '}
          <b>TOT COMERCIAL, S.A.</b> of it under the terms set out in this
          Policy for the exercise of rights. This revocation will in no case be
          retroactive.
        </p>
        <h4>LEGISLATION</h4>
        <p>
          In general, the relationships between <b>TOT COMERCIAL, S.A.</b> and
          the Users of its telematic services available on this website are
          subject to Spanish legislation and jurisdiction, to which the parties
          expressly submit themselves to the jurisdiction of the Courts and
          Tribunals of Lleida.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
