import React from 'react';
import useEnvironment from '../../hooks/useEnvironment';
import useLocale from '../../hooks/useIntl';

import useTranslate from '../../hooks/useTranslate';

interface Props {
  privacyFileName: string;
}

const PrivacyLink: React.FC<Props> = ({ privacyFileName }: Props) => {
  const t = useTranslate();
  const { locale } = useLocale();
  const { getLiteral } = useEnvironment();

  const policiesUrl = getLiteral('policies', `${privacyFileName}_url`, [
    { language: locale || '' }
  ]);
  return (
    <a href={policiesUrl} target="_blank" rel="noreferrer">
      {t({ id: `POLICIES.${privacyFileName.toUpperCase()}` })}
    </a>
  );
};

export default PrivacyLink;
