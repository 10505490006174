import React, { useMemo } from 'react';

import { Menu } from 'antd';

import useTranslate from '../../hooks/useTranslate';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import useToggleCookies from '../../hooks/useToggleCookies';
import useEnvironment from '../../hooks/useEnvironment';
import routes from '../../routes';

interface Props {
  onClick?: () => void;
}

const FooterMenu: React.FC<Props> = ({ onClick }: Props) => {
  const t = useTranslate();
  const urlLocation = useLocation();
  const { setBannerVisible } = useToggleCookies();
  const { isTablet } = useEnvironment();
  const navigate = useNavigate();

  const selectedSubMenu = useMemo(() => {
    const paths = urlLocation.pathname.split('/');

    if (!!paths && !!paths[0]) {
      return `/${paths[0]}}`;
    }

    return '/home';
  }, [urlLocation.pathname]);

  const items = [
    {
      key: 'cookies_policy',
      label: t({ id: 'POLICIES.COOKIES_POLICY' }),
      onClick: () => {
        navigate(routes.cookies_policy());
      }
    },
    {
      key: 'privacy_policy',
      label: t({ id: 'POLICIES.PRIVACY_POLICY' }),
      onClick: () => {
        navigate(routes.privacy_policy());
      }
    },
    {
      key: 'legal_notice',
      label: t({ id: 'POLICIES.LEGAL_NOTICE' }),
      onClick: () => {
        navigate(routes.legal_notice());
      }
    },
    {
      key: 'config_cookies',
      label: t({ id: 'COOKIES.CONFIG_TITLE' }),
      onClick: () => {
        setBannerVisible(true);
      }
    }
    // ,
    // {
    //   key: 'terms_of_service',
    //   label: t({ id: 'POLICIES.TERMS_OF_SERVICE' }),
    //   onClick: () => {
    //     navigate(routes.terms_of_service());
    //   }
    // }
  ];

  return (
    <Menu
      mode={isTablet ? 'vertical' : 'horizontal'}
      selectedKeys={[selectedSubMenu]}
      onClick={() => {
        if (!!onClick) {
          onClick();
        }
      }}
      items={items}
      overflowedIndicator={<MenuOutlined />}
    />
  );
};

export default FooterMenu;
