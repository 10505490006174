import React, { createContext, useContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { Messages } from '../locales/messages';

import english from '../locales/en.json';
import spanish from '../locales/es.json';

import { CONFIG } from '../config/config';
import { flattenMessages } from '../lib/flatten-messages.lib';
import useLocalStorageState from './useLocalStorageState';
import { useSearchParams } from 'react-router-dom';
import {
  Locale as FullLocale,
  Locale as ProviderLocale
} from 'antd/lib/locale-provider';

function defaultLocale(): ProviderLocale {
  const aux: {
    default: ProviderLocale;
  } = require(`antd/lib/locale/en_US.js`);
  require(`dayjs/locale/en.js`);
  return aux.default;
}

function getFullLocale(): ProviderLocale {
  const lang = navigator.language.replace('-', '_');

  try {
    const aux: {
      default: ProviderLocale;
    } = require(`antd/lib/locale/${lang}.js`);
    require(`dayjs/locale/${aux.default.locale}.js`);
    return aux.default;
  } catch (e) {
    return defaultLocale();
  }
}

const ca: Messages = english;
const en: Messages = english;
const es: Messages = spanish;
const fr: Messages = english;
const nl: Messages = english;
const pt: Messages = english;

const AvailableLocalesMap = { ca, en, es, fr, nl, pt } as const;
type Locale = keyof typeof AvailableLocalesMap;
const availableLocales: Locale[] = Object.keys(AvailableLocalesMap) as Locale[];
const defaultLang = CONFIG.LANGUAGE.DEFAULT as Locale;

interface LocaleContextState {
  locale: Locale;
  availableLocales: Locale[];
  setLocale: (locale: Locale) => void;
  fullLocale: FullLocale;
}

const LocaleContext = createContext<LocaleContextState>({
  locale: defaultLang,
  availableLocales,
  setLocale: () => undefined,
  fullLocale: defaultLocale()
});

interface Props {
  children: React.ReactNode;
}

export const LocaleProvider: React.FC<Props> = ({ children }: Props) => {
  const [search] = useSearchParams();

  const [fullLocale, setFullLocale] = useState<FullLocale>(defaultLocale());
  const [locale, setLocale] = useLocalStorageState<Locale>(
    'LOCALE',
    defaultLang
  );

  const messages: Record<string, any> = AvailableLocalesMap[locale];

  const langParam = search.get('lang');

  useEffect(() => {
    if (!!!locale || !!langParam) {
      switch (langParam || navigator.languages[0].slice(0, 2)) {
        case 'es':
          setLocale('es');
          break;
        default:
          setLocale('en');
          break;
      }
    }
  }, []);

  useEffect(() => {
    const returnLocale = getFullLocale();

    setFullLocale(returnLocale);

    document.documentElement.setAttribute('lang', returnLocale.locale);
  }, [locale]);

  return (
    <LocaleContext.Provider
      value={{ locale, setLocale, availableLocales, fullLocale }}
    >
      <IntlProvider locale={locale} messages={flattenMessages(messages)}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default function useLocale(): LocaleContextState {
  return useContext(LocaleContext);
}
