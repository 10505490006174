import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

interface Props {
  label: string;
  required?: boolean;
  info?: string;
  extra?: string;
}

const FormLabel: React.FC<Props> = ({
  label,
  required,
  info,
  extra
}: Props) => {
  return (
    <div className="form__label" title={label.toUpperCase()}>
      {info && (
        <Tooltip
          title={info}
          overlayClassName="form__label__info__tooltip"
          placement="topLeft"
          color="white"
          destroyTooltipOnHide
        >
          <InfoCircleFilled className="form__label__info" />
        </Tooltip>
      )}
      {label}
      {required && <span className="form__label__required">*</span>}
      {!!extra && <span className="form__label__extra">{extra}</span>}
    </div>
  );
};

export default FormLabel;
