import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Col, Row } from 'antd';

import useTranslate from '../../hooks/useTranslate';
import CustomImage from '../Shared/CustomImage';
import RequestDemoFooter from '../Shared/RequestDemoFooter';

const Functions: React.FC = () => {
  const t = useTranslate();
  const { hash } = useLocation();

  const scrollTo = (elementTag: string): void => {
    const element = document.getElementById(elementTag);

    if (!!element) {
      for (let i = 0; i < element.offsetTop - 80 /*Header height*/; i++) {
        setTimeout(() => {
          window.scrollTo(0, i);
        }, 0.1 * i);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (!!hash && hash !== '') {
        scrollTo(hash.replace('#', ''));
      }
    }, 100);
  }, [hash]);

  return (
    <div className="s-functions">
      <div className="header">
        <div className="block block__full">
          <div className="content half left">
            <h2>{t({ id: 'FUNCTIONS.HEADER.TITLE' })}</h2>
            <p>{t({ id: 'FUNCTIONS.HEADER.SUBTITLE' })}</p>
          </div>
          <div className="content half right">
            <CustomImage pageName="functions" imageName="header" />
          </div>
        </div>
      </div>
      <div className="icons">
        <div className="block block__single">
          <div className="content content__full">
            <Row gutter={44}>
              {['supplies', 'tanks', 'consumption', 'integrations'].map(
                (icon: string) => {
                  return (
                    <Col
                      xs={24}
                      sm={12}
                      md={6}
                      key={icon}
                      className="data"
                      onClick={() => {
                        scrollTo(icon);
                      }}
                    >
                      <CustomImage
                        pageName="functions"
                        imageName={`icon-${icon}`}
                      />
                      <span>
                        {t({
                          id: `FUNCTIONS.${icon.toUpperCase()}.TITLE`
                        })}
                      </span>
                    </Col>
                  );
                }
              )}
            </Row>
          </div>
        </div>
      </div>
      <div className="supplies" id="supplies">
        <div className="block block__single">
          <div className="content">
            <div className="titles">
              <h3>{t({ id: 'FUNCTIONS.SUPPLIES.TITLE' })}</h3>
              <h4>
                {t({ id: 'FUNCTIONS.SUPPLIES.SUBTITLE' }, { br: <br /> })}
              </h4>
            </div>
          </div>
        </div>
        <div className="block block__top data">
          <div className="content half left">
            <h5>{t({ id: 'FUNCTIONS.SUPPLIES.FIRST_TITLE' })}</h5>
            <p>
              {t({ id: 'FUNCTIONS.SUPPLIES.FIRST_SUBTITLE' }, { br: <br /> })}
            </p>
          </div>
          <div className="content half right">
            <CustomImage pageName="functions" imageName="supplies-first" />
          </div>
        </div>
        <div className="block block__top data">
          <div className="content half left">
            <h5>{t({ id: 'FUNCTIONS.SUPPLIES.SECOND_TITLE' })}</h5>
            <p>
              {t({ id: 'FUNCTIONS.SUPPLIES.SECOND_SUBTITLE' }, { br: <br /> })}
            </p>
          </div>
          <div className="content half right">
            <CustomImage pageName="functions" imageName="supplies-second" />
          </div>
        </div>
      </div>
      <div className="tanks" id="tanks">
        <div className="block block__single">
          <div className="content">
            <div className="titles">
              <h3>{t({ id: 'FUNCTIONS.TANKS.TITLE' })}</h3>
              <h4>{t({ id: 'FUNCTIONS.TANKS.SUBTITLE' }, { br: <br /> })}</h4>
            </div>
            <div className="cards">
              <Row gutter={44}>
                {['gauge', 'integration'].map((card: string) => {
                  return (
                    <Col sm={24} md={12} key={card}>
                      <Card>
                        <div>
                          <h5>
                            {t(
                              {
                                id: `FUNCTIONS.TANKS.CARD_${card.toUpperCase()}_TITLE`
                              },
                              { br: <br /> }
                            )}
                          </h5>
                          <p>
                            {t(
                              {
                                id: `FUNCTIONS.TANKS.CARD_${card.toUpperCase()}_TEXT`
                              },
                              { br: <br /> }
                            )}
                          </p>
                        </div>
                        <div className="image">
                          <CustomImage
                            pageName="functions"
                            imageName={`tank-card-${card}`}
                          />
                        </div>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </div>
      <div className="consumption" id="consumption">
        <div className="block block__single">
          <div className="content">
            <div className="titles">
              <h3>{t({ id: 'FUNCTIONS.CONSUMPTION.TITLE' })}</h3>
              <h4>
                {t({ id: 'FUNCTIONS.CONSUMPTION.SUBTITLE' }, { br: <br /> })}
              </h4>
            </div>
          </div>
        </div>
        <div className="block block__top data">
          <div className="content half left">
            <h5>{t({ id: 'FUNCTIONS.CONSUMPTION.FIRST_TITLE' })}</h5>
            <p>
              {t(
                { id: 'FUNCTIONS.CONSUMPTION.FIRST_SUBTITLE' },
                { br: <br /> }
              )}
            </p>
          </div>
          <div className="content half right">
            <CustomImage pageName="functions" imageName="consumption-first" />
          </div>
        </div>
      </div>
      <div className="integrations" id="integrations">
        <div className="block block__single">
          <div className="content">
            <div className="titles">
              <h3>{t({ id: 'FUNCTIONS.INTEGRATIONS.TITLE' })}</h3>
              <h4>
                {t({ id: 'FUNCTIONS.INTEGRATIONS.SUBTITLE' }, { br: <br /> })}
              </h4>
            </div>
            <div className="cards">
              <Row gutter={44}>
                {['integration'].map((card: string) => {
                  return (
                    <Col sm={24} md={12} key={card}>
                      <Card>
                        <div>
                          <h5>
                            {t(
                              {
                                id: `FUNCTIONS.INTEGRATIONS.CARD_${card.toUpperCase()}_TITLE`
                              },
                              { br: <br /> }
                            )}
                          </h5>
                          <p>
                            {t(
                              {
                                id: `FUNCTIONS.INTEGRATIONS.CARD_${card.toUpperCase()}_TEXT`
                              },
                              { br: <br /> }
                            )}
                          </p>
                        </div>
                        <div className="image">
                          <CustomImage
                            pageName="functions"
                            imageName={`integrations-card-${card}`}
                          />
                        </div>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </div>
      <RequestDemoFooter />
    </div>
  );
};

export default Functions;
