import { variables } from '../core/variables';

export const forms = {
  '& .form': {
    '& a': {
      color: variables['@red-diesel']
    },

    '& .switch-text': {
      verticalAlign: 'middle',
      position: 'absolute',
      top: '6px',
      left: '60px'
    },

    '& .info-legal-text': {
      margin: '0px',
      '&__title': {
        fontWeight: 'bold',
        marginRight: '4px',
        fontSize: '16px'
      },
      '&__description a': {
        textTransform: 'lowercase'
      }
    },

    '& .ant-picker, input .ant-input-affix-wrapper': {
      height: '34px',
      width: '100%'
    },

    '& .ant-input-affix-wrapper': {
      paddingTop: 0,
      paddingBottom: 0,

      '& .ant-input': {
        height: '32px'
      }
    },

    '& .ant-input-search': {
      '& input': {
        height: '32px'
      }
    },

    '& .ant-input-password': {
      padding: '0 11px'
    },

    '& .ant-picker, .ant-picker div': {
      width: '100%'
    },

    '& .ant-form-item': {
      marginBottom: '20px'
    },

    '& input, textarea': {
      borderRadius: variables['@border-radius']
    },

    '& .ant-select-selector': {
      borderRadius: `${variables['@border-radius']} !important`,
      height: '34px !important',

      '& .ant-select-selection-item': {
        lineHeight: '32px'
      }
    },

    '&__label': {
      fontSize: '16px',
      fontWeight: '600',
      marginBottom: '5px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: '#2e2f33',

      '&__required': {
        marginLeft: '3px',
        color: variables['@red-diesel']
      },

      '&__info': {
        marginRight: '3px',
        color: '#1890ff',

        '&__tooltip': {
          '& .ant-tooltip-inner': {
            color: 'black',
            padding: '10px',
            minWidth: '30vw'
          }
        }
      },

      '&__extra': {
        position: 'absolute',
        right: '10px',
        color: '#61636b',
        fontSize: '14px',
        fontWeight: 400
      }
    },

    '& .ant-divider-horizontal': {
      marginTop: 0,
      marginBottom: '10px'
    }
  }
};
