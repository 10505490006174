import { variables } from '../core/variables';

export const header = {
  '& .s-header': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    width: '100%',
    zIndex: 99,
    backgroundColor: variables['@layout-header-background'],
    height: variables['@layout-header-height'],
    padding: variables['@layout-header-padding'],

    '&__logo-wrapper': {
      '& img': {
        height: '21px',
        verticalAlign: 'sub',
        cursor: 'pointer'
      }
    },

    '& .ant-menu': {
      backgroundColor: 'transparent',
      border: 'none',
      width: '100%',
      justifyContent: 'flex-end',

      [`@media (max-width: ${variables['@responsive-width']})`]: {
        width: '50px'
      },

      '& .ant-menu-item.login, .ant-menu-item.contact': {
        '& span': {
          padding: '10px 16px',
          border: '1px solid white',
          borderRadius: variables['@border-radius']
        }
      },

      '& .ant-menu-item.login': {
        '&:hover': {
          '& span': {
            borderColor: variables['@red-diesel']
          }
        }
      },

      '& .ant-menu-item.contact': {
        '& span': {
          backgroundColor: 'white',
          color: variables['@layout-header-background']
        },

        '&:hover': {
          '& span': {
            borderColor: variables['@red-diesel'],
            backgroundColor: variables['@red-diesel'],
            color: 'white'
          }
        }
      },

      '& .ant-menu-item.divider': {
        cursor: 'default',

        '& .ant-divider': {
          borderColor: 'white',
          height: '41px',
          margin: 0
        }
      },

      '& .ant-menu-submenu.language': {
        marginLeft: '15px',

        '& .ant-menu-title-content': {
          verticalAlign: 'sub',

          '& .anticon svg': {
            fontSize: '25px'
          }
        },

        '&.ant-menu-submenu-active': {
          '& svg': {
            stroke: variables['@red-diesel'],
            fill: variables['@red-diesel']
          }
        }
      },

      '& .ant-menu-item, .ant-menu-overflow-item': {
        padding: 0,
        margin: 0,
        marginLeft: '20px',
        border: 'none',
        fontSize: '16px',
        fontWeight: 400,
        letterSpacing: '0.03em',
        color: 'white',

        '&.ant-menu-item-active': {
          color: `${variables['@red-diesel']} !important`
        },

        '& span': {
          transition: '0ms',

          '& svg': {
            fontSize: '20px'
          }
        }
      },

      '& .ant-menu-item-selected::after, .ant-menu-item-active::after, .ant-menu-submenu-item-active::after':
        {
          display: 'none'
        }
    }
  },

  [`@media (min-width: ${variables['@responsive-width']})`]: {
    '& .ant-menu-item-selected': {
      '& span': {
        color: variables['@red-diesel']
      }
    },

    '&.header-sub-menu.ant-menu-submenu': {
      top: '65px !important',
      zIndex: 9999,
      borderRadius: `${variables['@border-radius']} !important`,

      '& ul': {
        backgroundColor: 'white !important',
        boxShadow: '5px 10px 31px 0 rgba(29, 30, 32, 0.1)',

        '& li': {
          backgroundColor: 'white !important',

          '& span': {
            fontSize: '16px !important',
            fontWeight: '400 !important',
            color: variables['@layout-header-background']
          },

          '&.ant-menu-item-active': {
            '& span': {
              color: variables['@red-diesel']
            }
          }
        }
      }
    },

    '& .ant-menu-submenu-popup': {
      position: 'fixed',
      top: '75px !important',

      '& .ant-menu-item:hover': {
        backgroundColor: 'white !important'
      }
    }
  },

  [`@media (max-width: ${variables['@responsive-width']})`]: {
    '& .header-sub-menu': {
      left: '0 !important',
      right: '0 !important',
      backgroundColor: `${variables['@layout-header-background']} !important`,
      borderRadius: '0 !important',

      '& span': {
        color: '#f5f5f5 !important'
      },

      '&.ant-menu-submenu ul': {
        backgroundColor: `${variables['@layout-header-background']} !important`,
        margin: 0
      },

      '&.ant-menu:not(.ant-menu-root)': {
        top: '345px !important',
        paddingBottom: '30px',
        position: 'fixed',

        '& ul': {
          backgroundColor: `${variables['@layout-header-background']} !important`,
          borderBottom: '1px solid #61636b',
          margin: '0 16px !important',
          boxShadow: 'initial !important',
          paddingBottom: '3px',

          '& li': {
            backgroundColor: 'transparent !important',
            padding: 0,
            height: '33px',
            lineHeight: '33px',

            '& span': {
              fontSize: '16px !important'
            }
          }
        }
      },

      '&:not(.ant-menu)': {
        top: `${variables['@layout-header-height']} !important`,
        zIndex: 999,
        position: 'fixed',

        '& li': {
          borderTop: '1px solid #61636b',
          margin: '0 16px !important',
          marginBottom: '14px !important',
          padding: 0,
          paddingTop: '7px',
          backgroundColor: `${variables['@layout-header-background']} !important`,

          '& span': {
            fontSize: '20px !important',
            fontWeight: '500 !important'
          },

          '&.language': {
            '& div': {
              margin: '4px 0'
            },

            '&::marker': {
              content: '""'
            }
          }
        },

        '& .ant-menu-submenu-title': {
          padding: 0,

          '& .ant-menu-submenu-arrow': {
            right: 0,
            color: 'white'
          }
        }
      },

      '& .ant-menu-item-active, .ant-menu-item-selected, .language.ant-menu-submenu-active':
        {
          '& span': {
            color: `${variables['@red-diesel']} !important`
          },

          '& .ant-menu-submenu-arrow': {
            color: `${variables['@red-diesel']} !important`
          }
        },

      '& .ant-menu-submenu.language': {
        '&.ant-menu-submenu-active': {
          '& svg': {
            stroke: variables['@red-diesel'],
            fill: variables['@red-diesel']
          }
        }
      },

      '& .divider': {
        display: 'none'
      },

      '& ul, li': {
        borderRadius: '0 !important'
      }
    }
  }
};
