import React from 'react';
import useIntl from '../hooks/useIntl';
import PrivacyPolicyEs from './es/PrivacyPolicy';
import PrivacyPolicyEn from './en/PrivacyPolicy';

const PrivacyPolicy: React.FC = () => {
  const { locale } = useIntl();

  switch (locale) {
    case 'es':
      return <PrivacyPolicyEs />;
    default:
      return <PrivacyPolicyEn />;
  }
};

export default PrivacyPolicy;
