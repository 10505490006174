import React from 'react';

const LegalNotice: React.FC = () => {
  return (
    <div className="block block__single block__single__legal">
      <div className="legal legal-notice">
        <h2>Aviso legal</h2>
        <p>
          Para dar cumplimiento al artículo 10 de la Ley 34/2002 de Servicios de
          la Sociedad de la Información y del Comercio Electrónico, informamos a
          los usuarios de nuestros datos:
        </p>
        <p>
          Denominación Social: <b>TOT COMERCIAL, S.A.</b>
          <br />
          Domicílio Social: <b>Partida Horta Amunt s/n, 25600, Balaguer</b>
          <br />
          NIF: <b>A25031154</b>
          <br />
          E-Mail:{' '}
          <b>
            <a target="_blank" href="mailto:gespasa@gespasa.es">
              gespasa@gespasa.es
            </a>
          </b>
          <br />
          Web:{' '}
          <b>
            <a target="_blank" href="www.gespasa.es">
              www.gespasa.es
            </a>
          </b>
          <br />
          Datos Registrales:{' '}
          <b>
            Inscrita en el Registro Mercantil de Lleida, Folio 17, Tomo 190,
            Libro 143
          </b>
        </p>
        <h3>1. OBJETO</h3>
        <p>
          <b>TOT COMERCIAL, S.A.</b> (desde ahora también el prestador) como
          responsable del sitio web y aplicación web, pone a disposición de los
          usuarios el presente documento, que regula el uso de éstos des de los
          dominios dieselplus.net, mydieselplus.net y sus subdominios, con el
          que pretendemos dar cumplimiento a las obligaciones que dispone la Ley
          34/2002, de servicios de la Sociedad de la Información y del Comercio
          Electrónico (LSSICE o LSSI), así como informar a todos los usuarios
          del sitio web respecto de cuáles son las condiciones de uso del sitio
          web.
        </p>
        <p>
          A través de la web, <b>TOT COMERCIAL, S.A.</b> facilita a los usuarios
          el acceso y utilización de diferentes servicios y contenidos.
        </p>
        <p>
          Toda persona que acceda a este sitio web asume el papel de usuario y
          toda persona que acceda a usar la aplicación web (desde ahora también{' '}
          <b>DIESELPLUS</b>) lo hará como usuario-cliente (ambas partes
          denominadas como usuarios) e implica la aceptación total y sin
          reservas de todas y cada una de las disposiciones incluidas en este
          Aviso Legal, así como cualesquiera otras disposiciones legales que
          fueran de aplicación.
        </p>
        <p>
          Como usuarios, deben leer atentamente el presente Aviso Legal en
          cualquiera de las ocasiones que entren en el sitio web o aplicación
          web, ya que ésta puede sufrir modificaciones ya que el prestador se
          reserva el derecho a modificar cualquier tipo de información que
          pudiera aparecer en la web, sin que haya la obligación de preavisar o
          poner en conocimiento de los usuarios dichas obligaciones, siendo
          suficientes la publicación en el sitio web del prestador.
        </p>
        <h3>
          2. CONDICIONES DE ACCESO Y USO DEL SITIO WEB Y DE LA APLICACIÓN WEB
        </h3>
        <h4>2.1. Acceso y uso del sitio web y la aplicación web.</h4>
        <p>
          Con carácter general la navegación por el sitio web no exige la previa
          suscripción o registro de los Usuarios. Aún así,{' '}
          <b>TOT COMERCIAL, S.A.</b>
          condiciona el uso de la aplicación web <b>DIESELPLUS</b> a la previa
          contratación del servicio y por ende el correspondiente registro de
          Usuario. El registro inicial del usuario lo realizará el prestador
          quien le enviará las credenciales. El acceso se realizará mediante la
          URL que informe el prestador. Es responsabilidad del usuario cambiar
          las credenciales proporcionadas por el Prestador durante el primer
          acceso.
        </p>
        <h4>2.2. Veracidad de la información.</h4>
        <p>
          Toda la información que facilita el Usuario debe ser veraz. En estos
          efectos, el Usuario garantiza la autenticidad de los datos comunicados
          a través de los formularios, contratos, presupuestos para la
          suscripción de los Servicios. Será responsabilidad del Usuario
          mantener toda la información facilitada a <b>TOT COMERCIAL, S.A.</b>
          permanentemente actualizada de forma que responda, en cada momento, a
          su situación real. En todo caso, el Usuario será el único responsable
          de las manifestaciones falsas o inexactas que realice y de los
          perjuicios que cause al prestador o a terceros.
        </p>
        <h4>2.3. Usuarios autorizados.</h4>
        <p>
          <b>DIESELPLUS</b> está disponible{' '}
          <b>SOLAMENTE para usuarios mayores de 18 años</b>. Al usar este sitio
          web, el usuario declara y garantiza que es mayor de edad en su país de
          residencia para formar un contrato vinculante con nosotros y cumplir
          con todos los requisitos establecidos. Si no cumple con todos estos
          requisitos, no debe acceder ni utilizar la plataforma.
        </p>
        <p>
          Se informa al usuario de que el prestador se reserva el derecho a
          limitar, suspender o terminar su acceso al sitio web o aplicación web,
          adoptando cualquier medida técnica que sea necesaria con ese fin, en
          el caso de que infrinja las presentes condiciones, cualesquiera otros
          términos o condiciones particulares recogidos en el sitio web, los
          derechos de terceros o la legislación aplicable.
        </p>
        <h4>
          2.4. Obligación de hacer uso correcto del sitio web y la aplicación
          web.
        </h4>
        <p>
          El Usuario se compromete a utilizar la web de conformidad a la Ley y
          al presente Aviso Legal, así como la moral y las buenas costumbres. En
          efecto, el Usuario se abstendrá de utilizar el sitio web o la
          aplicación web con fines ilícitos o prohibidas, lesivos de derechos e
          intereses de terceros, o que de cualquier forma puedan dañar,
          inutilizar, sobrecargar, deteriorar o impedir la normal utilización de
          equipos informáticos o documentos, archivos y toda clase de contenidos
          almacenados en cualquier equipo informático del prestador.
        </p>
        <p>
          En particular, y a título indicativo, pero no exhaustivo, el Usuario
          se compromete a no transmitir, difundir o poner a disposición de
          terceros informaciones, datos, contenidos, mensajes, gráficos,
          dibujos, archivos de sonido o imagen, fotografías, grabaciones,
          software y, en general, cualquier clase de material que:
          <br />
          (A) sea contraria, menosprecie o atente contra los derechos
          fundamentales y las libertades públicas reconocidas
          constitucionalmente, en tratados internacionales y otras normas
          vigentes;
          <br />
          (B) induzca, incite o promueva actuaciones delictivas, denigratorias,
          difamatorias, violentas o, en general, contrarias a la ley, a la moral
          y el orden público;
          <br />
          (C) induzca, incite o promueva actuaciones, actitudes o pensamientos
          discriminatorios por razón de sexo, raza, religión, creencias, edad o
          condición;
          <br />
          (D) sea contrario al derecho al honor, a la intimidad personal o
          familiar o la propia imagen de las personas;
          <br />
          (E) de cualquier manera perjudique la credibilidad del prestador o de
          terceros; y<br />
          (F) constituya publicidad ilícita, engañosa o desleal.
        </p>
        <h3>3. EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</h3>
        <p>
          El prestador se exime de cualquier tipo de responsabilidad derivada de
          la información publicada en el sitio web y la aplicación web, siempre
          que esta información haya sido manipulada o introducida por un tercero
          ajeno.
        </p>
        <p>
          Este sitio web y la aplicación web han sido revisadas y probadas para
          que funcione correctamente. En principio, se puede garantizar el
          correcto funcionamiento los 365 días del año, 24 horas al día. Sin
          embargo, el prestador no descarta la posibilidad de que existan
          determinados errores de programación, o que sucedan causas de fuerza
          mayor, catástrofes naturales, huelgas, o circunstancias semejantes que
          hagan imposible el acceso a este sitio web, o a la aplicación web, o a
          cualquiera de los servicios ofrecidos, como el acceso a través de API
          o la comunicación con equipos de control y medición.
        </p>
        <p>
          El acceso a la web o en cualquiera de los servicios ofrecidos, podría
          suspenderse temporalmente sin previo aviso debido a algún fallo del
          sistema, o a trabajos de mantenimiento o reparación, por razones que
          escapen al control del prestador. El prestador no asume ninguna
          responsabilidad en tales casos.
        </p>
        <p>
          <b>TOT COMERCIAL, S.A.</b> no otorga ninguna garantía ni se hace
          responsable, en ningún caso, de los daños y perjuicios de cualquier
          naturaleza que puedan salir a colación de la falta de disponibilidad,
          mantenimiento y efectivo funcionamiento de la web, aplicación web o de
          sus servicios y contenidos; de la existencia de virus, programas
          maliciosos o lesivos en los contenidos; del uso ilícito, negligente,
          fraudulento o contrario a este Aviso legal y condiciones de uso; o de
          la falta de licitud, calidad, fiabilidad, utilidad y disponibilidad de
          los servicios prestados por terceros y puestos a disposición de los
          usuarios en el sitio web.
        </p>
        <p>
          El prestador no se hace responsable bajo ningún concepto de los daños
          que puedan dimanar del uso ilegal o indebido de la presente página
          web.
        </p>
        <h3>4. ALTERACIONES DEL CONTENIDO DEL SITIO WEB Y APLICACIÓN WEB</h3>
        <p>
          Queda prohibido alterar, copiar, modificar, descompilar, desensamblar,
          realizar ingeniería inversa de ninguna parte del sitio web, o de la
          aplicación web, o de sus contenidos, eludir, desactivar o manipular de
          cualquier otra forma (o tratar de eludir, desactivar o manipular) las
          funciones de seguridad u otras funciones del programa y utilizar el
          sitio web o la aplicación web o sus contenidos para un fin comercial o
          publicitario.
          <br />
          Queda prohibido su uso con la finalidad de lesionar bienes, derechos o
          intereses del prestador o de terceros. Queda igualmente prohibido
          realizar cualquier otro uso que altere, dañe o inutilice las redes,
          servidores, equipos, productos y programas informáticos del prestador
          o de terceros.
        </p>
        <h3>5. COOKIES</h3>
        <p>
          El sitio web del prestador puede utilizar cookies (pequeños archivos
          de información que el servidor envía al ordenador de quien accede a la
          página) y tecnologías similares para llevar a cabo determinadas
          funciones que son consideradas imprescindibles para el correcto
          funcionamiento y visualización del sitio. Las cookies utilizadas
          tienen, en todo caso, carácter temporal, con la única finalidad de
          hacer más eficaz la navegación, y desaparecen al terminar la sesión
          del usuario. Las tecnologías similares usadas, LocalStorage debe ser
          eliminado por el usuario del historial de navegación. En ningún caso
          se utilizarán las cookies para recoger información de carácter
          personal. para más información, ver nuestra Política de Cookies en{' '}
          <a target="_blank" href="https://www.dieselplus.net/cookies_policy">
            https://www.dieselplus.net/cookies_policy
          </a>
          .
        </p>
        <h3>6. ENLACES (LINKS)</h3>
        <p>
          Desde el sitio web es posible que se redirija a contenidos
          patrocinados, anuncios y / o enlaces de afiliados y / o de terceras
          webs.
        </p>
        <p>
          Entre los enlaces facilitados, existe la posibilidad de informar o
          hacer referencia a productos y / o servicios de terceros. Dado que
          desde la web no podemos controlar los contenidos introducidos por los
          terceros, <b>TOT COMERCIAL, S.A.</b> no asume ningún tipo de
          responsabilidad respecto a dichos contenidos, ni garantiza la
          experiencia, integridad o calidad de sus productos. En todo caso, el
          prestador manifiesta que procederá a la retirada inmediata de
          cualquier contenido que pudiera contravenir la legislación nacional o
          internacional, la moral o el orden público, procediendo a la retirada
          inmediata de la redirección a estas webs, poniendo en conocimiento de
          las autoridades competentes el contenido en cuestión.
        </p>
        <p>
          <b>TOT COMERCIAL, S.A.</b> tendrá publicados estos enlaces hasta que
          sean eliminados o suspendidos por el prestador o por el anunciante, y
          no será responsable, aunque estos anuncios puedan ser indexados por
          otros buscadores ajenos a esta web.
        </p>
        <p>
          El prestador no se responsabiliza de la información y contenidos
          almacenados, a título enunciativo, pero no limitativo, en foros,
          chats, generadores de blogs, comentarios, redes sociales o cualquier
          otro medio que permita a terceros publicar contenidos de forma
          independiente en la página web del prestador. No obstante, y en
          cumplimiento de lo dispuesto en el art. 11 y 16 de la LSSICE, el
          prestador se pone a disposición de todos los usuarios, autoridades y
          fuerzas de seguridad, y colaborando de forma activa en la retirada o,
          cuando sea necesario, el bloqueo de todos aquellos contenidos que
          puedan afectar o contravenir la legislación nacional, o internacional,
          derechos de terceros o la moral y el orden público. En caso de que el
          usuario considere que puede existir algún contenido que pudiera ser
          susceptible de esta clasificación, se ruega lo notifique de forma
          inmediata al administrador del sitio web.
        </p>
        <p>
          Sin embargo, <b>TOT COMERCIAL, S.A.</b> le informa que cualquier
          relación contractual o extracontractual que el usuario formalice con
          estos terceros, afiliados o anunciantes mediante el link proporcionado
          por esta web, será válido únicamente y exclusivamente entre el usuario
          y el anunciante y / o tercera persona y <b>TOT COMERCIAL, S.A.</b> no
          tendrá ningún tipo de responsabilidad sobre los daños o perjuicios que
          pudieran ser ocasionados por la prestación de servicios y / o
          relaciones contractuales o extracontractuales realizadas entre el
          usuario y los anunciantes o terceros contactadas a través de este
          portal ya que el prestador sólo actúa como intermediario o medio
          publicitario.
        </p>
        <h3>7. PROTECCIÓN DE DATOS PERSONALES</h3>
        <p>
          El prestador está profundamente comprometido con el cumplimiento de la
          normativa de protección de datos personales y garantiza el
          cumplimiento íntegro de las obligaciones dispuestas, así como la
          implementación de las medidas de seguridad dispuestas en el Reglamento
          Europeo de Protección de datos y la normativa española de protección
          de datos. Para más información, ver nuestra Política de Privacidad en{' '}
          <a target="_blank" href="https://www.dieselplus.net/privacy_policy">
            https://www.dieselplus.net/privacy_policy
          </a>
          .
        </p>
        <h3>8. PROPIEDAD INTELECTUAL E INDUSTRIAL</h3>
        <p>
          El sitio web, incluyendo a título enunciativo, pero no limitativo: la
          programación, edición, compilación y demás elementos necesarios para
          su funcionamiento, los diseños, logotipos, textos, fotografías y / o
          gráficos son propiedad del prestador o, en caso de que fuera
          necesario, dispone de la licencia o autorización expresa por parte de
          los autores. Todos los contenidos del sitio web se encuentran
          debidamente protegidos por la normativa de propiedad intelectual e
          industrial.
        </p>
        <p>
          Independientemente de la finalidad para la que fueran destinados, la
          reproducción total o parcial, uso, distribución y comunicación pública
          requieren de la autorización escrita previa por parte del prestador.
          Cualquier uso no autorizado previamente por parte del prestador será
          considerado un incumplimiento grave de los derechos de propiedad
          intelectual o industrial del autor.
        </p>
        <p>
          Los diseños, logotipos, texto y / o gráficos ajenos al prestador y que
          puedan aparecer en el sitio web, pertenecen a sus respectivos
          propietarios, siendo ellos mismos responsables de cualquier posible
          controversia que pudiera devenir respecto a los mismos. En todo caso,
          el prestador cuenta con la autorización expresa y previa por parte de
          los mismos.
        </p>
        <p>
          El prestador reconoce a favor de sus titulares los correspondientes
          derechos de propiedad industrial e intelectual, no implicando la
          mención o aparición en el sitio web, de la existencia de derechos o
          responsabilidad del prestador sobre los mismos, como tampoco respaldo,
          patrocinio o recomendación por parte del mismo.
        </p>
        <p>
          Para realizar cualquier tipo de observación respecto a posibles
          incumplimientos de los derechos de propiedad intelectual o industrial,
          así como sobre cualquiera de los contenidos del sitio web, puede
          hacerlo a través del correo electrónico arriba reseñado.
        </p>
        <h3>9. LEY APLICABLE Y JURISDICCIÓN</h3>
        <p>
          Para la resolución de las controversias o cuestiones relacionadas con
          la presente página web o de las actividades en esta desarrolladas,
          será de aplicación la legislación española, a la que se someten
          expresamente las partes, siendo competentes para la resolución de
          todos los conflictos derivados o relacionados con su uso los Juzgados
          y Tribunales de Lleida.
        </p>
      </div>
    </div>
  );
};

export default LegalNotice;
