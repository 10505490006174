import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Tabs } from 'antd';
import useLocale from '../../hooks/useIntl';

import useTranslate from '../../hooks/useTranslate';
import CustomImage from '../Shared/CustomImage';
import RequestDemoFooter from '../Shared/RequestDemoFooter';
import YoutubeEmbedPlayer from '../Shared/YoutubeEmbedPlayer';
import { useNavigate } from 'react-router-dom';
import routes from '../../routes';

const Home: React.FC = () => {
  const t = useTranslate();
  const { locale } = useLocale();
  const navigate = useNavigate();

  const [activeKey, setActiveKey] = useState<string>('1');

  const changeTab = () => {
    const currentActiveKey = Number(activeKey);
    if (currentActiveKey < 4) {
      setActiveKey((currentActiveKey + 1).toString());
    } else {
      setActiveKey((1).toString());
    }
  };

  useEffect(() => {
    const timeout = setTimeout(changeTab, 45000);
    return () => {
      clearTimeout(timeout);
    };
  }, [activeKey]);

  return (
    <div className="s-home">
      <div className="header">
        <div className="block block__full">
          <div className="content half left">
            <h2>{t({ id: 'HOME.HEADER.TITLE' })}</h2>
            <p>{t({ id: 'HOME.HEADER.SUBTITLE' })}</p>
            <Button
              className="layout-button"
              onClick={() => {
                navigate(routes.contact());
              }}
            >
              {t({ id: 'HOME.HEADER.BUTTON' })}
            </Button>
          </div>
          <div className="content half right">
            <CustomImage pageName="home" imageName="header" />
          </div>
        </div>
      </div>
      <div className="video">
        <div className="block block__single">
          <YoutubeEmbedPlayer
            videoId="RKzsdYz3LFE"
            placeholderImageName="home-video"
          />
        </div>
      </div>
      <div className="software">
        <div className="block block__single">
          <div className="content">
            <div className="titles">
              <h3>{t({ id: 'HOME.SOFTWARE.TITLE' })}</h3>
              <h4>{t({ id: 'HOME.SOFTWARE.SUBTITLE' })}</h4>
            </div>
            <div className="cards">
              <Row gutter={44}>
                {['anywhere', 'anysupply', 'fleet'].map((card: string) => {
                  return (
                    <Col sm={24} md={8} key={card}>
                      <Card>
                        <CustomImage
                          pageName="home"
                          imageName={`software-card-${card}`}
                        />
                        <h5>
                          {t({
                            id: `HOME.SOFTWARE.CARD_${card.toUpperCase()}_TITLE`
                          })}
                        </h5>
                        <p>
                          {t({
                            id: `HOME.SOFTWARE.CARD_${card.toUpperCase()}_TEXT`
                          })}
                        </p>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </div>
      <div className="applications">
        <div className="block block__single">
          <div className="content">
            <div className="titles">
              <h3>{t({ id: 'HOME.APPLICATIONS.TITLE' })}</h3>
              <h4>{t({ id: 'HOME.APPLICATIONS.SUBTITLE' })}</h4>
            </div>
            <div className="cards">
              <Row gutter={44}>
                {[
                  'fuel',
                  'electric',
                  'water',
                  'lubricants',
                  'adblue',
                  'level'
                ].map((card: string) => {
                  return (
                    <Col sm={24} md={12} lg={8} key={card}>
                      <Card>
                        <CustomImage
                          pageName="home"
                          imageName={`applications-card-${card}`}
                        />
                        <h5>
                          {t({
                            id: `HOME.APPLICATIONS.CARD_${card.toUpperCase()}_TITLE`
                          })}
                        </h5>
                        <p>
                          {t({
                            id: `HOME.APPLICATIONS.CARD_${card.toUpperCase()}_TEXT`
                          })}
                        </p>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </div>
      <div className="functions">
        <div className="block block__single">
          <div className="content">
            <div className="titles">
              <h3>{t({ id: 'HOME.FUNCTIONS.TITLE' })}</h3>
              <h4>{t({ id: 'HOME.FUNCTIONS.SUBTITLE' })}</h4>
            </div>
            <div className="tabs">
              <Tabs
                activeKey={activeKey}
                onChange={setActiveKey}
                animated={{ inkBar: true, tabPane: true }}
                items={['supplies', 'tanks', 'consumption', 'integrations'].map(
                  (tab: string, index: number) => {
                    return {
                      label: (
                        <span className="label">
                          <span className="tab-number">{index + 1}</span>
                          {t({
                            id: `HOME.FUNCTIONS.TAB_${tab.toUpperCase()}_TITLE`
                          })}
                        </span>
                      ),
                      key: (index + 1).toString(),
                      children: (
                        <div className="tab-content">
                          <div className="tab-content__text">
                            <h5>
                              {t({
                                id: `HOME.FUNCTIONS.TAB_${tab.toUpperCase()}_TITLE`
                              })}
                            </h5>
                            <p>
                              {t(
                                {
                                  id: `HOME.FUNCTIONS.TAB_${tab.toUpperCase()}_TEXT`
                                },
                                { br: <br /> }
                              )}
                            </p>
                            <Button
                              className="layout-button"
                              onClick={() => {
                                navigate(`${routes.functions()}#${tab}`);
                              }}
                            >
                              {t({ id: 'HOME.FUNCTIONS.MORE' })}
                            </Button>
                          </div>
                          <div className="tab-content__image">
                            <CustomImage
                              pageName="home"
                              imageName={`functions-tab-${tab}`}
                            />
                          </div>
                        </div>
                      )
                    };
                  }
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tools">
        <div className="block block__single">
          <div className="content">
            <div className="titles">
              <h3>{t({ id: 'HOME.TOOLS.TITLE' })}</h3>
              <h4>{t({ id: 'HOME.TOOLS.SUBTITLE' })}</h4>
            </div>
            <div className="cards">
              <Row gutter={44}>
                {(locale === 'es'
                  ? [
                      'tasks',
                      'reports',
                      'permissions',
                      'additional_fields',
                      'remote_access',
                      'trackers',
                      'safe_data',
                      'updates',
                      'help',
                      'gaspro'
                    ]
                  : [
                      'tasks',
                      'reports',
                      'permissions',
                      'additional_fields',
                      'remote_access',
                      'trackers',
                      'safe_data',
                      'updates',
                      'help'
                    ]
                ).map((card: string) => {
                  return (
                    <Col sm={24} md={12} lg={8} key={card}>
                      <Card>
                        <CustomImage
                          pageName="home"
                          imageName={`tools-card-${card}`}
                        />
                        <h5>
                          {t({
                            id: `HOME.TOOLS.CARD_${card.toUpperCase()}_TITLE`
                          })}
                        </h5>
                        <p>
                          {t({
                            id: `HOME.TOOLS.CARD_${card.toUpperCase()}_TEXT`
                          })}
                        </p>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </div>
      <RequestDemoFooter />
    </div>
  );
};

export default Home;
