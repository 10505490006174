import ky, { KyResponse } from 'ky';
import { trackPromise } from 'react-promise-tracker';
import { CONFIG } from '../config/config';

const timeout = 30000;
const long_timeout = 600000;

type ErrorListener = (response: Response) => void;

interface ResponseType {
  response: Response;
}

let listener: ErrorListener | undefined = undefined;

function getUrlPath(path: string): string {
  return `${CONFIG.API_URL}${path}`;
}

function getAuthHeader(): Record<string, string> {
  const headers: Record<string, string> = {};

  return headers;
}

export function setClientErrorListener(newListener?: ErrorListener): void {
  listener = newListener;
}

export async function handleErrors<T>({ response }: ResponseType): Promise<T> {
  if (listener) {
    listener(response);
  }

  return Promise.reject(response);
}

export async function get<T>(
  path: string,
  searchParams: Record<string, string> = {},
  long_request = false,
  isMobile?: boolean
): Promise<T> {
  try {
    return await trackPromise(
      ky
        .get(getUrlPath(path), {
          headers: getAuthHeader(),
          searchParams,
          timeout: long_request ? long_timeout : timeout
        })
        .json(),
      isMobile ? 'mobile' : 'desktop'
    );
  } catch (e) {
    return handleErrors(e as ResponseType);
  }
}

export async function getNoTracking<T>(
  path: string,
  searchParams: Record<string, string> = {},
  long_request = false
): Promise<T> {
  try {
    return await ky
      .get(getUrlPath(path), {
        headers: getAuthHeader(),
        searchParams,
        timeout: long_request ? long_timeout : timeout
      })
      .json();
  } catch (e) {
    return handleErrors(e as ResponseType);
  }
}

export async function getRaw(
  path: string,
  searchParams: Record<string, string> = {},
  long_request = false
): Promise<any> {
  try {
    return await trackPromise(
      ky.get(getUrlPath(path), {
        headers: getAuthHeader(),
        searchParams,
        timeout: long_request ? long_timeout : timeout
      }),
      'desktop'
    );
  } catch (e) {
    return handleErrors(e as ResponseType);
  }
}

export async function post<T>(
  path: string,
  json: unknown = undefined,
  long_request = false
): Promise<T> {
  try {
    return await trackPromise(
      ky
        .post(getUrlPath(path), {
          headers: getAuthHeader(),
          json,
          timeout: long_request ? long_timeout : timeout
        })
        .json(),
      'desktop'
    );
  } catch (e) {
    return handleErrors(e as ResponseType);
  }
}

export async function postNoJson(
  path: string,
  json: unknown = undefined,
  long_request = false
): Promise<KyResponse> {
  try {
    return await trackPromise(
      ky.post(getUrlPath(path), {
        headers: getAuthHeader(),
        json,
        timeout: long_request ? long_timeout : timeout
      }),
      'desktop'
    );
  } catch (e) {
    return handleErrors(e as ResponseType);
  }
}

export async function put<T>(
  path: string,
  json: unknown = undefined,
  long_request = false
): Promise<T> {
  try {
    return await trackPromise(
      ky
        .put(getUrlPath(path), {
          headers: getAuthHeader(),
          json,
          timeout: long_request ? long_timeout : timeout
        })
        .json(),
      'desktop'
    );
  } catch (e) {
    return handleErrors(e as ResponseType);
  }
}

export async function del(
  path: string,
  json: unknown = undefined,
  long_request = false
): Promise<any> {
  try {
    return await trackPromise(
      ky.delete(getUrlPath(path), {
        headers: getAuthHeader(),
        json,
        timeout: long_request ? long_timeout : timeout
      }),
      'desktop'
    );
  } catch (e) {
    return handleErrors(e as ResponseType);
  }
}
