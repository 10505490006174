import { ErrorMessage } from '../../models/error-message.model';

import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';

export const backendValidation = (
  fieldName: string,
  errors: Record<string, ErrorMessage>
): RuleObject => {
  const validator = async (): Promise<void> => {
    return errors[fieldName]
      ? Promise.reject(errors[fieldName].message)
      : Promise.resolve();
  };

  return { validator };
};

export const numericStringValidation = (message: string): RuleObject => {
  const validator = async (_: any, value: StoreValue): Promise<void> => {
    return value === '' ||
      value === undefined ||
      value === null ||
      RegExp(/^\d+$/g).test(value)
      ? Promise.resolve()
      : Promise.reject(message);
  };

  return { validator };
};

export const decimalStringValidation = (
  message: string,
  separator: string | undefined
): RuleObject => {
  const validator = async (_: any, value: StoreValue): Promise<void> => {
    const regexp =
      separator === '.' ? /^(?:\d+(?:[.]\d+)?)$/ : /^(?:\d+(?:[,]\d+)?)$/;
    return value === '' ||
      value === undefined ||
      value === null ||
      RegExp(regexp).test(value)
      ? Promise.resolve()
      : Promise.reject(message);
  };

  return { validator };
};

export const maxMinNumericStringValidation = (
  message: string,
  min: number,
  max: number,
  separator?: string,
  permittedZero?: boolean
): RuleObject => {
  const validator = async (_: any, value: StoreValue): Promise<void> => {
    const regexp = !!separator
      ? separator === '.'
        ? /^(?:\d+(?:[.]\d+)?)$/
        : /^(?:\d+(?:[,]\d+)?)$/
      : /^\d+$/g;

    const stringValue = String(value);

    return stringValue === '' ||
      stringValue === undefined ||
      stringValue === null ||
      (permittedZero &&
        parseFloat(stringValue.toString().replace(/,/g, '.')) === 0) ||
      (RegExp(regexp).test(stringValue) &&
        parseFloat(stringValue.toString().replace(/,/g, '.')) <= max &&
        parseFloat(stringValue.toString().replace(/,/g, '.')) >= min)
      ? Promise.resolve()
      : Promise.reject(message);
  };

  return { validator };
};
