import React from 'react';

import { Layout } from 'antd';

import DieselplusLogo from '../../images/dieselplus-logo.svg';
import HeaderMenu from './HeaderMenu';
import { useNavigate } from 'react-router-dom';
import routes from '../../routes';

const Header: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Layout.Header className="s-header">
      <span className="block block__header">
        <div className="s-header__logo-wrapper">
          <img
            src={DieselplusLogo}
            onClick={() => {
              navigate(routes.root());
            }}
          />
        </div>
        <HeaderMenu />
      </span>
    </Layout.Header>
  );
};

export default Header;
