import { variables } from '../core/variables';

export const modals = {
  '& .ant-modal': {
    minWidth: '60%',
    paddingBottom: 0,

    '&.is-fullheight-modal': {
      height: 'calc(var(--vh) - 4rem)',

      '& textarea': {
        height: 'calc(var(--vh) - 16rem)'
      }
    }
  },

  '& .ant-modal-header': {
    flexShrink: 0,
    background: variables['@layout-header-background'],
    height: '60px',
    padding: '0 24px'
  },

  '& .ant-modal-body': {
    margin: 0,
    padding: '24px',
    height: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
    fontSize: variables['@font-size-lg'],

    '& .ant-spin-nested-loading, .ant-spin-container': {
      '& .ant-pagination': {
        marginRight: 0
      }
    }
  },

  '& .ant-modal-footer': {
    margin: 0,
    padding: '10px 16px',
    position: 'relative',
    borderTop: '1px solid #f0f0f0',

    '& .ant-btn': {
      borderRadius: variables['@border-radius'],
      padding: '10px 16px',
      height: 'auto',
      fontSize: '16px'
    },

    '& .ant-btn-default': {
      '&:hover': {
        borderColor: variables['@red-diesel'],
        color: variables['@red-diesel'],

        '& svg': {
          color: variables['@red-diesel'],
          stroke: variables['@red-diesel']
        }
      },

      '& svg': {
        color: 'black',
        stroke: 'black'
      }
    },

    '& .ant-btn-primary': {
      backgroundColor: variables['@red-diesel'],
      borderColor: variables['@red-diesel'],

      '&:hover': {
        backgroundColor: variables['@red-diesel-hover'],
        borderColor: variables['@red-diesel-hover']
      },

      '& svg': {
        color: 'white',
        stroke: 'white'
      }
    }
  },

  '& .ant-modal-close': {
    top: 0,
    backgroundColor: 'initial !important'
  },

  '& .ant-modal-close, .ant-modal-close-x': {
    height: '60px',
    lineHeight: '60px',
    color: 'white'
  },

  '& .ant-modal-title': {
    height: '60px',
    lineHeight: '60px',
    color: 'white',

    '& .modal-title': {
      display: 'inline-block',
      height: '60px',
      lineHeight: '60px',
      fontWeight: 400
    }
  },

  '& .ant-modal-body .error-list': {
    marginBottom: '15px'
  },

  '& .ant-modal-content': {
    padding: 0,
    height: '100%',
    maxHeight: 'calc(var(--vh) - 4rem) !important',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: variables['@border-radius']
  },

  '& .modal': {
    '&__form.cookies-configure-form': {
      '& table': {
        margin: 0,
        marginTop: '18px',
        fontSize: `${variables['@font-size-lg']} !important`,

        '& thead': {
          display: 'none'
        },

        '& .ant-table-row': {
          margin: 0,

          '& .ant-table-cell': {
            border: 'none',
            padding: '5px 0'
          }
        }
      }
    }
  },

  '& .modal-error': {
    color: variables['@red-diesel']
  }
};
