import { variables } from '../core/variables';

export const home = {
  '& .s-home': {
    '& .titles': {
      maxWidth: '770px',
      margin: '0px auto',
      marginBottom: '50px'
    },

    '& .ant-col': {
      marginBottom: '44px'
    },

    '& h3': {
      fontWeight: 500,
      fontSize: '20px',

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        fontSize: '16px'
      }
    },

    '& h4': {
      fontWeight: 700,
      fontSize: '42px',
      lineHeight: 1.3,

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        fontSize: '32px',
        lineHeight: 1.25
      }
    },

    '& .ant-card': {
      height: '100%',
      borderRadius: variables['@border-radius'],
      border: 'none',

      '& .ant-card-body': {
        padding: 0,
        textAlign: 'center',

        '& h5': {
          color: variables['@red-diesel'],
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: 1.2,
          margin: '20px'
        },

        '& p': {
          color: '#44454b',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: 1.5,
          margin: '20px 26px',
          marginBottom: '30px'
        }
      }
    },

    '& .header': {
      paddingTop: '50px',
      paddingBottom: '100px',
      backgroundColor: variables['@background-color-light'],

      '& h2': {
        color: '#1d1e20',
        fontWeight: 900,
        fontSize: '54px',
        lineHeight: 1.05,
        marginRight: '30px',
        marginBottom: '0.4em',

        [`@media (max-width: ${variables['@mobile-width']})`]: {
          fontSize: '42px',
          lineHeight: 1.2
        },

        [`@media (max-width: ${variables['@responsive-width']})`]: {
          marginRight: 0
        }
      },

      '& p': {
        color: '#44454b',
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: 1.2,
        marginRight: '80px',

        [`@media (max-width: ${variables['@mobile-width']})`]: {
          fontSize: '20px',
          lineHeight: 1.25
        },

        [`@media (max-width: ${variables['@responsive-width']})`]: {
          marginRight: 0
        }
      }
    },

    '& .video': {
      backgroundImage: `linear-gradient(180deg, ${variables['@background-color-light']} 50%, ${variables['@background-color']} 50%)`,
      padding: '10px 0'
    },

    '& .software': {
      paddingTop: '143px',
      paddingBottom: '75px',
      backgroundImage: `linear-gradient(180deg, ${variables['@background-color']} 0%, ${variables['@background-color-gradient']} 80.47%)`,

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        paddingTop: '70px',
        paddingBottom: '64px'
      },

      '& h3': {
        color: '#a9abb1'
      },

      '& h4': {
        color: '#ecedee'
      },

      '& .ant-card': {
        backgroundColor: variables['@background-color-light'],

        '& .ant-card-body': {
          '& img': {
            width: '100%'
          }
        }
      }
    },

    '& .applications': {
      paddingTop: '111px',
      paddingBottom: '75px',
      backgroundColor: variables['@background-color-light'],

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        paddingTop: '70px',
        paddingBottom: '64px'
      },

      '& h3': {
        color: '#61636b'
      },

      '& h4': {
        color: '#1d1e20'
      },

      '& .ant-card': {
        backgroundColor: 'white',
        boxShadow: '5px 10px 31px 0 rgba(29, 30, 32, 0.1)',

        '& .ant-card-body': {
          '& img': {
            marginTop: '33px',
            marginBottom: '11px',
            maxWidth: '135px'
          }
        }
      }
    },

    '& .functions': {
      paddingTop: '143px',
      paddingBottom: '120px',
      backgroundColor: variables['@background-color'],

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        paddingTop: '70px',
        paddingBottom: '64px'
      },

      '& h3': {
        color: '#a9abb1'
      },

      '& h4': {
        color: '#ecedee'
      },

      '& .ant-tabs': {
        '& .ant-tabs-nav': {
          marginBottom: '87px',

          [`@media (max-width: ${variables['@mobile-width']})`]: {
            marginBottom: '40px'
          }
        },

        '& .ant-tabs-nav-list': {
          width: '100%',
          flexWrap: 'wrap',

          '& .ant-tabs-tab': {
            width: '25%',
            margin: '0 !important',
            borderBottom: 'solid #1d1e20 4px',

            [`@media (max-width: ${variables['@responsive-width']})`]: {
              width: '50%',
              padding: '16px 0 2px'
            },

            [`@media (max-width: ${variables['@mobile-width']})`]: {
              width: '100%'
            },

            '& .label': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              fontSize: '20px',
              color: '#1d1e20',
              fontWeight: 600,
              textAlign: 'center',

              '& .tab-number': {
                color: '#61636b',
                width: '40px',
                height: '40px',
                borderRadius: '50px',
                paddingTop: '1px',
                fontSize: '24px',
                marginBottom: '10px',
                backgroundColor: '#1e1e20',

                [`@media (max-width: ${variables['@responsive-width']})`]: {
                  marginBottom: '3px'
                }
              }
            },

            '&.ant-tabs-tab-active': {
              borderBottomColor: variables['@red-diesel'],

              '& .label': {
                color: variables['@red-diesel'],

                '& .tab-number': {
                  backgroundColor: variables['@red-diesel'],
                  color: 'white'
                }
              }
            }
          }
        },

        '& .ant-tabs-nav::before': {
          border: 'none'
        },

        '& .ant-tabs-ink-bar, .ant-tabs-nav-operations': {
          display: 'none'
        },

        '& .tab-content': {
          display: 'flex',
          flexDirection: 'row',

          [`@media (max-width: ${variables['@responsive-width']})`]: {
            flexDirection: 'column'
          },

          '&__text': {
            width: '50%',
            textAlign: 'start',
            marginRight: '45px',

            [`@media (max-width: ${variables['@responsive-width']})`]: {
              width: '100%',
              marginRight: 0
            },

            '& h5': {
              color: '#ecedee',
              fontWeight: 700,
              fontSize: '36px',

              [`@media (max-width: ${variables['@mobile-width']})`]: {
                fontSize: '28px'
              }
            },

            '& p': {
              color: '#cacace',
              fontWeight: 400,
              fontSize: '20px',
              lineHeight: 1.3,

              [`@media (max-width: ${variables['@mobile-width']})`]: {
                fontSize: '16px',
                lineHeight: 1.5
              }
            }
          },

          '&__image': {
            width: '50%',

            [`@media (max-width: ${variables['@responsive-width']})`]: {
              width: '100%',
              marginTop: '36px'
            },

            '& img': {
              width: '100%'
            }
          }
        }
      }
    },

    '& .tools': {
      paddingTop: '111px',
      paddingBottom: '75px',
      backgroundColor: variables['@background-color-light'],

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        paddingTop: '70px',
        paddingBottom: '64px'
      },

      '& h3': {
        color: '#61636b'
      },

      '& h4': {
        color: '#1d1e20'
      },

      '& .ant-card': {
        backgroundColor: 'white',
        boxShadow: '5px 10px 31px 0 rgba(29, 30, 32, 0.1)',

        '& .ant-card-body': {
          textAlign: 'start',

          '& img': {
            maxWidth: '48px',
            position: 'absolute',
            right: '20px',
            top: '20px'
          },

          '& h5': {
            height: '60px',
            marginRight: '80px'
          }
        }
      }
    }
  }
};
