import { variables } from '../core/variables';

export const pageActions = {
  '& .s-page-actions': {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '10px',
    paddingBottom: '10px',
    margin: variables['@layout-header-padding'],
    backgroundColor: variables['@layout-header-background'],
    borderBottom: '1px solid #f0f0f0',

    '&__primary-actions': {
      display: 'flex',

      '& button': {
        width: '200px',
        marginRight: '10px',
        color: 'white',
        textTransform: 'uppercase',
        backgroundColor: variables['@action-button-bg-color'],
        borderColor: variables['@action-button-bg-color'],

        '& .anticon': {
          stroke: 'white'
        },

        '&.disabled': {
          cursor: 'not-allowed',

          '& .anticon': {
            stroke: 'rgba(0, 0, 0, 0.1)'
          }
        },

        '&:hover, &:focus': {
          color: 'white',
          backgroundColor: variables['@action-button-hover-bg-color'],
          borderColor: variables['@action-button-hover-bg-color']
        },

        '& .action-button-icon': {
          verticalAlign: 'top',
          marginRight: '5px'
        },

        '& .action-button-text': {
          maxWidth: '150px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    }
  }
};
