import { Spin } from 'antd';
import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

interface Props {
  children?: React.ReactNode;
}

const LoadingSpinner: React.FC<Props> = ({ children }: Props) => {
  const { promiseInProgress } = usePromiseTracker({ area: 'desktop' });

  return (
    <Spin spinning={promiseInProgress} size={'large'}>
      {children}
    </Spin>
  );
};

export default LoadingSpinner;
