// https://egghead.io/lessons/react-convert-a-hard-coded-string-using-react-intl-formattedmessage
export function flattenMessages(
  nestedMessages: Record<string, string> = {},
  prefix = ''
): Record<string, string> {
  return Object.keys(nestedMessages).reduce(
    (messages: Record<string, string>, key: string) => {
      const value = nestedMessages[key];
      const prefixedKey = prefix ? `${prefix}.${key}` : key;

      if (typeof value === 'string') {
        messages[prefixedKey] = value;
      } else {
        Object.assign(messages, flattenMessages(value, prefixedKey));
      }

      return messages;
    },
    {}
  );
}
