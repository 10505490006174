import React from 'react';

import { Layout } from 'antd';
import Header from './Header';
import Footer from './Footer';

interface Props {
  children: React.ReactNode;
}

const CustomLayout: React.FC<Props> = ({ children }: Props) => {
  return (
    <Layout>
      <Header />
      <Layout.Content>{children}</Layout.Content>
      <Footer />
    </Layout>
  );
};

export default CustomLayout;
