import React from 'react';
import useIntl from '../hooks/useIntl';
import LegalNoticeEs from './es/LegalNotice';
import LegalNoticeEn from './en/LegalNotice';

const LegalNotice: React.FC = () => {
  const { locale } = useIntl();

  switch (locale) {
    case 'es':
      return <LegalNoticeEs />;
    default:
      return <LegalNoticeEn />;
  }
};

export default LegalNotice;
