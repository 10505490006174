import { variables } from '../core/variables';

export const footer = {
  '& .s-footer': {
    display: 'flex',
    height: '104px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: variables['@layout-header-background'],
    padding: '0 23px',

    [`@media (max-width: ${variables['@responsive-width']})`]: {
      flexDirection: 'column',
      height: '300px',

      '& .block': {
        alignItems: 'flex-start'
      }
    },

    '&__left': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'nowrap',

      [`@media (max-width: ${variables['@responsive-width']})`]: {
        flexDirection: 'column'
      },

      '&__logo-wrapper': {
        marginRight: '25px',

        '& img': {
          height: '16px',
          verticalAlign: 'sub',
          cursor: 'pointer'
        }
      },

      '& .ant-menu': {
        backgroundColor: 'transparent',
        border: 'none',
        width: '100%',
        justifyContent: 'flex-end',

        [`@media (max-width: ${variables['@responsive-width']})`]: {
          margin: '10px 0'
        },

        '& .ant-menu-item, .ant-menu-overflow-item': {
          padding: 0,
          margin: 0,
          marginLeft: '15px',
          color: 'white',
          border: 'none',
          fontSize: '12px',
          fontWeight: 400,
          letterSpacing: '0.01em',
          position: 'relative!important',

          '&:hover': {
            color: variables['@red-diesel']
          },

          '&:after': {
            border: 'none'
          },

          [`@media (max-width: ${variables['@responsive-width']})`]: {
            marginLeft: 0
          },

          '& span': {
            transition: '0ms'
          }
        },

        '& .ant-menu-item-active:after, .ant-menu-submenu-item-active:after': {
          display: 'none'
        }
      }
    },

    '&__copyright': {
      color: '#909297',

      [`@media (max-width: ${variables['@responsive-width']})`]: {
        marginTop: '10px !important'
      }
    },

    '&__request-demo': {
      position: 'relative',
      padding: '140px 0',
      backgroundImage: `linear-gradient(180deg, ${variables['@background-color-gradient']} 19.34%, ${variables['@background-color']} 100%)`,

      [`@media (max-width: ${variables['@responsive-width']})`]: {
        paddingBottom: '300px'
      },

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        paddingTop: '70px'
      },

      '& h3': {
        color: '#ecedee',
        fontSize: '42px !important',
        fontWeight: '700 !important',
        lineHeight: 1.3,

        [`@media (max-width: ${variables['@mobile-width']})`]: {
          fontSize: '32px !important',
          lineHeight: '1.25 !important'
        }
      },

      '& p': {
        color: '#a9abb1',
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: 1.3,

        [`@media (max-width: ${variables['@mobile-width']})`]: {
          fontSize: '16px',
          lineHeight: 1.5
        }
      },

      '& .background-image': {
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '30%',

        [`@media (max-width: ${variables['@responsive-width']})`]: {
          width: '50%'
        },

        [`@media (max-width: ${variables['@mobile-width']})`]: {
          width: '80%'
        }
      }
    }
  }
};
