import React, { useState, createContext, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { CONFIG } from '../config/config';

interface EnvironmentContextState {
  getLiteral: (
    model: string,
    literal: string,
    params?: Record<string, string>[]
  ) => string;
  isMobile: boolean;
  isTablet: boolean;
}

const EnvironmentContext = createContext<EnvironmentContextState>({
  getLiteral: () => '',
  isMobile: false,
  isTablet: false
});

interface Props {
  children: React.ReactNode;
}

export const EnvironmentProvider: React.FC<Props> = ({ children }: Props) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 576);
  const [isTablet, setIsTablet] = useState<boolean>(window.innerWidth < 992);
  const intl = useIntl();

  const updateParams = (
    params: Record<string, string>[],
    literal?: string
  ): string => {
    params?.forEach((param: Record<string, string>) => {
      const paramKey = `{${Object.keys(param)[0]}}`;
      while (literal?.includes(paramKey)) {
        literal = literal.replace(paramKey, Object.values(param)[0]);
      }
    });

    return literal || '';
  };

  const getLiteral = (
    model: string,
    literal: string,
    params?: Record<string, string>[]
  ): string => {
    if (!!model && !!literal) {
      let configValue: string | undefined = undefined;

      for (const [key, value] of Object.entries(CONFIG)) {
        if (key === literal.toUpperCase()) {
          configValue = updateParams(params || [], value as string);
        }
      }

      const modelKey = `${model.toUpperCase()}.${literal.toUpperCase()}`;

      return configValue || intl.formatMessage({ id: modelKey });
    } else {
      return '';
    }
  };

  const resize = (): void => {
    setIsMobile(window.innerWidth < 576);
    setIsTablet(window.innerWidth < 992);
  };

  const callSizeChanged = () => setTimeout(resize, 500);

  useEffect(() => {
    callSizeChanged();
    window.addEventListener('resize', () => callSizeChanged());
    return () => {
      window.removeEventListener('resize', callSizeChanged);
    };
  }, []);

  return (
    <EnvironmentContext.Provider value={{ isMobile, isTablet, getLiteral }}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export default function useEnvironment(): EnvironmentContextState {
  return useContext(EnvironmentContext);
}
