import React from 'react';

import { Button, Modal } from 'antd';
import LoadingSpinner from './LoadingSpinner';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import classNames from 'classnames';

interface Props {
  formModalTitle: string;
  isFormModalVisible: boolean;
  onFinish?: () => void;
  onCancel: () => void;
  isSecondaryModal?: boolean;
  children: React.ReactNode;
  extra?: React.ReactNode[];
  formId?: string;
  inner?: boolean;
  importModal?: boolean;
  infoModal?: boolean;
  readOnly?: boolean;
  footer?: React.ReactNode[];
  className?: string;
}

const ModalForm: React.FC<Props> = ({
  formModalTitle,
  isFormModalVisible,
  onFinish,
  onCancel,
  isSecondaryModal,
  children,
  extra,
  formId,
  inner,
  importModal,
  infoModal,
  readOnly,
  footer,
  className
}: Props) => {
  const defaultFooter: React.ReactNode[] = [
    <Button key="cancel" onClick={onCancel}>
      <CloseOutlined />
    </Button>,
    !readOnly && (
      <Button
        key="submit"
        form={formId}
        type="primary"
        htmlType="submit"
        onClick={onFinish}
      >
        <CheckOutlined />
      </Button>
    )
  ];

  const extraFooter: React.ReactNode | undefined = !!extra && (
    <div className="form__extra">{extra}</div>
  );

  return (
    <Modal
      title={<span className="modal-title">{formModalTitle}</span>}
      centered
      visible={isFormModalVisible}
      destroyOnClose
      footer={
        !!extraFooter
          ? [extraFooter, ...[footer ? footer : defaultFooter]]
          : [...[footer ? footer : defaultFooter]]
      }
      onCancel={onCancel}
      className={classNames(
        'modal__form',
        importModal ? 'modal__form__import' : undefined,
        infoModal ? 'modal__form__info' : undefined,
        inner ? undefined : 'is-fullheight-modal',
        isSecondaryModal ? 'is-secondary-modal' : undefined,
        className
      )}
      maskClosable={false}
      keyboard={false}
    >
      <LoadingSpinner>{children}</LoadingSpinner>
    </Modal>
  );
};

export default ModalForm;
