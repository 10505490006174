import { useEffect, useState } from 'react';

import { message } from 'antd';

import { setClientErrorListener } from '../../api/client';
import useTranslate from '../../hooks/useTranslate';
import useLocale from '../../hooks/useIntl';

function useServerError(): { error: Response | undefined } {
  const [error, setError] = useState<Response | undefined>();
  const t = useTranslate();
  const { locale } = useLocale();

  useEffect(() => {
    setClientErrorListener(setError);

    return () => {
      setClientErrorListener();
    };
  }, [locale]);

  useEffect(() => {
    if (!!error) {
      switch (error.status) {
        default:
          message.error(t({ id: 'API_ERRORS.SERVER_ERROR' }));
      }
    }
  }, [error]);

  return { error };
}

export default useServerError;
