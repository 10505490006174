import Bowser from 'bowser';
import { createUseStyles } from 'react-jss';

import { base } from './base';
import { blocks } from './blocks';
import { oldBrowsers } from './old-browsers';

const minimumBrowsers = {
  desktop: {
    chrome: '>=89',
    edge: '>=89',
    firefox: '>=66',
    opera: '>=77',
    safari: '>=15'
  },
  mobile: {
    chrome: '>=89',
    firefox: '>=66',
    safari: '>=15',
    samsung: '>=15.0',
    webview: '>=89'
  }
};

const isValidBrowser = Bowser.getParser(window.navigator.userAgent).satisfies(
  minimumBrowsers
);
console.log('isValidBrowser', isValidBrowser);
export const useStyles = createUseStyles({
  themeStyle: {
    ...base,
    ...blocks,
    ...(!isValidBrowser ? oldBrowsers : [])
  }
});
