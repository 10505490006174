import React, { useMemo } from 'react';

import { Divider, Menu } from 'antd';

import useTranslate from '../../hooks/useTranslate';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from '../../routes';
import { CONFIG } from '../../config/config';
import { MenuOutlined } from '@ant-design/icons';
import useLocale from '../../hooks/useIntl';
import { IconLanguage } from '../../icons';

interface Props {
  onClick?: () => void;
}

const HeaderMenu: React.FC<Props> = ({ onClick }: Props) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const urlLocation = useLocation();
  const { setLocale } = useLocale();

  const selectedSubMenu = useMemo(() => {
    const paths = urlLocation.pathname.split('/');

    if (!!paths && !!paths[1]) {
      return paths[1];
    }

    return 'home';
  }, [urlLocation.pathname]);

  const items = [
    {
      key: routes.functions().substring(1),
      label: t({ id: 'FUNCTIONS.TITLE' }),
      onClick: () => {
        navigate(routes.functions());
      },
      className: 'functions'
    },
    {
      key: 'demo',
      label: t({ id: 'DEMO.TITLE' }),
      onClick: () => {
        window.open(CONFIG.DEMO_URL);
      },
      className: 'demo'
    },
    {
      key: 'login',
      label: t({ id: 'LOGIN' }),
      onClick: () => {
        window.open(CONFIG.APP_URL);
      },
      className: 'login'
    },
    {
      key: routes.contact().substring(1),
      label: t({ id: 'CONTACT.TITLE' }),
      onClick: () => {
        navigate(routes.contact());
      },
      className: 'contact'
    },
    {
      key: 'divider',
      label: <Divider type="vertical" />,
      className: 'divider'
    },
    {
      key: 'language',
      label: <IconLanguage />,
      className: 'language',
      children: [
        {
          key: 'en',
          label: t({ id: 'LANGUAGES.ENGLISH' }),
          onClick: () => {
            setLocale('en');
          }
        },
        {
          key: 'es',
          label: t({ id: 'LANGUAGES.SPANISH' }),
          onClick: () => {
            setLocale('es');
          }
        }
      ]
    }
  ];

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[selectedSubMenu]}
      onClick={() => {
        if (!!onClick) {
          onClick();
        }
      }}
      items={items}
      rootClassName="header-sub-menu"
      overflowedIndicator={<MenuOutlined />}
      triggerSubMenuAction="click"
    />
  );
};

export default HeaderMenu;
