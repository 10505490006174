import { getNoTracking } from './client';
import { toParams } from '../lib/params.lib';
import { Country, CountryResponse } from '../models/country.model';

export async function getCountriesCompact(
  name: string,
  language: string
): Promise<Country[]> {
  const params = {
    ...toParams({ name }),
    ...toParams({ language }),
    ...toParams({ sort_by: 'name', sort_direction: 'asc' })
  };

  return getNoTracking<CountryResponse>('/countries/compact', params).then(
    (resp: CountryResponse) => resp.data
  );
}
