import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import routes from './routes';

import CustomLayout from './components/Layout/Layout';

import LoadingSpinner from './components/Shared/LoadingSpinner';
import CookieConsent from './components/Shared/CookieConsent';
import useServerError from './components/Shared/ServerError';

import HomePage from './pages/HomePage';
import FunctionsPage from './pages/FunctionsPage';
import ContactPage from './pages/ContactPage';

import CookiesPolicy from './legal/CookiesPolicy';
import LegalNotice from './legal/LegalNotice';
import PrivacyPolicy from './legal/PrivacyPolicy';
import TermsOfService from './legal/TermsOfService';

const Router: React.FC = () => {
  const { pathname } = useLocation();

  const [refresh, setRefresh] = useState<number>(0);

  const onRefresh = (): void => {
    setRefresh(Math.random());
  };

  const resize = (): void => {
    const element = document?.querySelector(':root') as HTMLElement;
    element.style?.setProperty('--vh', `${window.innerHeight}px`);
  };

  const callSizeChanged = () => setTimeout(resize, 500);

  useEffect(() => {
    callSizeChanged();
    window.addEventListener('resize', () => callSizeChanged());
    return () => {
      window.removeEventListener('resize', callSizeChanged);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!!refresh) {
    }
  }, [refresh]);

  const getRouterElement = (): React.ReactNode => {
    return (
      <LoadingSpinner>
        <CustomLayout>
          <Routes>
            <Route path={routes.contact()} element={<ContactPage />} />
            <Route path={routes.functions()} element={<FunctionsPage />} />
            <Route path={routes.root()} element={<HomePage />} />

            <Route path={routes.cookies_policy()} element={<CookiesPolicy />} />
            <Route path={routes.legal_notice()} element={<LegalNotice />} />
            <Route path={routes.privacy_policy()} element={<PrivacyPolicy />} />
            <Route
              path={routes.terms_of_service()}
              element={<TermsOfService />}
            />

            <Route path="*" element={<Navigate to={routes.root()} />} />
          </Routes>
        </CustomLayout>
      </LoadingSpinner>
    );
  };

  useServerError();

  return (
    <>
      {getRouterElement()}
      <CookieConsent
        closeBanner={() => {
          onRefresh();
        }}
      />
    </>
  );
};

export default Router;
