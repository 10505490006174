import dayjs from 'dayjs';
import { getZStringFromDayjsDate } from './date.lib';

export function toParams(
  obj: { [id: string]: any } | undefined
): Record<string, string> {
  if (!obj || typeof obj !== 'object') {
    return {};
  }

  return Object.keys(obj).reduce(
    (params: Record<string, string>, key: string) => {
      const value = obj[key];
      if (
        value !== null &&
        value !== undefined &&
        value !== '' &&
        !Array.isArray(value)
      ) {
        return {
          ...params,
          [key]: value instanceof Date ? value.toISOString() : String(value)
        };
      } else if (Array.isArray(value)) {
        return {
          ...params,
          [key]: value.join(',')
        };
      }
      return params;
    },
    {}
  );
}

export function applyDateFiltering(
  params: { [id: string]: any },
  since_key = 'since_date',
  until_key = 'until_date'
): any {
  return {
    ...toParams({ ...params }),
    [since_key]: params[since_key]
      ? getZStringFromDayjsDate(dayjs(params[since_key]).startOf('day'))
      : undefined,
    [until_key]: params[until_key]
      ? getZStringFromDayjsDate(dayjs(params[until_key]).endOf('day'))
      : undefined
  };
}
