import React from 'react';

const imageSrc = require('./imageDisponibilidad.png');

const TermsOfService: React.FC = () => (
  <div className="block block__single block__single__legal">
    <div className="legal terms-of-service">
      <h2>Condiciones generales de contratación de "Plataforma DieselPlus"</h2>
      <p>
        De una parte, <b>TOT COMERCIAL, S.A.</b>, de ahora en adelante, el{' '}
        <b>Proveedor</b>, con domicilio social en Partida Horta Amunt s/n,
        25600, Balaguer, NIF A25031154, teléfono de{' '}
        <b>
          atención al cliente <a href="tel:973451072">973451072</a>
        </b>
        , y e-mail a <a href="mailto:gespasa@gespasa.es">gespasa@gespasa.es</a>,
        siendo titular de la plataforma “DIESELPLUS” (también la{' '}
        <b>Plataforma</b>), en régimen de <i>Software as a Service</i> (en
        adelante <b>SaaS</b>) expone el documento contractual que regirá la
        contratación de la plataforma con los módulos deseados por el
        cliente-usuario a través del sitio web reseñado o mediante contacto
        directo con un comercial o un distribuidor autorizado del Proveedor.
      </p>
      <p>
        Y de otra, el cliente-usuario del servicio contratado (en adelante el{' '}
        <b>Cliente</b>), cuya voluntad es adherirse a este contrato por el que
        se regulan los términos y condiciones generales de contratación para el
        uso de la plataforma titularidad del Proveedor.
      </p>
      <h3>1. INTRODUCCIÓN</h3>
      <p>
        Ambas partes se reconocen la más amplia capacidad para contratar y
        obligarse, y celebran en este acto el presente contrato de adhesión.
      </p>
      <p>
        El Cliente declara que:
        <ol>
          <li>Ha leído, entiende y comprende lo aquí expuesto.</li>
          <li>
            Que es una persona mayor de edad y con capacidad suficiente para
            contratar.
          </li>
          <li>Que asume todas las obligaciones aquí dispuestas.</li>
          <li>
            Que ha leído y acepta las presentes condiciones generales de compra
            desde el momento que contracte el uso de la plataforma.
          </li>
        </ol>
      </p>
      <p>Es recomendable que guarde una copia de estas Condiciones.</p>
      <p>
        El prestador pone a disposición de éstos la dirección de e-mail{' '}
        <a href="mailto:gespasa@gespasa.es">gespasa@gespasa.es</a> para que
        planteen cualquier duda acerca de estas condiciones.
      </p>
      <p>
        Las presentes condiciones tendrán un periodo de validez indefinido y
        serán aplicables a todas las contrataciones realizadas a través del
        sitio web o mediante un comercial o un distribuidor autorizado del{' '}
        <b>Proveedor</b>.
      </p>
      <p>
        El <b>Proveedor</b> se reserva el derecho de modificar unilateralmente
        estas Condiciones Generales, sin que ello pueda afectar a la
        contratación de la plataforma previa a la modificación, con el fin de
        mejorar el servicio. En todo caso, antes de contratar la plataforma han
        de consultarse estas condiciones generales.
      </p>
      <p>
        El <b>Proveedor</b> no se hace responsable de las consecuencias que
        pudieran resultar de una utilización inadecuada de la plataforma.
      </p>
      <h3>2. DEFINICIONES</h3>
      <p>
        Ambas partes acuerdan que, en este contrato, se entenderá por:
        <ul>
          <li>
            <b>Contrato</b>: La totalidad de los documentos que regulan el
            servicio contratado. Se compone de los siguientes documentos:{' '}
            <b>
              Condiciones generales de contratación, Condiciones particulares
              reguladas en los anexos de las presentes condiciones, la Oferta
              comercial y Acuerdo de Nivel de Servicio (definido como el
              Mecanismo de gestión de incidencias en el anexo 3)
            </b>
            . Este contrato podrá ser consultado en todo momento desde la
            Plataforma DIESELPLUS.
          </li>
          <li>
            <b>Condiciones generales de contratación</b>: documento donde se
            recoge el conjunto de condiciones generales con las que el{' '}
            <b>Cliente</b> accederá y utilizará la plataforma, sin perjuicio de
            lo que pueda indicarse en las condiciones particulares establecidas
            con el cliente que se detallan en los anexos del presente documento.
          </li>
          <li>
            <b>Oferta comercial</b>: documento donde se recogen los módulos y
            servicios que el cliente ha contratado y su coste, junto con el
            periodo de prueba acordado si es diferente del aquí regulado y la
            permanencia.
          </li>
          <li>
            <b>Acuerdo de Nivel de Servicio</b> -SLA-, documento donde se
            estipulan las condiciones y parámetros que comprometen al Proveedor
            del Servicio a cumplir con unos niveles de calidad de servicio
            frente al Cliente en relación con la plataforma.
          </li>
          <li>
            <b>Cliente</b>: La persona física o jurídica que tiene interés en
            contratar una licencia de uso de la <b>Plataforma</b>, total o
            parcialmente (en régimen de SaaS).
          </li>
          <li>
            <b>Partes</b>: Quienes suscriben el contrato –el <b>Proveedor</b> y
            el <b>Cliente–</b>.
          </li>
          <li>
            <b>Equipo Informático</b>: ordenadores de sobremesa portátiles,
            tabletas digitales, <i>smartphones</i> o cualquier otro dispositivo
            en el que se pueda instalar o se pueda acceder debidamente a la{' '}
            <b>Plataforma</b>, atendiendo a los requerimientos mínimos para cada
            tipo de equipo y sistema operativo establecidos por el{' '}
            <b>Proveedor</b>.
          </li>
          <li>
            Servicio SAAS: El servicio prestado por el Proveedor a <b>favor</b>{' '}
            del <b>Cliente</b> a través de la <b>Plataforma</b> SaaS.
          </li>
          <li>
            <b>Plataforma DIESELPLUS</b>: Plataforma informática desarrollada y
            gestionada por el Proveedor, de su propia titularidad.
            <ul>
              <li>
                La Plataforma está compuesta por diferentes elementos de
                software, sistemas de comunicación y servicios, proporcionados
                por el <b>Proveedor</b>, accesible a través de internet, dónde
                se almacena la información que aporta y manipula el{' '}
                <b>Cliente</b>. El <b>Cliente</b> accede a la{' '}
                <b>Plataforma DIESELPLUS</b> a través de su Entorno Privado,
                mediante los identificadores de usuario y claves (personales e
                intransferibles) facilitados por el <b>Proveedor</b>.
              </li>
              <li>
                El <b>Cliente</b>, mediante la contratación de la{' '}
                <b>Plataforma DIESELPLUS</b>, adquiere la correspondiente
                Licencia de uso en el ámbito del Servicio SaaS prestado por el{' '}
                <b>Proveedor, en ningún caso de forma exclusiva</b>.
              </li>
            </ul>
          </li>
          <li>
            <b>Entorno privado del Cliente</b>: Espacio de gestión de los
            módulos y funcionalidades de la aplicación que el <b>Proveedor</b>{' '}
            pone a disposición del <b>Cliente</b> para poder prestarle el
            Servicio SaaS contratado.
          </li>
        </ul>
      </p>
      <h3>3. CONDICIONES GENERALES DE USO</h3>
      <h4>A. OBJETO DE ESTAS CONDICIONES GENERALES</h4>
      <p>
        El presente contrato tiene por objeto regular los términos y condiciones
        en virtud de las cuales el <b>Proveedor</b> presta a favor del{' '}
        <b>Cliente</b> el servicio de acceso y mantenimiento de la plataforma
        con el objetivo de que éste pueda realizar el control de existencias de
        los distintos carburantes, lubricantes u otros productos que pueden
        medirse a través de los equipos compatibles con la Plataforma
        DIESELPLUS. También ofrece una gestión de la flota a través de sus
        consumos de combustible u otros productos y de su posicionamiento. Estos
        contenidos pueden mostrarse como listas, informes, alarmas o ser
        enviados a través de correo electrónico a los usuarios para la obtención
        de datos estadísticos y para optimizar el consumo de carburantes,
        lubricantes u otros productos, así como la reducción de costes e impacto
        medioambiental.
      </p>
      <p>
        La Plataforma además de los servicios indicados, dispone de varios
        módulos que la complementan. En el presupuesto se establecen los
        distintos módulos que el Cliente ha contratado y sus características
        están detalladas en los anexos 1 a) a 1 e), que se encuentran en las
        presentes condiciones. Éstos son:
        <ul>
          <li>
            Servicio de Comunicaciones y Acceso a DIESELPLUS para Controladores
            Compatibles, que permiten al CLIENTE comunicar con la Plataforma un
            equipo controlador compatible. Toda la información relativa a las
            condiciones particulares de este servicio, están reguladas en el
            ANEXO 1 a.
          </li>
          <li>
            Servicio de Comunicaciones y acceso a DIESELPLUS para Sondas de
            Nivel compatibles con TANK MODEM PLUS, que permiten al CLIENTE
            comunicar con la Plataforma una sonda de nivel compatible con
            DIESELPLUS a través del equipo TANK MODEM PLUS. Toda la información
            relativa a las condiciones particulares de este servicio, están
            reguladas en el ANEXO 1 b.
          </li>
          <li>
            Servicio de Comunicación y acceso a DIESELPLUS para sondas Dolphin,
            que permiten al CLIENTE comunicar la sonda Dolphin con DIESELPLUS.
            Toda la información relativa a las condiciones particulares de este
            servicio, están reguladas en el ANEXO 1 c.
          </li>
          <li>
            Servicio de integración y acceso a DIESELPLUS para aplicaciones de
            geolocalización de terceros compatibles, que permiten al CLIENTE
            integrar con la Plataforma un sistema de localización compatible.
            Toda la información relativa a las condiciones particulares de este
            servicio, están reguladas en el ANEXO 1 d.
          </li>
          <li>
            Servicio de acceso a la API DIESELPLUS para integración con
            aplicaciones de terceros, que permiten al CLIENTE integrar con la
            Plataforma una aplicación externa a través del API DIESELPLUS. Toda
            la información relativa a las condiciones particulares de este
            servicio, están reguladas en el ANEXO 1 e.
          </li>
        </ul>
      </p>
      <h4>
        B. PROCEDIMIENTO DE CONTRATACIÓN DEL SERVICIO Y LICENCIA DE USO DE LA
        PLATAFORMA
      </h4>
      <p>
        Para iniciar el proceso de contratación, el Cliente deberá aceptar estas
        Condiciones Generales de contratación, así como las condiciones
        particulares del contrato de los módulos y personalizaciones que desea
        contratar el <b>Cliente</b>, incluidos como anexos de este documento.
        Todo ello supondrá la contratación del uso de la plataforma. Todas las
        informaciones contractuales presentes se muestran en castellano e inglés
        y la comunicación con los clientes y usuarios, así como la formalización
        del contrato, se realizarán en el idioma que el cliente indique antes de
        iniciar el procedimiento de contratación.
      </p>
      <p>
        En el proceso de contratación:
        <ul>
          <li>
            Al cliente se le indicará en la oferta comercial entregada:
            <ul>
              <li>
                Licencias, características y precios de los módulos que desea
                contratar
              </li>
              <li>
                El periodo de permanencia y el de prueba si es distinto del
                estipulado en las presentes condiciones
              </li>
            </ul>
          </li>
          <li>
            Datos de contacto del interlocutor encargado de centralizar y
            coordinar las relaciones con el <b>Proveedor</b>.
          </li>
          <li>Datos de facturación y medio de pago.</li>
          <li>
            Datos técnicos que permitan crear y configurar una cuenta y los
            equipos compatibles, según los módulos contratados.
          </li>
          <li>
            Para cada uno de los módulos el Cliente dispondrá del número de
            cuentas de acceso que se estipulan en las Condiciones particulares
            de dicho módulo.
          </li>
        </ul>
      </p>
      <p>
        Una vez que el <b>Proveedor</b> haya comprobado que dispone de toda la
        información solicitada y del pago realizado por parte del <b>Cliente</b>
        , aquél comunicará a éste la confirmación de la contratación realizada.
        Cuando el pago sea periódico, se comprobará la efectiva y correcta
        suscripción a los medios de pago online habilitado cuando proceda, a tal
        efecto o bien la adecuada cumplimentación y comunicación de los datos de
        pago.
      </p>
      <p>
        El <b>Cliente</b> queda informado y acepta que las informaciones
        contenidas en el sitio web del proveedor, así como las ofertas,
        presupuestos y proyectos facilitados en su caso al <b>Cliente</b>, no
        constituyen oferta contractual ni el inicio de una relación negocial con
        el Proveedor, sino que requerirá en cualquier caso el cumplimiento de
        los requisitos establecidos para la conclusión del proceso de
        contratación en las presentes Condiciones Generales de contratación de
        conformidad con la normativa aplicable.
      </p>
      <p>
        Si el <b>Cliente</b> no estuviera de acuerdo con el contendido de las
        presentes Condiciones Generales o de cualquier parte del Contrato,
        deberá abstenerse de continuar el proceso de contratación.
      </p>
      <h4>C. CONSERVACIÓN DE LA INFORMACIÓN DE LA PLATAFORMA</h4>
      <p>
        La plataforma DIESELPLUS, según los módulos contratados, puede almacenar
        el histórico de repostajes y movimientos de existencias, el histórico de
        lecturas de los depósitos o el histórico de las posiciones y el estado
        de los vehículos. Si no se especifica un periodo de conservación en la
        oferta comercial o en alguna de las condiciones particulares de los
        distintos módulos, por defecto y mientras sea vigente el presente
        contrato, la plataforma DIESELPLUS conservará la información del
        histórico, al menos, los últimos 5 años.
      </p>
      <h4>D. LICENCIA DE USO DE LA PLATAFORMA</h4>
      <p>
        El <b>Proveedor</b> concede al cliente una licencia intransferible y no
        exclusiva de uso de la Plataforma titularidad del <b>Proveedor</b>, bajo
        los términos y condiciones que se indican en el Contrato. La utilización
        de la <b>Plataforma</b> sólo estará permitida al cliente que disponga
        del entorno Privado y credenciales para acceder a la misma, con el
        límite máximo de usuarios indicados en el anexo del módulo
        correspondiente.
      </p>
      <p>
        El Cliente se abstendrá de realizar copias, revelar y permitir el acceso
        a terceros a la <b>Plataforma</b>.
      </p>
      <p>
        Los derechos concedidos al Cliente de conformidad con lo previsto en las
        siguientes Condiciones Generales se extienden a cualquier actualización
        o nueva versión que sustituya o complemente a la Plataforma.
      </p>
      <h4>E. GARANTÍA SOBRE LA PLATAFORMA</h4>
      <p>
        El cliente acepta que la Plataforma es “tal cual es”, por lo que el{' '}
        <b>Proveedor</b> no puede garantizar en ningún caso que las funciones
        que contiene cumplan con las expectativas o las necesidades del cliente,
        ni que la plataforma funcione ininterrumpidamente o sin errores. El{' '}
        <b>Cliente</b> asumirá el coste íntegro de todos los servicios,
        reparaciones, correcciones necesarias para adaptarla a sus necesidades.
        Cualquier cambio, adaptación o personalización de la Plataforma la
        realizará el <b>Proveedor</b> o quien éste designe.
      </p>
      <p>
        Se ofrece un período de prueba de 14 días naturales, que se entiende
        como período de garantía. Este puede modificarse por acuerdo entre las
        partes y quedará establecido en el acuerdo comercial. Si el{' '}
        <b>Cliente</b> no ha notificado de forma fehaciente al Proveedor la
        existencia de anomalías durante el referido periodo, se considerará que
        está conforme en todos los aspectos con la Plataforma y que la misma
        funciona correctamente y se adapta a sus necesidades, renunciando, a
        partir de entonces, a formular cualquier reclamación.
      </p>
      <p>
        Para hacer uso de la licencia concedida sobre la plataforma, el Cliente
        deberá cumplir en todo momento, entre otras, con las obligaciones de:
        <ol>
          <li>
            Utilizar la plataforma conforme a las indicaciones realizadas por el
            Proveedor,
          </li>
          <li>
            Disponer de sistemas y equipos informáticos en buen estado y
            correctamente mantenidos,
          </li>
          <li>Disponer de acceso a internet en sus equipos,</li>
          <li>
            Realizar un control del acceso a la Plataforma con el fin de evitar
            manipulaciones de terceros no autorizados
          </li>
          <li>
            Cumplir con los requerimientos técnicos necesarios para la
            utilización de la Plataforma y dispongan del software y sistema
            operativo requerido debidamente actualizado y mantenido,
          </li>
          <li>No permitir la instalación de virus o programas maliciosos,</li>
          <li>Aplicar las medidas de seguridad preventivas razonables.</li>
        </ol>
      </p>
      <p>
        En ningún caso el <b>Proveedor</b> será responsable de los errores de
        funcionamiento o daños provocados por el incumplimiento de las
        obligaciones estipuladas en el punto anterior o cualesquiera
        obligaciones que le sean de aplicación de conformidad con estas
        Condiciones Generales.
      </p>
      <p>
        En ningún caso el <b>Cliente</b> podrá reclamar indemnización alguna por
        daños y perjuicios producidos por causas fuera de control del{' '}
        <b>Proveedor</b> y, en particular, interrupción o fallos de cualquier
        tipo producidos en sistemas de alimentación, redes de
        telecomunicaciones, elementos de interconexión o equipos del Cliente.
      </p>
      <h4>F. PROPIEDAD INTELECTUAL DE LA PLATAFORMA</h4>
      <p>
        La Plataforma ha sido creada y es gestionada por el <b>Proveedor</b>,
        quien conservará todos los derechos de propiedad intelectual, industrial
        o cualesquiera otros sobre la misma, y no podrá, bajo ningún concepto,
        ser objeto de ulterior modificación, copia, alteración, reproducción,
        adaptación o traducción por parte del <b>Cliente</b>.
      </p>
      <p>
        La estructura, características, códigos, métodos de trabajo, sistemas de
        información, métodos de desarrollo, know-how, metodologías, procesos,
        tecnologías, algoritmos, así como los manuales de uso, textos, dibujos
        gráficos, bases de datos, videos o soportes de audio referidos o que
        complementan a la Plataforma son propiedad del <b>Proveedor</b> y están
        protegidos por las normas españolas, comunitarias e internacionales de
        propiedad intelectual e industrial.
      </p>
      <p>
        La puesta a disposición al <b>Cliente</b> de la <b>Plataforma</b> no
        implica, en ningún caso, la cesión de su titularidad ni la concesión de
        un derecho de uso en favor del Cliente distinto del previsto en las
        Condiciones Generales.
      </p>
      <p>
        En consecuencia, está totalmente prohibido cualquier uso por el{' '}
        <b>Cliente</b> de la Plataforma que se realice sin autorización del{' '}
        <b>Proveedor</b>, incluida su explotación, reproducción, difusión,
        transformación, distribución, transmisión por cualquier medio y
        posterior publicación o exhibición, comunicación pública o
        representación total o parcial, fuera del ámbito operativo previsto para
        el uso del a Plataforma. El incumplimiento de cualquiera de las
        prohibiciones indicadas constituirá la infracción de los derechos de
        propiedad intelectual o industrial del proveedor, sancionadas por la
        legislación vigente.
      </p>
      <h4>G. RÉGIMEN DE PRESTACIÓN DEL SERVICIO</h4>
      <p>
        El Proveedor presta el servicio de SaaS directamente, con sus propios
        medios. Para el alojamiento de sus servidores, el Proveedor tiene
        subcontratado los mismos a la empresa Microsoft Azure. Por todo lo que
        respecta a este servicio ambas empresas tienen firmado el contrato de
        subencargado de tratamiento, en cumplimiento con la normativa de
        protección de datos vigente.
      </p>
      <h4>H. ESPECIFICACIONES TÉCNICAS</h4>
      <p>
        En el Anexo 2 se detallan las especificaciones técnicas para el acceso y
        uso de la plataforma e instalación de las aplicaciones vinculadas. En
        las Condiciones particulares, que encontrará en los ANEXOS del 1.a ) al
        1.e), de cada módulo contratado por el <b>Cliente</b> se contienen las
        Especificaciones técnicas necesarias que deberán cumplir las redes,
        equipos y terminales del <b>Cliente</b> en todo momento para poder
        instalar y/o acceder y hacer uso de la Plataforma. En caso de que las
        redes, equipos y terminales del <b>Cliente</b>
        no cumplan con las especificaciones técnicas referidas, el{' '}
        <b>Cliente</b> deberá abstenerse de instalar y/o acceder y utilizar la
        Plataforma.
      </p>
      <p>
        El <b>Proveedor</b> pone a disposición del <b>Cliente</b> los manuales
        de aplicación y de los distintos módulos de Dieselplus que encontrara en
        la plataforma una vez registrado en el apartado de “ayuda”, que detallan
        la operativa concreta y las instrucciones básicas de utilización de la
        Plataforma. Para aquellas personalizaciones que el <b>Cliente</b> haya
        solicitado al <b>Proveedor</b>, puede que no haya a su disposición
        instrucciones o manuales. El <b>Proveedor</b>
        declina cualquier responsabilidad que pudiera derivarse de una
        utilización por el <b>Cliente</b> no conforme a dicho manual,
        instrucciones o notificaciones facilitadas por el Proveedor, o en caso
        de redes, equipos y terminales del <b>Cliente</b> que no cumplieran con
        las referidas especificaciones técnicas.
      </p>
      <p>
        Previo aviso por parte del <b>Proveedor</b>, el <b>Cliente</b> deberá
        desconectar sus redes, equipos o terminales de la Plataforma si, en
        opinión del <b>Proveedor</b>
        dichas redes, equipos o terminales han provocado o pueden provocar
        fallos, interrupciones, errores o defectos en la Plataforma.
      </p>
      <h4>I. CONDICIONES DE UTILIZACIÓN DE LA PLATAFORMA</h4>
      <p>
        La Plataforma solamente será accesible por parte del <b>Cliente</b> a
        favor del cual el <b>Proveedor</b> haya asignado al menos una licencia y
        le haya facilitado unas credenciales para el acceso.
      </p>
      <p>
        El <b>Cliente</b> únicamente podrá utilizar la Plataforma de conformidad
        con las finalidades previstas en el Contrato.
      </p>
      <p>
        El <b>Cliente</b> responderá de cualesquiera daños y perjuicios que el{' '}
        <b>Proveedor</b> o terceros puedan sufrir como consecuencia del
        incumplimiento por el propio <b>Cliente</b> de cualesquiera obligaciones
        asumidas y mantendrá indemne al <b>Proveedor</b> frente a cualquier
        reclamación que le pueda ser interpuesta al respecto, aceptando pagar
        las cantidades a las que por cualquier concepto el <b>Proveedor</b>{' '}
        pueda venir obligado a satisfacer.
      </p>
      <p>
        A fin de verificar el cumplimiento por el <b>Cliente</b> de lo previsto
        en las presentes Condiciones y garantizar la seguridad y buena
        utilización de la Plataforma, y al objeto de asegurar un mejor
        rendimiento o ajuste de determinados elementos de la misma, el{' '}
        <b>Proveedor</b> se reserva el derecho de monitorizar el uso que el{' '}
        <b>Cliente</b> realice de la misma.
      </p>
      <p>
        El <b>Cliente</b> es el único responsable del contenido incluido en su
        entorno al utilizar la plataforma, así como de la actividad que se
        produzca en la misma, de los usuarios habilitados a usarla y de toda la
        información en ella almacenada.
      </p>
      <p>
        El <b>Cliente</b> se compromete a mantener en secreto sus claves de
        acceso y conviene en notificar inmediatamente al <b>Proveedor</b> acerca
        de cualquier uso no autorizado de su cuenta asociada.
      </p>
      <p>
        El <b>Cliente</b> se compromete a atender los pagos en las condiciones
        acordadas con el <b>Proveedor</b> y entiende y consiente que, en el
        supuesto caso de impago, el <b>Proveedor</b> puede facultativamente
        cancelar temporalmente su acceso a la Plataforma hasta regularizar la
        situación, manteniendo su entorno privado en la Plataforma. Si tras el
        proceso de regularización del impago no se subsana la situación mediante
        el pago y/o acuerdo de la eventual deuda contraída, el <b>Proveedor</b>{' '}
        está facultado para extinguir la relación contractual entre ambos,
        siendo de aplicación lo dispuesto en el apartado de Resolución de estas
        Condiciones Generales.
      </p>
      <h4>J. OBLIGACIONES DEL CLIENTE</h4>
      <p>
        El <b>Cliente</b> deberá cumplir con todos los términos y condiciones de
        estas condiciones generales en el ejercicio de su actividad profesional,
        además deberá actuar respetando la legalidad vigente y la buena fe.
      </p>
      <p>
        También son obligaciones del <b>Cliente</b>:
        <ol>
          <li>
            Cumplir escrupulosamente cuantos requerimientos se establecen en las
            condiciones técnicas.
          </li>
          <li>
            Hacer efectivo el precio convenido en el contrato, de conformidad
            con lo indicado en el apartado correspondiente al Precio y Pago.
          </li>
          <li>
            Cumplir escrupulosamente la normativa relativa a la protección de
            datos (RGPD y LOPDGDD), así como con la normativa de la LSSICE, en
            el sentido de que no podrá utilizar el servidor de correo del
            dominio y de las direcciones de correo electrónico con fines de
            spamming (envío de correo masivo no deseado, de tipo comercial o de
            cualquier otra naturaleza) o mail bombing (envío de grandes mensajes
            con el fin de bloquear un servidor).
          </li>
          <li>
            Mantener operativa, activa y actualizada la dirección e-mail
            proporcionada en el documento de contratación para las
            comunicaciones con el <b>Proveedor</b>, ya que constituye el medio
            de comunicación preferente del <b>Proveedor</b> para la gestión ágil
            y fluida en la prestación del servicio contratado. Si el{' '}
            <b>Cliente</b> cambia la dirección e-mail de contacto deberá
            comunicarlo inmediatamente. El Proveedor se exonera de toda
            responsabilidad por las consecuencias derivadas de la falta de
            operatividad del correo electrónico facilitado por el <b>Cliente</b>
            , por la desactualización de aquél o desinformación del{' '}
            <b>Cliente</b> debido a su propia negligencia.
          </li>
          <li>
            Indemnizar al <b>Proveedor</b> por los gastos que éste tuviera por
            imputarle en alguna causa cuya responsabilidad fuera atribuible al{' '}
            <b>Cliente</b>, incluidos honorarios y gastos de los abogados del{' '}
            <b>Proveedor</b>, incluso en el caso de decisión judicial no
            definitiva.
          </li>
          <li>
            Queda prohibido al <b>Cliente</b> acceder, modificar, visualizar la
            configuración, estructura y ficheros de los servidores y plataforma
            del <b>Proveedor</b>. Cualquier problema que se pudiera producir en
            los servidores, plataforma y/o sistemas de seguridad del{' '}
            <b>Proveedor</b> como consecuencia directa de una actuación
            negligente del <b>Cliente</b>, éste responderá por la
            responsabilidad civil y penal que le pudiera corresponder.
          </li>
          <li>
            Realizar los trámites legales acordes y necesarios para que la
            documentación que genera la plataforma para ser usada en las
            gestiones o presentaciones a la administración tenga validez. Es
            responsabilidad del <b>Cliente</b> generar toda la documentación con
            suficiente antelación al periodo de entrega para que la presentación
            sea aceptada.
          </li>
        </ol>
      </p>
      <h4>K. ENTRADA EN VIGOR, DURACIÓN Y PRÓRROGA</h4>
      <p>
        La regulación contractual regulada en las presentes Condiciones
        Generales surtirá efectos desde el momento en que el <b>Proveedor</b>{' '}
        haya recibido la aceptación por parte del <b>Cliente</b> de las
        Condiciones Generales de contratación y el documento de contratación,
        así como la confirmación del pago. El <b>Cliente</b> recibirá por correo
        electrónico copia de esta confirmación y tendrá disponibles el presente
        contrato en la plataforma.
      </p>
      <p>
        Llegado el día de su vencimiento o de fin del periodo de permanencia el
        contrato se prorrogará por un período igual si el <b>Cliente</b> no le
        ha notificado por escrito y con prueba de recepción, con una antelación
        mínima de un mes antes de la finalización de la anualidad, su voluntad
        de no prorrogarlo. En caso de prórroga, la cuota experimentará el
        incremento previsto en la estipulación cuarta.
      </p>
      <p>
        Lo dispuesto en el párrafo anterior se aplicará a la primera prórroga
        del contrato, así como a las sucesivas.
      </p>
      <p>
        En todo caso, si el <b>Cliente</b> solicita la rescisión de este
        contrato antes de la fecha de vencimiento o, en su caso, de sus
        prórrogas, el <b>Cliente</b> se obliga a descargar los archivos o
        informes necesarios para guardar su información Transcurridos dos meses
        a partir de la fecha de vencimiento del presente contrato, o de sus
        prórrogas, el <b>Proveedor</b>, de forma irrevocable, eliminará todos
        los datos contenidos en la licencia concedida al <b>Cliente</b> en
        ejecución de este contrato. Si el <b>Cliente</b> necesitara más espacio
        de tiempo para la retirada del contenido, deberá solicitarlo por escrito
        al <b>Proveedor</b> y éste aceptar la propuesta.
      </p>
      <p>
        La eliminación de los datos reseñada en el párrafo anterior comporta la
        destrucción o inutilización definitiva de éstos. Para el caso de que tal
        eliminación pueda causar algún perjuicio a terceros, el <b>Cliente</b>{' '}
        se obliga a dejar indemne al <b>Proveedor</b> de cualquier acción que un
        tercero pueda intentar por este motivo.
      </p>
      <p>
        El <b>Cliente</b> entiende y acepta que el <b>Proveedor</b> cancele de
        forma definitiva el acceso facilitado y destruya toda la información que
        éste contenga una vez extinguida la relación contractual entre ambos.
      </p>
      <h4>L. CANCELACIÓN DEL SERVICIO</h4>
      <p>
        Si el servicio solicitado se cancela antes de la fecha de entrega
        prevista por el Proveedor, éste se reserva el derecho a reclamar los
        costes razonables en los que haya incurrido como consecuencia de la
        cancelación o modificación, incluso cuando el <b>Proveedor</b> no haya
        facturado expresamente costes de instalación o exista un descuento sobre
        los mismos.
        <br />
        Si la cancelación se recibe entre 0 y 2 días naturales antes de la fecha
        prevista de la activación del servicio, se establece una compensación
        del 100 % de la cuota del periodo contratado; si la notificación es
        recibida entre 3 y 4 días naturales la compensación es del 50 % de la
        cuota del periodo contratado; y si la notificación es recibida entre 5
        días o más antes de la activación del servicio se establece una
        compensación del 20 % de la cuota del periodo contratado.
        <br />
        Si el contrato, una vez aprovisionado el servicio, se cancela
        anticipadamente a la fecha prevista de finalización, el <b>
          Proveedor
        </b>{' '}
        se reserva el derecho de reclamar al <b>Cliente</b> los costes
        razonables en los que haya incurrido como consecuencia de la
        cancelación. El importe de la penalización se corresponderá a las cuotas
        pendientes hasta la finalización de la duración inicial del contrato.
      </p>
      <p>
        Si el Cliente, una vez iniciado el servicio, solicitara la baja antes de
        haber transcurrido el primer año, mantendrá acceso al mismo hasta que
        finalice el plazo anual de contratación.
      </p>
      <h4>M. FUERZA MAYOR</h4>
      <p>
        Cuando se produzca un incumplimiento que se deba a causas de Fuerza
        Mayor, ninguna de las partes será responsable por el incumplimiento de
        sus obligaciones derivadas del contrato y, por lo tanto, no habrá
        derecho a indemnización.
      </p>
      <p>
        Si la suspensión por esta circunstancia fuera superior a 1 mes, este
        contrato se podrá rescindir a petición de cualquiera de las partes.
      </p>
      <p>
        Se entienden por causas de fuerza mayor, entre otras, incendios,
        inundaciones, huelgas, conflictos laborales u otros desórdenes sociales,
        escasez o indisponibilidad de energía eléctrica, indisponibilidad o
        funcionamiento anómalo de las redes de comunicaciones, accidentes,
        guerras (declaradas o no declaradas), embargos comerciales, bloqueos,
        disturbios o insurrecciones.
      </p>
      <h4>N. NATURALEZA</h4>
      <p>
        Todas las obligaciones que el <b>Proveedor</b> asume en virtud de este
        contrato son de actividad o servicio, no de resultado. Tales
        obligaciones no comprometen ni garantizan la obtención de un objetivo,
        obra o resultado determinados, salvo en los casos en que así lo pacten
        las partes de manera expresa y terminante.
      </p>
      <h4>O. CORTES EN EL SERVICIO</h4>
      <p>
        En algunos casos, el acceso puede ser denegado por el <b>Proveedor</b> o
        la empresa alojadora de los datos subcontratada para realizar algún tipo
        de ajuste. Dichos ajustes, en los cuales se cortará el acceso de forma
        temporal, se llevarán a cabo en horarios que no coincidan con los de
        mayor afluencia de público, bajo compromiso de realizar copia de
        seguridad de la información, y preavisando al <b>Cliente</b> del corte,
        así como de la duración aproximada del mismo.
      </p>
      <h4>
        P. MODIFICACIONES, ADAPTACIONES O NUEVAS VERSIONES DE LA PLATAFORMA
      </h4>
      <p>
        El <b>Proveedor</b> se reserva el derecho a modificar, adaptar o
        actualizar, en cualquier momento y modo, las características y
        condiciones de la Plataforma, siempre en desarrollo y beneficio de la
        misma. Para ello no deberá cumplir más formalidad que la de informar al{' '}
        <b>Cliente</b> a través de la Plataforma o correo electrónico y llevar a
        cabo dichas modificaciones o actualizaciones a las cláusulas del
        contrato.
      </p>
      <p>
        Si como consecuencia del desarrollo constante del <b>Proveedor</b>, o
        por avances técnicos y tecnológicos, se produjeran cambios en el
        servicio del <b>Proveedor</b>, se procederá, si así éste lo estima
        oportuno, a realizar las modificaciones que sean necesarias para la
        adaptación a dichos avances.
      </p>
      <p>
        En ambos casos, el <b>Proveedor</b> comunicará al <b>Cliente</b> la
        información sobre los cambios que le puedan afectar. El <b>Cliente</b>,
        una vez comunicada la modificación/adaptación/actualización por parte
        del <b>Proveedor</b>, dispondrá del plazo de 7 días, desde que recibe
        dicha comunicación, para resolver el contrato si no estuviera de acuerdo
        con las nuevas circunstancias. Pasado este plazo sin comunicación en
        contrario del <b>Cliente</b> se entenderá que acepta las nuevas
        condiciones.
      </p>
      <p>
        El <b>Proveedor</b>, en su actuar diligente y de buena fe, procederá a
        realizar estas modificaciones siempre que supongan un beneficio no sólo
        del desarrollo de las nuevas tecnologías, sino también del
        funcionamiento de la empresa e incluso del servicio ofrecido en este
        contrato de manera que no supongan un perjuicio a los clientes en todo
        su conjunto.
      </p>
      <p>
        En ningún caso podrá el <b>Cliente</b> reclamar del <b>Proveedor</b>{' '}
        indemnización alguna por daños y perjuicios que puedan derivarse de un
        mal funcionamiento de la Plataforma como consecuencia de que el Cliente
        no haya aceptado las referidas actualizaciones.
      </p>
      <h3>4. PRECIO Y PAGO</h3>
      <p>
        Durante la vigencia del presente contrato, el <b>Cliente</b> abonará al{' '}
        <b>Proveedor</b> la cantidad estipulada en la oferta comercial adjunta.
        A esas cantidades se les añadirán el Impuesto sobre el Valor Añadido y
        cualesquiera otros impuestos que les correspondan.
      </p>
      <p>
        Los pagos se realizarán a través de:
        <ul>
          <li>
            Domiciliación bancaria a la cuenta corriente indicada por el{' '}
            <b>Cliente</b> (y de su titularidad), cuyos datos se facilitarán en
            el documento SEPA firmado por el Cliente.
          </li>
          <li>Transferencia bancaria</li>
          <li>
            Medio de pago online, en caso de estar disponible, que se realizará
            por parte del <b>Cliente</b> a través de la plataforma de pago
            proporcionada por el <b>Proveedor</b> en el sitio web.
          </li>
        </ul>
      </p>
      <p>
        Una vez verificado el pago, el Proveedor le remitirá por correo
        electrónico al <b>Cliente</b> confirmación de la contratación efectuada.
      </p>
      <p>
        La cuantía de los precios estipulados por el <b>Proveedor</b> recogidos
        en la oferta comercial podrán ser objeto de revisión por parte del
        mismo. Si el <b>Proveedor</b> llevara a cabo un cambio en los precios al
        alza del servicio de alojamiento, se notificará al <b>Cliente</b> vía el
        email indicado por éste un mes antes de que tal modificación se vea
        reflejado en la siguiente factura que se le emita, de manera que si no
        estuviera de acuerdo en aceptar dicho cambio pueda ejercitar el derecho
        a resolver el presente contrato sin necesidad de pagar cantidad
        adicional alguna al <b>Proveedor</b> mientras mantenga su cuota pagada.
        Al finalizar el plazo de pago por adelantado quedará resuelto el
        contrato y, por lo tanto, causará baja su servicio si se mantuviera en
        su no aceptación al nuevo precio.
      </p>
      <p>
        El <b>Proveedor</b> se reserva el derecho, previa comunicación por
        escrito o por correo electrónico al <b>Cliente</b>, de suspender
        temporalmente el servicio prestado ante cualquier incidencia en el pago
        del servicio. Si el <b>Cliente</b> no procediera a su pago después de 15
        días tras la suspensión temporal, se resolverá este contrato, causando
        la baja definitiva del servicio por incumplimiento del contrato por su
        parte. Transcurridos 30 días posteriores a la suspensión del servicio el{' '}
        <b>Proveedor</b> procederá a la eliminación de toda la información,
        según se detalla en el apartado K. ENTRADA EN VIGOR, DURACIÓN Y PRÓRROGA
        de las presentes condiciones de uso.
      </p>
      <h3>5. SUSTITUCIÓN DE LAS PARTES</h3>
      <p>
        Todos los derechos y obligaciones que para el <b>Cliente</b> se derivan
        del presente contrato son intransmisibles, no pudiendo el <b>Cliente</b>{' '}
        enajenarlos, ni cederlos a terceros, ni en todo, ni en parte, sin la
        previa autorización por escrito del <b>Proveedor</b>.
      </p>
      <p>
        El <b>Proveedor</b>, por su parte, se reserva el derecho de transferir
        la página web a otro <b>Proveedor</b> distinto del originario,
        notificándoselo por escrito al <b>Cliente</b> en caso de desaparición
        del <b>Proveedor</b> o de la prestación de este servicio.
      </p>
      <h3>6. RESPONSABILIDAD</h3>
      <ol>
        <li>
          Será el <b>Cliente</b> quien suba la información a la plataforma. En
          ningún caso el <b>Proveedor</b> acepta responsabilidades derivadas de
          pérdidas de datos, interrupción de negocio o cualesquiera otros
          perjuicios producidos por el funcionamiento de la plataforma o por no
          cumplir ésta con las expectativas del <b>Cliente</b>.
        </li>
        <li>
          El <b>Proveedor</b>, no será responsable por la información almacenada
          a petición del <b>Cliente</b>, siempre que no tenga conocimiento
          efectivo de que la actividad o la información sea ilícita o lesione
          bienes o derechos de un tercero susceptibles de indemnización, y, en
          caso de que tuviera conocimiento efectivo, el <b>Proveedor</b> estará
          obligado a actuar de forma diligente retirando los datos o impidiendo
          el acceso a ellos, cuando un órgano competente haya declarado la
          ilicitud de los datos, ordenado su retirada, o que se imposibilite el
          acceso a los mismos, o se hubiera declarado la existencia de la
          lesión, y el <b>Proveedor</b> conociera la correspondiente resolución
          u otros medios de conocimiento efectivo que pudieran establecerse. En
          consonancia con lo anterior, el <b>Proveedor</b> podrá acceder con los
          dispositivos que crea oportunos a dichos contenidos libremente para
          comprobar la naturaleza de éstos.
        </li>
        <li>
          En cuanto a la disponibilidad del servicio, se exonera de
          responsabilidad al <b>Proveedor</b> en todas aquellas situaciones que
          puedan traer causa por la conexión del <b>Cliente</b> a la red,
          hardware y software utilizados por éste, uso incorrecto del{' '}
          <b>Cliente</b> o cualesquiera cuestiones que no dependan del{' '}
          <b>Proveedor</b>.
        </li>
        <li>
          El Proveedor no asume responsabilidades, ya sean directas o
          indirectas, subsidiarias, por daño emergente o lucro cesante,
          derivadas de un uso indebido o con fines distintos a los concebidos
          para DIESELPLUS. Se considerará terminantemente prohibido el uso del
          módulo de comunicaciones, definido en las distintas Condiciones
          particulares del ANEXO 1, propiedad del Proveedor, con fines ilegales
          o no autorizados, y en concreto con carácter exhaustivo: cualquier
          forma de violación de los derechos de terceros (derecho a la
          intimidad, al honor, a la propia imagen, al secreto de las
          comunicaciones, a la propiedad intelectual e industrial, de protección
          de datos, etc.). El Proveedor podrá, a su exclusivo criterio y sin que
          dé lugar a indemnización alguna, desactivar de forma inmediata el
          módulo de comunicaciones.
        </li>
        <li>
          La responsabilidad del <b>Proveedor</b> en relación con las
          obligaciones objeto de estas Condiciones estará sujeta a las
          siguientes limitaciones:
          <ol>
            <li>
              La responsabilidad del <b>Proveedor</b> por los conceptos
              derivados de la licencia de uso de la plataforma no podrá exceder
              en ningún caso de la cantidad equivalente al precio pactado en
              estas Condiciones, siendo en caso de modalidades de pago
              fraccionadas el importe menor entre el equivalente al precio de un
              año y la suma de los pagos debidamente satisfechos por el servicio
              desde la fecha inicial de contratación, excluyendo en todos los
              supuestos los impuestos y eventuales recargos y costes adicionales
              distintos al precio acordado. En ningún caso podrá el{' '}
              <b>Cliente</b> reclamar al Proveedor indemnización alguna por
              daños o perjuicios que puedan ser calificados como daños
              derivados, lucro cesante, pérdida de negocios, pérdida de
              reputación comercial o reclamaciones de terceros contra el{' '}
              <b>Cliente</b>, ni por cualesquiera daños similares.
            </li>
            <li>
              Con relación a incumplimientos producidos en el desarrollo de
              funciones o servicios por parte de las empresas subcontratadas por
              el <b>Proveedor</b>, la responsabilidad total del <b>Proveedor</b>{' '}
              no excederá, en su conjunto, de la cantidad total que el{' '}
              <b>Proveedor</b>, conforme a los contratos que en cada caso tenga
              suscritos, pueda obtener, por el incumplimiento de que se trate,
              del tercero subcontratado responsable de la prestación del
              servicio afectado por el incumplimiento.
            </li>
          </ol>
        </li>
      </ol>
      <h3>7. RESOLUCIÓN</h3>
      <p>
        Además de las causas generales de extinción de los contratos previstas
        en el ordenamiento jurídico, las partes coinciden en atribuir el
        carácter de causas de resolución del presente contrato, a las
        siguientes:
        <ol>
          <li>Mutuo acuerdo de las partes.</li>
          <li>
            Cuando el <b>Cliente</b>, al inicio del contrato, y aun en período
            de prueba, desista del Contrato.
          </li>
          <li>
            El incumplimiento, aun solamente parcial, por cualquier causa de
            alguna de las obligaciones del <b>Cliente</b>. En estos casos, el{' '}
            <b>Proveedor</b> se reserva el derecho a terminar de forma
            anticipada el presente contrato y, por lo tanto, a desposeer al{' '}
            <b>Cliente</b> del servicio contratado sin previo aviso y sin que el{' '}
            <b>Cliente</b> tenga derecho a indemnización ni a devolución de
            cantidad alguna.
          </li>
          <li>
            La causación por el <b>Cliente</b> de cualesquiera molestias, daños
            o perjuicios, al Proveedor, sus clientes o empleados, como
            consecuencia de la ejecución de este contrato.
          </li>
          <li>
            El transcurso del plazo fijado contractualmente o el de la prórroga.
          </li>
          <li>
            Impago por parte del <b>Cliente</b>.
          </li>
          <li>
            La sustitución o subrogación de un tercero en la posición que el{' '}
            <b>Cliente</b> ocupa en el contrato, realizada sin la autorización
            que prevé la estipulación quinta.
          </li>
          <li>
            En general, cualquier cumplimiento defectuoso o incumplimiento,
            parcial o total, de las obligaciones que derivan de este contrato o
            su Anexos para el <b>Cliente</b>.
          </li>
        </ol>
      </p>
      <p>
        En caso de resolución o rescisión del contrato, por las causas
        anteriormente citadas o cualesquiera otras admitidas en derecho, el{' '}
        <b>Cliente</b> deberá cumplir las obligaciones asumidas con anterioridad
        a la resolución del contrato frente al <b>Proveedor</b> y frente a
        terceros.
      </p>
      <p>
        Para que cualquiera de estas causas de resolución surta plenos efectos,
        bastará que el <b>Proveedor</b> lo notifique por escrito al{' '}
        <b>Cliente</b>, sin perjuicio de lo que se pacta en las estipulaciones
        octava y novena.
      </p>
      <h3>8. INTERESES MORATORIOS</h3>
      <p>
        En el caso de que el <b>Cliente</b> incumpla la obligación de pago del
        precio previsto en la estipulación cuarta o se retrase en el pago de
        cualquier cantidad líquida a la que resulte obligado en virtud del
        cumplimiento de este contrato o, muy especialmente, de su resolución,
        quedará constituido en mora automáticamente, desde el mismo día en que
        falte a su obligación, sin necesidad de requerimiento alguno por parte
        del <b>Proveedor</b>.
      </p>
      <p>
        En tales casos, las sumas adeudadas y no pagadas por el <b>Cliente</b>{' '}
        devengarán intereses moratorios que se calcularán diariamente aplicando
        al principal de la deuda el tipo de interés legal del dinero vigente en
        cada momento, incrementado en cuatro puntos.
      </p>
      <p>
        Los intereses moratorios debidos y no satisfechos devengarán a su vez
        intereses por acuerdo de las partes, sin necesidad de requerimiento, a
        partir de los noventa días de su devengo, generando a su vez intereses
        moratorios al tipo previsto en el párrafo anterior.
      </p>
      <h3>9. CONFIDENCIALIDAD</h3>
      <p>
        Toda información o documentación que cualquiera de las partes aporte a
        la otra en desarrollo y ejecución del presente contrato se considerará
        confidencial y exclusiva de quien lo aporte y no podrá comunicarse a
        terceros sin su consentimiento. De igual manera, las partes acuerdan dar
        el carácter de confidencialidad a este contrato, no pudiendo revelar a
        terceros ninguno de los puntos que integran su contenido sin el
        consentimiento expreso de ambas partes. Esta obligación de
        confidencialidad persiste hasta un año después de finalizar este
        contrato. Ninguna de las partes adquirirá ningún derecho sobre cualquier
        información confidencialidad u otros derechos de propiedad de la otra
        parte como resultado de este contrato.
      </p>
      <h3>10. POLÍTICA DE PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL</h3>
      <ol>
        <li>
          El <b>Proveedor</b> le informa que la recogida y tratamiento de los
          datos de carácter personal tiene como finalidad el mantenimiento,
          desarrollo, control y ejecución de la relación contractual que, en el
          marco de la prestación de los servicios que se identifican en el
          objeto del contrato, mantenga con el <b>Proveedor</b>. Este
          tratamiento se realizará exclusivamente para la ejecución del contrato
          y los datos se conservarán mientras dure la relación con el Cliente y,
          una vez finalizado aquél, durante el plazo legal exigido mientras
          puedan derivarse responsabilidades por parte del as Partes. Los datos
          no se comunicarán a terceros, salvo por obligación legal,
          requerimiento judicial o a aquellas entidades que sean necesarias con
          el único objetivo de dar cumplimiento a la finalidad anteriormente
          expuesta.
        </li>
        <li>
          El <b>Proveedor</b> tiene implantadas todas las medidas técnicas y
          organizativas necesarias para garantizar la seguridad, integridad,
          confidencialidad y resiliencia de los mismos conforme a lo dispuesto
          en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
          de Carácter Personal y Garantía de los Derechos Digitales (en adelante
          LOPDGDD) y al Reglamento (UE) 2016/679, del Parlamento Europeo y del
          Consejo, de 27 de abril de 2016 (en adelante RGPD).
        </li>
        <li>
          El <b>Cliente</b> podrá ejercer los derechos de acceso, rectificación,
          supresión y portabilidad de sus datos y los de limitación u oposición
          al tratamiento dirigiéndose a <b>TOT COMERCIAL, S.A.</b>, con
          domicilio en Partida Horta Amunt s/n, 25600, Balaguer, o enviando un
          correo electrónico a{' '}
          <a href="mailto:lopd@gespasa.es">lopd@gespasa.es</a>. Si considera que
          el tratamiento de datos personales no se ajusta a la normativa
          vigente, tiene derecho a presentar una reclamación ante la Autoridad
          de control (
          <a target="_blank" href="https://www.aepd.es">
            www.aepd.es
          </a>
          ).
        </li>
        <li>
          En los casos en que el <b>Cliente</b> incluya información con datos de
          carácter personal en los servidores de alojamiento compartido, según
          el artículo 28 RGPD, el <b>Proveedor</b> actúa como encargado del
          tratamiento y, por ello, se compromete a tratar los datos conforme las
          instrucciones del Cliente, a no aplicarlos ni utilizarlos con fines
          distintos a la prestación del servicio contratado, ni los comunicará a
          terceras personas, aunque sea para su conservación, salvo petición
          expresa del <b>Cliente</b>. Se firma el debido contrato de Encargado
          del Tratamiento entre <b>Proveedor</b> y <b>Cliente</b> (Responsable
          del tratamiento) como ANEXO 4 del presente contrato. Cualquier
          indicación, por parte del responsable del tratamiento, respecto a la
          implantación de medidas de seguridad especiales en el servidor será
          preciso ponerse en contacto con el <b>Proveedor</b> para regular las
          nuevas condiciones en caso de ser necesario, no quedando, por lo
          tanto, sujeto a este contrato.
        </li>
        <li>
          El <b>Proveedor</b> no se hace responsable del incumplimiento por
          parte del <b>Cliente</b> de la LOPDGDD ni RGPD en la parte que en su
          actividad le corresponda y que se encuentre relacionado con la
          ejecución de este contrato. Por lo que cada una de las partes no se
          hará responsable por el incumplimiento que de estas normas realice la
          otra.
        </li>
        <li>
          El <b>Cliente</b> manifiesta que todos los datos facilitados por él
          son ciertos y correctos, se compromete mantenerlos ciertos,
          comunicándolo al <b>Proveedor</b>. El <b>Cliente</b> responderá de la
          veracidad de sus datos y será el único responsable de cuantos
          conflictos o litigios pudieran resultar por la falsedad de éstos.
        </li>
        <li>
          El <b>Cliente</b> consiente expresamente que el Proveedor pueda
          subcontratar a las empresas que en su caso serán necesarias para
          prestar determinados servicios al Proveedor para la prestación de su
          servicio. En todos los casos, mediará contrato de Encargado de
          Tratamiento entre el Proveedor y la empresa subcontratada.
        </li>
      </ol>
      <p>
        Para más información acerca de nuestra política de privacidad, puede
        acudir a{' '}
        <a href="https://www.dieselplus.net/privacy-policy">
          https://www.dieselplus.net/privacy-policy
        </a>
      </p>
      <h3>11. NULIDAD</h3>
      <p>
        Si cualquier estipulación de este Contrato fuese declarada nula o sin
        efecto, en todo o en parte, por cualquier tribunal o autoridad
        competente, las restantes estipulaciones conservarán su validez, salvo
        que las partes discrecionalmente decidan la resolución de este contrato.
      </p>
      <h3>12. LEGISLACIÓN APLICABLE Y TRIBUNALES COMPETENTES</h3>
      <p>
        En lo no previsto en este contrato, así como en la interpretación y
        resolución de los conflictos que pudieran surgir entre las partes como
        consecuencia de este contrato, será de aplicación la legislación
        española.
      </p>
      <p>
        Si llegara a surgir algún conflicto o diferencia entre las partes en la
        interpretación y ejecución de este contrato, y no se resolviera de mutuo
        acuerdo, las partes se someterán ante la jurisdicción y competencia de
        los Juzgados y Tribunales de Lleida, renunciando expresamente a su fuero
        propio en los casos que las normas procesales lo permitan, de ser otro.
      </p>
      <h3>
        ANEXO 1. A): CONDICIONES PARTICULARES PARA EL SERVICIO DE COMUNICACIONES
        PARA CONTROLADORES COMPATIBLES
      </h3>
      <p>
        La contratación de la plataforma, que se regirá por los pactos, términos
        y condiciones recogidos en el contrato y las presentes condiciones
        particulares regulan el módulo que consiste en el Servicio de
        Comunicaciones y Acceso a DIESELPLUS para Controladores Compatibles, que
        permiten al CLIENTE usar y gestionar mediante la Plataforma un equipo
        controlador compatible con DIESELPLUS. Si así se informa en la oferta
        comercial, este servicio incluirá el coste de las comunicaciones según
        la capacidad de almacenaje del controlador compatible. La contratación
        de esta licencia da derecho al acceso de 3 cuentas de acceso a la
        plataforma. Estas cuentas se ampliarán con 3 más por cada licencia igual
        contratada.
      </p>
      <h4>Definiciones</h4>
      <p>
        Día laborable: Se definen como días laborables los incluidos entre lunes
        y viernes, excepto festivos y los correspondientes a periodos
        vacacionales. la comunidad autónoma de Cataluña en España es la
        referencia para los días festivos.
        <br />
        Día natural: Se definen como días naturales cualquier día de la semana
        sea o no festivo.
        <br />
        Módulo de comunicación: Elementos propiedad del Proveedor y activados
        por éste destinados exclusivamente a la comunicación con DIESELPLUS. En
        caso de comunicación a través de red móvil (2G, 3G, 4G, 5G), incluye la
        tarjeta SIM propiedad del Proveedor y activada por éste.
      </p>
      <h4>Entrega y disponibilidad del servicio</h4>
      <p>
        El Proveedor se compromete a realizar la entrega del servicio solicitado
        por el <b>Cliente</b> en un máximo de 10 días laborables a partir de la
        firma del contrato, siempre que se cumplan las condiciones técnicas en
        las presentes condiciones detalladas y el Cliente haya facilitado los
        datos correctos solicitados por el Proveedor a la activación del
        presente contrato.
      </p>
      <p>
        El Cliente no tendrá derecho a exigir la puesta en marcha del servicio
        si:
        <ul>
          <li>Hay un retraso se debe a causas ajenas al Proveedor</li>
          <li>
            El Cliente ha solicitado alguna modificación posterior al inicio del
            contrato
          </li>
          <li>
            Las instalaciones del Cliente no se encuentran debidamente
            acondicionadas
          </li>
          <li>
            El Proveedor o los instaladores de los equipos compatibles con
            DIESELPLUS no puedan acceder a las instalaciones en la fecha y hora
            acordadas por ambas partes o el Cliente no obtenga los permisos
            requeridos de la propiedad de las ubicaciones.
          </li>
        </ul>
      </p>
      <p>
        La disponibilidad garantizada por el <b>Proveedor</b> se circunscribe a
        que el Cliente cumpla con los requisitos de funcionamiento establecidos
        en las condiciones técnicas en las presentes condiciones que permiten el
        acceso a la Plataforma y al servicio de comunicaciones con el equipo de
        control compatible con DIESELPLUS. El Proveedor no podrá garantizar la
        disponibilidad del servicio cuando se produzcan incidencias ajenas a su
        responsabilidad.
      </p>
      <img src={imageSrc} />
      <p>
        Siendo:
        <ul>
          <li>
            DDS: Días en el intervalo de medida en el que el servicio no está
            totalmente caído en días laborables, sin considerar la duración de
            los cortes programados previamente comunicados al CLIENTE, así como
            los cortes debidos a una modificación del servicio explícitamente
            pedida por el CLIENTE o las caídas del servicio no responsabilidad
            del PROVEEDOR.
          </li>
          <li>
            DT: Intervalos de medida de la duración de disponibilidad del
            servicio en días laborables.
          </li>
        </ul>
        El PROVEEDOR garantiza una disponibilidad del 95 % anual, siendo el
        intervalo de medida DT el correspondiente a los días laborables, tal
        como se define en el apartado de definiciones. Si la disponibilidad
        anual estuviese por debajo de la indicada, el CLIENTE tendrá derecho a
        solicitar la aplicación de una compensación. Las compensaciones que se
        aplicarán serán: un 5 % de la cuota anual para una disponibilidad entre
        el 90 y 95 %; un 10 % de la cuota anual para una disponibilidad entre el
        80 y 90 % y un 15 % para una disponibilidad inferior al 80 %.
        <br />
        El máximo importe por compensación será del 15 % de la cuota anual.
        Dichas compensaciones se realizarán mediante un pago único en la
        siguiente factura bajo el concepto “Compensación: Indisponibilidad del
        Servicio”.
      </p>
      <h4>Especificaciones técnicas</h4>
      <p>
        En el ANEXO 2 se detallan las especificaciones técnicas para el acceso y
        uso de la plataforma e instalación de aplicaciones vinculadas.
      </p>
      <p>
        Requisitos para las comunicaciones:
        <ul>
          <li>
            El CLIENTE deberá disponer de un controlador compatible con
            DIESELPLUS y aprobado por éste.
          </li>
          <li>
            El citado equipo deberá estar instalado según las instrucciones del
            fabricante y las propias de DIESELPLUS que se detallan a
            continuación.
          </li>
          <li>
            El equipo de control deberá estar protegido eléctricamente con un
            SAI On-Line.
          </li>
          <li>
            En caso de comunicación mediante red móvil 2G, 3G o 4G, según el
            modelo, el equipo deberá estar instalado en una zona con cobertura
            de la red suficiente y verificada por el instalador en el momento de
            la instalación.
          </li>
          <li>
            En caso de comunicación mediante Ethernet, el CLIENTE será el
            encargado de poner a disposición un punto de acceso a internet
            dentro de su infraestructura de red. El administrador de red del
            CLIENTE reservará una dirección IP fija y pública para el
            dispositivo a la que redirigirá el acceso externo de los puertos TCP
            solicitados por el Proveedor para que el equipo sea accesible de
            forma remota.
          </li>
          <li>
            El equipo deberá estar permanentemente conectado a la alimentación
            eléctrica para que se pueda monitorizar el estado de las
            comunicaciones por parte DIESELPLUS.
          </li>
          <li>
            La electrónica del equipo de control destinada a comunicaciones sólo
            puede ser manipulada por personal autorizado por el Proveedor.
            Cualquier manipulación del equipo de comunicaciones por personal no
            autorizado implica la pérdida inmediata de la garantía del servicio
            aquí contratado, pudiendo el Proveedor facturar al CLIENTE por los
            daños causados y gastos originados para su reparación.
          </li>
          <li>
            En caso de comunicación mediante red móvil 2G, 3G o 4G, el equipo
            controlador debe disponer del módulo de comunicaciones, tal como se
            define en el apartado de definiciones. En caso de que este módulo
            sea propiedad del PROVEEDOR, este módulo estará preinstalado en el
            controlador y será activado una vez formalizado el contrato.
          </li>
        </ul>
      </p>
      <p>
        El CLIENTE que haya adquirido equipos relacionados con DIESELPLUS, que
        se encuentran fuera del alcance de este contrato, deberá cumplir, junto
        con las normas generales recogidas en el anexo y de las condiciones
        generales, las siguientes normas para poderlos utilizar conjuntamente
        con el Servicio de Comunicaciones y Acceso a DIESELPLUS para
        Controladores Compatibles:
        <ul>
          <li>
            El CLIENTE no podrá alterar ni manipular el módulo de comunicaciones
            ni la parte de los equipos donde se encuentra, así como no podrá
            conectar otros módulos de comunicaciones al equipo, ni utilizar el
            módulo de comunicaciones preinstalado a otros equipos, ni destinarlo
            a otros usos distintos a DIESELPLUS.
          </li>
          <li>
            El CLIENTE se compromete al acondicionamiento, con el tiempo
            suficiente y los requisitos mínimos, incluyendo entre otros el
            suministro eléctrico de la ubicación donde vayan a instalarse los
            equipos.
          </li>
          <li>
            El CLIENTE se compromete a facilitar el acceso del personal del
            Proveedor a la ubicación donde se encuentren los equipos, si es
            preciso, a los efectos de instalación, mantenimiento y retirada del
            módulo de comunicaciones, en su caso, de sus instalaciones por
            cancelación del servicio
          </li>
          <li>
            El CLIENTE estará sujeto a todas aquellas obligaciones derivadas de
            la ley aplicable y las contenidas en el presente contrato.
          </li>
          <li>Se establece que DIESELPLUS es apto para consumos propios.</li>
          <li>
            El proveedor o sus instaladores homologados gestionarán, instalarán,
            pondrán en funcionamiento y mantendrán los módulos de comunicación.
          </li>
          <li>
            El proveedor se hará cargo de la activación del servicio y los
            costes de comunicación siempre que el módulo de comunicación sea de
            su propiedad y no se haga un uso indebido o se sobrepasen los
            límites indicados en el apartado Usos indebidos del modulo de
            comunicaciones.
          </li>
          <li>
            El proveedor realizará todas sus intervenciones en días laborales
            salvo que, debido a una emergencia o causa de Fuerza mayor, se
            precise una intervención inmediata.
          </li>
        </ul>
      </p>
      <h4>Usos indebidos del módulo de comunicaciones</h4>
      <p>
        Se considera uso indebido del módulo de comunicaciones:
        <ul>
          <li>
            Se detecta un uso indebido o una manipulación del módulo de
            comunicaciones. Se facturará el exceso de consumo más los posibles
            daños causados.
          </li>
          <li>
            Se sobrepasan los límites de comunicación. En caso de comunicación
            mediante red móvil 2G, 3G o 4G Si el CLIENTE sobrepasa el límite de
            5MB/mes, el exceso de consumo le podrá ser facturado a éste.
          </li>
        </ul>
      </p>
      <p>
        En caso de detectarse un uso indebido de éste, se facturarán los excesos
        de consumo o los usos indebidos según los datos de pago proporcionados
        en la contratación. Estos deberán ser abonados en el momento que sean
        detectados, sin necesidad de esperar a la renovación del contrato.
      </p>
      <h3>
        ANEXO 1. B): CONDICIONES PARTICULARES PARA EL SERVICIO DE COMUNICACIONES
        PARA SONDAS DE NIVEL COMPATIBLES CON TANK MODEM PLUS
      </h3>
      <p>
        La contratación de la plataforma, que se regirá por los pactos, términos
        y condiciones recogidos en el contrato y las presentes condiciones
        particulares regulan el módulo que consiste en el Servicio de
        Comunicaciones y Acceso a DIESELPLUS para Sondas de nivel compatibles
        con TANK MODEM PLUS, que permiten al CLIENTE usar y gestionar mediante
        la Plataforma una sonda de nivel compatible con DIESELPLUS. Si así se
        informa en la oferta comercial, este servicio incluirá el coste de las
        comunicaciones. La contratación de esta licencia da derecho al acceso de
        3 cuentas de acceso a la plataforma. Estas cuentas se ampliarán con 3
        más por cada licencia igual contratada.
      </p>
      <h4>Definiciones</h4>
      <p>
        Día laborable: Se definen como días laborables los incluidos entre lunes
        y viernes, excepto festivos y los correspondientes a periodos
        vacacionales. la comunidad autónoma de Cataluña en España es la
        referencia para los días festivos.
        <br />
        Día natural: Se definen como días naturales cualquier día de la semana
        sea o no festivo.
        <br />
        Módulo de comunicación: Elementos propiedad del Proveedor y activados
        por éste destinados exclusivamente a la comunicación con DIESELPLUS. En
        caso de comunicación a través de red móvil (2G, 3G, 4G, 5G), incluye la
        tarjeta SIM propiedad del Proveedor y activada por éste.
      </p>
      <h4>Entrega y disponibilidad del servicio</h4>
      <p>
        El Proveedor se compromete a realizar la entrega del servicio solicitado
        por el Cliente en un máximo de 10 días laborables a partir de la firma
        del contrato, siempre que se cumplan las condiciones técnicas en las
        presentes condiciones detalladas y el Cliente haya facilitado los datos
        correctos solicitados por el Proveedor a la activación del presente
        contrato.
      </p>
      <p>
        El Cliente no tendrá derecho a exigir la puesta en marcha del servicio
        si:
        <ul>
          <li>Hay un retraso se debe a causas ajenas al Proveedor</li>
          <li>
            El Cliente ha solicitado alguna modificación posterior al inicio del
            contrato
          </li>
          <li>
            Las instalaciones del Cliente no se encuentran debidamente
            acondicionadas
          </li>
          <li>
            El Proveedor o los instaladores de los equipos compatibles con
            DIESELPLUS no puedan acceder a las instalaciones en la fecha y hora
            acordadas por ambas partes o el Cliente no obtenga los permisos
            requeridos de la propiedad de las ubicaciones.
          </li>
        </ul>
      </p>
      <p>
        La disponibilidad garantizada por el <b>Proveedor</b> se circunscribe a
        que el Cliente cumpla con los requisitos de funcionamiento establecidos
        en las condiciones técnicas en las presentes condiciones que permiten el
        acceso a la Plataforma y al servicio de comunicaciones con el equipo de
        control compatible con DIESELPLUS. El Proveedor no podrá garantizar la
        disponibilidad del servicio cuando se produzcan incidencias ajenas a su
        responsabilidad.
      </p>
      <img src={imageSrc} />
      <p>
        Siendo:
        <ul>
          <li>
            DDS: Días en el intervalo de medida en el que el servicio no está
            totalmente caído en días laborables, sin considerar la duración de
            los cortes programados previamente comunicados al CLIENTE, así como
            los cortes debidos a una modificación del servicio explícitamente
            pedida por el CLIENTE o las caídas del servicio no responsabilidad
            del PROVEEDOR.
          </li>
          <li>
            DT: Intervalos de medida de la duración de disponibilidad del
            servicio en días laborables.
          </li>
        </ul>
        El PROVEEDOR garantiza una disponibilidad del 95 % anual, siendo el
        intervalo de medida DT el correspondiente a los días laborables, tal
        como se define en el apartado de definiciones. Si la disponibilidad
        anual estuviese por debajo de la indicada, el CLIENTE tendrá derecho a
        solicitar la aplicación de una compensación. Las compensaciones que se
        aplicarán serán: un 5 % de la cuota anual para una disponibilidad entre
        el 90 y 95 %; un 10 % de la cuota anual para una disponibilidad entre el
        80 y 90 % y un 15 % para una disponibilidad inferior al 80 %.
        <br />
        El máximo importe por compensación será del 15 % de la cuota anual.
        Dichas compensaciones se realizarán mediante un pago único en la
        siguiente factura bajo el concepto “Compensación: Indisponibilidad del
        Servicio”.
      </p>
      <h4>Especificaciones técnicas</h4>
      <p>
        En el ANEXO 2 se detallan las especificaciones técnicas para el acceso y
        uso de la plataforma e instalación de aplicaciones vinculadas.
      </p>
      <p>
        Requisitos para las comunicaciones:
        <ul>
          <li>El cliente deberá disponer de un equipo TANK MODEM PLUS.</li>
          <li>
            El CLIENTE deberá disponer de una sonda de nivel compatible con TANK
            MODEM PLUS y aprobada por éste y los equipos o accesorios apropiados
            para la comunicación con DIESELPLUS.
          </li>
          <li>
            El citado equipo deberá estar instalado según las instrucciones del
            fabricante y las propias de DIESELPLUS que se detallan a
            continuación.
          </li>
          <li>
            El equipo de control deberá estar protegido eléctricamente con un
            SAI On-Line.
          </li>
          <li>
            En caso de comunicación mediante red móvil 2G, 3G o 4G, según el
            modelo, el equipo deberá estar instalado en una zona con cobertura
            de red suficiente y verificada por el instalador en el momento de la
            instalación.
          </li>
          <li>
            El equipo deberá estar permanentemente conectado a la alimentación
            eléctrica para que se pueda monitorizar el estado de las
            comunicaciones por parte DIESELPLUS.NET.
          </li>
          <li>
            La electrónica del equipo destinada a comunicaciones sólo puede ser
            manipulada por personal autorizado por el Proveedor. Cualquier
            manipulación del equipo de comunicaciones por personal no autorizado
            implica la pérdida inmediata de la garantía del servicio aquí
            contratado, pudiendo el Proveedor facturar al CLIENTE por los daños
            causados y gastos originados para su reparación.
          </li>
          <li>
            En caso de comunicación mediante red móvil 2G, 3G o 4G, el equipo
            controlador debe disponer del módulo de comunicaciones, tal como se
            define en el apartado de definiciones. En caso de que este módulo
            sea propiedad del PROVEEDOR, este módulo viene preinstalado en el
            controlador y será activado una vez formalizado el contrato.
          </li>
        </ul>
      </p>
      <p>
        El CLIENTE que haya adquirido equipos relacionados con DIESELPLUS, que
        se encuentran fuera del alcance de este contrato, deberá cumplir, junto
        con las normas generales recogidas en el anexo I de las condiciones
        generales, las siguientes normas para poderlos utilizar juntamente con
        el Servicio de Comunicaciones y Acceso a DIESELPLUS para SONDAS
        Compatibles con TANK MODEM PLUS:
        <ul>
          <li>
            El CLIENTE no podrá alterar ni manipular el módulo de comunicaciones
            ni la parte de los equipos donde se encuentra, así como no podrá
            conectar otros módulos de comunicaciones al equipo, ni utilizar el
            módulo de comunicaciones preinstalado a otros equipos, ni destinarlo
            a otros usos distintos a DIESELPLUS.
          </li>
          <li>
            El CLIENTE se compromete al acondicionamiento, con el tiempo
            suficiente y los requisitos mínimos.
          </li>
          <li>
            El CLIENTE se compromete a facilitar el acceso del personal del
            Proveedor a la ubicación donde se encuentren los equipos, si es
            preciso, a los efectos de instalación, mantenimiento y retirada del
            módulo de comunicaciones, en su caso, de sus instalaciones por
            cancelación del servicio.
          </li>
          <li>
            El CLIENTE estará sujeto a todas aquellas obligaciones derivadas de
            la ley aplicable y las contenidas en el presente contrato.
          </li>
          <li>
            El Proveedor o sus instaladores homologados gestionarán, instalarán,
            pondrán en funcionamiento y mantendrán los módulos de comunicación.
          </li>
          <li>
            El proveedor se hará cargo de la activación del servicio y los
            costes de comunicación siempre que el módulo de comunicación sea de
            su propiedad y no se haga un uso indebido o se sobrepasen los
            límites indicados en el apartado Usos indebidos del modulo de
            comunicaciones.
          </li>
          <li>
            El Proveedor realizará todas sus intervenciones en días laborales
            salvo que, debido a una emergencia o causa de Fuerza mayor, se
            precise una intervención inmediata.
          </li>
        </ul>
      </p>
      <h4>Usos indebidos del módulo de comunicaciones</h4>
      <p>
        Se considera uso indebido del módulo de comunicaciones:
        <ul>
          <li>
            Se detecta un uso indebido o una manipulación del módulo de
            comunicaciones. Se facturará el exceso de consumo más los posibles
            daños causados.
          </li>
          <li>
            Se sobrepasan los límites de comunicación. En caso de comunicación
            mediante red móvil 2G, 3G o 4G, Si el CLIENTE sobrepasa el límite de
            5MB/mes, el exceso de consumo le podrá ser facturado a éste.
          </li>
        </ul>
      </p>
      <p>
        En caso de detectarse un uso indebido de éste, se facturarán los excesos
        de consumo o los usos indebidos según los datos de pago proporcionados
        en la contratación. Estos deberán ser abonados en el momento que sean
        detectados, sin necesidad de esperar a la renovación del contrato.
      </p>
      <h4>Conservación de los datos</h4>
      <p>
        En el Servicio de comunicaciones y acceso DIESELPLUS para sondas
        compatibles con TANK MODEM PLUS, el periodo de conservación de la
        información relativa al histórico de las mediciones del nivel de los
        depósitos será de un año, mientras sea vigente el presente contrato.
      </p>
      <h3>
        ANEXO 1. C): CONDICIONES PARTICULARES PARA EL SERVICIO DE COMUNICACIONES
        PARA SONDAS DE NIVEL DOLPHIN
      </h3>
      <p>
        La contratación de la plataforma, que se regirá por los pactos, términos
        y condiciones recogidos en el contrato y las presentes condiciones
        particulares regulan el módulo que consiste en el Servicio de
        Comunicaciones y Acceso a DIESELPLUS para sondas de nivel DOLPHIN, que
        permiten al CLIENTE usar y gestionar mediante la Plataforma una sonda de
        nivel DOLPHIN. Si así se informa en la oferta comercial, este servicio
        incluirá el coste de las comunicaciones en función del modelo de sonda
        de nivel DOLPHIN. La contratación de esta licencia da derecho al acceso
        de 3 cuentas de acceso a la plataforma. Estas cuentas se ampliarán con 3
        más por cada licencia igual contratada.
      </p>
      <h4>Definiciones</h4>
      <p>
        Día laborable: Se definen como días laborables los incluidos entre lunes
        y viernes, excepto festivos y los correspondientes a periodos
        vacacionales. la comunidad autónoma de Cataluña en España es la
        referencia para los días festivos.
        <br />
        Día natural: Se definen como días naturales cualquier día de la semana
        sea o no festivo.
        <br />
        Módulo de comunicación: Elementos propiedad del Proveedor y activados
        por éste destinados exclusivamente a la comunicación con DIESELPLUS. En
        caso de comunicación a través de red móvil (2G, 3G, 4G, 5G), incluye la
        tarjeta SIM propiedad del Proveedor y activada por éste. En caso de
        comunicación a través de red SIGFOX incluye el módulo con la licencia
        SIGFOX asociada, también activada por el Proveedor.
      </p>
      <h4>Entrega y disponibilidad del servicio</h4>
      <p>
        El Proveedor se compromete a realizar la entrega del servicio solicitado
        por el Cliente en un máximo de 10 días laborables a partir de la firma
        del contrato, siempre que se cumplan las condiciones técnicas en las
        presentes condiciones detalladas y el Cliente haya facilitado los datos
        correctos solicitados por el Proveedor a la activación del presente
        contrato.
      </p>
      <p>
        El Cliente no tendrá derecho a exigir la puesta en marcha del servicio
        si:
        <ul>
          <li>Hay un retraso se debe a causas ajenas al Proveedor</li>
          <li>
            El Cliente ha solicitado alguna modificación posterior al inicio del
            contrato
          </li>
          <li>
            Las instalaciones del Cliente no se encuentran debidamente
            acondicionadas
          </li>
          <li>
            El Proveedor o los instaladores de los equipos compatibles con
            DIESELPLUS no puedan acceder a las instalaciones en la fecha y hora
            acordadas por ambas partes o el Cliente no obtenga los permisos
            requeridos de la propiedad de las ubicaciones.
          </li>
        </ul>
      </p>
      <p>
        La disponibilidad garantizada por el <b>Proveedor</b> se circunscribe a
        que el Cliente cumpla con los requisitos de funcionamiento establecidos
        en las condiciones técnicas en las presentes condiciones que permiten el
        acceso a la Plataforma y al servicio de comunicaciones con el equipo de
        control compatible con DIESELPLUS. El Proveedor no podrá garantizar la
        disponibilidad del servicio cuando se produzcan incidencias ajenas a su
        responsabilidad.
      </p>
      <img src={imageSrc} />
      <p>
        Siendo:
        <ul>
          <li>
            DDS: Días en el intervalo de medida en el que el servicio no está
            totalmente caído en días laborables, sin considerar la duración de
            los cortes programados previamente comunicados al CLIENTE, así como
            los cortes debidos a una modificación del servicio explícitamente
            pedida por el CLIENTE o las caídas del servicio no responsabilidad
            del PRESTADOR.
          </li>
          <li>
            DT: Intervalos de medida de la duración de disponibilidad del
            servicio en días laborables.
          </li>
        </ul>
        El PRESTADOR garantiza una disponibilidad del 95 % anual, siendo el
        intervalo de medida DT el correspondiente a los días laborables, tal
        como se define en el apartado de definiciones. Si la disponibilidad
        anual estuviese por debajo de la indicada, el CLIENTE tendrá derecho a
        solicitar la aplicación de una compensación. Las compensaciones que se
        aplicarán serán: un 5 % de la cuota anual para una disponibilidad entre
        el 90 y 95 %; un 10 % de la cuota anual para una disponibilidad entre el
        80 y 90 % y un 15 % para una disponibilidad inferior al 80 %.
        <br />
        El máximo importe por compensación será del 15 % de la cuota anual.
        Dichas compensaciones se realizarán mediante un pago único en la
        siguiente factura bajo el concepto “Compensación: Indisponibilidad del
        Servicio”.
      </p>
      <h4>Especificaciones técnicas</h4>
      <p>
        En el ANEXO 2 se detallan las especificaciones técnicas para el acceso y
        uso de la plataforma e instalación de las aplicaciones vinculadas.
      </p>
      <p>
        Requisitos para las comunicaciones:
        <ul>
          <li>El CLIENTE deberá disponer de una sonda de nivel DOLPHIN.</li>
          <li>
            El citado equipo deberá estar instalado según las instrucciones del
            fabricante y las propias de DIESELPLUS que se detallan a
            continuación.
          </li>
          <li>
            En caso de comunicación mediante red móvil 2G, 3G o 4G, o red
            SIGFOX, según el modelo, el equipo deberá estar instalado en una
            zona con cobertura de la red suficiente y verificada por el
            instalador en el momento de la instalación.
          </li>
          <li>
            El equipo deberá estar permanentemente conectado a la alimentación
            eléctrica para que se pueda monitorizar el estado de las
            comunicaciones por parte DIESELPLUS. El mantenimiento y sustitución
            de pilas o baterías es a cargo del CLIENTE.
          </li>
          <li>
            La electrónica del equipo de control destinada a comunicaciones sólo
            puede ser manipulada por personal autorizado por el Proveedor.
            Cualquier manipulación del equipo de comunicaciones por personal no
            autorizado implica la pérdida inmediata de la garantía del servicio
            aquí contratado, pudiendo el Proveedor facturar al CLIENTE por los
            daños causados y gastos originados para su reparación.
          </li>
          <li>
            En caso de comunicación mediante red móvil 2G, 3G o 4G, o red
            SIGFOX, el equipo controlador debe disponer del módulo de
            comunicaciones, tal como se define en el apartado de definiciones.
            En caso que este módulo sea propiedad del PROVEEDOR, este módulo
            viene preinstalado en el controlador y será activado una vez
            formalizado el contrato.
          </li>
        </ul>
      </p>
      <p>
        El CLIENTE que haya adquirido equipos relacionados con DIESELPLUS, que
        se encuentran fuera del alcance de este contrato, deberá cumplir, junto
        con las normas generales recogidas en el anexo I de las condiciones
        generales, las siguientes normas para poderlos utilizar juntamente con
        el Servicio de Comunicaciones y Acceso a DIESELPLUS para sondas de nivel
        DOLPHIN
        <ul>
          <li>
            El CLIENTE no podrá alterar ni manipular el módulo de comunicaciones
            ni la parte de los equipos donde se encuentra, así como no podrá
            conectar otros módulos de comunicaciones al equipo, ni utilizar el
            módulo de comunicaciones preinstalado a otros equipos, ni destinarlo
            a otros usos distintos a DIESELPLUS.NET.
          </li>
          <li>
            El CLIENTE se compromete al acondicionamiento, con el tiempo
            suficiente y los requisitos mínimos.
          </li>
          <li>
            El CLIENTE se compromete a facilitar el acceso del personal del
            Proveedor a la ubicación donde se encuentren los equipos, si es
            preciso, a los efectos de instalación, mantenimiento y retirada del
            módulo de comunicaciones, en su caso, de sus instalaciones por
            cancelación del servicio.
          </li>
          <li>
            El CLIENTE estará sujeto a todas aquellas obligaciones derivadas de
            la ley aplicable y las contenidas en el presente contrato.
          </li>
          <li>
            El Proveedor o sus instaladores homologados gestionarán, instalarán,
            pondrán en funcionamiento y mantendrán los módulos de comunicación.
          </li>
          <li>
            El proveedor se hará cargo de la activación del servicio y los
            costes de comunicación siempre que el módulo de comunicación sea de
            su propiedad y no se haga un uso indebido o se sobrepasen los
            límites indicados en el apartado Usos indebidos del modulo de
            comunicaciones.
          </li>
          <li>
            El Proveedor realizará todas sus intervenciones en días laborales
            salvo que, debido a una emergencia o causa de Fuerza mayor, se
            precise una intervención inmediata.
          </li>
        </ul>
      </p>
      <h4>Usos indebidos del módulo de comunicaciones</h4>
      <p>
        Se considera uso indebido del módulo de comunicaciones:
        <ul>
          <li>
            Se detecta un uso indebido o una manipulación del módulo de
            comunicaciones. Se facturará el exceso de consumo más los posibles
            daños causados.
          </li>
          <li>
            Se sobrepasan los límites de comunicación. En caso de comunicación
            mediante red móvil 2G, 3G o 4G, Si el CLIENTE sobrepasa el límite de
            5MB/mes, el exceso de consumo le podrá ser facturado a éste. En caso
            de comunicación mediante red SIGFOX el límite serán 25 mensajes de
            bajada por día.
          </li>
        </ul>
      </p>
      <p>
        En caso de detectarse un uso indebido de éste, se facturarán los excesos
        de consumo o los usos indebidos según los datos de pago proporcionados
        en la contratación. Estos deberán ser abonados en el momento que sean
        detectados, sin necesidad de esperar a la renovación del contrato.
      </p>
      <h4>Conservación de los datos</h4>
      <p>
        En el Servicio de comunicaciones y acceso DIESELPLUS para sondas
        DOLPHIN, el periodo de conservación de la información relativa al
        histórico de las mediciones del nivel de los depósitos será de un año,
        mientras sea vigente el presente contrato
      </p>
      <h3>
        ANEXO 1. D): CONDICIONES PARTICULARES PARA EL SERVICIO DE INTEGRACIÓN DE
        APLICACIONES DE GEOLOCALIZACIÓN DE TERCEROS COMPATIBLES
      </h3>
      <p>
        La contratación de la plataforma, que se regirá por los pactos, términos
        y condiciones recogidos en el contrato y las presentes condiciones
        particulares regulan el módulo que consiste en el Servicio de
        Integración y Acceso a DIESELPLUS para aplicaciones de geolocalización
        de terceros compatibles, que permiten al CLIENTE usar la Plataforma e
        integrar en ella información de las aplicaciones de geolocalización
        compatibles. Esta licencia no incluye las licencias, ni ningún coste
        directo o indirecto de la aplicación de geolocalización. La contratación
        de esta licencia da derecho al acceso de 3 cuentas de acceso a la
        plataforma. Estas cuentas se ampliarán con 3 más por cada licencia igual
        contratada.
      </p>
      <h4>
        Información que puede integrar en la plataforma de las aplicaciones de
        geolocalización de terceros compatibles
      </h4>
      <p>
        La información a integrar dependerá de su disponibilidad en la
        aplicación de geolocalización.
        <ul>
          <li>El odómetro en el momento del repostaje.</li>
        </ul>
      </p>
      <p>
        El <b>Proveedor</b> no puede garantizar la validez y disponibilidad de
        la información integrada, siendo esta información responsabilidad del
        Cliente, que se encuentra bajo licencia y/o acuerdo con el titular de la
        aplicación de geolocalización de terceros compatible. En ningún caso
        podrá el <b>Cliente</b> reclamar al <b>Proveedor</b> indemnización
        alguna por daños y perjuicios que puedan derivarse de la existencia de
        información inválida procedente de las aplicaciones de geolocalización
        externas del Cliente.
      </p>
      <p>
        El <b>Proveedor</b> queda eximido de la responsabilidad de informar al
        trabajador o usuario del cliente sobre el que se recoja la
        geolocalización. Esta responsabilidad recae sobre el <b>Cliente</b> que
        contrata el servicio.
      </p>
      <h4>Definiciones</h4>
      <p>
        Día laborable: Se definen como días laborables los incluidos entre lunes
        y viernes, excepto festivos y los correspondientes a periodos
        vacacionales. la comunidad autónoma de Cataluña en España es la
        referencia para los días festivos.
        <br />
        Día natural: Se definen como días naturales cualquier día de la semana
        sea o no festivo.
      </p>
      <h4>Entrega y disponibilidad del servicio</h4>
      <p>
        El Proveedor se compromete a realizar la entrega del servicio solicitado
        por el Cliente en un máximo de 10 días laborables a partir de la firma
        del contrato, siempre que se cumplan las condiciones técnicas en las
        presentes condiciones detalladas y el Cliente haya facilitado los datos
        correctos solicitados por el Proveedor a la activación del presente
        contrato.
      </p>
      <p>
        El Cliente no tendrá derecho a exigir la puesta en marcha del servicio
        si:
        <ul>
          <li>Hay un retraso se debe a causas ajenas al Proveedor</li>
          <li>
            El Cliente ha solicitado alguna modificación posterior al inicio del
            contrato
          </li>
        </ul>
      </p>
      <p>
        La disponibilidad garantizada por el <b>Proveedor</b> se circunscribe a
        que el Cliente cumpla con los requisitos de funcionamiento establecidos
        en las condiciones técnicas en las presentes condiciones que permiten el
        acceso a la Plataforma y al servicio de integración con aplicaciones de
        geolocalización compatibles. El Proveedor no podrá garantizar la
        disponibilidad del servicio cuando se produzcan incidencias ajenas a su
        responsabilidad.
      </p>
      <img src={imageSrc} />
      <p>
        Siendo:
        <ul>
          <li>
            DDS: Días en el intervalo de medida en el que el servicio no está
            totalmente caído en días laborables, sin considerar la duración de
            los cortes programados previamente comunicados al CLIENTE, así como
            los cortes debidos a una modificación del servicio explícitamente
            pedida por el CLIENTE o las caídas del servicio no responsabilidad
            del PROVEEDOR.
          </li>
          <li>
            DT: Intervalos de medida de la duración de disponibilidad del
            servicio en días laborables.
          </li>
        </ul>
        El PROVEEDOR garantiza una disponibilidad del 95 % anual, siendo el
        intervalo de medida DT el correspondiente a los días laborables, tal
        como se define en el apartado de definiciones. Si la disponibilidad
        anual estuviese por debajo de la indicada, el CLIENTE tendrá derecho a
        solicitar la aplicación de una compensación. Las compensaciones que se
        aplicarán serán: un 5 % de la cuota anual para una disponibilidad entre
        el 90 y 95 %; un 10 % de la cuota anual para una disponibilidad entre el
        80 y 90 % y un 15 % para una disponibilidad inferior al 80 %.
        <br />
        El máximo importe por compensación será del 15 % de la cuota anual.
        Dichas compensaciones se realizarán mediante un pago único en la
        siguiente factura bajo el concepto “Compensación: Indisponibilidad del
        Servicio”.
      </p>
      <h4>Especificaciones técnicas</h4>
      <p>
        En el Anexo 2 se detallan las especificaciones técnicas para el acceso y
        uso de la plataforma e instalación de las aplicaciones vinculadas.
      </p>
      <p>
        Requisitos para la integración:
        <ul>
          <li>
            El CLIENTE deberá disponer de las licencias necesarias y
            actualizadas para utilizar la aplicación de geolocalización
            compatible de terceros con DIESELPLUS aprobada por el Proveedor.
          </li>
          <li>
            La integración con DIESELPLUS se realiza a través de las API
            públicas de las aplicaciones de geolocalización compatibles. Para su
            acceso, el CLIENTE deberá disponer de las credenciales de una cuenta
            que permita el acceso a la API y disponga de los permisos de lectura
            necesarios sobre la información que se desea integrar.
          </li>
        </ul>
      </p>
      <h3>
        ANEXO 1. E): CONDICIONES PARTICULARES PARA EL SERVICIO DE ACCESO A LA
        API DIESELPLUS PARA LA INTEGRACIÓN CON APLICACIONES DE TERCEROS
      </h3>
      <p>
        La contratación de la plataforma, que se regirá por los pactos, términos
        y condiciones recogidos en el contrato y las presentes condiciones
        particulares regulan el módulo que consiste en el Servicio de Acceso a
        la API DIESELPLUS para la integración con aplicaciones de terceros, que
        permiten al CLIENTE acceder a la Plataforma a través de su API para
        automatizar la integración de aplicaciones de terceros, externas a la
        Plataforma.
      </p>
      <p>
        La contratación de esta licencia da derecho al acceso de 1 cuentas de
        acceso API a la plataforma. Estas cuentas se ampliarán con 1 más por
        cada licencia igual contratada. Esta cuenta es de uso exclusivo para el
        acceso a través de la API.
      </p>
      <h4>Definiciones</h4>
      <p>
        Día laborable: Se definen como días laborables los incluidos entre lunes
        y viernes, excepto festivos y los correspondientes a periodos
        vacacionales. la comunidad autónoma de Cataluña en España es la
        referencia para los días festivos.
        <br />
        Día natural: Se definen como días naturales cualquier día de la semana
        sea o no festivo.
      </p>
      <h4>Entrega y disponibilidad del servicio</h4>
      <p>
        El Proveedor se compromete a realizar la entrega del servicio solicitado
        por el Cliente en un máximo de 10 días laborables a partir de la firma
        del contrato, siempre que se cumplan las condiciones técnicas en las
        presentes condiciones detalladas y el Cliente haya facilitado los datos
        correctos solicitados por el Proveedor a la activación del presente
        contrato.
      </p>
      <p>
        El Cliente no tendrá derecho a exigir la puesta en marcha del servicio
        si:
        <ul>
          <li>Hay un retraso se debe a causas ajenas al Proveedor</li>
          <li>
            El Cliente ha solicitado alguna modificación posterior al inicio del
            contrato
          </li>
        </ul>
      </p>
      <p>
        La disponibilidad garantizada por el <b>Proveedor</b> se circunscribe a
        que el Cliente cumpla con los requisitos de funcionamiento establecidos
        en las condiciones técnicas en las presentes condiciones que permiten el
        acceso a la Plataforma y al servicio de comunicaciones con el equipo de
        control compatible con DIESELPLUS. El Proveedor no podrá garantizar la
        disponibilidad del servicio cuando se produzcan incidencias ajenas a su
        responsabilidad.
      </p>
      <img src={imageSrc} />
      <p>
        Siendo:
        <ul>
          <li>
            DDS: Días en el intervalo de medida en el que el servicio no está
            totalmente caído en días laborables, sin considerar la duración de
            los cortes programados previamente comunicados al CLIENTE, así como
            los cortes debidos a una modificación del servicio explícitamente
            pedida por el CLIENTE o las caídas del servicio no responsabilidad
            del PROVEEDOR.
          </li>
          <li>
            DT: Intervalos de medida de la duración de disponibilidad del
            servicio en días laborables.
          </li>
        </ul>
        El PROVEEDOR garantiza una disponibilidad del 95 % anual, siendo el
        intervalo de medida DT el correspondiente a los días laborables, tal
        como se define en el apartado de definiciones. Si la disponibilidad
        anual estuviese por debajo de la indicada, el CLIENTE tendrá derecho a
        solicitar la aplicación de una compensación. Las compensaciones que se
        aplicarán serán: un 5 % de la cuota anual para una disponibilidad entre
        el 90 y 95 %; un 10 % de la cuota anual para una disponibilidad entre el
        80 y 90 % y un 15 % para una disponibilidad inferior al 80 %.
        <br />
        El máximo importe por compensación será del 15 % de la cuota anual.
        Dichas compensaciones se realizarán mediante un pago único en la
        siguiente factura bajo el concepto “Compensación: Indisponibilidad del
        Servicio”.
      </p>
      <h4>Especificaciones técnicas</h4>
      <p>
        En el ANEXO 2 se detallan las especificaciones técnicas para el acceso y
        uso de la plataforma e instalación de las aplicaciones vinculadas.
      </p>
      <p>
        Requisitos para las comunicaciones:
        <ul>
          <li>
            El CLIENTE será el encargado y responsable del desarrollo y
            mantenimiento de cualquiera de las aplicaciones externas que accedan
            a la plataforma a través de la API DIESELPLUS.
          </li>
          <li>
            La estructura de las llamadas a la API de DIESELPLUS, deberán ser
            desarrolladas siguiendo las instrucciones presentes en la
            documentación y guías proporcionadas por el PROVEEDOR, y únicamente
            incluirán aquellas URL que estén definidas en dicha documentación.
          </li>
          <li>
            Si no se especifica en la oferta comercial, el nombre de llamadas a
            la API está limitada a 60 llamadas por minuto y cuenta.
          </li>
          El <b>Proveedor</b> se reserva el derecho a modificar la definición y
          estructura de las llamadas a la API, siempre en desarrollo y beneficio
          de la misma. En estos casos el Proveedor informará al <b>Cliente</b> a
          través de la Plataforma o correo electrónico y será el Cliente el
          encargado de realizar las actualizaciones necesarias en aquellas
          aplicaciones externas con acceso a la API Dieselplus para que se
          adapten a las nuevas modificaciones. En ningún caso podrá el Cliente
          reclamar del Proveedor indemnización alguna por daños y perjuicios que
          puedan derivarse de un mal funcionamiento de las aplicaciones externas
          como consecuencia de que el Cliente no haya adaptado las referidas
          modificaciones.
        </ul>
      </p>
      <h3>
        ANEXO 2: ESPECIFICACIONES TÉCNICAS PARA EL USO E DE LA PLATAFORMA E
        INSTALACIÓN DE APLICACIONES
      </h3>
      <h4>Requisitos para el acceso a la plataforma DIESELPLUS</h4>
      <ul>
        <li>
          El CLIENTE deberá disponer de un equipo con conexión a Internet libre
          de virus y malware.
        </li>
        <li>
          El equipo que utilice el CLIENTE para conectarse al portal DIESELPLUS
          debe disponer de un navegador compatible con las especificaciones que
          se indiquen en cada momento en el portal. Estas especificaciones
          pueden variar con el tiempo debido a los cambios introducidos por los
          distintos fabricantes de navegadores.
        </li>
        <li>
          El CLIENTE deberá disponer de las licencias necesarias para utilizar
          el software instalado en el equipo que use para acceder a la
          plataforma DIESELPLUS a través del portal{' '}
          <a target="_blank" href="http://www.dieselplus.net">
            www.dieselplus.net
          </a>{' '}
          o otras URL facilitadas por el PROVEEDOR.
        </li>
      </ul>
      <p>
        Las especificaciones correspondientes y necesarias para cada uno de los
        módulos de servicio de DISELPLUS, estarán detalladas en las condiciones
        particulares presentes en el anexo I.
      </p>
      <h4>Requisitos para la instalación y uso de aplicaciones DIESELPLUS</h4>
      <p>
        La plataforma DIESELPLUS puede solicitar la descarga e instalación de
        aplicaciones para la comunicación de la plataforma con los periféricos
        de su ordenador.
      </p>
      <p>
        El uso de estas aplicaciones está sujeto a las licencias adquiridas y se
        regulan en las presentes cláusulas y en aquellas que pudiera especificar
        cada aplicación en particular. Su instalación significa el
        consentimiento y la aceptación de los términos y condiciones detallados
        en este acuerdo.
      </p>
      <ul>
        <li>
          Para la instalación y uso el CLIENTE deberá disponer de un equipo con
          el sistema operativo Windows, en una versión mantenida y actualizada.
        </li>
        <li>
          El equipo que utilice el CLIENTE deberá disponer de conexión a
          Internet libre de virus y malware.
        </li>
        <li>
          Es responsabilidad del CLIENTE mantener actualizada la aplicación
          DIESELPLUS.
        </li>
      </ul>
      <h3>ANEXO 3: SISTEMA DE SEGURIDAD DEL PROVEEDOR</h3>
      <p>
        DIESELPLUS aloja los datos del servicio principalmente en centros de
        datos de AZURE que posen la certificación de conformidad con los
        estándares más exigentes, Entre ellos el cumplimento según las normas
        ISO/IEC 27001 orientadas al establecimiento y buenas prácticas en
        relación con la implantación, mantenimiento y gestión del Sistema de
        gestión de seguridad de la información. Más información acerca la
        seguridad en los centros de datos físicos e infraestructura{' '}
        <a
          target="_blank"
          href="https://azure.microsoft.com/es-es/explore/trusted-cloud"
        >
          https://azure.microsoft.com/es-es/explore/trusted-cloud
        </a>
      </p>
      <p>
        Para los datos que se alojan en los servidores locales del Proveedor, la
        infraestructura del Proveedor cuenta con altos niveles de seguridad
      </p>
      <h4>NIVEL FÍSICO</h4>
      <ul>
        <li>
          Instalaciones:
          <ul>
            <li>Sala acondicionada para Servidores</li>
            <li>Unidades de SAI</li>
            <li>Grupos electrógenos por combustible</li>
            <li>Sistemas de aire acondicionado.</li>
          </ul>
        </li>
        <li>Control de accesos</li>
        <li>Sistema de detección y extinción de incendios</li>
        <li>Vigilancia 24 horas</li>
        <li>Monitorización de los sistemas 24/7</li>
        <li>
          Los datacenters están dotados de las mejores condiciones para ubicar
          sistemas conectados a Internet y para soluciones de Hosting
        </li>
        <li>
          El datacenter central está gestionado por personal de ingeniería en
          telecomunicaciones y en Sistemas que aseguran el correcto
          funcionamiento de todos los Servicios
        </li>
        <li>
          Adicionalmente, y dada nuestra estricta política de calidad, todos los
          sistemas están supervisados por tareas de mantenimiento diarias y
          semanales que permiten tener los sistemas siempre disponibles.
        </li>
      </ul>
      <h4>NIVEL LÓGICO</h4>
      <p>
        En la infraestructura local del Proveedor cuenta con una arquitectura de
        protección permimetral, en la que se utilizan erutadores (routes) y
        cortafuegos (firewalls) que permiten altos niveles de seguridad y
        protección frente a los ataques externos que puedan producirse.
      </p>
      <h4>COPIAS DE SEGURIDAD</h4>
      <p>
        Se ejecutan, cada pocos minutos, procedimientos de copias de respaldo o
        backup de los datos de la Platafoma. Las copias de seguridad se realizan
        de forma cifrada con las siguientes características:
        <ul>
          <li>
            Copias de seguridad redundadas en dos Centro de Datos diferentes,
            ubicados en diferentes países.
          </li>
          <li>
            Copias con sistema cifrado en los servidores de backup destino.
          </li>
          <li>Copias con sistema de duplicación.</li>
        </ul>
      </p>
      <p>
        Estas medidas cumplen estrictamente con la normativa de protección de
        datos vigente.
      </p>
      <h4>MECANISMO PARA LA GESTIÓN DE INCIDENCIAS</h4>
      <p>
        Para la previsión y agilizar la resolución de incidencias, el Proveedor
        dispone de:
        <ul>
          <li>Medidas de prevención contra ataques contra el sistema</li>
          <li>Auditorías y realización de chequeos periódicos</li>
          <li>Planes de contingencia y recuperación</li>
          <li>Jerarquización incidencias</li>
        </ul>
      </p>
      <p>
        A todas las incidencias registradas se les asigna un rango de gravedad
        clasificado en 3 tipos: "Fallo Crítico", “Fallo no crítico” y
        "Reclamación" en función de la gravedad de dicha incidencia.{' '}
        <b>
          Estos rangos se refieren al tiempo de respuesta, no al de resolución
          de la incidencia.
        </b>{' '}
        Esta prioridad podrá verse modificada una vez haya sido evaluada por
        nuestro equipo técnico. Quedan excluidas las incidencias o peticiones de
        las pruebas gratuitas o de demostración de nuestros módulos.
        <ul>
          <li>
            <b>Fallo crítico</b>: Se considera fallo crítico aquel que provoque
            que el Cliente no pueda acceder a sus datos en DIESELPLUS mediante
            el portal proporcionado por el Proveedor y, por tanto, requiere una
            investigación por parte del Proveedor.
          </li>
          <li>
            <b>Fallo no crítico</b>: Se considera fallo no crítico aquel que
            permita utilizar el servicio del portal DIESELPLUS pero no refleje o
            no reciba los cambios realizados por cualquiera de los equipos con
            comunicación a DIESELPLUS durante los últimos 3 días naturales y,
            por lo tanto, requiere una investigación por parte del Proveedor.
          </li>
          <li>
            <b>Reclamación</b>: Toda petición del Cliente que no sea ni un fallo
            crítico, ni un fallo no crítico.
          </li>
        </ul>
      </p>
      <p>
        El cliente tendrá que estar localizable (email, móvil) y estar al tanto
        de las notificaciones para poder tener una comunicación ágil. Los
        horarios de atención a las incidencias, estará sujeto a horario laboral
        y calendario de la comunidad autónoma de Cataluña en España.
      </p>
      <h3>ANEXO 4: CONTRATO DE ENCARGADO DEL TRATAMIENTO</h3>
      <h4>REUNIDOS</h4>
      <p>
        De una parte, el <b>Cliente</b> identificado en el inicio del contrato,
        en adelante el <b>RESPONSABLE</b>
      </p>
      <p>
        Y, de otra parte, el <b>Proveedor</b>, en adelante el <b>ENCARGADO</b>,
      </p>
      <h4>EXPONEN</h4>
      <ol>
        <li>
          Que ambas partes se reconocen capacidad legal necesaria para contratar
          y suscribir el presente contrato, de conformidad con el artículo 28
          del Reglamento (UE) 2016/679, de 27 de abril de 2016, del Parlamento
          Europeo y del Consejo relativo a la Protección de las personas físicas
          en lo que respecta al tratamiento de sus datos personales y a la libre
          circulación de los mismos.
        </li>
        <li>
          Que el RESPONSABLE ha contratado los servicios del ENCARGADO que se
          detallaran a continuación.
        </li>
        <li>
          Que la prestación de los servicios se realizará por conexión remota.
          Para la realización del servicio, el ENCARGADO DEL TRATAMIENTO, si
          fuera necesario, podrá incorporar datos del responsable del
          tratamiento en sus sistemas.
        </li>
        <li>
          Ambas partes convienen en aceptar el presente Contrato de acuerdo con
          las siguientes:
        </li>
      </ol>
      <h4>CLÁUSULAS</h4>
      <ol>
        <li>
          <h4>Objeto del encargo del tratamiento</h4>
          Mediante las presentes cláusulas se habilita al Proveedor, encargado
          del tratamiento, para tratar por cuenta del Responsable del
          tratamiento, los datos de carácter personal necesarios para prestar
          el/los servicio/s detallado en la estipulación 3.A.
          <br />
          Las operaciones de tratamiento autorizadas serán las estrictamente
          necesarias para alcanzar la finalidad del encargo incluyendo, si se
          precisa, la recogida, registro, estructuración, modificación,
          conservación, extracción, consulta, comunicación por transmisión,
          difusión, interconexión, cotejo, limitación, supresión y destrucción
          de datos.
        </li>
        <li>
          <h4>Identificación de la información afectada</h4>
          Para la ejecución de las prestaciones derivadas del cumplimiento del
          objeto de este encargo, el responsable del tratamiento puede poner a
          disposición del encargado del tratamiento la siguiente información:
          Ver <b>ANEXO 4.1: CATEGORÍAS DE DATOS</b>.
        </li>
        <li>
          <h4>Duración</h4>
          Este contrato tiene la misma duración que la establecida en el
          contrato principal.
          <br />
          Una vez finalice el presente contrato, el encargado del tratamiento
          debe devolver al responsable o transmitir a otro encargado que designe
          el responsable los datos personales, y suprimir cualquier copia que
          esté en su poder. No obstante, podrá mantener bloqueados los datos
          para atender posibles responsabilidades administrativas o
          jurisdiccionales.
        </li>
        <li>
          <h4>Obligaciones del encargado del tratamiento</h4>
          El encargado del tratamiento y todo su personal se obliga a:
          <ol>
            <li>
              Utilizar los datos personales objeto de tratamiento, o los que
              recoja para su inclusión, sólo para la finalidad objeto de este
              encargo. En ningún caso podrá utilizar los datos para fines
              propios.
            </li>
            <li>
              Tratar los datos de acuerdo con las instrucciones del responsable
              del tratamiento. Si el encargado del tratamiento considera que
              alguna de las instrucciones infringe el RGPD o cualquier otra
              disposición en materia de protección de datos de la Unión o de los
              Estados miembros, el encargado informará inmediatamente al
              responsable.
            </li>
            <li>
              Llevar, por escrito, un registro de todas las categorías de
              actividades de tratamiento efectuadas por cuenta del responsable.
            </li>
            <li>
              <p>
                No comunicar los datos a terceras personas, salvo que cuente con
                la autorización expresa del responsable del tratamiento, en los
                supuestos legalmente admisibles.
              </p>
              <p>
                El encargado puede comunicar los datos a otros encargados del
                tratamiento del mismo responsable, de acuerdo con las
                instrucciones del responsable. En este caso, el responsable
                identificará, de forma previa y por escrito, la entidad a la que
                se deben comunicar los datos, los datos a comunicar y las
                medidas de seguridad a aplicar para proceder a la comunicación.
              </p>
              <p>
                Si el encargado debe transferir datos personales a un tercer
                país o a una organización internacional, en virtud del Derecho
                de la Unión o de los Estados miembros que le sea aplicable,
                informará al responsable de esa exigencia legal de manera
                previa, salvo que tal Derecho lo prohíba por razones importantes
                de interés público.
              </p>
            </li>
            <li>
              <p>
                Se autoriza al encargado a subcontratar con la empresa Microsoft
                Ireland Operations Limited los servicios de hosting de Microsoft
                Azure (Datacenter en Europa Norte), con domicilio en One
                Microsoft Place, South County Business Park, Leopardstown,
                Dublin 18, Irlanda y contacto en +353 1 295 3826 o mediante el
                sitio web{' '}
                <a href="https://www.microsoft.com">
                  https://www.microsoft.com
                </a>
              </p>
              <p>
                Microsoft Ireland Operations Limited podría subcontratar parte
                de estas tareas a Microsoft Corporation, radicada en EEUU. Para
                regular la transferencia internacional de datos personales entre
                Microsoft Ireland Operations Limited y Microsoft Corporation se
                han foralizado las Cláusulas Contractuales Tipo aprobadas por la
                Comisión Europea.
              </p>
              <p>
                El subcontratista tiene también la condición de encargado del
                tratamiento y está obligado a cumplir las obligaciones
                establecidas en este documento para el encargado del tratamiento
                y las instrucciones que dicte el responsable. Corresponde al
                encargado inicial regular la nueva relación, de forma que el
                nuevo encargado quede sujeto a las mismas condiciones
                (instrucciones, obligaciones, medidas de seguridad…) y con los
                mismos requisitos formales que él, en lo referente al
                tratamiento de los datos personales y a la garantía de los
                derechos de las personas afectadas. En el caso de incumplimiento
                por parte del subencargado, el encargado inicial seguirá siendo
                plenamente responsable ante el responsable en lo referente al
                cumplimiento de las obligaciones.
              </p>
            </li>
            <li>
              Mantener el deber de secreto respecto a los datos de carácter
              personal a los que haya tenido acceso en virtud del presente
              encargo, incluso después de que finalice su objeto.
            </li>
            <li>
              Garantizar que las personas autorizadas para tratar datos
              personales se comprometan, de forma expresa y por escrito, a
              respetar la confidencialidad y a cumplir las medidas de seguridad
              correspondientes, de las que hay que informarles convenientemente.
            </li>
            <li>
              Mantener a disposición del responsable la documentación
              acreditativa del cumplimiento de la obligación establecida en el
              apartado anterior.
            </li>
            <li>
              Garantizar la formación necesaria en materia de protección de
              datos personales de las personas autorizadas para tratar datos
              personales.
            </li>
            <li>
              Cuando las personas afectadas ejerzan los derechos de acceso,
              rectificación, supresión y oposición, limitación del tratamiento,
              portabilidad de datos y a no ser objeto de decisiones
              individualizadas automatizadas ante el encargado de tratamiento,
              éste debe comunicarlo por correo electrónico a la dirección que
              indique el responsable de tratamiento. La comunicación debe
              hacerse de forma inmediata y en ningún caso más allá del día
              laborable siguiente al de la recepción de la solicitud,
              juntamente, en su caso, con otras informaciones que puedan ser
              relevantes para resolver la solicitud.
            </li>
            <li>
              En el caso de que el Encargado del Tratamiento se encargue de
              recoger directamente los datos de los interesados en cumplimiento
              de los servicios, éste deberá cumplir con las obligaciones
              derivadas del derecho de información de los interesados en el
              momento de la recogida de estos datos.
            </li>
            <li>
              <p>
                El encargado del tratamiento notificará al responsable del
                tratamiento, sin dilación indebida, y en cualquier caso antes
                del plazo máximo de 48h, y a través de correo electrónico
                correspondiente, las violaciones de la seguridad de los datos
                personales a su cargo de las que tenga conocimiento, juntamente
                con toda la información relevante para la documentación y
                comunicación de la incidencia.
              </p>
              <p>
                No será necesaria la notificación cuando sea improbable que
                dicha violación de la seguridad constituya un riesgo para los
                derechos y las libertades de las personas físicas. Si no es
                posible facilitar la información simultáneamente, y en la medida
                en que no lo sea, la información se facilitará de manera gradual
                sin dilación indebida.
              </p>
              <p>
                Corresponde al encargado del tratamiento, a petición del
                responsable de tratamiento, comunicar las violaciones de la
                seguridad de los datos a la Autoridad de Protección de Datos y
                en caso de que corresponda, a los interesados. Si no es posible
                facilitar la información simultáneamente, y en la medida en que
                no lo sea, la información se facilitará de manera gradual sin
                dilación indebida.
              </p>
            </li>
            <li>
              Dar apoyo al responsable del tratamiento en la realización de las
              evaluaciones de impacto relativas a la protección de datos, cuando
              proceda.
            </li>
            <li>
              Dar apoyo al responsable del tratamiento en la realización de las
              consultas previas a la autoridad de control, cuando proceda.
            </li>
            <li>
              Poner a disposición del responsable toda la información necesaria
              para demostrar el cumplimiento de sus obligaciones, así como para
              la realización de las auditorías o las inspecciones que realicen
              el responsable u otro auditor autorizado por él. Con el propósito
              de realizar las referidas auditorías, previo aviso mínimo de cinco
              (5) días naturales por parte del Responsable del Tratamiento, el
              Encargado facilitará al personal del Responsable, o al personal
              externo designado por éste, acceso libre a:
              <ul>
                <li>
                  Almacenes o instalaciones del Encargado donde preste servicios
                </li>
                <li>
                  Documentación relativa a los servicios, independientemente del
                  formato o soporte en que se encuentre. Esta documentación
                  deberá estar actualizada y ser cierta.
                </li>
                <li>
                  Personas que estén involucradas de cualquier forma en la
                  prestación de los servicios.
                </li>
              </ul>
              El resultado de estas auditorías dará lugar a la aplicación por
              parte del Encargado de las acciones correctoras para solucionar
              las disconformidades identificadas, así como la ejecución de
              planes de mejora que procedan.
            </li>
            <li>
              Implantar las medidas de seguridad técnicas y organizativas
              necesarias para garantizar la seguridad de los datos tratados, tal
              y como se detallan en el anexo 4.2. Medidas de seguridad. En todo
              caso, deberá implantar mecanismos para:
              <ol>
                <li>
                  Garantizar la confidencialidad, integridad, disponibilidad y
                  resiliencia permanentes de los sistemas y servicios de
                  tratamiento.
                </li>
                <li>
                  Restaurar la disponibilidad y el acceso a los datos personales
                  de forma rápida, en caso de incidente físico o técnico.
                </li>
                <li>
                  Verificar, evaluar y valorar, de forma regular, la eficacia de
                  las medidas técnicas y organizativas implantadas para
                  garantizar la seguridad del tratamiento.
                </li>
                <li>Seudonimizar y cifrar los datos personales, en su caso.</li>
              </ol>
            </li>
            <li>
              Designar un delegado de protección de datos y comunicar su
              identidad y datos de contacto al responsable, en caso de que
              proceda.
            </li>
            <li>
              En el caso de que finalice la prestación del servicio, respecto a
              los datos, el encargado deberá destruir los datos, una vez
              cumplida la prestación. El encargado puede conservar una copia,
              con los datos debidamente bloqueados, mientras puedan derivarse
              responsabilidades de la ejecución de la prestación.
            </li>
          </ol>
        </li>
        <li>
          <h4>Obligaciones del responsable del tratamiento</h4>
          Corresponde al responsable del tratamiento:
          <ol>
            <li>
              Entregar al encargado los datos a los que se refiere la cláusula 2
              de este documento.
            </li>
            <li>
              Realizar una evaluación del impacto en la protección de datos
              personales de las operaciones de tratamiento a realizar por el
              encargado, en caso de que proceda.
            </li>
            <li>Realizar las consultas previas que corresponda.</li>
            <li>
              Velar, de forma previa y durante todo el tratamiento, por el
              cumplimiento del RGPD por parte del encargado.
            </li>
            <li>
              Supervisar el tratamiento, incluida la realización de inspecciones
              y auditorías.
            </li>
          </ol>
        </li>
        <li>
          <h4>Responsabilidades y garantías</h4>
          <p>
            Si el Encargado del tratamiento o cualquiera de sus subcontratistas
            infringe el presente Contrato o la normativa, será considerado
            Responsable del Tratamiento y asumirá las responsabilidades directas
            e indirectas que pudieran derivarse para el Responsable debido al
            incumplimiento por parte del Encargado.
          </p>
          <p>
            Asimismo, ambas partes convienen que el incumplimiento de estas
            obligaciones serán causa de resolución de este contrato como el de
            prestación de servicios primario; de esta forma, el incumplimiento
            por parte del Encargado, las personas a su cargo o quienes
            intervengan en la prestación del servicio en nombre o a instancia
            del encargado, facultará al Responsable del Tratamiento para
            resolver el mismo y dará lugar a la correspondiente indemnización
            por daños y perjuicios por incumplimiento de las obligaciones
            contractuales.
          </p>
        </li>
        <li>
          <h4>Datos de los intervinientes</h4>
          <p>
            Los datos personales incluidos en este Contrato y aquellos otros
            intercambiados entre las Partes para posibilitar la prestación de
            los Servicios serán tratadas por la otra parte con la finalidad de
            permitir el desarrollo, cumplimiento y control de la relación de
            prestación de servicios concertada, siendo la base del tratamiento
            el cumplimiento y la ejecución del Contrato, conservando los datos
            durante todo el tiempo en que éste subsista y aún después, hasta que
            prescriban las eventuales responsabilidades derivadas de él. Las
            Partes se comprometen a trasladar a los titulares de los datos
            facilitados esta información, así como a indicarles que podrán
            dirigirse por escrito a los respectivos domicilios reseñados en el
            encabezamiento de este Contrato para ejercer cualquiera de los
            derechos de protección de datos que les amparan.
          </p>
        </li>
        <li>
          <h4>Legislación aplicable</h4>
          <p>
            Este Contrato se regirá de acuerdo a la normativa española y europea
            sobre protección de datos, así como las resoluciones y directrices
            de la Agencia Española de Protección de Datos y otros organismos
            competentes en la materia.
          </p>
          <p>
            Ambas partes renuncian expresamente al fuero que por ley pudiera
            corresponderles, sometiéndose a la jurisdicción de los Juzgados y
            Tribunales de Lleida, para la resolución de las controversias que
            surjan con motivo de la interpretación o ejecución de este contrato.
          </p>
          <p>
            Y para que así conste, en prueba de conformidad y aceptación del
            contenido de este escrito, ambas partes lo firman por duplicado y a
            un solo efecto en la fecha y lugar indicados en el encabezamiento.
          </p>
        </li>
      </ol>
      <h4>ANEXO 4.1: CATEGORÍAS DE DATOS</h4>
      <p>
        Para la ejecución de las prestaciones derivadas del cumplimiento del
        objeto de este encargo, el responsable del tratamiento pone a
        disposición del encargado del tratamiento la siguiente información:
      </p>
      <h4>Datos básicos</h4>
      <ul>
        <li>Nombre y apellidos</li>
        <li>Dirección</li>
        <li>DNI</li>
        <li>Imagen</li>
        <li>Correo electrónico</li>
        <li>Teléfono</li>
      </ul>
      <h4>ANEXO 4.2: MEDIDAS DE SEGURIDAD</h4>
      <h4>INFORMACIÓN DE INTERÉS GENERAL</h4>
      <p>
        El artículo 5.1.f del Reglamento General de Protección de Datos (RGPD)
        determina la necesidad de establecer garantías de seguridad adecuadas
        contra el tratamiento no autorizado o ilícito, contra la pérdida, la
        destrucción o el daño accidental de los datos personales. Esto implica
        el establecimiento de medidas técnicas y organizativas encaminadas a
        asegurar la integridad y confidencialidad de los datos personales y la
        posibilidad (artículo 5.2) de demostrar que estas medidas se han llevado
        a la práctica (responsabilidad proactiva).
      </p>
      <p>
        Dando cumplimiento a esta normativa vigente en protección de datos
        personales, detallamos algunas de las medidas implantadas. Nuestras
        medidas son exigidas a todos nuestros proveedores. Todo el personal con
        acceso a datos personales en nuestra entidad, sea propio o sea de un
        tercero, debe seguir las medidas de seguridad.
      </p>
      <ol>
        <li>
          <h4>MEDIDAS GENERALES</h4>
          <ul>
            <li>Documento de política de protección de datos personales</li>
            <li>
              Funciones y obligaciones para los usuarios y normas de uso de los
              recursos TIC
            </li>
            <li>Firmar con empleados el acuerdo de confidencialidad</li>
            <li>Formación a los usuarios en materia de protección de datos</li>
            <li>
              Inventario de recursos que pueden contener información (listado de
              los activos de información - documentos, softwares, personal,
              instalaciones, servicios etc. que tengan valor para la
              organización y deban ser protegidos de potenciales riesgos)
            </li>
            <li>
              Protocolo ante ejercicio de derechos de los afectados establecidos
              y puesto en conocimiento de los empleados
            </li>
          </ul>
        </li>
        <li>
          <h4>
            MEDIDAS PARA GARANTIZAR LA CONFIDENCIALIDAD, INTEGRIDAD,
            DISPONIBILIDAD Y RESILIENCIA DE LOS SISTEMAS Y SERVICIOS DE
            TRATAMIENTO
          </h4>
          <ul>
            <li>Sistema de identificación y autenticación de usuarios</li>
            <li>
              Garantía confidencialidad de las contraseñas, imposibilitando
              acceso de terceros
            </li>
            <li>Perfiles de Acceso</li>
            <li>Límites de intentos reiterados de acceso no autorizado</li>
            <li>Control de acceso a los sistemas de información</li>
            <li>
              Registro de accesos al sistema de información (logs de acceso)
            </li>
            <li>Sistemas de firma electrónica (autenticación)</li>
            <li>Firewall</li>
            <li>
              Sistema de análisis y gestión de vulnerabilidades y amenazas
            </li>
            <li>Antivirus</li>
            <li>Copia de seguridad del servidor</li>
            <li>Protocolo HTTPS en la página web (Certificado SSL)</li>
            <li>
              Control de acceso físico al CPD (Centro de Procesamiento de Datos)
              / Sala del servidor
            </li>
            <li>Política de gestión de los activos</li>
            <li>Destrucción segura y confidencial de activos</li>
            <li>
              Medidas preventivas de diversas amenazas (incendios, robos,
              humedades, inundaciones etc)
            </li>
            <li>
              Métodos de organización de los soportes físicos (Clasificación y
              ordenación)
            </li>
            <li>
              Control de acceso a la documentación (llaves, identificación
              biométrica, tarjetas inteligentes, código de accesos, etc)
            </li>
            <li>
              Registro de acceso a la documentación • Política de “escritorios
              limpios”
            </li>
            <li>Política de trabajo des del servidor</li>
            <li>Traslado y envío seguro de la documentación en papel</li>
            <li>Normas de uso de las impresoras compartidas</li>
            <li>
              Destrucción segura y confidencial de la documentación en papel y
              soportes informáticos
            </li>
            <li>
              Máquinas destructoras de documentos o contratación de empresa
              externa que certifique la destrucción
            </li>
            <li>Evitar pantallas desatendidas</li>
            <li>Evitar dejar documentos en zonas de acceso público</li>
            <li>Bloqueo de pantalla protegido con contraseña</li>
          </ul>
        </li>
        <li>
          <h4>
            MEDIDAS PARA RESTAURAR LA DISPONIBILIDAD Y ACCESO A LOS DATOS
            PERSONALES DE FORMA RÁPIDA EN CASO DE INCIDENTE FÍSICO O TÉCNICO
          </h4>
          <ul>
            <li>
              Sistema de gestión de copias de seguridad y recuperación de datos
              personales
            </li>
            <li>
              Sistema de identificación, registro y gestión de incidencias
            </li>
            <li>
              Sistema de gestión y notificación de violaciones de seguridad de
              datos personales
            </li>
            <li>SAI (Sistema de Alimentación ininterrumpida)</li>
            <li>Plan de Contingencia y Continuidad del negocio</li>
            <li>Plan de Continuidad del Negocio</li>
            <li>Plan de Continuidad o Contingencia TIC</li>
            <li>Plan de Recuperación ante Desastres</li>
          </ul>
        </li>
        <li>
          <h4>
            MEDIDAS PARA GARANTIZAR LOS PROCESOS DE VERIFICACIÓN, EVALUACIÓN Y
            VALORACIÓN DE LA EFICACIA DE ESTAS MEDIDAS PARA GARANTIZAR LA
            SEGURIDAD DEL TRATAMIENTO
          </h4>
          <ul>
            <li>Auditoría Externa anual</li>
            <li>Auditoría Interna mensual</li>
            <li>Controles internos de cumplimiento anual</li>
            <li>
              Revisión regular de la política de protección de datos anual
            </li>
          </ul>
        </li>
      </ol>
    </div>
  </div>
);

export default TermsOfService;
