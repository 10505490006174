import { Rule } from 'antd/lib/form';

import { ErrorMessage } from '../../models/error-message.model';
import { backendValidation } from './form-validations.lib';

export const contactValidation = (
  errors: Record<string, ErrorMessage>
): ((field: string) => Rule[]) => {
  const validations: { [key: string]: Rule[] } = {
    name: [{ required: true }],
    last_name: [{ required: true }],
    position: [{ required: true }],
    department: [{ required: true }],
    country_id: [{ required: true }],
    area_id: [{ required: true }],
    email: [{ required: true }, { type: 'email' }],
    message_subject: [{ required: true }],
    message_text: [{ required: true }],
    agree_legal: [{ required: true }]
  };

  return (field: string): Rule[] => {
    return [...(validations[field] || []), backendValidation(field, errors)];
  };
};
