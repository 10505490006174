import React, { createContext, useContext } from 'react';

import dayjs, { Dayjs } from 'dayjs';

import useIntl from './useIntl';

interface FormatContextState {
  dateFormat: string;
  monthFormat: string;
  yearFormat: string;
  dateTimeFormat: string;
  timeFormat: string;
  timeFormatWithoutSeconds: string;

  fDayjsDate: (date: Dayjs) => string;
  fDate: (date: Date) => string;
  fDateTime: (date: Date) => string;
  fTime: (date: Date) => string;
  fTimeWithoutSeconds: (date: Date) => string;
  fDateWithoutTimeZone: (date: Date) => string;
  fDateTimeWithoutTimeZone: (date: Date) => string;
  fFileNameDateTimeFormat: (date: Date) => string;
  fFileNameDateFormat: (date: Date) => string;
  fNumber: (n?: number) => string;
  fNumberNoDecimal: (n?: number) => string;
  fPriceNumber: (n?: number) => string;
  fTotalNumber: (n?: number) => string;
  fFormNumber: (n?: number) => string;
  fFormNumberNoDecimal: (n?: number) => string;
  fPriceFormNumber: (n?: number) => string;
  fTotalFormNumber: (n?: number) => string;
  fApiNumber: (n?: string) => string;
}

const FormatContext = createContext<FormatContextState>({
  dateFormat: '',
  monthFormat: '',
  yearFormat: '',
  dateTimeFormat: '',
  timeFormat: '',
  timeFormatWithoutSeconds: '',

  fDayjsDate: () => '',
  fDate: () => '',
  fDateTime: () => '',
  fTime: () => '',
  fTimeWithoutSeconds: () => '',
  fDateWithoutTimeZone: () => '',
  fDateTimeWithoutTimeZone: () => '',
  fFileNameDateTimeFormat: () => '',
  fFileNameDateFormat: () => '',
  fNumber: () => '',
  fNumberNoDecimal: () => '',
  fPriceNumber: () => '',
  fTotalNumber: () => '',
  fFormNumber: () => '',
  fFormNumberNoDecimal: () => '',
  fPriceFormNumber: () => '',
  fTotalFormNumber: () => '',
  fApiNumber: () => ''
});

interface Props {
  children: React.ReactNode;
}

export const FormatProvider: React.FC<Props> = ({ children }: Props) => {
  const { fullLocale } = useIntl();

  const getDateFormat = (): string =>
    fullLocale?.DatePicker?.lang.dateFormat || 'DD/MM/YYYY';

  const getMonthFormat = (): string =>
    fullLocale?.DatePicker?.lang.monthFormat || 'MM/YYYY';

  const formattedNumber = Intl.NumberFormat(fullLocale?.locale).format(12345.6);

  let commaIndex = 0;
  let pointIndex = 0;

  if (!!formattedNumber) {
    commaIndex = formattedNumber.indexOf(',');
    pointIndex = formattedNumber.indexOf('.');
  }

  const decimalSeparator = commaIndex > pointIndex ? ',' : '.';
  const thousandSeparator = commaIndex < pointIndex ? ',' : '.';
  const decimalPrecision = 2;

  const dateFormat: string = getDateFormat();
  const monthFormat: string = getMonthFormat();
  const yearFormat = 'YYYY';
  const dateTimeFormat = `${dateFormat} HH:mm:ss`;
  const timeFormat = 'HH:mm:ss';
  const timeFormatWithoutSeconds = 'HH:mm';
  const fileNameDateTimeFormat = 'YYYYMMDDHHmmss';
  const fileNameDateFormat = 'YYYYMMDD';

  function fDayjsDate(date: Dayjs): string {
    return date?.format(dateFormat);
  }

  function fDate(date: Date): string {
    return date && dayjs(date).format(dateFormat);
  }

  function fDateTime(date: Date): string {
    return date && dayjs(date).format(dateTimeFormat);
  }

  function fTime(date: Date): string {
    return date && dayjs(date).format(timeFormat);
  }

  function fTimeWithoutSeconds(date: Date): string {
    return date && dayjs(date).format(timeFormatWithoutSeconds);
  }

  function fDateWithoutTimeZone(date: Date): string {
    return date && dayjs(date).utc().format(dateFormat);
  }

  function fDateTimeWithoutTimeZone(date: Date): string {
    return date && dayjs(date).utc().format(dateTimeFormat);
  }

  function fFileNameDateTimeFormat(date: Date): string {
    return (
      date &&
      dayjs(date)
        .subtract(dayjs(date).utcOffset(), 'minutes')
        .format(fileNameDateTimeFormat)
    );
  }

  function fFileNameDateFormat(date: Date): string {
    return (
      date &&
      dayjs(date)
        .subtract(dayjs(date).utcOffset(), 'minutes')
        .format(fileNameDateFormat)
    );
  }

  function getDecimals(r: string, decimals: number): string {
    if (r === null) {
      return '';
    }

    if (r.lastIndexOf(decimalSeparator) > 0) {
      if (decimals === 0) {
        r = r.substr(0, r.lastIndexOf(decimalSeparator));
      } else {
        for (
          let i = r.length - r.lastIndexOf(decimalSeparator);
          i <= decimals;
          i++
        ) {
          r += '0';
        }
      }
    } else {
      if (decimals > 0) {
        r += `${decimalSeparator}`;
        for (let i = 0; i < decimals; i++) {
          r += '0';
        }
      }
    }

    return r;
  }

  function setDecimals(n?: number, decimals = 2): string {
    if (n === undefined || n === null) {
      return '';
    }

    const r = parseFloat(parseFloat(n.toString()).toFixed(decimals))
      .toLocaleString('en')
      .replace(/,/g, ';') // Si substituïm per ',' al següent es substituïrà per '.''
      .replace(/\./g, decimalSeparator)
      .replace(/;/g, thousandSeparator); // Substituïm el ';' pel correcte

    return getDecimals(r, decimals);
  }

  function fNumber(n?: number): string {
    if (n === undefined || n === null) {
      return '';
    }

    return decimalSeparator && thousandSeparator ? setDecimals(n) : String(n);
  }

  function fNumberNoDecimal(n?: number): string {
    if (n === undefined || n === null) {
      return '';
    }

    return decimalSeparator && thousandSeparator
      ? setDecimals(n, 0)
      : String(n);
  }

  function fPriceNumber(n?: number): string {
    if (n === undefined || n === null) {
      return '';
    }

    return decimalSeparator && thousandSeparator
      ? setDecimals(n, decimalPrecision)
      : String(n);
  }

  function fTotalNumber(n?: number): string {
    if (n === undefined || n === null) {
      return '';
    }

    return decimalSeparator && thousandSeparator
      ? setDecimals(n, decimalPrecision)
      : String(n);
  }

  function setFormDecimals(n?: number, decimals = 2): string {
    if (n === undefined || n === null) {
      return '';
    }

    const r = parseFloat(parseFloat(n.toString()).toFixed(decimals))
      .toLocaleString('en')
      .replace(/,/g, ';') // Si substituïm per ',' al següent es substituïrà per '.'
      .replace(/\./g, decimalSeparator)
      .replace(/;/g, ''); // Substituïm el ';' pel correcte

    return getDecimals(r, decimals);
  }

  function fFormNumber(n?: number): string {
    if (n === undefined || n === null) {
      return '';
    }

    return decimalSeparator ? setFormDecimals(n) : String(n);
  }

  function fFormNumberNoDecimal(n?: number): string {
    if (n === undefined || n === null) {
      return '';
    }

    return decimalSeparator ? setFormDecimals(n, 0) : String(n);
  }

  function fPriceFormNumber(n?: number): string {
    if (n === undefined || n === null) {
      return '';
    }

    return decimalSeparator ? setFormDecimals(n, decimalPrecision) : String(n);
  }

  function fTotalFormNumber(n?: number): string {
    if (n === undefined || n === null) {
      return '';
    }

    return decimalSeparator ? setFormDecimals(n, decimalPrecision) : String(n);
  }

  function fApiNumber(n?: string): string {
    if (n === undefined || n === null) {
      return '';
    }

    return decimalSeparator && decimalSeparator === ','
      ? n.toString().replace(/,/g, '.')
      : String(n);
  }

  return (
    <FormatContext.Provider
      value={{
        dateFormat,
        monthFormat,
        yearFormat,
        dateTimeFormat,
        timeFormat,
        timeFormatWithoutSeconds,
        fDayjsDate,
        fDate,
        fDateTime,
        fTime,
        fTimeWithoutSeconds,
        fDateWithoutTimeZone,
        fDateTimeWithoutTimeZone,
        fFileNameDateTimeFormat,
        fFileNameDateFormat,
        fNumber,
        fNumberNoDecimal,
        fPriceNumber,
        fTotalNumber,
        fFormNumber,
        fFormNumberNoDecimal,
        fPriceFormNumber,
        fTotalFormNumber,
        fApiNumber
      }}
    >
      {children}
    </FormatContext.Provider>
  );
};

export default function useFormat(): FormatContextState {
  return useContext(FormatContext);
}
