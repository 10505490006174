import { variables } from '../core/variables';

export const contact = {
  '& .s-contact': {
    '& .titles': {
      maxWidth: '770px',
      marginBottom: '50px'
    },

    '& h3': {
      color: '#61636b',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: 1.3,
      marginBottom: '24px',

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        fontSize: '16px',
        lineHeight: 1.25
      }
    },

    '& h4': {
      color: '#777983',
      fontWeight: 600,
      fontSize: '16px',
      margin: '20px 0',

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        fontSize: '14px'
      }
    },

    '& .header': {
      paddingTop: '154px',
      paddingBottom: '80px',
      backgroundImage: `linear-gradient(180deg, ${variables['@background-color-gradient']} 19.34%, ${variables['@background-color']} 100%)`,

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        paddingTop: '50px',
        paddingBottom: '50px'
      },

      '& h2': {
        color: '#ecedee',
        fontWeight: 800,
        fontSize: '54px',
        lineHeight: 1.05,

        [`@media (max-width: ${variables['@mobile-width']})`]: {
          fontSize: '42px',
          lineHeight: 1.2
        },

        [`@media (max-width: ${variables['@responsive-width']})`]: {
          marginRight: 0
        }
      },

      '& p': {
        color: '#a9abb1',
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: 1.2,

        [`@media (max-width: ${variables['@mobile-width']})`]: {
          fontSize: '20px',
          lineHeight: 1.25
        },

        [`@media (max-width: ${variables['@responsive-width']})`]: {
          marginRight: 0
        }
      }
    },

    '& .form': {
      padding: '128px 0',
      backgroundColor: variables['@background-color-light'],

      [`@media (max-width: ${variables['@mobile-width']})`]: {
        paddingTop: '70px'
      },

      '& .content': {
        maxWidth: '480px',
        width: '100%'
      },

      '& .agree-sales': {
        marginBottom: '0 !important'
      }
    }
  }
};
