import React, { useEffect, useState } from 'react';

import { Table, Tooltip } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { ColumnType } from 'antd/lib/table';
import classNames from 'classnames';

import { TableColumn } from '../../models/table-column.model';
import useTranslate from '../../hooks/useTranslate';

const getValue = (value: any): any => {
  switch (value) {
    case null:
    case undefined:
    case '':
      return <span>&nbsp;</span>;
    case true:
      return <CheckOutlined />;
    case false:
      return <CloseOutlined />;
    default:
      return value;
  }
};

const renderCell = (value: any): any => {
  let children: any = undefined;
  let className = '';
  if (value?.value) {
    children = getValue(value.value);
    className = value.className;
  } else {
    children = getValue(value);
  }
  const element = {
    props: { className },
    children
  };
  return element;
};

const toColumnType = ({
  title,
  id,
  align,
  sorter,
  className
}: TableColumn): ColumnType<any> => ({
  title,
  dataIndex: id,
  key: id,
  sorter,
  render: renderCell,
  align,
  className,
  onHeaderCell: (): React.HTMLAttributes<HTMLElement> => ({ title })
});

interface Props {
  className?: string;
  dataSource:
    | Record<string, string | number | boolean | React.ReactNode | {}>[]
    | undefined;
  columns: TableColumn[];
  rowClassName?: (record: any, index: any) => string;
  actions?: (text: string, record: any) => React.ReactNode;
  additionalColumns?: TableColumn[];
  possibleErrors?: boolean;
  withActionButtons?: boolean;
  divHeight?: number;
  selected?: number[];
  onSelectedChange?: (selected: number[]) => void;
}

const ResourceInnerTable: React.FC<Props> = ({
  className,
  dataSource,
  columns,
  rowClassName,
  actions,
  additionalColumns,
  possibleErrors,
  withActionButtons,
  divHeight,
  selected,
  onSelectedChange
}: Props) => {
  const t = useTranslate();

  const [tableColumns, setTableColumns] = useState<ColumnType<any>[]>([]);

  const actionColumn = (): ColumnType<any> => {
    return {
      align: 'right',
      key: 'actions',
      render: actions,
      fixed: 'right',
      className: 'cell-actions'
    };
  };

  const prepareColumns = () => {
    let columnsToShow: ColumnType<any>[] = [];

    additionalColumns?.forEach(column => {
      let hasItems = false;

      dataSource?.forEach(item => {
        const aux = Object.values(item)[Object.keys(item).indexOf(column.id)];

        if (!!aux) {
          hasItems = true;
        }
      });

      if (hasItems) {
        columnsToShow = [...columnsToShow, toColumnType(column)];
      }
    });

    columns.forEach(column => {
      columnsToShow = [...columnsToShow, toColumnType(column)];
    });

    if (!!actions) {
      columnsToShow = [...columnsToShow, actionColumn()];
    }

    if (possibleErrors) {
      setTableColumns([
        {
          align: 'center',
          render: (value: any): React.ReactNode =>
            (value.errors && (
              <Tooltip
                title={value.errors}
                overlayClassName="inner-table__tooltip"
                placement="topLeft"
                color="white"
                destroyTooltipOnHide
              >
                <ExclamationCircleOutlined className="error" />
              </Tooltip>
            )) as React.ReactNode,
          fixed: 'left',
          className: 'cell-errors'
        },
        ...columnsToShow
      ]);
    } else {
      setTableColumns(columnsToShow);
    }
  };

  useEffect(() => {
    prepareColumns();
  }, [dataSource]);

  return (
    <Table
      className={classNames(
        'inner-table',
        className,
        withActionButtons ? undefined : 'inner-table__no-button'
      )}
      rowSelection={
        !!!selected && !!!onSelectedChange
          ? undefined
          : {
              fixed: true,
              selectedRowKeys: selected,
              onChange: (selectedRowKeys: any): void =>
                onSelectedChange?.(selectedRowKeys)
            }
      }
      rowClassName={rowClassName}
      dataSource={dataSource}
      columns={tableColumns}
      showSorterTooltip={false}
      scroll={{ x: true, y: divHeight ? divHeight : undefined }}
      locale={{ emptyText: t({ id: 'NO_DATA_TABLE' }) }}
      pagination={false}
    />
  );
};

export default ResourceInnerTable;
