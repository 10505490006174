import { useState, useMemo, useEffect } from 'react';

import { ErrorMessage } from '../models/error-message.model';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

interface FormErrors {
  errors: ErrorMessage[];
  alertErrors: ErrorMessage[];
  fieldErrors: Record<string, ErrorMessage>;
  genericErrors: ErrorMessage[];
  clearValidations: (
    field: any[],
    fieldErrors: Record<string, ErrorMessage>
  ) => void;
  clearError: (
    fieldName: string,
    fieldErrors: Record<string, ErrorMessage>
  ) => void;
  setFormErrors: (errors: ErrorMessage[]) => void;
  setAlertErrors: (errors: ValidateErrorEntity) => void;
}
export default function useFormErrors(): FormErrors {
  const [errors, setErrors] = useState<ErrorMessage[]>([]);
  const [alertErrors, setInternalAlertErrors] = useState<ErrorMessage[]>([]);

  const getCapitalizeErrorMessage = (errorMessage: string): string => {
    let message: string = errorMessage.charAt(0).toUpperCase();
    message += errorMessage.substr(1);

    return message;
  };

  const setFormErrors = (formErrors: ErrorMessage[]): void => {
    const newErrors: ErrorMessage[] = [];
    formErrors.map((formError: ErrorMessage) => {
      const splitField: string[] | undefined = formError.field
        ?.replace(']', '[')
        .split('[');
      if (splitField && splitField?.length > 1) {
        newErrors.push({
          field: splitField[0] + splitField[2],
          id: splitField[1],
          message: getCapitalizeErrorMessage(formError.message),
          transient_id: formError.transient_id || splitField[1]
        });
      } else {
        newErrors.push({
          ...formError,
          message: getCapitalizeErrorMessage(formError.message)
        });
      }
    });
    setErrors(newErrors);
  };

  useEffect(() => {
    if (errors.length > alertErrors.length) {
      setInternalAlertErrors(errors);
    } else {
      setInternalAlertErrors([]);
    }
  }, [errors]);

  const fieldErrors: Record<string, ErrorMessage> = useMemo(() => {
    const fields: Record<string, ErrorMessage> = {};

    errors.forEach((error: ErrorMessage) => {
      if (error.field) {
        fields[error.field] = error;
      }
    });

    return fields;
  }, [errors]);

  const clearError = (
    fieldName: string,
    fErrors: Record<string, ErrorMessage>
  ) => {
    delete fErrors.fieldName;
    setFormErrors(errors.filter(error => error.field !== fieldName));
  };

  const clearValidations = (
    fields: any[],
    fErrors: Record<string, ErrorMessage>
  ): void => {
    fields.forEach(field => {
      const fieldName: string =
        !!field && typeof field === 'string'
          ? field
          : Array.isArray(field)
          ? field[0]
          : undefined;

      if (!!fieldName && !!fErrors[fieldName] && !fErrors[fieldName].manual) {
        clearError('' + fieldName, fErrors);
      }
    });
  };

  const genericErrors: ErrorMessage[] = useMemo(
    () => errors.filter((error: ErrorMessage) => !error.field),
    [errors]
  );

  const setAlertErrors = (errorInfo: ValidateErrorEntity): void => {
    setInternalAlertErrors(
      errorInfo.errorFields.map(error => ({
        message: error.errors[0],
        field: '' + error.name.toString()
      }))
    );
  };

  return {
    errors,
    alertErrors,
    clearError,
    clearValidations,
    fieldErrors,
    genericErrors,
    setFormErrors,
    setAlertErrors
  };
}
