export const variables = {
  '@red-diesel': '#e1001a',
  '@red-diesel-hover': '#a80013',

  '@action-button-bg-color': '#4a4a54',
  '@action-button-hover-bg-color': '#646471',

  '@primary-color': '#4a90e2',
  '@hint-color': '#c6c6c6',

  '@background-color': '#35383d',
  '@background-color-light': '#f2f2f3',
  '@background-color-gradient': '#1d1d21',

  '@border-radius': '6px',
  '@border-radius-antd': '2px',
  '@box-shadow-base':
    'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',

  '@block-width': '1180px',
  '@max-block-width': '1340px',

  '@font-size-xxl': '22px',
  '@font-size-xl': '20px',
  '@font-size-l': '18px',

  '@responsive-width': '991px',
  '@mobile-width': '576px',

  '@font-family':
    "Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",

  // Antd variables overide
  '@layout-body-background': 'white',
  '@layout-header-background': '#242429',
  '@layout-header-height': '82px',
  '@layout-header-padding': '0 23px'
};
