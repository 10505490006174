const routes = {
  functions: (): string => '/functions',
  contact: (): string => '/contact',
  root: (): string => '/',
  cookies_policy: (): string => '/cookies_policy',
  legal_notice: (): string => '/legal_notice',
  privacy_policy: (): string => '/privacy_policy',
  terms_of_service: (): string => '/terms_of_service'
};

export default routes;
