import React from 'react';

const imageSrc = require('./imageDisponibilidad.png');

const TermsOfService: React.FC = () => (
  <div className="block block__single block__single__legal">
    <div className="legal terms-of-service">
      <h2>General "DieselPlus Platform" contract conditions</h2>
      <p>
        The party of the first part, <b>TOT COMERCIAL, S.A.</b>, hereinafter the{' '}
        <b>Supplier</b>, with registered office at Partida Horta Amunt s/n,
        25600 Balaguer, the bearer of Tax Identification Number A25031154, with{' '}
        <b>
          customer service telephone number{' '}
          <a href="tel:973451072">973 451 072</a>
        </b>{' '}
        and email address{' '}
        <a href="mailto:gespasa@gespasa.es">gespasa@gespasa.es</a>, the
        proprietor of the DIESELPLUS platform (also referred to herein as the{' '}
        <b>Platform</b>) under the Software as a Service (hereafter <b>SaaS</b>)
        system presents this contractual document which shall govern the
        contracting of the platform and its modules as required by the customer
        through the aforesaid website or direct contact with a sales
        representative or one of the Supplier’s authorised distributors and
      </p>
      <p>
        The party of the second part, the customer-contracted service user
        (hereinafter the <b>Customer</b>), who seeks to enter into this contract
        regulated by the general terms and conditions applicable to the use of
        the Supplier’s platform.
      </p>
      <h3>1. INTRODUCTION</h3>
      <p>
        Both parties hereby recognise their respective capacity in the widest
        sense of the term to enter into and be bound by this contract.
      </p>
      <p>
        The Customer declares that:
        <ol>
          <li>
            They have read and fully understand the content of this document.
          </li>
          <li>
            They are persons of legal age with sufficient capacity to enter into
            contract.
          </li>
          <li>They duly assume all the obligations as set forth herein.</li>
          <li>
            They have read and accept these general purchase conditions from the
            moment that they contract the use of the platform.
          </li>
        </ol>
      </p>
      <p>It is advisable that a copy of these conditions are kept on file.</p>
      <p>
        The service provider has made the{' '}
        <a href="mailto:gespasa@gespasa.es">gespasa@gespasa.es</a> email address
        available for any doubts arising from these conditions.
      </p>
      <p>
        These terms and conditions shall have indefinite validity and will be
        applicable to all contracts entered into through the <b>Supplier</b>’s
        website, its sales representative or its authorised distributors.
      </p>
      <p>
        The <b>Supplier</b> reserves the right to unilaterally amend these
        general conditions to improve the service it offers without this having
        any bearing on the contracting of the platform prior to the alteration.
        In all circumstances, before contracting the platform, these general
        conditions should be duly consulted.
      </p>
      <p>
        The <b>Supplier</b> shall not be held responsible for any consequences
        that may arise as a result of improper use of the platform.
      </p>
      <h3>2. DEFINITIONS</h3>
      <p>
        Both parties agree that the following terms shall be understood as
        described below:
        <ul>
          <li>
            <b>Contract</b>: All the documents that regulate the contracted
            service. It comprises the following documents:{' '}
            <b>
              General contracting conditions, Specific conditions as set forth
              in the annexes to these conditions, the Commercial offer and the
              Service Level Agreement (defined as the Incident Management
              Process in Annex 3)
            </b>
            . This contract may be consulted at all times from the DIESELPLUS
            Platform.
          </li>
          <li>
            <b>General contract conditions</b>: the document that sets forth the
            general conditions under which the Customer shall access and use the
            platform, without prejudice to that outlined in the specific
            conditions as established with the Customer, which are detailed in
            the annexes to this document.
          </li>
          <li>
            <b>Commercial offer</b>: the document that sets forth the modules
            and services that the customer has contracted and the cost thereof,
            as well as the agreed trial and permanence periods, where these
            differ from that stipulated herein.
          </li>
          <li>
            <b>Service Level Agreement (SLA)</b>: the document that stipulates
            the conditions and parameters that oblige the service provider to
            comply with a series of service quality levels with regard to the
            Customer and the platform.
          </li>
          <li>
            <b>Customer</b>: The individual or organisation that has an interest
            in contracting a licence for the total or partial use of the{' '}
            <b>Platform</b> (under the SaaS system).
          </li>
          <li>
            <b>Parties</b>: Those individuals or organisations entering into the
            contract, namely the Supplier and <b>Customer</b> in this instance.
          </li>
          <li>
            <b>IT equipment</b>: computers and laptops, tablets, smartphones and
            any other device on which the <b>Platform</b> can be installed and
            accessed that meets the minimum requirements for each type of
            equipment and operating system as specified by the <b>Supplier</b>.
          </li>
          <li>
            <b>SaaS</b>: The service provided to the <b>Customer</b> by the{' '}
            <b>Supplier</b> through the <b>Platform</b>.
          </li>
          <li>
            <b>DIESELPLUS Platform</b>: An IT platform owned, developed and
            administered by the <b>Supplier</b>.
            <ul>
              <li>
                The Platform consists of a series of software elements and
                communications and service systems provided by the{' '}
                <b>Supplier</b> accessible via the internet on which the
                information supplied and processed by the <b>Customer</b> is
                stored. The <b>Customer</b> accesses the{' '}
                <b>DIESELPLUS Platform</b> through their Private Environment,
                using a personal and non-transferable username and password
                provided by the Supplier.
              </li>
              <li>
                By contracting the <b>DIESELPLUS Platform</b>, the{' '}
                <b>Customer</b> acquires their licence to use the SaaS service
                provided by the <b>Supplier</b>, <b>in no case exclusively</b>.
              </li>
            </ul>
          </li>
          <li>
            <b>The Customer’s Private Environment</b>: The space in which the
            modules and functions that the <b>Supplier</b> makes available to
            the <b>Customer</b> are administered to provide them with the
            contracted SaaS.
          </li>
        </ul>
      </p>
      <h3>3. GENERAL CONDITIONS OF USE</h3>
      <h4>A. PURPOSE OF THESE GENERAL CONDITIONS</h4>
      <p>
        The purpose of this contract is to regulate the terms and conditions
        under which the <b>Supplier</b> provides the <b>Customer</b> with a
        service granting access to and maintenance of the platform in order that
        the latter may control their stock of various fuels, lubricants and
        other products that they wish to measure using equipment that is
        compatible with the DIESELPLUS Platform. It also offers fleet management
        through control of fuel consumption and other products and the
        positioning thereof. This content can be shown in the form of lists,
        reports and alarms and can be sent via email to users to obtain
        statistical data, optimise the consumption of fuel, lubricants and other
        products and reduce costs and environmental impact.
      </p>
      <p>
        As well as the services outlined above, the Platform also features a
        number of complementary modules. The quote provided establishes the
        different modules that the Customer has contracted, with the
        characteristics thereof set forth in Annexes 1 a) to 1 e) herein. These
        are as follows:
        <ul>
          <li>
            The DIESELPLUS communications and access service for compatible
            controllers, allowing the CUSTOMER to communicate with the Platform
            via a compatible controller. All the information relating to the
            particular conditions of the aforesaid service is set forth in ANNEX
            1 a.
          </li>
          <li>
            The DIESELPLUS communications and access service for TANK MODEM PLUS
            compatible level sensors that allow the CUSTOMER to communicate with
            the Platform via a DIESELPLUS compatible level sensor using TANK
            MODEM PLUS equipment. All the information relating to the particular
            conditions of the aforesaid service is set forth in ANNEX 1 b.
          </li>
          <li>
            The DIESELPLUS communications and access service for Dolphin
            sensors, allowing the CUSTOMER to communicate with the DIESELPLUS
            via a compatible Dolphin sensor controller. All the information
            relating to the particular conditions of the aforesaid service is
            set forth in ANNEX 1 c.
          </li>
          <li>
            DIESELPLUS INTEGRATION and access service for compatible third-party
            geolocation applications, allowing the CUSTOMER to integrate with
            the Platform via a compatible location system. All the information
            relating to the particular conditions of the aforesaid service is
            set forth in ANNEX 1 d.
          </li>
          <li>
            API DIESELPLUS access service ensuring integration with third-party
            applications, allowing the CUSTOMER to integrate with the Platform
            via an external application via API DIESELPLUS. All the information
            relating to the particular conditions of the aforesaid service is
            set forth in ANNEX 1 e.
          </li>
        </ul>
      </p>
      <h4>
        B. PROCEDURE THROUGH WHICH THE PLATFORM SERVICE AND USE LICENCE IS
        CONTRACTED
      </h4>
      <p>
        To begin the contracting process, the Customer should accept these
        General Contract Conditions, as well as the specific conditions
        applicable to modules and customisations that the <b>Customer</b> wishes
        to contract, included as annexes to this document. This will involve
        contracting the use of the platform. All the contractual information set
        forth herein appears in Spanish and English. Communication with
        customers and users and the formalisation of the contract shall be made
        in the language that the Customer indicates prior to contracting.
      </p>
      <p>
        In the contracting process:
        <ul>
          <li>
            The customer shall be informed of the following in the commercial
            offer:
            <ul>
              <li>
                The licences, characteristics and prices of the modules that
                they wish to contract
              </li>
              <li>
                The trial and permanence period, if this differs from that
                stipulated herein
              </li>
            </ul>
          </li>
          <li>
            Contact details for the intermediary responsible for centralising
            and coordinating relations with the <b>Supplier</b>.
          </li>
          <li>Invoicing and method of payment details.</li>
          <li>
            Technical information that allows an account to be set up and the
            compatible equipment, depending on the contracted modules.
          </li>
          <li>
            For each module, the Customer will have a number of access accounts
            that stipulate the particular conditions applicable thereto.
          </li>
        </ul>
      </p>
      <p>
        Once the <b>Supplier</b> has checked that all the information requested
        has been duly provided and payment made by the <b>Customer</b>, the
        former will notify the latter that the contracting process has been
        successful. Where payment is in instalments, correct subscription to the
        online payment channel will be checked, where necessary, as will the
        effective completion and notification of payment details.
      </p>
      <p>
        The <b>Customer</b> recognises that they have been informed of and
        accept that the information contained on the supplier’s website and any
        offers, quotes and projects provided to the <b>Customer</b> do not
        represent a contractual offer or the start of a business relationship
        with the supplier. They do, however, require compliance with the clauses
        established in to conclude the contracting process in the General
        Contract Conditions, pursuant to all applicable law.
      </p>
      <p>
        If the <b>Customer</b> does not agree with the content of these General
        Conditions or any part of the Contract, they should not proceed with the
        contracting process.
      </p>
      <h4>C. RETENTION OF PLATFORM INFORMATION</h4>
      <p>
        Depending on the modules contracted, the DIESELPLUS platform may store a
        record of refuelling and stock movements, tank readings and vehicle
        location and status. If a retention period is not specified in the
        commercial offer or in any of the specific conditions applicable to the
        various modules, by default and for the duration of this contract, the
        DIESELPLUS platform will retain the aforesaid records for at least five
        years.
      </p>
      <h4>D. PLATFORM USE LICENCE</h4>
      <p>
        The <b>Supplier</b> grants the <b>Customer</b> a non-transferable and
        non-exclusive licence to use the platform owned by the Supplier, under
        the terms and conditions set forth herein. Use of the <b>Platform</b> is
        only allowed in the case of customers with a Private Environment and
        credentials to access it, with the maximum limit of users indicated in
        the annex of the corresponding module.
      </p>
      <p>
        The Customer shall desist from making copies, disclosing details and
        permitting third-party access to the Platform.
      </p>
      <p>
        The rights granted to the Customer pursuant to the following General
        Conditions may be updated or extended to any new version that replaces
        or complements the <b>Platform</b>.
      </p>
      <h4>E. GUARANTEE APPLICABLE TO THE PLATFORM</h4>
      <p>
        The Customer accepts that the Platform is offered “as is”, meaning that
        the <b>Supplier</b> does not under any circumstances guarantee that its
        functions will meet the Customer’s expectations or needs, nor that the
        platform will work without interruption or error. The <b>Customer</b>{' '}
        shall assume the full cost of all services, repairs and corrections
        required to adapt it to their needs. Any modification, adaptation or
        customisation of the Platform should be made by the <b>Supplier</b> or
        the person that it designates.
      </p>
      <p>
        A period of 14 calendar days is understood as a guarantee period. This
        may be amended where there is consent of both parties and is established
        in the commercial agreement. If the <b>Customer</b> has not formally
        notified the Supplier of the existence of any anomaly during the
        aforesaid period, they shall be deemed to be compliant in all material
        respects with the Platform, that it functions correctly and that it
        meets their needs, waiving their right at that point to lodge any
        complaint.
      </p>
      <p>
        To use the platform licence that they have been issued, the{' '}
        <b>Customer</b> shall at all times comply with the following
        obligations:
        <ol>
          <li>
            To use the platform in accordance with the Supplier’s indications
          </li>
          <li>
            To keep their IT systems and equipment in good condition and
            correctly maintained
          </li>
          <li>Have internet access on their equipment</li>
          <li>
            Ensure access to the Platform is controlled to prevent unauthorised
            third-party manipulation
          </li>
          <li>
            Meet all the technical requirements needed to use the Platform,
            having the required and duly updated and correctly maintained
            operating system and software
          </li>
          <li>Prevent the installation of viruses and malware</li>
          <li>Apply all reasonable preventative security measures.</li>
        </ol>
      </p>
      <p>
        Under no circumstances shall the <b>Supplier</b> be held responsible for
        operating errors or damage resulting from the breach of the foregoing
        obligations or any other that might be applicable pursuant to these
        General Conditions.
      </p>
      <p>
        Under no circumstances may the <b>Customer</b> claim compensation for
        damages and losses that are outside the <b>Supplier</b>’s control, in
        particular, for interruptions or faults of any nature occurring in power
        supply systems, telecommunication networks, connectivity elements or the
        Customer’s equipment.
      </p>
      <h4>F. PLATFORM INTELLECTUAL PROPERTY</h4>
      <p>
        The Platform was created and is managed by the <b>Supplier</b>, who
        retains all intellectual and industrial property rights or any other
        prerogative applicable thereto. It may not, under any circumstances, be
        subject to any subsequent modification, copy, alteration, reproduction,
        adaptation or translation by the <b>Customer</b>.
      </p>
      <p>
        The Platform’s structure, characteristics, codes, working methods,
        information systems, development methods, know-how, approaches,
        processes, technologies and algorithms, as well as all it’s manual,
        texts, graphic design, databases and videos and audio supports or which
        complement the Platform are the property of the <b>Supplier</b> and are
        protected by Spanish, European Union and international intellectual and
        industrial property law.
      </p>
      <p>
        The making available of the <b>Platform</b> to the <b>Customer</b> does
        not, under any circumstances, imply any transfer of ownership or
        granting of any right of use in favour of the Customer other than that
        provided for in the General Conditions.
      </p>
      <p>
        Consequently, any use by the <b>Customer</b> of the Platform that is not
        authorised by the Supplier is totally prohibited. This shall include its
        exploitation, reproduction, dissemination, transformation, distribution,
        transmission by any means and subsequent publication or exhibition,
        public communication and total or partial representation beyond the
        Platform’s intended operational domain. Any breach of these prohibitions
        shall constitute an infraction of the Supplier’s intellectual and
        industrial property rights and shall be punishable pursuant to
        applicable law.
      </p>
      <h4>G. PROVISION OF THE SERVICE</h4>
      <p>
        The Supplier shall provide the SaaS service directly, through its own
        means. It has outsourced its server storage to Microsoft Azure. With
        regard to this service, both companies have signed a sub-processing
        agreement, in compliance with currently applicable data protection
        legislation.
      </p>
      <h4>H. TECHNICAL SPECIFICATIONS</h4>
      <p>
        Annex 2 sets forth the technical specifications for platform access and
        the use and installation of associated applications. In the Specific
        Conditions, which can be found in ANNEXES 1(a) to 1(e), each module
        contracted by the <b>Customer</b> contains the technical specifications
        for the networks, equipment and terminals required at all times to be
        able to install, access and use the Platform. In the event that the{' '}
        <b>Customer</b>’s networks, equipment and terminals do not meet the
        aforementioned technical specifications, the <b>Customer</b> shall
        desist from attempting to install, access or use the Platform.
      </p>
      <p>
        The <b>Supplier</b> shall make all DIESELPLUS module manuals available
        to the <b>Customer</b>. These can be found on the platform once
        registered in the “Help” section which identifies the specific operator
        and provides basic instructions regarding use of the Platform. Where the{' '}
        <b>Customer</b> has requested customisation from the <b>Supplier</b>,
        there may not be instructions or manuals available. The <b>Supplier</b>{' '}
        accepts no liability for any use of the Platform by the <b>Customer</b>{' '}
        that does not follow the corresponding manual, instructions or
        notifications provided by the former, or, in the case of the latter’s
        networks, equipment and terminals, which do not meet the required
        technical specifications.
      </p>
      <p>
        On notification by the <b>Supplier</b>, the <b>Customer</b> shall
        disconnect their Platform networks, equipment and terminals, if, in the
        opinion of the supplier, the aforesaid networks, equipment and terminals
        have caused or may cause faults, interruptions, errors o defects to the
        Platform.
      </p>
      <h4>I. PLATFORM USAGE CONDITIONS</h4>
      <p>
        The Platform shall only be accessible by a <b>Customer</b> to whom the{' '}
        <b>Supplier</b> has assigned at least one licence and has provided
        access information.
      </p>
      <p>
        The <b>Customer</b> may only use the Platform for the purposes
        stipulated in the Contract.
      </p>
      <p>
        The <b>Customer</b> shall be responsible for all damages and losses that
        the <b>Supplier</b> or a third party might incur as a consequence of the
        Customer's breach of any of the obligations assumed through this
        contract, compensating the latter for any claim that may be presented in
        this regard and accepting the sums that the <b>Supplier</b> is obliged
        to pay for any concept.
      </p>
      <p>
        To verify the <b>Customer</b>’s compliance with the aforesaid conditions
        and guarantee the security and correct use of the Platform, as well as
        to ensure the optimum performance or adjustment of certain elements
        thereof, the <b>Supplier</b> reserves the right to monitor the{' '}
        <b>Customer</b>’s use of the Platform.
      </p>
      <p>
        The <b>Customer</b> shall be the sole party responsible for content
        during their use of the Platform, as well as for all activities
        undertaken and information stored thereon and the operators who are
        authorised to use it.
      </p>
      <p>
        The <b>Customer</b> hereby undertakes to keep all access passwords
        secret, immediately notifying the <b>Supplier</b> of any unauthorised
        use of their associated account.
      </p>
      <p>
        The <b>Customer</b> also undertakes to fulfil all payments under the
        terms agreed upon with the <b>Supplier</b>. They declare that they
        understand that in the event of any non-payment, the <b>Supplier</b> may
        temporarily cancel their access to the Platform until the situation has
        been resolved while maintaining their Private Environment on the
        Platform. If, after the non-payment situation has been highlighted it is
        still not resolved through the settlement and/or agreement regarding the
        debt incurred, the <b>Supplier</b> shall be empowered to terminate the
        contractual relationship between both parties, pursuant to that set
        forth in the Termination section of these General Conditions.
      </p>
      <h4>J. THE CUSTOMER’S OBLIGATIONS</h4>
      <p>
        The <b>Customer</b> shall comply with all the terms and conditions set
        forth in these general conditions in the performance of their
        professional activities, as well as acting with respect to currently
        applicable law and principles of good faith.
      </p>
      <p>
        The following are also obligations that apply to the <b>Customer</b>:
        <ol>
          <li>
            Scrupulous compliance with all the requirements established in the
            technical conditions.
          </li>
          <li>
            Payment of the price as agreed in the contract, pursuant to that
            indicated in the corresponding Price and Payment section.
          </li>
          <li>
            Scrupulous compliance with the regulations covering data protection
            (GDPR and the Spanish Data Protection and Guarantee of Digital
            Rights Act), as well as the regulations set forth in the Information
            Society Services Act, in the sense that they may not use the domain
            email server and its addresses for the purposes of spamming (mass
            unwanted mailings of a commercial or other nature) and mail bombing
            (the sending of large volumes of email to overwhelm the server).
          </li>
          <li>
            To maintain the email address issued by the <b>Supplier</b> in the
            contracting document operative, active and up-to-date, as this
            represents the <b>Supplier</b>’s preferred channel of communication,
            ensuring flexible, fluid administration in the provision of the
            contracted service. If the Customer changes their contact email,
            notification thereof should be given immediately. The{' '}
            <b>Supplier</b> is exempted from all liability owing to faults in
            the email service provided to the <b>Customer</b>, issues related to
            its updating and disinformation proffered by the latter due to their
            own negligence.
          </li>
          <li>
            To compensate the <b>Supplier</b> for expenses that it has to cover
            as a result of blame being apportioned in a matter that is
            attributable to the <b>Customer</b>, including the former’s legal
            fees and other costs, even where the judicial ruling is not final.
          </li>
          <li>
            The <b>Customer</b> shall not access, alter or view the
            configuration, structure and files on the <b>Supplier</b>’s servers
            and platform. Any problem that may arise on the <b>Supplier</b>’s
            servers, platform and/or security systems as a direct consequence of
            the <b>Customer</b>’s negligence will result in the civil and
            criminal action that corresponds to the former party.
          </li>
          <li>
            To undertake all legal steps as agreed upon and required to ensure
            that the documentation created by the platform can be used in the
            procedures concerning and presentations to the relevant authorities.
            The <b>Customer</b> is responsible for generating all required
            documentation in good time prior to the aforesaid presentation.
          </li>
        </ol>
      </p>
      <h4>K. ENTRY INTO FORCE, DURATION AND EXTENSION OF THE CONTRACT</h4>
      <p>
        The contractual regulation covered by these General Conditions shall
        come into force from the moment that the <b>Supplier</b> receives the{' '}
        <b>Customer</b>’s acceptance thereof, the contractual document and
        confirmation of payment. The <b>Customer</b> will receive a copy of this
        confirmation by email, with a copy of this contract also available on
        platform.
      </p>
      <p>
        On the day that the contract concludes or at the end of the period of
        permanence, the contract shall be extended for a similar term if the{' '}
        <b>Customer</b> does not give written notification of their desire to
        terminate the relationship with proof of receipt at least one month
        prior to the completion of the annual period. In the event of extension,
        the annual charge will increase, as provided for in Clause Four.
      </p>
      <p>
        That set forth in the foregoing paragraph shall apply to the first and
        all subsequent extensions.
      </p>
      <p>
        In all circumstances, if the <b>Customer</b> requests the termination of
        this contract prior to the conclusion date or, where applicable, its
        extensions, the <b>Customer</b> shall be obliged to download all files
        and reports required to store their data. Two months after the
        conclusion date of this contract or its subsequent extensions, the{' '}
        <b>Supplier</b> shall irrevocably delete all information contained in
        the licence issued to the Customer through the performance of this
        contract. If the <b>Customer</b> requires more time to remove the
        aforesaid content, they should apply to the <b>Supplier</b> for this in
        writing, with the latter party duly acceding to the request.
      </p>
      <p>
        The deletion of the information referred to in the previous paragraph
        implies the definitive destruction or disablement thereof. In the event
        that this deletion may cause damage or loss to any third party, the{' '}
        <b>Customer</b> shall be obliged to compensate the <b>Supplier</b> for
        any legal action that the aforesaid party may embark upon.
      </p>
      <p>
        The <b>Customer</b> declares that they understand and accept that the{' '}
        <b>Supplier</b> shall definitively cancel all access to the platform,
        destroying all the information contained thereon on termination of the
        contractual relationship between both parties.
      </p>
      <h4>L. CANCELLATION OF SERVICE</h4>
      <p>
        If the requested service is cancelled prior to the date of delivery
        foreseen by the Supplier, this party reserves the right to claim
        reasonable compensation for the costs it has incurred as a result of the
        cancellation or alteration, including in cases where the Supplier has
        not expressly invoiced the installation costs or where there is a
        discount applicable thereto.
        <br />
        If notification of cancellation is received up to two calendar days
        prior to the service activation date, compensation of 100% of the charge
        for the contracted period will be payable. If notification is received
        three to four calendar days prior to the aforesaid date, compensation of
        50% of the charge for the contracted period will be payable. If
        notification of cancellation is received five or more days prior to
        activation of the service, compensation of 20% of the charge for the
        contracted period will be payable.
        <br />
        Once the service has been provided, if the contract is cancelled prior
        to the date of conclusion thereof, the <b>Supplier</b> reserves the
        right to claim reasonable compensation for the costs it has incurred as
        a consequence of the cancellation from the <b>Customer</b>. The amount
        of the penalty shall correspond to the sums payable until the end of the
        initial duration of the contract.
      </p>
      <p>
        Once the service has commenced, if the Customer requests its
        cancellation after the first year, they will continue to have access
        thereto until the annual contractual period has ended.
      </p>
      <h4>M. FORCE MAJEURE</h4>
      <p>
        Where a breach of breach of contract occurs as a result of force
        majeure, neither party shall be liable for the failure to comply with
        their contractual obligations and therefore no compensation shall be
        payable.
      </p>
      <p>
        If suspension of the service in such circumstances lasts longer than one
        month, this contract may be cancelled at the request of either party.
      </p>
      <p>
        Force majeure shall be taken to include fire, floods, strikes, labour
        disputes or other social unrest, the shortage or unavailability of
        electrical power, the unavailability or malfunction of communications
        networks, accidents, war (declared or otherwise), commercial embargoes,
        blockades and disturbances or insurrection.
      </p>
      <h4>N. NATURE OF THE CONTRACTUAL OBLIGATIONS</h4>
      <p>
        All obligations assumed by the <b>Supplier</b> through this contract
        refer to activities and service, not results. These obligations do not
        imply or guarantee the attainment of an objective or work or can be
        deemed to be determining factors, except in those cases in which the
        parties expressly and definitively agree to the contrary.
      </p>
      <h4>O. SERVICE OUTAGES</h4>
      <p>
        In certain cases, access may be denied by the Supplier or the company
        hosting the outsourced data in order to implement some form of
        adjustment to the service. These adjustments, in which access will be
        temporarily suspended, will be undertaken at times that do not coincide
        with the busiest periods, with a commitment to ensuring that a back-up
        copy is made of all information. The <b>Customer</b> shall be forewarned
        of any such outage, as well as its approximate duration.
      </p>
      <h4>P. MODIFICATIONS, ADAPTATIONS AND NEW VERSIONS OF THE PLATFORM</h4>
      <p>
        The <b>Supplier</b> reserves the right to alter, adapt or update the
        Platform’s characteristics and conditions at any time and in any way,
        where this is deemed to be beneficial to the service. In such
        circumstances, it will be sufficient that the <b>Customer</b> is
        notified via the Platform or by email, with the aforesaid modifications
        and updates duly forming a part of this contract.
      </p>
      <p>
        If, as a consequence of the <b>Supplier</b>’s ongoing development or due
        to technical and technological advances, changes are made to the service
        offered, if the <b>Supplier</b> deems it opportune it will implement the
        modifications needed to adapt to these advances.
      </p>
      <p>
        In both cases, the <b>Supplier</b> shall notify the <b>Customer</b> as
        to the changes that might affect them. Once the <b>Supplier</b> has
        provided notification of the aforementioned changes, adaptations or
        updates, the <b>Customer</b> shall have seven days in which to cancel
        the contract if they do not agree to the new circumstances. Once this
        period has elapsed and no notification of cancellation given, the{' '}
        <b>Customer</b> shall be understood to have accepted the new conditions.
      </p>
      <p>
        The <b>Supplier</b> shall make the aforesaid modifications diligently
        and in good faith, representing a benefit not only to the development of
        the new technologies but also to the operations of the company and the
        service offered through this contract in such a way that it does not
        prejudice the interest of its customers as a whole.
      </p>
      <p>
        For the duration of this contract, the <b>Customer</b> shall pay the{' '}
        <b>Supplier</b> the amount stipulated in the accompanying commercial
        offer. These sums shall be subject to Value Added Tax and any other
        taxes that may be applicable.
      </p>
      <h3>4. PRICE AND PAYMENT</h3>
      <p>
        For the duration of this contract, the <b>Customer</b> shall pay the{' '}
        <b>Supplier</b> the amount stipulated in the accompanying commercial
        offer. These sums shall be subject to Value Added Tax and any other
        taxes that may be applicable.
      </p>
      <p>
        Payments shall be made via:
        <ul>
          <li>
            Direct debit to the current account indicated by the <b>Customer</b>{' '}
            (of which they are the account holder), the details of which shall
            be provided in the SEPA document, duly signed and presented by the{' '}
            <b>Customer</b>.
          </li>
          <li>Bank transfer.</li>
          <li>
            Via online payment, where available, to be made by the{' '}
            <b>Customer</b> through the platform provided by the <b>Supplier</b>{' '}
            on its website.
          </li>
        </ul>
      </p>
      <p>
        Once payment has been verified, the <b>Supplier</b> shall email the{' '}
        <b>Customer</b> confirming that the contracting process has been
        successful.
      </p>
      <p>
        The prices stipulated by the <b>Supplier</b> may be subject to review.
        If the <b>Supplier</b> introduces changes to the price of the hosting
        service, the <b>Customer</b> will be notified via email one month prior
        to the change coming into effect. This shall be reflected in the next
        invoice issued, in such a way that if the latter party does not agree to
        accept the change in question, they may exercise their right to
        terminate this contract without the need to pay any additional sum to
        the <b>Supplier</b> for as long as the corresponding instalment has been
        paid. At the end of the advance payment period, the contract will be
        terminated, with the corresponding cancellation of their service if the
        new price is not accepted.
      </p>
      <p>
        The <b>Supplier</b> reserves the right to temporarily suspend the
        service in the event of any payment, duly notifying the <b>Customer</b>{' '}
        in advance in writing or by email of such a decision. If the{' '}
        <b>Customer</b> does not make payment within 15 days of the temporary
        suspension, this contract will be terminated, with the corresponding
        cancellation of the provided service for breach of contract. After 30
        days have passed subsequent to the suspension of the service, the{' '}
        <b>Supplier</b> shall delete all information, as set forth in Section K.
        ENTRY INTO FORCE, DURATION AND EXTENSION OF THE CONTRACT in these
        conditions of use.
      </p>
      <h3>5. REPLACEMENT OF THE PARTIES</h3>
      <p>
        All the <b>Customer</b>’s rights and obligations stemming from this
        contract are non-transferable. The <b>Customer</b> may not divest them
        or transfer them to any third party, either wholly or in part, without
        prior written authorisation from the <b>Supplier</b>.
      </p>
      <p>
        The Supplier reserves the right to transfer its website to another
        supplier, duly notifying the Customer in writing in the event of the
        Supplier’s withdrawal or that of the provision of this service.
      </p>
      <h3>6. RESPONSIBILITY</h3>
      <ol>
        <li>
          The <b>Customer</b> is responsible for uploading its information to
          the platform. Under no circumstances shall the <b>Supplier</b> accept
          liability for any loss of data, interruption of business or any
          problems arising from operation of the platform or as a result of the
          platform not meeting the <b>Customer</b>’s expectations.
        </li>
        <li>
          The <b>Supplier</b> shall not be liable for the information stored at
          the <b>Customer</b>’s request where it does not have sufficient
          awareness that the activity or information is unlawful or that it
          damages the property or rights of a third party that may result in
          indemnification. Where the <b>Supplier</b> has this effective
          knowledge, it is obliged to act diligently, deleting data or
          preventing access thereto, when an appropriate body has declared the
          unlawfulness of the data, ordering its removal, where access thereto
          is impossible or where the existence of damages has been declared and
          the <b>Supplier</b> is aware of the corresponding decision or of other
          channel of effective knowledge that might exist. Similarly, the{' '}
          <b>Supplier</b> may access devices that automatically create content
          to inspect the nature thereof.
        </li>
        <li>
          With regard to service availability, the <b>Supplier</b> shall be held
          harmless from any liability in all situations which may result from
          the <b>Customer</b>’s network connections, the hardware and software
          it may use, the <b>Customer</b>’s incorrect use of the service and any
          other matters that are not the responsibility of the <b>Supplier</b>.
        </li>
        <li>
          The Supplier shall be held harmless from any liability, whether direct
          or indirect, for consequential damages or lost profits arising from
          the misuse of the services or for ends other than those for which
          DIESELPLUS is intended. The use of the communications module (which is
          the property of the Supplier) as defined in the Specific Conditions
          set forth in ANNEX 1 for illegal or unauthorised purposes is expressly
          prohibited. Specifically, this shall refer to any breach of
          third-party rights (the right to intimacy, honour, to one’s own image,
          to privacy of communications, to intellectual and industrial property,
          to data protection etc.) The Supplier may, at its sole discretion and
          without any right to claim subsequent compensation, immediately
          deactivate the communications module.
        </li>
        <li>
          The <b>Supplier</b>’s liability with regard to the obligations covered
          by these Conditions shall be subject to the following limitations:
          <ol>
            <li>
              The responsibility of the <b>Supplier</b> for the concepts derived
              from the platform use licence shall not exceed the amount
              equivalent agreed upon in these Conditions under any
              circumstances. In the case of instalment payments, this shall be
              the lesser amount between the annual price and the sum of the
              payments duly made for the service taken from the contract start
              date, excluding in all cases taxes and eventual additional charges
              and costs beyond the agreed price. Under no circumstances may the{' '}
              <b>Customer</b> claim any form of compensation from the Supplier
              for damages or losses which may be deemed to be derived damages,
              loss of revenue, loss of business, loss of commercial reputation
              or third-party claims made against the <b>Customer</b> or for any
              other similar damages.
            </li>
            <li>
              With regard to breaches of contract arising from the development
              of functions or services by companies sub-contracted by the{' '}
              <b>Supplier</b>, the latter’s total liability shall not exceed the
              total amount that it may obtain, pursuant to any contract that it
              may have entered into, for the non-compliance in question, of the
              sub-contracted third party responsible for providing the service
              affected by the breach of contract.
            </li>
          </ol>
        </li>
      </ol>
      <h3>7. RESOLUTION</h3>
      <p>
        As well as the general grounds for contract termination set forth in the
        legislative framework, both parties agree to consider the following as
        grounds for termination:
        <ol>
          <li>By mutual agreement between both parties.</li>
          <li>
            Where, at the commencement of the contract, the Customer withdraws
            from the contract during the trial period.
          </li>
          <li>
            A breach of any of the <b>Customer</b>’s obligations, albeit partial
            and for any reason. In such cases, the Supplier reserves the right
            to move to early termination of this contract and therefore deny the{' '}
            <b>Customer</b> their contracted service without giving prior notice
            and without the latter party having redress to compensation or the
            refund of any amount paid.
          </li>
          <li>
            The <b>Customer</b> causing the <b>Supplier</b>, its customers or
            employees any inconvenience or damage during the performance of this
            contract.
          </li>
          <li>The lapse of the contractual or extension period.</li>
          <li>
            <b>Customer</b> non-payment.
          </li>
          <li>
            The replacement or subrogation by a third party in the position that
            the <b>Customer</b> occupies in the contract without the
            authorisation set forth in Clause Five.
          </li>
          <li>
            In general, any defective performance or breach of contract by the{' '}
            <b>Customer</b> of the obligations set forth in this contract or its
            Annexes, be it partial or total.
          </li>
        </ol>
      </p>
      <p>
        In the event of cancellation or termination of the contract as a result
        of the reasons cited above or any other grounds admissible under law,
        the <b>Customer</b> shall comply with their obligations to the{' '}
        <b>Supplier</b> or third parties as assumed prior to this cancellation
        or termination.
      </p>
      <p>
        In any of the grounds for termination, the <b>Supplier</b> shall only be
        required to notify the <b>Customer</b> in writing, without prejudice to
        that agreed to in Clauses Eight and Nine.
      </p>
      <h3>8. DEFAULT INTEREST</h3>
      <p>
        In the event that the <b>Customer</b> is in breach of their payment
        obligation as provided for in Clause Four herein or is in arrears in the
        payment of any sum arising from the performance of this contract and
        especially its cancellation, shall be automatically liable to default
        interest from the day on which payment was due, without the need for any
        action on the part of the <b>Supplier</b>.
      </p>
      <p>
        In such cases, the sums owed the <b>Customer</b> shall accrue default
        interest which will be calculated on a daily basis at the legal interest
        rate applicable at any moment plus four points.
      </p>
      <p>
        The parties to this contract agree that the unpaid default interest
        shall in turn accrue interest after 90 days, without further
        notification, resulting in default interest at the rate outlined in the
        foregoing paragraph.
      </p>
      <h3>9. CONFIDENTIALITY </h3>
      <p>
        All information and documentation that one party supplies to the other
        in the performance of this contract shall be considered to be
        confidential and exclusive the party providing it and may not be passed
        on to third parties without their consent. Similarly, the parties agree
        to the confidential nature of this contract. None of its content may be
        disclosed to third parties without the express consent of both parties.
        This obligation of confidentiality shall continue for a year after the
        conclusion of this contract. Neither of both parties shall acquire any
        right with regard to any confidential information or other property
        rights resulting from this contract.
      </p>
      <h3>10. PERSONAL DATA PROTECTION POLICY </h3>
      <ol>
        <p>
          The <b>Supplier</b> hereby gives notification that personal data is
          gathered and processed for the purposes of the development, control
          and execution of the contractual relationship, which, within the
          framework of the services as identified in the purpose of the
          contract, is maintained by the <b>Supplier</b>. This processing shall
          be exclusively undertaken for the performance of the contract, with
          the data retained for the duration of the relationship with the
          Customer. Once that has concluded, it may be retained for the legally
          established period while there may still be responsibilities binding
          both parties. Information shall not be disclosed to third parties,
          except where there is a legal obligation or judicial requirement to do
          so.
        </p>
        <p>
          The <b>Supplier</b> has implemented all the technical and
          organisational means necessary to guarantee the security, integrity,
          confidentiality and resilience thereof, pursuant to Spain’s Protection
          of Personal Data and Guarantee of Digital Rights Act, Organic Law
          3/2018 of 5 December 2018 (hereafter the Digital Rights Act) and
          Regulation (EU) 2016/679 of the European Parliament and of the Council
          of 27 April 2016 (hereafter GDPR).
        </p>
        <p>
          The <b>Customer</b> may exercise their rights of access,
          rectification, deletion and portability of their data and limitation
          and opposition to its processing by writing to{' '}
          <b>TOT COMERCIAL, S.A.</b>, Partida Horta Amunt s/n, 25600 Balaguer or
          by sending an email to{' '}
          <a href="mailto:lopd@gespasa.es">lopd@gespasa.es</a>. If it is felt
          that the processing of personal data does not comply with currently
          applicable law, the Customer may present a claim before the
          supervisory authority (
          <a target="_blank" href="https://www.aepd.es">
            www.aepd.es
          </a>
          ).
        </p>
        <p>
          Where the <b>Customer</b> includes personal information on shared
          hosting servers, pursuant to Article 28 of the GDPR, the{' '}
          <b>Supplier</b> acts as a data processor and is therefore obliged to
          handle all data in accordance with the Customer’s instructions. As
          such, the <b>Supplier</b> shall not apply nor use any data for
          purposes other than the provision of the contracted service, nor
          disclose it to third parties, even for storage purposes, except at the{' '}
          <b>Customer</b>’s express request. The duly signed data processing
          contract, which shall be signed by the <b>Supplier</b> and the
          Customer (the data controller), accompanies this contract as ANNEX 4.
          Any indication by the data controller regarding the implementation of
          special security measures on the server will require the Supplier to
          be contacted in order to regulate the new conditions of use where
          necessary and shall therefore not be covered by this contract.
        </p>
        <p>
          The <b>Supplier</b> shall be exempt from any liability as a result of
          any breach of the Digital Rights Act or GDPR by the <b>Customer</b>{' '}
          with regard to the activities that correspond to them and which are
          related to the performance of this contract. Each party shall not be
          liable for any failure on the part of the counterparty to comply with
          these regulations.
        </p>
        <p>
          The <b>Customer</b> declares that all the information they have
          provided is correct and true and undertakes to maintain it so,
          notifying the <b>Supplier</b> where necessary. The <b>Customer</b>{' '}
          shall answer as to the veracity of their data and shall be the sole
          party responsible in the event of any conflict or litigation that may
          result from any falsehood.
        </p>
        <p>
          The <b>Customer</b> hereby gives their express consent for the{' '}
          <b>Supplier</b> to outsource certain services to third-party companies
          where it deems it necessary. In all cases, a contract shall be signed
          by data processor and the sub-contracted company.
        </p>
      </ol>
      <p>
        For further information regarding our privacy policy, go to{' '}
        <a href="https://www.dieselplus.net/privacy-policy">
          https://www.dieselplus.net/privacy-policy
        </a>
      </p>
      <h3>11. NULLITY</h3>
      <p>
        If any clause of this Contract is declared wholly or partly null and
        void by any court or competent authority, all other clauses shall
        maintain their full validity, except where the parties decide to cancel
        this contract.
      </p>
      <h3>12. APPLICABLE LEGISLATION AND COMPETENT COURTS</h3>
      <p>
        Any matter not covered by this contract and the interpretation or
        resolution of any disputes or conflicts arising between both parties as
        a result thereof shall be resolved pursuant to Spanish law.
      </p>
      <p>
        Should there be any discrepancy between both parties regarding the
        interpretation and execution of this contract which cannot be resolved
        by mutual agreement, the parties shall submit themselves to the
        jurisdiction and competence of the courts and tribunals of the city of
        Lleida, expressly waiving any other jurisdiction that may be applicable
        to them.
      </p>
      <h3>
        ANNEX 1. A) SPECIFIC CONDITIONS APPLICABLE TO THE COMMUNICATIONS SERVICE
        FOR COMPATIBLE CONTROLLERS
      </h3>
      <p>
        The contracting of the platform, which shall be governed by the
        agreements, terms and conditions set forth herein and these specific
        conditions, regulate the module that provides the DIESELPLUS
        communications and access service for compatible controllers, allowing
        the CUSTOMER to be able to use and administer compatible controller
        equipment via the Platform. If so specified in the commercial offer,
        this service shall include the cost of communications, depending on the
        storage capacity of the compatible controller. Contracting this licence
        provides access to three platform accounts. These accounts can be
        extended to three more for each contracted licence.
      </p>
      <h4>Definitions</h4>
      <p>
        Working day: Working days are defined as the week from Monday to Friday,
        except public holidays and those days that correspond to holiday leave.
        The Autonomous Community of Catalonia shall be taken as the point of
        reference in Spain with regard to public holidays.
        <br />
        Calendar day: Calendar days are any days of the week, whether they are
        holidays of not.
        <br />
        Communications module: Elements that are the property of and activated
        by the Supplier. They are used exclusively for communication with
        DIESELPLUS. In the case of communication via the mobile network (2G, 3G,
        4G, 5G), the SIM card provided and activated by the Supplier is
        included.
      </p>
      <h4>Service provision and availability</h4>
      <p>
        The Supplier undertakes to provide the service requested by the{' '}
        <b>Customer</b> within a maximum of ten working days taken from the
        signing of the contract, where the technical conditions set forth herein
        are met and the Customer has correctly provided the activation
        information required by the Supplier.
      </p>
      <p>
        The Customer shall not have the right to demand service start-up if:
        <ul>
          <li>
            The delay is the result of causes beyond the Supplier’s control
          </li>
          <li>
            The Customer has requested a modification after the start of the
            contract
          </li>
          <li>The Customer’s equipment is not of the required standard</li>
          <li>
            The Supplier or the equipment compatible with DIESELPLUS cannot
            access the Customer’s equipment on the date and at the time agreed
            upon by both parties, or the Customer has not obtained the permits
            required by the domain location.
          </li>
        </ul>
      </p>
      <p>
        The availability guaranteed by the <b>Supplier</b> is dependent on the
        Customer meeting the operational requirements established in the
        technical conditions set forth herein that ensure access to the Platform
        and communication with DIESELPLUS-compatible control equipment. The
        Supplier cannot guarantee service availability in the event of incidents
        beyond its control.
      </p>
      <img src={imageSrc} />
      <p>
        Where:
        <ul>
          <li>
            DDS: Measuring range days in which the service is not fully down on
            working days, without taking into consideration the duration of the
            programmed downtime of which the CUSTOMER has been notified, cuts
            due to a service modification explicitly requested by the CUSTOMER
            and service drop outs beyond the Supplier’s control.
          </li>
          <li>DT: Measuring range of service availability in working days.</li>
        </ul>
        The SUPPLIER guarantees an annual availability of 95%, with the DT
        measuring range corresponding to working days, as defined in the
        Definitions section. If the annual availability is lower, the CUSTOMER
        shall have the right to seek compensation. The compensation to be
        applied will be as follows: 5% of the annual charge in the event of
        90%-95% availability, 10% of the annual charge in the event of 80%-90%
        availability and 15% the event of availability below 80%.
        <br />
        The maximum compensation payable will be 15% of the annual charge.
        Compensation will be made via a single payment in the next invoicing,
        appearing therein as “Compensation: service unavailability”.
      </p>
      <h4>Technical specifications </h4>
      <p>
        ANNEX 2 sets forth the technical specifications for platform access and
        the use and installation of associated applications.
      </p>
      <p>
        Communication requirements:
        <ul>
          <li>
            The CUSTOMER should have a DIESELPLUS-compatible and -approved
            controller.
          </li>
          <li>
            The aforesaid equipment should be installed according to the
            manufacturer’s and DIESELPLUS instructions as detailed below.
          </li>
          <li>
            The control equipment should be SAI Online electrically protected.
          </li>
          <li>
            In the case of communication via a 2G, 3G or 4G network and
            depending on the model, equipment should be installed in an area
            with sufficient network coverage and checked by the technician
            during installation.
          </li>
          <li>
            In the case of communication via Ethernet, the CUSTOMER shall be
            responsible for ensuring internet access within their network
            infrastructure. The CUSTOMER’s network administrator shall reserve a
            fixed public IP address for the device that will redirect external
            access of the TCP ports requested by the Supplier to ensure that the
            equipment is remotely accessible.
          </li>
          <li>
            The equipment should be permanently connected to the power supply to
            ensure that communication status can be monitored by DIESELPLUS.
          </li>
          <li>
            The electronics of the control equipment used in communications may
            only be handled by personnel authorised by the Supplier. Any
            unauthorised handling of the communications equipment shall imply
            the immediate cancellation of the service guarantee contracted here,
            with the Supplier invoicing the CUSTOMER for damages and repair
            costs.
          </li>
          <li>
            In the case of communication via a 2G, 3G or 4G network, controller
            equipment should feature a communications module, as defined in the
            Definitions section. Where this module is the property of the
            SUPPLIER, it shall be pre-installed on the controller and activated
            once the contract has been formalised.
          </li>
        </ul>
      </p>
      <p>
        Customers who have acquired DIESELPLUS-related equipment which is
        outside the scope of this contract should comply with the following
        regulations when jointly using the DIESELPLUS communications and access
        service for compatible controllers, as well all general regulations set
        forth in the annex and the general conditions:
        <ul>
          <li>
            The CUSTOMER may not alter or handle the communications module nor
            the part of the equipment in which it is located. Neither may they
            connect other communications modules to the equipment, use the
            pre-installed communications module on other equipment or use it for
            other non-DIESELPLUS purposes.
          </li>
          <li>
            The CUSTOMER shall be responsible for conditioning and ensuring
            compliance with all minimum requirements in good time, including the
            power supply to the location in which the equipment is to be
            installed.
          </li>
          <li>
            The CUSTOMER undertakes to provide access to the equipment to the
            Supplier’s staff where required for the purposes of installation,
            maintenance and removal of the communications module where necessary
            on cancellation of the service.
          </li>
          <li>
            The CUSTOMER shall be subject to all applicable legal obligations as
            well as those outlined herein.
          </li>
          <li>
            DIESELPLUS is recognised as being suitable for in-house consumption.
          </li>
          <li>
            The supplier or its authorised technicians shall install, start up,
            maintain and administer the communications modules.
          </li>
          <li>
            The supplier shall be responsible for the activation of the service
            and communications costs where it is the owner of the communications
            module and there has been no inappropriate use of the equipment or
            exceeding of the limits, as outlined in the Inappropriate use of the
            communications module section.
          </li>
          <li>
            The supplier shall carry out all its interventions on working days
            except where, due to an emergency or force majeure, immediate
            intervention is required.
          </li>
        </ul>
      </p>
      <h4>Inappropriate use of the communications module</h4>
      <p>
        The following is deemed to be inappropriate use of the communications
        module:
        <ul>
          <li>
            Where incorrect use or handling of the communications module is
            detected. Excess consumption will be invoiced along with any
            possible damages caused.
          </li>
          <i>
            Communication limits are exceeded. In the case of communications via
            the 2G, 3G or 4G mobile network, if the CUSTOMER passes the limit of
            5MB/month, the excess will be invoiced.
          </i>
        </ul>
      </p>
      <p>
        Where inappropriate use is detected, excess consumption or inappropriate
        use will be invoiced on the basis of the payment details provided during
        contracting. This shall be paid at the moment that it is detected,
        without renewal of the contract being required.
      </p>
      <h3>
        ANNEX 1. B) SPECIFIC CONDITIONS APPLICABLE TO THE COMMUNICATIONS SERVICE
        FOR TANK MODEM PLUS-COMPATIBLE LEVEL SENSORS
      </h3>
      <p>
        The contracting of the platform, which shall be governed by the
        agreements, terms and conditions set forth herein and these specific
        conditions, regulate the module that provides the DIESELPLUS
        communications and access service for TANK MODEM PLUS-compatible level
        sensors allowing the CUSTOMER to be able to use and administer
        DIESELPLUS-compatible level sensors via the Platform. If so specified in
        the commercial offer, this service shall include the cost of
        communications. The contracting of this licence provides access to three
        platform accounts. These accounts can be extended to three more for each
        contracted licence.
      </p>
      <h4>Definitions</h4>
      <p>
        Working day: Working days are defined as the week from Monday to Friday,
        except public holidays and those days that correspond to holiday leave.
        The Autonomous Community of Catalonia shall be taken as the point of
        reference in Spain with regard to public holidays.
        <br />
        Calendar day: Calendar days are any days of the week, whether they are
        holidays of not.
        <br />
        Communications module: Elements that are the property of and activated
        by the Supplier. They are used exclusively for communication with
        DIESELPLUS. In the case of communication via the mobile network (2G, 3G,
        4G, 5G), the SIM card provided and activated by the Supplier is
        included.
      </p>
      <h4>Service provision and availability</h4>
      <p>
        The Supplier undertakes to provide the service requested by the Customer
        within a maximum of ten working days taken from the signing of the
        contract, where the technical conditions set forth herein are met and
        the Customer has correctly provided the activation information required
        by the Supplier.
      </p>
      <p>
        The Customer shall not have the right to demand service start-up if:
        <ul>
          <li>
            The delay is the result of causes beyond the Supplier’s control
          </li>
          <li>
            The Customer has requested a modification after the start of the
            contract
          </li>
          <li>The Customer’s equipment is not of the required standard</li>
          <li>
            The Supplier or the equipment compatible with DIESELPLUS cannot
            access the Customer’s equipment on the date and at the time agreed
            upon by both parties, or the Customer has not obtained the permits
            required by the domain location.
          </li>
        </ul>
      </p>
      <p>
        The availability guaranteed by the <b>Supplier</b> is dependent on the
        Customer meeting the operational requirements established in the
        technical conditions set forth herein that ensure access to the Platform
        and communication with DIESELPLUS-compatible control equipment. The
        Supplier cannot guarantee service availability in the event of incidents
        beyond its control.
      </p>
      <img src={imageSrc} />
      <p>
        Where:
        <ul>
          <li>
            DDS: Measuring range days in which the service is not fully down on
            working days, without taking into consideration the duration of the
            programmed downtime of which the CUSTOMER has been notified, cuts
            due to a service modification explicitly requested by the CUSTOMER
            and service drop outs beyond the Supplier’s control.
          </li>
          <li>DT: Measuring range of service availability in working days.</li>
        </ul>
        The SUPPLIER guarantees an annual availability of 95%, with the DT
        measuring range corresponding to working days, as defined in the
        Definitions section. If the annual availability is lower, the CUSTOMER
        shall have the right to seek compensation. The compensation to be
        applied will be as follows: 5% of the annual charge in the event of
        90%-95% availability, 10% of the annual charge in the event of 80%-90%
        availability and 15% the event of availability below 80%.
        <br />
        The maximum compensation payable will be 15% of the annual charge.
        Compensation will be made via a single payment in the next invoicing,
        appearing therein as “Compensation: service unavailability”.
      </p>
      <h4>Technical specifications</h4>
      <p>
        ANNEX 2 sets forth the technical specifications for platform access and
        the use and installation of associated applications.
      </p>
      <p>
        Communication requirements:
        <ul>
          <li>The customer has TANK MODEM PLUS equipment.</li>
          <li>
            The CUSTOMER has a TANK MODEM PLUS-compatible and -approved level
            sensor for use on equipment and accessories that are appropriate in
            terms of communication with DIESELPLUS.
          </li>
          <li>
            The aforesaid equipment should be installed according to the
            manufacturer’s and DIESELPLUS instructions as detailed below.
          </li>
          <li>
            The control equipment should be SAI Online electrically protected.
          </li>
          <li>
            In the case of communication via a 2G, 3G or 4G network and
            depending on the model, equipment should be installed in an area
            with sufficient network coverage and checked by the technician
            during installation.
          </li>
          <li>
            The equipment should be permanently connected to the power supply to
            ensure that communication status can be monitored by DIESELPLUS.NET.
          </li>
          <li>
            The equipment electronics used in communications may only be handled
            by personnel authorised by the Supplier. Any unauthorised handling
            of the communications equipment shall imply the immediate
            cancellation of the service guarantee contracted here, with the
            Supplier invoicing the CUSTOMER for damages and repair costs.
          </li>
          <li>
            In the case of communication via a 2G, 3G or 4G network, controller
            equipment should feature a communications module, as defined in the
            Definitions section. Where this module is the property of the
            SUPPLIER, it is pre-installed on the controller and activated once
            the contract has been formalised.
          </li>
        </ul>
      </p>
      <p>
        Customers who have acquired DIESELPLUS-related equipment which is
        outside the scope of this contract should comply with the following
        regulations when jointly using the DIESELPLUS communications and access
        service for TANK MODEM PLUS-COMPATIBLE SENSORS, as well all general
        regulations set forth in the annex and the general conditions:
        <ul>
          <li>
            The CUSTOMER may not alter or handle the communications module nor
            the part of the equipment in which it is located. Neither may they
            connect other communications modules to the equipment, use the
            pre-installed communications module on other equipment or use it for
            other non-DIESELPLUS purposes.
          </li>
          <li>
            The CUSTOMER shall be responsible for conditioning and ensuring
            compliance with all minimum requirements in good time.
          </li>
          <li>
            The CUSTOMER undertakes to provide access to the equipment to the
            Supplier’s staff where required for the purposes of installation,
            maintenance and removal of the communications module where necessary
            on cancellation of the service.
          </li>
          <li>
            The CUSTOMER shall be subject to all applicable legal obligations as
            well as those outlined herein.
          </li>
          <i>
            The Supplier or its authorised technicians shall install, start up,
            maintain and administer the communications modules.
          </i>
          <li>
            The supplier shall be responsible for the activation of the service
            and communications costs where it is the owner of the communications
            module and there has been no inappropriate use of the equipment or
            exceeding of the limits, as outlined in the Inappropriate use of the
            communications module section.
          </li>
          <li>
            The Supplier shall carry out all its interventions on working days
            except where, due to an emergency or force majeure, immediate
            intervention is required.
          </li>
        </ul>
      </p>
      <h4>Inappropriate use of the communications module</h4>
      <p>
        The following is deemed to be inappropriate use of the communications
        module:
        <ul>
          <li>
            Where incorrect use or handling of the communications module is
            detected. Excess consumption will be invoiced along with any
            possible damages caused.
          </li>
          <li>
            Communication limits are exceeded. In the case of communications via
            the 2G, 3G or 4G mobile network, if the CUSTOMER passes the limit o
            5MB/month, the excess will be invoiced.
          </li>
        </ul>
      </p>
      <p>
        Where inappropriate use is detected, excess consumption or inappropriate
        use will be invoiced on the basis of the payment details provided during
        contracting. This shall be paid at the moment that it is detected,
        without renewal of the contract being required.
      </p>
      <h4>Data retention</h4>
      <p>
        In the DIESELPLUS communications and access service for TANK MODEM
        PLUS-COMPATIBLE SENSORS, the data retention period relating to the
        history of tank-level measurements will be one year for the duration of
        the validity of this contract.
      </p>
      <h3>
        ANNEX 1. C) SPECIFIC CONDITIONS APPLICABLE TO THE COMMUNICATIONS SERVICE
        FOR DOLPHIN LEVEL SENSORS
      </h3>
      <p>
        The contracting of the platform, which shall be governed by the
        agreements, terms and conditions set forth herein and these specific
        conditions, regulate the module that provides the DIESELPLUS
        communications and access service for DOLPHIN level sensors, allowing
        the CUSTOMER to be able to use and administer such level sensors via the
        Platform. If so specified in the commercial offer, this service shall
        include the cost of communications according to the DOLPHIN level sensor
        model. The contracting of this licence provides access to three platform
        accounts. These accounts can be extended to three more for each
        contracted licence.
      </p>
      <h4>Definitions</h4>
      <p>
        Working day: Working days are defined as the week from Monday to Friday,
        except public holidays and those days that correspond to holiday leave.
        The Autonomous Community of Catalonia shall be taken as the point of
        reference in Spain with regard to public holidays.
        <br />
        Calendar day: Calendar days are any days of the week, whether they are
        holidays of not.
        <br />
        Communications module: Elements that are the property of and activated
        by the Supplier. They are used exclusively for communication with
        DIESELPLUS. In the case of communication via the mobile network (2G, 3G,
        4G, 5G), the SIM card provided and activated by the Supplier is
        included. In the case of communication via the SIGFOX network, the
        module with the associated SIGFOX licence is included, which is also
        activated by the Supplier.
      </p>
      <h4>Service provision and availability</h4>
      <p>
        The Supplier undertakes to provide the service requested by the Customer
        within a maximum of ten working days taken from the signing of the
        contract, where the technical conditions set forth herein are met and
        the Customer has correctly provided the activation information required
        by the Supplier.
      </p>
      <p>
        The Customer shall not have the right to demand service start-up if:
        <ul>
          <li>
            The delay is the result of causes beyond the Supplier’s control
          </li>
          <li>
            The Customer has requested a modification after the start of the
            contract
          </li>
          <li>The Customer’s equipment is not of the required standard</li>
          <li>
            The Supplier or the equipment compatible with DIESELPLUS cannot
            access the Customer’s equipment on the date and at the time agreed
            upon by both parties, or the Customer has not obtained the permits
            required by the domain location.
          </li>
        </ul>
      </p>
      <p>
        The availability guaranteed by the <b>Supplier</b> is dependent on the
        Customer meeting the operational requirements established in the
        technical conditions set forth herein that ensure access to the Platform
        and communication with DIESELPLUS-compatible control equipment. The
        Supplier cannot guarantee service availability in the event of incidents
        beyond its control.
      </p>
      <img src={imageSrc} />
      <p>
        Where:
        <ul>
          <li>
            DDS: Measuring range days in which the service is not fully down on
            working days, without taking into consideration the duration of the
            programmed downtime of which the CUSTOMER has been notified, cuts
            due to a service modification explicitly requested by the CUSTOMER
            and service drop outs beyond the SERVICE PROVIDER’s control.
          </li>
          <li>DT: Measuring range of service availability in working days.</li>
        </ul>
        The SERVICE PROVIDER guarantees an annual availability of 95%, with the
        DT measuring range that corresponding to working days, as defined in the
        Definitions section. If the annual availability is lower, the CUSTOMER
        shall have the right to seek compensation. The compensation to be
        applied will be as follows: 5% of the annual charge in the event of
        90%-95% availability, 10% of the annual charge in the event of 80%-90%
        availability and 15% the event of availability below 80%.
        <br />
        The maximum compensation payable will be 15% of the annual charge.
        Compensation will be made via a single payment in the next invoicing,
        appearing therein as “Compensation: service unavailability”.
      </p>
      <h4>Technical specifications</h4>
      <p>
        ANNEX 2 sets forth the technical specifications for platform access and
        the use and installation of associated applications.
      </p>
      <p>
        Communication requirements:
        <ul>
          <li>The CUSTOMER should have a DOLPHIN level sensor.</li>
          <li>
            The aforesaid equipment should be installed according to the
            manufacturer’s and DIESELPLUS instructions as detailed below.
          </li>
          <li>
            In the case of communication via a 2G, 3G or 4G or SIGFOX network
            and depending on the model, equipment should be installed in an area
            with sufficient network coverage and checked by the technician
            during installation.
          </li>
          <li>
            The equipment should be permanently connected to the power supply to
            ensure that communication status can be monitored by DIESELPLUS.
            Maintenance and replacement of batteries is the CUSTOMER’s
            responsibility.
          </li>
          <li>
            The electronics of the control equipment used in communications may
            only be handled by personnel authorised by the Supplier. Any
            unauthorised handling of the communications equipment shall imply
            the immediate cancellation of the service guarantee contracted here,
            with the Supplier invoicing the CUSTOMER for damages and repair
            costs.
          </li>
          <li>
            In the case of communication via a 2G, 3G or 4G or SIGFOX network,
            controller equipment should feature a communications module, as
            defined in the Definitions section. Where this module is the
            property of the SUPPLIER, it is pre-installed on the controller and
            activated once the contract has been formalised.
          </li>
        </ul>
      </p>
      <p>
        Customers who have acquired DIESELPLUS-related equipment which is
        outside the scope of this contract should comply with the following
        regulations when jointly using the DIESELPLUS communications and access
        service for DOLPHIN level sensors, as well all general regulations set
        forth in the annex and the general conditions:
        <ul>
          <li>
            The CUSTOMER may not alter or handle the communications module nor
            the part of the equipment in which it is located. Neither may they
            connect other communications modules to the equipment, use the
            pre-installed communications module on other equipment or use it for
            other non-DIESELPLUS.NET purposes.
          </li>
          <li>
            The CUSTOMER shall be responsible for conditioning and ensuring
            compliance with all minimum requirements in good time.
          </li>
          <li>
            The CUSTOMER undertakes to provide access to the equipment to the
            Supplier’s staff where required for the purposes of installation,
            maintenance and removal of the communications module where necessary
            on cancellation of the service.
          </li>
          <li>
            The CUSTOMER shall be subject to all applicable legal obligations as
            well as those outlined herein.
          </li>
          <li>
            The Supplier or its authorised technicians shall install, start up,
            maintain and administer the communications modules.
          </li>
          <li>
            The supplier shall be responsible for the activation of the service
            and communications costs where it is the owner of the communications
            module and there has been no inappropriate use of the equipment or
            exceeding of the limits, as outlined in the Inappropriate use of the
            communications module section.
          </li>
          <li>
            The Supplier shall carry out all its interventions on working days
            except where, due to an emergency or force majeure, immediate
            intervention is required.
          </li>
        </ul>
      </p>
      <h4>Inappropriate use of the communications module</h4>
      <p>
        The following is deemed to be inappropriate use of the communications
        module:
        <ul>
          <li>
            Where incorrect use or handling of the communications module is
            detected. Excess consumption will be invoiced along with any
            possible damages caused.
          </li>
          <li>
            Communication limits are exceeded. In the case of communications via
            the 2G, 3G or 4G mobile network, if the CUSTOMER passes the limit o
            5MB/month, the excess will be invoiced. Where communications via the
            SIGFOX network, the limit will be 25 messages downloaded per day.
          </li>
        </ul>
      </p>
      <p>
        Where inappropriate use is detected, excess consumption or inappropriate
        use will be invoiced on the basis of the payment details provided during
        contracting. This shall be paid at the moment that it is detected,
        without renewal of the contract being required.
      </p>
      <h4>Data retention</h4>
      <p>
        In the DIESELPLUS communications and access service for DOLPHIN sensors,
        the data retention period relating to the history of tank-level
        measurements will be one year for the duration of the validity of this
        contract.
      </p>
      <h3>
        ANNEX 1. D) SPECIFIC CONDITIONS APPLICABLE TO THE INTEGRATION SERVICE
        FOR COMPATIBLE THIRD-PARTY GEOLOCATION APPLICATIONS
      </h3>
      <p>
        The contracting of the platform, which shall be governed by the
        agreements, terms and conditions set forth herein and these specific
        conditions, regulate the module that provides the DIESELPLUS integration
        and access service for compatible third-party geolocation applications,
        allowing the CUSTOMER to be able to use and administer such information
        via the Platform. This licence does not include geolocation application
        licences nor any direct or indirect costs. The contracting of this
        licence provides access to three platform accounts. These accounts can
        be extended to three more for each contracted licence.
      </p>
      <h4>
        Information that can be integrated into the compatible third-party
        geolocation application platform.
      </h4>
      <p>
        The information that can be integrated will depend on the geolocation
        application.
        <ul>
          <li>The odometer at the time of refuelling.</li>
        </ul>
      </p>
      <p>
        The <b>Supplier</b> cannot guarantee the validity or availability of the
        integrated information, with this information the responsibility of the
        Customer and covered by licence and/or agreement with the title holder
        of the compatible third-party geolocation application. Under no
        circumstances may the <b>Customer</b> claim any compensation from the{' '}
        <b>Supplier</b> for damages and losses resulting from the existence of
        invalid information from the Customer’s external geolocation
        applications.
      </p>
      <p>
        The <b>Supplier</b> shall be exempt from any obligation to notify the
        customer’s staff or users that geolocation information is being
        gathered. This will be the responsibility of the <b>customer</b>{' '}
        contracting the service.
      </p>
      <h4>Definitions</h4>
      <p>
        Working day: Working days are defined as the week from Monday to Friday,
        except public holidays and those days that correspond to holiday leave.
        The Autonomous Community of Catalonia shall be taken as the point of
        reference in Spain with regard to public holidays.
        <br />
        Calendar day: Calendar days are any days of the week, whether they are
        holidays of not.
      </p>
      <h4>Service provision and availability</h4>
      <p>
        The Supplier undertakes to provide the service requested by the Customer
        within a maximum of ten working days taken from the signing of the
        contract, where the technical conditions set forth herein are met and
        the Customer has correctly provided the activation information required
        by the Supplier.
      </p>
      <p>
        The Customer shall not have the right to demand service start-up if:
        <ul>
          <li>
            The delay is the result of causes beyond the Supplier’s control
          </li>
          <li>
            The Customer has requested a modification after the start of the
            contract
          </li>
        </ul>
      </p>
      <p>
        The availability guaranteed by the <b>Supplier</b> is dependent on the
        Customer meeting the operational requirements established in the
        technical conditions set forth herein that ensure access to the Platform
        and integration with the compatible geolocation applications service.
        The Supplier cannot guarantee service availability in the event of
        incidents beyond its control.
      </p>
      <img src={imageSrc} />
      <p>
        Where:
        <ul>
          <li>
            DDS: Measuring range days in which the service is not fully down on
            working days, without taking into consideration the duration of the
            programmed downtime of which the CUSTOMER has been notified, cuts
            due to a service modification explicitly requested by the CUSTOMER
            and service drop outs beyond the Supplier’s control.
          </li>
          <li>DT: Measuring range of service availability in working days.</li>
        </ul>
        The SUPPLIER guarantees an annual availability of 95%, with the DT
        measuring range corresponding to working days, as defined in the
        Definitions section. If the annual availability is lower, the CUSTOMER
        shall have the right to seek compensation. The compensation to be
        applied will be as follows: 5% of the annual charge in the event of
        90%-95% availability, 10% of the annual charge in the event of 80%-90%
        availability and 15% the event of availability below 80%.
        <br />
        The maximum compensation payable will be 15% of the annual charge.
        Compensation will be made via a single payment in the next invoicing,
        appearing therein as “Compensation: service unavailability”.
      </p>
      <h4>Technical specifications</h4>
      <p>
        Annex 2 sets forth the technical specifications for platform access and
        the use and installation of associated applications.
      </p>
      <p>
        Integration requirements:
        <ul>
          <li>
            The CUSTOMER shall have the up-to-date licences required to use the
            DIESELPLUS third-party compatible geolocation application as
            approved by the Supplier.
          </li>
          <li>
            Integration with DIESELPLUS takes place through the public API of
            the compatible geolocation application. To gain access, the CUSTOMER
            should have the username and password for an account that allows
            access to the API and the necessary read permissions for the
            information to be integrated.
          </li>
        </ul>
      </p>
      <h3>
        ANNEX 1. E) SPECIFIC CONDITIONS APPLICABLE TO THE API DIESELPLUS ACCESS
        SERVICE FOR COMPATIBLE CONTROLLERS FOR INTEGRATION WITH THIRD-PARTY
        APPLICATIONS
      </h3>
      <p>
        The contracting of the platform, which shall be governed by the
        agreements, terms and conditions set forth herein and these specific
        conditions, regulate the module that provides the API DIESELPLUS access
        service for integration with third-party applications to be able to
        automate external third-party application incorporation within the
        Platform.
      </p>
      <p>
        Contracting this licence provides access to one platform account. These
        accounts can be extended to one more for each contracted licence. This
        account is for the exclusive use of access via API.
      </p>
      <h4>Definitions</h4>
      <p>
        Working day: Working days are defined as the week from Monday to Friday,
        except public holidays and those days that correspond to holiday leave.
        The Autonomous Community of Catalonia shall be taken as the point of
        reference in Spain with regard to public holidays.
        <br />
        Calendar day: Calendar days are any days of the week, whether they are
        holidays of not.
      </p>
      <h4>Service provision and availability</h4>
      <p>
        The Supplier undertakes to provide the service requested by the Customer
        within a maximum of ten working days taken from the signing of the
        contract, where the technical conditions set forth herein are met and
        the Customer has correctly provided the activation information required
        by the Supplier.tos solicitados por el Proveedor a la activación del
        presente contrato.
      </p>
      <p>
        The Customer shall not have the right to demand service start-up if:
        <ul>
          <li>
            The delay is the result of causes beyond the Supplier’s control
          </li>
          <li>
            The Customer has requested a modification after the start of the
            contract
          </li>
        </ul>
      </p>
      <p>
        The availability guaranteed by the <b>Supplier</b> is dependent on the
        Customer meeting the operational requirements established in the
        technical conditions set forth herein that ensure access to the Platform
        and communication with DIESELPLUS-compatible control equipment. The
        Supplier cannot guarantee service availability in the event of incidents
        beyond its control.
      </p>
      <img src={imageSrc} />
      <p>
        Where:
        <ul>
          <li>
            DDS: Measuring range days in which the service is not fully down on
            working days, without taking into consideration the duration of the
            programmed downtime of which the CUSTOMER has been notified, cuts
            due to a service modification explicitly requested by the CUSTOMER
            and service drop outs beyond the Supplier’s control.
          </li>
          <li>DT: Measuring range of service availability in working days.</li>
        </ul>
        The SUPPLIER guarantees an annual availability of 95%, with the DT
        measuring range corresponding to working days, as defined in the
        Definitions section. If the annual availability is lower, the CUSTOMER
        shall have the right to seek compensation. The compensation to be
        applied will be as follows: 5% of the annual charge in the event of
        90%-95% availability, 10% of the annual charge in the event of 80%-90%
        availability and 15% the event of availability below 80%.
        <br />
        The maximum compensation payable will be 15% of the annual charge.
        Compensation will be made via a single payment in the next invoicing,
        appearing therein as “Compensation: service unavailability”.
      </p>
      <h4>Technical specifications</h4>
      <p>
        ANNEX 2 sets forth the technical specifications for platform access and
        the use and installation of associated applications.
      </p>
      <p>
        Communication requirements:
        <ul>
          <li>
            The CUSTOMER shall be responsible for the development and
            maintenance of all external applications accessing the platform via
            API DIESELPLUS.
          </li>
          <li>
            The structure of DIESELPLUS calls to the API should be developed in
            line with the instructions outlined in the document and guidelines
            provided by the SUPPLIER. They shall only include the URLs defined
            in that documentation.
          </li>
          <li>
            Where not specified in the commercial offer, calls to API are
            limited to 60 per minute and per account.
          </li>
          The <b>Supplier</b> reserves the right to alter the definition the API
          definition and structures at any time and in any way, where this is
          deemed to be beneficial to the service. In such cases, the Supplier
          shall notify the <b>Customer</b> to this effect, via the Platform or
          email. The Customer shall be responsible for implementing the required
          updates to external applications with API access to DIESELPLUS to
          adapt them to the latest modifications. Under no circumstances may the
          Customer claim any compensation from the <b>Supplier</b> for damages
          and losses which might stem from the malfunction of external
          applications as a consequence of the former party not implementing the
          aforementioned changes.
        </ul>
      </p>
      <h3>
        ANNEX 2: TECHNICAL SPECIFICATIONS FOR THE USE OF PLATFORM AND THE
        INSTALLATION OF APPLICATIONS
      </h3>
      <h4>DIESELPLUS platform requirements</h4>
      <ul>
        <li>
          The CUSTOMER needs to have equipment with an internet connection and
          be free from viruses and malware.
        </li>
        <li>
          The equipment that the CUSTOMER uses to connect to the DIESELPLUS
          website requires a browser that is compatible with the specifications
          as set forth at any time on the aforesaid website. These
          specifications may vary over time due to changes introduced by the
          various browser manufacturers.
        </li>
        <li>
          The CUSTOMER needs to have all the required licences in order to use
          the software installed on their equipment that they use to access the
          DIESELPLUS platform via{' '}
          <a target="_blank" href="http://www.dieselplus.net">
            www.dieselplus.net
          </a>{' '}
          or other URLs provided by the SUPPLIER.
        </li>
      </ul>
      <p>
        The corresponding necessary specifications for each DIESELPLUS service
        module are set forth in the specific conditions in Annex I.
      </p>
      <h4>
        Requirements for the installation and use of DIESELPLUS applications
      </h4>
      <p>
        The DIESELPLUS platform may request the download and installation of
        applications that ensure that the platform can communicate with the
        computer’s peripherals.
      </p>
      <p>
        The use of these applications is subject to the licences acquired and is
        regulated in these clauses and in those that may be specified for each
        individual application. Installation implies the consent to and
        acceptance of the terms and conditions as set forth herein.
      </p>
      <ul>
        <li>
          For installation and use, the CUSTOMER needs to have computers running
          on a properly maintained and updated Windows operating system.
        </li>
        <li>
          The equipment that the CUSTOMER uses needs to have an internet
          connection and be free from viruses and malware.
        </li>
        <li>
          The CUSTOMER is responsible for maintaining and updating the
          DIESELPLUS application.
        </li>
      </ul>
      <h3>ANNEX 3: THE SUPPLIER’S SECURITY SYSTEM.</h3>
      <p>
        DIESELPLUS mainly stores its service data at AZURE data centres that
        hold conformity certification that meets the highest standards,
        including compliance with the ISO/IEC 27001 standards aimed at
        establishing good practices regarding the implementation, maintenance
        and administration of the information security system. Further
        information regarding security at physical data centres and
        infrastructures, go to{' '}
        <a
          target="_blank"
          href="https://azure.microsoft.com/es-es/explore/trusted-cloud"
        >
          https://azure.microsoft.com/es-es/explore/trusted-cloud
        </a>
      </p>
      <p>
        With regard to the data stored on local servers, the Supplier’s
        infrastructure has high levels of security
      </p>
      <h4>PHYSICAL LEVEL</h4>
      <ul>
        <li>
          Facilities:
          <ul>
            <li>Servers in a room with a controlled environment</li>
            <li>UPS units</li>
            <li>Power generators for each fuel type</li>
            <li>Air conditioning systems</li>
          </ul>
        </li>
        <li>Access control</li>
        <li>Fire detection and extinguisher system</li>
        <li>24-hour security surveillance</li>
        <li>24/7 systems monitoring</li>
        <li>
          State-of-the-art data centres for internet-connected systems and
          hosting solutions
        </li>
        <li>
          The central data centre is overseen by IT and systems engineers who
          ensure the correct operation of all services.
        </li>
        <li>
          As a result of our strict quality control policy, all systems are
          supervised through daily and weekly maintenance that ensures that all
          are available at all times.
        </li>
      </ul>
      <h4>LOGICAL LEVEL</h4>
      <p>
        The Supplier’s local infrastructure features peripheral protection
        employing routers and firewalls that ensure the highest levels of
        security and protection from any external attack.
      </p>
      <h4>BACKUP COPIES</h4>
      <p>
        Every few minutes all Platform data is backed up: These backup copies
        are encrypted and have the following characteristics:
        <ul>
          <li>
            Redundant backup copies at two different data centres in two
            different countries
          </li>
          <li>Encrypted copies at destination backup servers</li>
          <li>Duplicate copies</li>
        </ul>
      </p>
      <p>
        These measures strictly comply with all currently applicable data
        protection legislation.
      </p>
      <h4>MECHANISM FOR INCIDENT MANAGEMENT</h4>
      <p>
        To be able to foresee and streamline the resolution of incidents, the
        Supplier has the following in place:
        <ul>
          <li>Measures that prevent system attacks</li>
          <li>Audits and other regular checks</li>
          <li>Contingency and recovery plans</li>
          <li>Prioritisation of incidents</li>
        </ul>
      </p>
      <p>
        All registered incidents are assigned one of three levels of severity:
        “Critical fault”, “Non-critical fault” and “Complaint”, depending on the
        seriousness of each incident.{' '}
        <b>
          These levels refer to response time, not the resolution of the
          incident.
        </b>{' '}
        This level of priority may change once it has been assessed by our
        technical team. Incidents and requests during free module trial periods
        and demonstrations are not included.
        <ul>
          <li>
            <b>Critical fault</b>: A critical fault is one which results in the
            Customer not being able to access its DIESELPLUS data on a portal
            provided by the Supplier and which therefore requires the latter to
            launch an investigation.
          </li>
          <li>
            <b>Non-critical fault</b>: A non-critical fault is one which allows
            use of the DIESELPLUS portal but which does not reflect or receive
            the changes made to equipment that communicates with it over the
            past three calendar days and which therefore requires the Supplier
            to launch an investigation.
          </li>
          <li>
            <b>Complaint</b>: Any request made by the Customer which is neither
            a Critical fault or a Non-critical fault.
          </li>
        </ul>
      </p>
      <p>
        The customer needs to be contactable by email or mobile phone aware of
        notifications to ensure effective communication. Incident service hours
        shall be subject to the schedule of working hours and days as applicable
        in the Autonomous Community of Catalonia in Spain.
      </p>
      <h3>ANNEX 4: CONTRACTING OF THE DATA PROCESSOR</h3>
      <h4>BY AND BETWEEN</h4>
      <p>
        The party of the first part, namely the <b>Customer</b> identified at
        the start of this contract, hereafter the <b>DATA CONTROLLER</b>.
      </p>
      <p>
        The party of the second part, hereafter the <b>DATA PROCESSOR</b>,
      </p>
      <h4>THEY DECLARE</h4>
      <ol>
        <li>
          Both parties recognise the legal capacity necessary to enter into this
          contract, pursuant to Regulation (EU) 2016/679 of the European
          Parliament and of the Council of 27 April 2016 on the protection of
          natural persons with regard to the processing of personal data and on
          the free movement of such data.
        </li>
        <li>
          The DATA CONTROLLER has contracted the services of the DATA PROCESSOR
          as set forth below.
        </li>
        <li>
          The provision of the services shall be made by remote connection. To
          carry out this service, the DATA PROCESSOR may incorporate the data
          controller’s data within its systems, if so required.
        </li>
        Both parties agree to accept this Contract and be bound by the
        following:
      </ol>
      <h4>CLAUSES</h4>
      <ol>
        <li>
          <h4>Purpose of the data processing</h4>
          Through these clauses, the Supplier is authorised, as the data
          processor, to act on behalf of the data controller and shall be
          responsible for the handling and processing of the personal
          information required in order to provide the service(s) specified in
          Clause 3A.
          <br />
          The authorised processing operations shall be those that are strictly
          necessary to ensure completion of the commission including, if
          required, the collection, recording, structuring, alteration,
          retention, retrieval, consultation, disclosure by transmission,
          dissemination, connection, comparison, limitation, suppression and
          destruction of data.
        </li>
        <li>
          <h4>Identification of the information concerned</h4>
          To provide the services that are the object of this commission, the
          data controller may make the following information available to the
          data processor: See <b>ANNEX 4.1: DATA CATEGORIES</b>
        </li>
        <li>
          <h4>Duration</h4>
          This contract shall have the same duration as that established for the
          main contract.
          <br />
          On conclusion of this contract, the data processor shall either return
          all material with which it has been supplied or pass it on to another
          party appointed to the task, undertaking to delete any copy thereof in
          its possession. However, certain data may remain blocked in order to
          attend to future administrative or jurisdictional matters.
        </li>
        <li>
          <h4>The data processor's obligations</h4>
          The data processor and all its employees are obliged to:
          <ol>
            <li>
              Use the personal data being processed or the information gathered
              for inclusion, exclusively for the purposes pertaining to this
              authorisation. Under no circumstances may the data be used for the
              data processor’s own purposes.
            </li>
            <li>
              Process the data pursuant to the instructions given by the data
              controller. If the data processor considers that any instruction
              violates the GDPR or any other EU or Member State data protection
              provisions, it shall immediately inform the data controller.
            </li>
            <li>
              Maintain a written <b>record</b> of all data processing activity
              categories <b>as undertaken on behalf of the data controller</b>.
            </li>
            <li>
              <p>
                The data processor shall not pass on data to third parties,
                except with the express authorisation of the data controller in
                legally admissible circumstances.
              </p>
              <p>
                The data processor may pass on data to other processors working
                with the same data controller, in accordance with the latter's
                instructions. In this case, in advance and in writing, the data
                processor shall identify the data to be communicated and the
                security measures to be applied in order to be able to proceed
                with the communication to the entity receiving the data.
              </p>
              <p>
                If the data processor has to transfer personal data to a third
                country or to an international organisation by virtue of EU law
                or that of the Member States that it is applicable to, it shall
                inform the data controller of this legal requirement in advance,
                unless this is prohibited by law for reasons of public interest.
              </p>
            </li>
            <li>
              <p>
                Authorisation is given to the data processor to sub-contract
                Microsoft Azure hosting services to Microsoft Ireland Operations
                Limited (data centre in Northern Europe), with registered
                address at One Microsoft Place, South County Business Park,
                Leopardstown, Dublin 18, Ireland. Contact: (+353) 1 295 3826 or
                at{' '}
                <a href="https://www.microsoft.com">
                  https://www.microsoft.com
                </a>
              </p>
              <p>
                Microsoft Ireland Operations Limited may sub-contract part of
                these duties to Microsoft Corporation, which is based in the
                USA. To regulate the international transfer of data between
                Microsoft Ireland Operations Limited and Microsoft Corporation,
                European Commission-approved standard contractual clauses have
                been formalised.
              </p>
              <p>
                The sub-contractor shall also have the status of data processor
                and is obliged to comply with all the obligations applicable to
                the data processor set forth herein and the instructions given
                by the data controller. The initial data processor is
                responsible for regulating the new relationship, meaning that
                the new data processor shall be subject to the same conditions
                (instructions, obligations, security measures etc.) and formal
                requirements in terms of the handling and processing of personal
                information and guaranteeing the rights of all those affected.
                In the event of any breach by the sub-contracted data processor,
                the initial data processor shall continue to be fully liable in
                terms of compliance with obligations.
              </p>
            </li>
            <li>
              Maintain its obligation to secrecy regarding the personal
              information to which it had access through this assignment, even
              after the conclusion of its object.
            </li>
            <li>
              Guarantee that those authorised to process personal expressly
              undertake in writing to respect confidentiality and comply with
              all corresponding security measures, of which they should be duly
              informed.
            </li>
            <li>
              Ensure that documentation covering compliance with the obligation
              outlined in the preceding paragraph is kept available for the data
              controller's inspection.
            </li>
            <li>
              Guarantee the necessary personal data protection training to those
              authorised to process the data in question.
            </li>
            <li>
              When those affected exercise their rights of access,
              rectification, suppression and opposition,restriction of
              processing, data portability and not be subject to automated
              individualised decisions by the data processor, this party must
              communicate this by email to the address given by the data
              controller. This notification should be given immediately and
              under no circumstances later than the working day following
              receipt of the request. Where necessary, it should also be
              accompanied by other information which may be relevant to the
              original request.
            </li>
            <li>
              In the event that the data processor takes responsibility for
              directly gathering the data pertaining to interested parties in
              compliance with the services to which it has been entrusted, it
              should fulfil all obligations stemming from the latter’s right to
              information protection at the time of gathering such data.
            </li>
            <li>
              <p>
                As soon as possible and always within 48 hours, the data
                processor shall notify the data controller via the corresponding
                email of any security breaches regarding personal data for which
                it is responsible and is aware of, providing all the relevant
                information for the documentation and communication of the
                incident.
              </p>
              <p>
                Notification will not be required where it is unlikely that this
                security breach constitutes a risk to the rights and freedoms of
                natural persons. If it is not possible to provide the aforesaid
                information simultaneously, and to the extent that it is not so,
                it should be gradually provided without undue delay.
              </p>
              <p>
                At the request of the data controller, the data processor shall
                give notification of any security breaches to the Data
                Protection Authority and, where appropriate, to the interested
                parties. If it is not possible to provide the aforesaid
                information simultaneously, and to the extent that it is not so,
                it should be gradually provided without undue delay.
              </p>
            </li>
            <li>
              Support shall be given to the data controller in the drawing up of
              impact assessments related to data protection, where appropriate.
            </li>
            <li>
              Support shall be given to the data controller when consulting the
              Data Protection Authority, where appropriate.
            </li>
            <li>
              The data controller shall be provided with all the information it
              requires in order to show compliance with security obligations and
              carry out audits and inspections to be undertaken by the data
              controller or another auditor that it appoints. When carrying out
              the aforesaid audits and having given the data controller at least
              five (5) calendar days’ notice, the data processor shall provide
              the data controller’s staff or the external personnel that it has
              authorised free access to:
              <ul>
                <li>
                  The data processor’s storage and other facilities related to
                  the provision of services
                </li>
                <li>
                  Documentation relating to the services in question, regardless
                  of the format or the platform on which these are provided.
                  This documentation should be accurate and be kept up-to-date
                </li>
                <li>
                  Those involved in any way in the provision of the services in
                  question
                </li>
              </ul>
              The result of these audits on the application by the data
              processor will allow corrective action to be taken to resolve the
              identified non-conformities and implement the necessary
              improvement plans.
            </li>
            <li>
              The technical and organisational security measures needed to
              guarantee the security of processed data shall be implemented, as
              outlined in Annex 4.2. Security measures. In all cases mechanisms
              should be implemented to:
              <ol>
                <li>
                  Guarantee the confidentiality, integrity, availability and
                  permanent resilience of processing systems and services.
                </li>
                <li>
                  Restore the availability and access to personal data quickly
                  in the event of a physical or technical incident.
                </li>
                <li>
                  Verify, evaluate and assess the effectiveness of the technical
                  and organisational measures implemented to guarantee the
                  safety of the processing on a regular basis.
                </li>
                <li>
                  Pseudonymise and encrypt personal data, where applicable.
                </li>
              </ol>
            </li>
            <li>
              Designate a data protection officer and notify their controller as
              to the identity and contact information thereof, if appropriate.
            </li>
            <li>
              In the event that the provision of the service ends, the data
              processor shall destroy all data on conclusion of the service. The
              data processor may keep a copy with the data in question duly
              blocked for as long as there may be responsibilities arising from
              the provision of the service.
            </li>
          </ol>
        </li>
        <li>
          <h4>The data controller’s obligations</h4>
          The data controller is responsible for:
          <ol>
            <li>
              Deliver the data processor with the data referred to in Clause 2
              of this document.
            </li>
            <li>
              Carry out an assessment of the impact on the protection of
              personal data of the processing operations that the data processor
              undertakes, where applicable.
            </li>
            <li>Carry out the corresponding prior consultations.</li>
            <li>
              Prior to and during the processing, ensure the data processor's
              compliance with the GDPR.
            </li>
            <li>
              Supervise data processing, including the undertaking of
              inspections and audits.
            </li>
          </ol>
        </li>
        <li>
          <h4>Responsibilities and guarantees</h4>
          <p>
            If the data processor or any of its sub-contractors fail to comply
            with this contract or any applicable regulations, it will be deemed
            to be a data controller and thus assume all corresponding direct and
            indirect responsibilities.
          </p>
          <p>
            Both parties agree that the breach of these obligations shall be
            considered grounds for the termination of the contract and the
            primary provision of services. Non-compliance by the data processor,
            its employees or those involved in the provision of the service on
            behalf of or at the behest of the data processor will thus give the
            data controller the right to terminate the contract and seek the
            corresponding compensation for damages and losses for the breach of
            contractual obligations.
          </p>
        </li>
        <li>
          <h4>Data from the intervening parties</h4>
          <p>
            The personal data included in this Contract and all other
            information exchanged by the Parties hereto facilitating the
            provision of services shall be processed by the counterparty with
            the purposes of allowing the development and control of and
            compliance with the aforesaid provision of services, with the basis
            for processing being the compliance and performance of the Contract,
            retaining all data for the time that it remains in force and until
            the responsibilities set forth herein have lapsed. Both parties
            undertake to transfer this information to the owners of the supplied
            data, notifying them that they may write to the addresses at the
            commencement of this Contract to exercise their data protection
            rights.
          </p>
        </li>
        <li>
          <h4>Applicable legislation</h4>
          <p>
            This Contract shall be governed pursuant to Spanish and European
            data protection law, as well as by the resolutions and directives
            issued by the Spanish Data Protection Agency and other public bodies
            with responsibility in this field.
          </p>
          <p>
            The parties hereto expressly waive their right to any other
            jurisdiction that may be applicable, agreeing to be bound by the
            courts and tribunal of the city of Lleida for the resolution of any
            disagreement that may arise through the interpretation or
            performance of this contract.
          </p>
          <p>
            In witness thereof and in acceptance of all contained herein, both
            parties sign this contract in duplicate, to one sole effect, in the
            city and on the date which features at the commencement of this
            document.
          </p>
        </li>
      </ol>
      <h4>ANNEX 4.1: DATA CATEGORIES</h4>
      <p>
        To provide the services that are the object of this commission, the data
        controller makes the following information available to the data
        processor:
      </p>
      <h4>Basic data</h4>
      <ul>
        <li>Name and surname(s)</li>
        <li>Street address</li>
        <li>Identity card number</li>
        <li>Image</li>
        <li>Email address</li>
        <li>Phone number</li>
      </ul>
      <h4>ANNEX 4.2: SECURITY MEASURES</h4>
      <h4>INFORMATION OF GENERAL INTEREST</h4>
      <p>
        Article 5.1 (f) of the General Data Protection Regulation (GDPR)
        establishes the need to implement appropriate security guarantees which
        protect against the unauthorised or unlawful processing and accidental
        loss, destruction or damage of personal information. This implies the
        establishment of appropriate technical or organisational measures that
        will ensure the integrity and confidentiality of personal data as well
        as the ability (Article 5.2) to demonstrate compliance therewith
        (accountability).
      </p>
      <p>
        In compliance with these protection of personal data regulations, below
        we outline some of the measures that we have implemented. These security
        measures are applicable to all our suppliers. All personnel at our
        company with access to personal data, either our own or pertaining to a
        third party, has to comply with these security measures.
      </p>
      <ol>
        <li>
          <h4>GENERAL MEASURES</h4>
          <ul>
            <li>Personal data protection policy document</li>
            <li>
              User functions and obligations. ICT resource regulations of use
            </li>
            <li>Signing of the confidentiality agreement with employees</li>
            <li>Training users in data protection issues</li>
            <li>
              Inventory of resources that may contain personal information (list
              of information assets – documents, software, personnel,
              facilities, services etc. of value to the organisation and which
              need to be protected from potential risks)
            </li>
            <li>
              Protocol for the exercise of the rights of affected parties
              implemented, with notification thereof given to employees
            </li>
          </ul>
        </li>
        <li>
          <h4>
            MEASURES TO ENSURE THE CONFIDENTIALITY, INTEGRITY, AVAILABILITY AND
            RESILIENCE OF THE PROCESSING SYSTEMS AND SERVICES
          </h4>
          <ul>
            <li>User identification and authentication system</li>
            <li>
              Guarantee password confidentiality, making third-party access
              impossible
            </li>
            <li>Access profiles</li>
            <li>
              Limits placed on the number of repeated unauthorised access:
              attempts
            </li>
            <li>IT systems access control</li>
            <li>Record of access to IT systems (access logs)</li>
            <li>Electronic signature systems (authentication)</li>
            <li>Firewall</li>
            <li>
              System offering analysis and supervision of vulnerabilities and
              threats
            </li>
            <li>Antivirus</li>
            <li>Server backup copies</li>
            <li>Website HTTPS protocol (SSL certificate)</li>
            <li>Data Processing Centre/server room physical access control</li>
            <li>Asset supervision policy</li>
            <li>Secure and confidential asset destruction</li>
            <li>
              Preventative measures implemented for a wide range of threats
              (fire, theft, floods, damp etc.)
            </li>
            <li>
              Physical support organisation methods (classification and
              organisation)
            </li>
            <li>
              Documentation access control (keys, biometric identification,
              smart cards, access codes etc.)
            </li>
            <li>Documentation access record</li>
            <li>Clean desk policy</li>
            <li>Server workflow policy</li>
            <li>Secure transfer and dispatch of documents on paper</li>
            <li>Shared printer rules of use</li>
            <li>
              Secure and confidential destruction of documents on paper and IT
              supports
            </li>
            <li>
              Document shredders and certified destruction of third-party
              contracts
            </li>
            <li>Avoid unattended screens</li>
            <li>Do not leave documents in areas with public access</li>
            <li>Password-protected screen blocking</li>
          </ul>
        </li>
        <li>
          <h4>
            MEASURES WHICH RESTORE THE AVAILABILITY AND ACCESS TO THE PERSONAL
            DETAILS IN A TIMELY MANNER IN THE EVENT OF A PHYSICAL OR TECHNICAL
            INCIDENT
          </h4>
          <ul>
            <li>
              Backup copy system ensures security and the recovery of personal
              data
            </li>
            <li>
              Incident identification, registration and supervision system
            </li>
            <li>
              Notification and supervision system covering personal data
              security breaches
            </li>
            <li>Uninterruptible Power Supply (UPS) system</li>
            <li>Business contingency and continuity plan</li>
            <li>Business continuity plan</li>
            <li>ICT contingency and continuity plan</li>
            <li>Disaster recovery plan</li>
          </ul>
        </li>
        <li>
          <h4>
            MEASURES TO GUARANTEE PROCESSING SECURITY VERIFICATION AND
            EFFECTIVENESS ASSESSMENT
          </h4>
          <ul>
            <li>Annual External audit</li>
            <li>Monthly Internal audit</li>
            <li>Annual internal compliance controls</li>
            <li>Annual review of the data protection policy</li>
          </ul>
        </li>
      </ol>
    </div>
  </div>
);

export default TermsOfService;
