import { drawers } from './drawers';
import { forms } from './forms';
import { layout } from './layout';
import { modals } from './modals';
import { pageActions } from './page-actions';

export const base = {
  ...drawers,
  ...forms,
  ...layout,
  ...modals,
  ...pageActions
};
