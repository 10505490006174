import { getAreasCompact } from './areas';
import { sendContactForm } from './contact';
import { getCountriesCompact } from './countries';

export const API = {
  areas: {
    getCompact: getAreasCompact
  },
  contact: {
    sendEmail: sendContactForm
  },
  countries: {
    getCompact: getCountriesCompact
  }
};
