import React from 'react';

const CookiesPolicy: React.FC = () => {
  return (
    <div className="block block__single block__single__legal">
      <div className="legal cookies-policy">
        <h2>Cookies Policy</h2>
        <h3>1. INTRODUCTION</h3>
        <p>
          The website of <b>TOT COMERCIAL, S.A.</b> uses own cookies and similar
          technologies. A cookie is a file unloaded in your computer when
          accessing some web sites. The cookies allows to a web site, among
          other purposes, guarantee the correct operation of the page, allow the
          user a quick access to the selected services, save and recover
          information about the browsing habits of a user or their equipment and
          even, depending on the information and the way to use their equipment,
          can be used to recognise the user. The cookies are only associated to
          an anonymous user and their computer or device and do not give any
          reference that allows to know their personal data, except any express
          permission from them.
        </p>
        <p>
          The user can, in every moment, accept or reject the installed cookies
          that are not strictly necessary for the correct operation of the web
          site and the access to its services through the settings panel of
          cookies given in our web site. It is possible to configure your
          browser any time without affecting the access to the User to the
          contents. However, we inform you that to reject cookies can reduce the
          good operation of the web site.
        </p>
        <h3>2. AFFECTED COOKIES BY THE RULES AND EXEMPT COOKIES</h3>
        <p>
          According the EU directive, the cookies that requires the consent
          informed by the user are the cookies of analytics, advertising and
          affiliation, being exempted those technical and required for the
          operation of the web site or or the provision of services expressly
          requested by the user.
        </p>
        <h3>3. TYPE OF COOKIES</h3>
        <p>
          a) According to <b>the entity that manages the cookies</b>, there are{' '}
          <b>own cookies</b> (those that are sent to the user kit from an
          equipment or domain managed by the own editor and from which the
          service asked by the user is given) and <b>third-party cookies</b>{' '}
          (those that are sent to the user kit from an equipment or domain that
          is not managed by the editor but by other entity that handles the data
          through the cookies).
        </p>
        <p>
          b) ccording to <b>the time they are active</b>, there are the{' '}
          <b>session ones</b> (designed to ask for and save data while the user
          access a web site) and the <b>persistent ones</b> (where the data are
          saved in the PC and the cookie responsible can access and handle them
          during a defined period -minutes or several years).
        </p>
        <p>
          c) According to the <b>purpose</b> for which they process the
          information they collect, they can be:
          <ul>
            <li>
              <b>Technical cookies</b> (required to use the web site and the
              provision of the contracted service),
            </li>
            <li>
              <b>Customisation cookies</b> (that store a user’s browser
              preferences, such as preferred language and display format),
            </li>
            <li>
              <b>Analytics cookies</b> (to collect information about users’ site
              activities and provide custom browsing experiences),
            </li>
            <li>
              <b>Advertising cookies</b> (to gather information from you on your
              device to display advertisements to you based on relevant topics
              that interest you)
            </li>
            <li>
              <b>Affiliate cookies</b> (that allow you to track visits from
              other web sites, with which the web site establishes an
              affiliation contract).
            </li>
          </ul>
        </p>
        <h3>4. EXEMPT COOKIES:</h3>
        <p>
          <ul>
            <li>
              those that only allow the communication between the user equipment
              and the network.
            </li>
            <li>
              those strictly necessary to give a service expressly required by
              the user.
            </li>
          </ul>
        </p>
        <p>
          Likewise, the GT29, in its Opinion 4/201210, has interpreted that
          among the excepted cookies would be those whose purpose is:
        </p>
        <ul>
          <li>
            User-input cookies (The user-input cookies are most often
            “first-party” session cookies that expire when the user’s session on
            the website is over. They are typically used to remember the user’s
            input when filling online forms or keeping track of the items added
            to the shopping cart.)
          </li>
          <li>
            Authentication cookies (When the users log in, the authentication
            cookies are used to identify them).
          </li>
          <li>
            User-centric security cookies (they are used for increasing the
            security of the service explicitly requested by the user. For e.g.
            cookies that detect repeated failed login attempts on a website).
          </li>
          <li>Multimedia player session cookies</li>
          <li>Load balancing session cookies</li>
          <li>
            UI customization cookies (used to store a user’s preference related
            to the user interface of service across web pages)
          </li>
          <li>
            Social plug-in content sharing cookies (The exception is only
            applied to users who have decided to be logged in).
          </li>
        </ul>
        <h3>5. TYPE OF COOKIES USED FOR THIS WEB SITE</h3>
        <table>
          <tr>
            <th>Name</th>
            <th>Purpose</th>
            <th>Caducity</th>
            <th>Type of cookie</th>
          </tr>
          <tr className="table-owner">
            <td colSpan={4}>Youtube</td>
          </tr>
          <tr>
            <td>CONSENT</td>
            <td>Save the user state respect to their cookie selection.</td>
            <td>2 years</td>
            <td>Advertising</td>
          </tr>
          <tr>
            <td>YSC</td>
            <td>Save and track the interaction.</td>
            <td>Session</td>
            <td>Advertising</td>
          </tr>
          <tr>
            <td>VISITOR_INFO1_LIVE</td>
            <td>
              Save user information for recommendations and advertising
              purposes.
            </td>
            <td>6 months</td>
            <td>Advertising</td>
          </tr>
          <tr className="table-owner">
            <td colSpan={4}>Own</td>
          </tr>
          <tr>
            <td>dp_consent_cookies</td>
            <td>Save the preferences of cookie consent.</td>
            <td>1 year</td>
            <td>Necesssary</td>
          </tr>
          <tr>
            <td>dp_account_token</td>
            <td>Save the user identification to remember the access.</td>
            <td>1 year</td>
            <td>Necesssary</td>
          </tr>
        </table>
        <h3>6. WHICH OTHER SIMILAR TECHNOLOGIES ARE WE USING?</h3>
        <p>
          We use the feature called "localStorage". For local storage we
          understand any file created in your device because of browsing in our
          web site. This information is saved in our web browser and is not sent
          to <b>TOT COMERCIAL, S.A.</b> or any third party.
        </p>
        <p>
          To delete "localStorage" the user must delete the browsing history. If
          you do not want to use the local storage, you have to modify the
          settings in your browser configuration in any moment. In this case, we
          must inform you that the features of our website and App web will be
          limited or will no longer be available and you will not be able to
          access your user.
        </p>
        <h3>7. REVOCATION</h3>
        <p>
          At any time you can access your browser settings by accepting or
          rejecting all cookies, or select those that the installation supports
          and those that it does not, following one of the following procedures,
          which depends on the browser you use:
        </p>
        <p>
          Google Chrome (in the Tool Menu): Configuration{' > '}Show advanced
          options{' > '}Privacy (Configuration of content){' > '}Cookies:
          <br />
          <a
            target="_blank"
            href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
          >
            https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en
          </a>
        </p>
        <p>
          Microsoft Internet Explorer (in the Tool Menu): Internet Options
          {' > '}Privacy{' > '}Advanced:
          <br />
          <a
            target="_blank"
            href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
          >
            https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
          </a>
        </p>
        <p>
          Firefox: Options{' > '}Privacy{' > '}Cookies:
          <br />
          <a
            target="_blank"
            href="https://support.mozilla.org/en-US/kb/third-party-cookies-firefox-tracking-protection"
          >
            https://support.mozilla.org/en-US/kb/third-party-cookies-firefox-tracking-protection
          </a>
        </p>
        <p>
          Safari, iPad y iPhone: Preferences{' > '}Privacy:
          <br />
          <a
            target="_blank"
            href="https://support.apple.com/en-us/guide/safari/sfri40732"
          >
            https://support.apple.com/en-us/guide/safari/sfri40732
          </a>
        </p>
        <p>
          Opera: Configuration{' > '}Options{' > '}Advanced{' > '}Cookies:
          <br />
          <a
            target="_blank"
            href="https://help.opera.com/en/latest/web-preferences/#cookies"
          >
            https://help.opera.com/en/latest/web-preferences/#cookies
          </a>
        </p>
        <p>
          These browsers are subject to changes or modifications, so we cannot
          guarantee that they will fully comply with the version of your
          browser. It may also be that you use another browser not covered by
          these links such as Konqueror, Arora, Flock, etc. To avoid these
          mismatches, you can access them directly from your browser options,
          usually in the «Options» menu in the «Privacy» section. (Please
          consult your browser's help for more information).
        </p>

        <h3>8. CHANGES IN THIS COOKIES POLICY</h3>
        <p>
          <b>TOT COMERCIAL, S.A.</b> reserves the right to modify this policy to
          adapt it to new legislation or jurisprudence.
        </p>

        <h3>9. DEACTIVATION / ACTIVATION AND ELIMINATION OF COOKIES</h3>
        <p>
          To restrict or block the cookies, it is done through the browser
          settings.
        </p>
        <p>
          If you do not want websites to place any cookies on your computer, you
          can adjust your browser settings so that you are notified before any
          cookies are placed. Similarly, you can adjust the settings so that the
          browser rejects all cookies, or only third-party cookies. You can also
          delete any cookies that are already on your computer. Remember that
          the configuration of each browser and equipment you use must be
          adapted separately.
        </p>
        <p>
          Remember that if you do not want to receive cookies, we can no longer
          guarantee that our website will work properly. Some features of the
          site may be lost and you may no longer be able to view certain
          websites. Also, refusing cookies does not mean that you will no longer
          see advertisements. The ads will simply not be tailored to your
          interests and will be repeated more often.
        </p>
        <p>
          Each browser has a different method to adapt the settings. If
          necessary, consult the help function in your browser to establish the
          correct configuration.
        </p>
        <p>
          To disable the cookies in the mobile phone, consult the device manual
          to get more information.
        </p>
        <p>
          You can get more information about cookies in the Internet,{' '}
          <a target="_blank" href="http://www.aboutcookies.org/">
            http://www.aboutcookies.org/
          </a>
          .
        </p>
        <p>
          Having in mind how the Internet and websites work, we do not always
          have information about cookies placed by third parties through our
          website. This applies especially to cases where the web page contains
          what are called embedded elements: texts, documents, images or short
          films that are stored elsewhere, but are displayed on or through our
          website.
        </p>
        <p>
          Therefore, if you come across this type of cookies on this website and
          they are not listed in the above list, please let us know. Or contact
          the third party directly to request information about the cookies it
          places, the purpose and duration of the cookie, and how it has
          guaranteed your privacy.
        </p>
        <h3>
          10. INFORMATION REGARDING TO THE PERSONAL DATA TREATMENT (ART.13 RGPD)
        </h3>
        <h4>Who is responsible for the processing of your personal data?</h4>
        <p>
          <b>TOT COMERCIAL, S.A.</b> is responsible for the processing of your
          personal data, placed in Partida Horta d'Amunt, s/n, 25600, Balaguer
          and VAT no. A25031154, with email:{' '}
          <a target="_blank" href="mailto:lopd@gespasa.es">
            lopd@gespasa.es
          </a>
        </p>
        <h4>Why and how do we process your personal data?</h4>
        <p>
          The information collected through own cookies (session and user input
          cookies) are typically used to track user actions when filling out
          online forms on multiple pages, or as a shopping cart to track
          purchases to track the items that the user has selected by pressing a
          button, or to customize the user interface when visiting the web site.
        </p>
        <p>
          The information collected through third-party cookies is as follows:
          <ul>
            <li>
              Through the analytics cookies your navigation habits are analysed
              to improve the services;
            </li>
            <li>
              Through behavioral cookies, information is collected on user
              preferences to manage advertising spaces in the most efficient way
              possible;
            </li>
            <li>
              and Through advertising cookies, the performance of advertisements
              is measured and recommendations regarding products based on
              statistical data are provided.
            </li>
          </ul>
        </p>
        <p>
          To see exactly how cookies are used, see the table on the use of
          cookies on this website.
        </p>
        <h4>On what legal ground(s) do we process your personal data?</h4>
        <p>
          The legitimacy for the processing of your personal data is given by
          your consent, granted at the time of contacting us, for the use of
          analytical, behavioral and advertising cookies; For session or
          personalization cookies, the legitimacy for the treatment is given by
          the legitimate interest of the provider (responsible for the
          treatment).
        </p>
        <h4>To whom do we communicate your data?</h4>
        <p>
          Data will not be transferred or communicated to third parties, except
          by legal obligation or to the third parties indicated in the case of
          the use of cookies managed by them.
        </p>
        <h4>How long do we keep your personal data?</h4>
        <p>
          The period of conservation of the collected data is indicated in the
          table on the use of cookies on this website.
        </p>
        <h4>What are your rights and how can you exercise them?</h4>
        <p>
          The rights that protect the user are those of access, rectification,
          deletion, portability of the same, withdrawal of consent (without
          affecting the legality of the treatment granted prior to its
          withdrawal), opposition to treatment, limitation (in certain
          circumstances) and to file a claim with the control authority (the
          Spanish Data Protection Agency,{' '}
          <a target="_blank" href="http://www.aepd.es">
            www.aepd.es
          </a>
          ).
        </p>
      </div>
    </div>
  );
};

export default CookiesPolicy;
