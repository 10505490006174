import dayjs from 'dayjs';

const functionalCookies = ['dp_consent_cookies', 'dp_account_token'];

export function getCookie(cname: string): string | undefined {
  const name = cname + '=';
  const ca = decodeURIComponent(document.cookie).split(';');

  for (const aux of ca) {
    let c = aux;
    while (c.startsWith(' ')) {
      c = c.substring(1);
    }
    if (c.startsWith(name)) {
      return c.substring(name.length, c.length);
    }
  }

  return undefined;
}

export function setCookie(
  cname: string,
  cvalue: string | undefined,
  exdays = 365
): void {
  if (
    functionalCookies.includes(cname) ||
    getCookie('dp_consent_cookies') === '11'
  ) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = `expires=${exdays > 0 ? d.toUTCString() : undefined}`;

    if (!!!cvalue) {
      expires = `expires=${dayjs().subtract(1, 'day').toDate().toUTCString()}`;
      cvalue = '';
    }

    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }
}
