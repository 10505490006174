import React from 'react';
import useIntl from '../hooks/useIntl';
import CookiesPolicyEs from './es/CookiesPolicy';
import CookiesPolicyEn from './en/CookiesPolicy';

const CookiesPolicy: React.FC = () => {
  const { locale } = useIntl();

  switch (locale) {
    case 'es':
      return <CookiesPolicyEs />;
    default:
      return <CookiesPolicyEn />;
  }
};

export default CookiesPolicy;
