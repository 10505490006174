export function templateValidateMessages(t: Function): any {
  return {
    default: t({ id: 'FORMS.DEFAULT_VALIDATION.DEFAULT' }),
    required: t({ id: 'FORMS.DEFAULT_VALIDATION.REQUIRED' }),
    enum: t({ id: 'FORMS.DEFAULT_VALIDATION.ENUM' }),
    whitespace: t({ id: 'FORMS.DEFAULT_VALIDATION.WHITESPACE' }),
    date: {
      format: t({ id: 'FORMS.DEFAULT_VALIDATION.DATE_FORMAT' }),
      parse: t({ id: 'FORMS.DEFAULT_VALIDATION.DATE_FORMAT' }),
      invalid: t({ id: 'FORMS.DEFAULT_VALIDATION.DATE_FORMAT' })
    },
    types: {
      string: t({ id: 'FORMS.DEFAULT_VALIDATION.TYPE' }),
      method: t({ id: 'FORMS.DEFAULT_VALIDATION.TYPE' }),
      array: t({ id: 'FORMS.DEFAULT_VALIDATION.TYPE' }),
      object: t({ id: 'FORMS.DEFAULT_VALIDATION.TYPE' }),
      number: t({ id: 'FORMS.DEFAULT_VALIDATION.TYPE' }),
      date: t({ id: 'FORMS.DEFAULT_VALIDATION.TYPE' }),
      boolean: t({ id: 'FORMS.DEFAULT_VALIDATION.TYPE' }),
      integer: t({ id: 'FORMS.DEFAULT_VALIDATION.TYPE' }),
      float: t({ id: 'FORMS.DEFAULT_VALIDATION.TYPE' }),
      regexp: t({ id: 'FORMS.DEFAULT_VALIDATION.TYPE' }),
      email: t({ id: 'FORMS.DEFAULT_VALIDATION.TYPE' }),
      url: t({ id: 'FORMS.DEFAULT_VALIDATION.TYPE' }),
      hex: t({ id: 'FORMS.DEFAULT_VALIDATION.TYPE' })
    },
    string: {
      len: t({ id: 'FORMS.DEFAULT_VALIDATION.STRING_LEN' }),
      min: t({ id: 'FORMS.DEFAULT_VALIDATION.STRING_MIN' }),
      max: t({ id: 'FORMS.DEFAULT_VALIDATION.STRING_MAX' }),
      range: t({ id: 'FORMS.DEFAULT_VALIDATION.STRING_RANGE' })
    },
    number: {
      len: t({ id: 'FORMS.DEFAULT_VALIDATION.NUMBER_LEN' }),
      min: t({ id: 'FORMS.DEFAULT_VALIDATION.NUMBER_MIN' }),
      max: t({ id: 'FORMS.DEFAULT_VALIDATION.NUMBER_MAX' }),
      range: t({ id: 'FORMS.DEFAULT_VALIDATION.NUMBER_RANGE' })
    },
    array: {
      len: t({ id: 'FORMS.DEFAULT_VALIDATION.ARRAY_LEN' }),
      min: t({ id: 'FORMS.DEFAULT_VALIDATION.ARRAY_MIN' }),
      max: t({ id: 'FORMS.DEFAULT_VALIDATION.ARRAY_MAX' }),
      range: t({ id: 'FORMS.DEFAULT_VALIDATION.ARRAY_RANGE' })
    },
    pattern: {
      mismatch: t({ id: 'FORMS.DEFAULT_VALIDATION.PATTERN_MISMATCH' })
    }
  };
}
