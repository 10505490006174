import React from 'react';
import { message } from 'antd';
import { MessageType } from 'antd/lib/message/interface';
import { uniqBy } from 'lodash';
import { ErrorMessage } from '../models/error-message.model';

export const displayErrors = (
  errors: ErrorMessage[],
  model: string,
  t: Function,
  fieldTranslate: Function
): MessageType | undefined => {
  if (errors.length === 0) return;

  // This removes the sub-fields from the field value. E.g.
  // `card.number` becomes `card`, this is used to ease translations.
  //
  const formattedErrors: ErrorMessage[] = errors.map((error) => {
    const { field, ...restProps } = error;
    const formattedField = field?.split('.')[0];

    return { ...restProps, field: formattedField };
  });

  if (formattedErrors.some(({ field }: ErrorMessage) => field !== 'base')) {
    message.error(
      <span>
        {`${t({ id: 'FORM_ERRORS' })} `}
        {uniqBy(formattedErrors, 'field')
          .filter(({ field }: ErrorMessage) => field !== 'base')
          .map(
            ({ field }: ErrorMessage) => fieldTranslate(model, field) as string
          )
          .join(', ')}
      </span>
    );
  }

  if (formattedErrors.some(({ field }: ErrorMessage) => field === 'base')) {
    message.error(
      <span>
        {`${t({ id: 'FORM_BASE_ERRORS' })} `}
        {uniqBy(formattedErrors, 'field')
          .filter(({ field }: ErrorMessage) => field === 'base')
          .map(({ message: errorMessage }: ErrorMessage) => errorMessage)
          .join(', ')}
      </span>
    );
  }
};
